import { Component, Vue } from "vue-property-decorator";
import Swal, { SweetAlertOptions } from "sweetalert2";
import { IRetornoBuscaNotificacoes } from "../parse/cloud.interfaces";
import { ETipoNotificacao } from "./notificacao.interfaces";


@Component
export default class Notificacao extends Vue {

  carregouNotificacoes = false;

  private notificacoes: IRetornoBuscaNotificacoes = {};

  logErro(e: Error) {
    console.trace(e);
  }

  async sessaoExpirou() {
    await this.$parse.logOut();
    window.location.reload()
  }

  mensagemErro(e: Error) {
    if (e?.message?.includes("token")) this.sessaoExpirou();
    // console.trace(e?.message);
  }

  async popup(params: { titulo?: string; texto?: string, html?: string }) {
    const {
      texto: text = "Algo deu errado",
      html
    } = params;

    const { isConfirmed } = await Swal.fire({
      text,
      html,
      showCloseButton: true,
      confirmButtonText: "ok",
      confirmButtonColor: '#f9136b',
    });

    return isConfirmed
  }

  sucesso(params: { titulo?: string; texto?: string, html?: string }) {
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Sucesso',
      text: params.texto,
      showConfirmButton: false,
      timer: 3000
    })
  }

  erro(params: { titulo?: string; texto?: string, html?: string }) {
    Swal.fire({
      position: 'top-end',
      icon: 'error',
      title: 'Erro',
      text: params.texto,
      showConfirmButton: false,
      timer: 4000,
      width: '300px',
    })
  }

  async confirmar(params: { titulo?: string; texto?: string, html?: string, textoSim?: string, textoNao?: string }) {
    const {
      titulo: title = "Tem certeza?",
      texto: text = "Deseja confirmar essa operação?",
      textoSim: confirmButtonText = "Sim",
      textoNao: cancelButtonText = "Não",
      html
    } = params;

    const { isConfirmed } = await Swal.fire({
      title,
      text,
      html,
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonText,
      cancelButtonText,
      confirmButtonColor: '#f9136b',
      customClass: {
        confirmButton: 'fw-bold'
      }
    });
    return isConfirmed
  }

  async confirmarEEsperar(params: {
    titulo?: string;
    texto?: string,
    html?: string,
    confirmou?: () => any,
    recusou?: () => any,
  }) {
    const {
      titulo: title = "Tem certeza?",
      texto: text = "Deseja confirmar essa operação?",
      html,
      confirmou,
      recusou,
    } = params;


    const swalParams: SweetAlertOptions = {
      title,
      text,
      html,
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      confirmButtonColor: '#f9136b',
      customClass: {
        confirmButton: 'fw-bold'
      },
      allowOutsideClick: () => !Swal.isLoading()
    }

    if (confirmou !== undefined) {
      swalParams.showLoaderOnConfirm = true;
      swalParams.preConfirm = confirmou
    }

    if (recusou !== undefined) {
      swalParams.showLoaderOnDeny = true;
      swalParams.preDeny = recusou
    }

    const { isConfirmed } = await Swal.fire(swalParams)//.catch(e => { throw e })
    return isConfirmed
  }

  async carregaNotificacoes() {
    if (this.carregouNotificacoes) return;

    const meusDados = await this.$utils.getMeusDados();

    if (!meusDados) return;

    this.notificacoes = await this.$cloud.buscaNotificacoes()

    // console.log(this.notificacoes);

    this.carregouNotificacoes = true;
  }

  temNotificacao(notificacao: keyof IRetornoBuscaNotificacoes) {
    return this.notificacoes[notificacao]
  }

  notificaNoMenu(notificacao: ETipoNotificacao) {
    this.notificacoes[notificacao] = true;

    this.$globalEvent.emit('atualizarNotificacoesMenu')
  }
}