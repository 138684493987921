
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class Cabecalho extends Vue {
  async logout() {
    this.$emit("logout");
  }
}
