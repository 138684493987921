
import { ParseObject } from "@/plugin/parse/parse.interfaces";
import { Component, Vue, Watch } from "vue-property-decorator";
import CarregandoInfo from "@/components/CarregandoInfo.vue";
import Error404 from "@/components/Error404.vue";
import TituloPrincipal from "@/components/TituloPrincipal.vue";

import ClubesDistrito from "./clubes/ClubesDistrito.vue";
import PendenciasDistrito from "./pendencias/PendenciasDistrito.vue";
import InformacoesDistrito from "./informacoes/InformacoesDistrito.vue";

@Component({
  components: {
    CarregandoInfo,
    Error404,
    TituloPrincipal,
    ClubesDistrito,
    PendenciasDistrito,
    InformacoesDistrito,
  },
})
export default class Distrito extends Vue {
  carregando = false;

  distrito: ParseObject | null | void = null;
  meuDistrito = false;

  distritoNaoExiste = false;

  rota = "";

  tituloPrincipal = "";
  tituloSecundario = "";

  /**
   * Carrega o objeto do distrito com base na url.
   *
   * Se a url não possuir o parametro id, significa que está acessando o 'meudistrito', caso contrário, está acessando um outro distrito.
   *
   * Após pegar o objeto do distrito, carrega todas as informações necessárias para exibição.
   *
   * O decorator @watch garante que quando o usuário estiver com esse componente aberto (já acessando as informações de um distrito)
   * e clicar para visualizar outro distrito, que o Vue recarrega as informações do novo distrito.
   */
  @Watch("$route", { immediate: true, deep: true })
  async carregaDadosDistrito() {
    this.carregando = true;

    const numeroDistrito = Number(this.$route.params.id_distrito);
    this.rota = this.$route.name || "";

    if (this.$route.name?.startsWith("meudistrito")) {
      this.distrito = await this.$utils.getMeuDistrito();
      this.meuDistrito = true;
    }

    if (numeroDistrito) {
      this.distrito = await this.$parse
        .getFirst({
          className: "Distrito",
          where: [["numero", "=", numeroDistrito]],
          include: ["*"],
        })
        .catch((e) => this.$notificacao.mensagemErro(e));
    }

    if (!this.distrito) {
      this.distritoNaoExiste = true;
      this.carregando = false;
      return;
    }

    let dadosDistrito = this.$cache.obter(`dados-distrito-${this.distrito.id}`);

    if (!dadosDistrito) {
      dadosDistrito = await this.$cloud.buscarDadosDistrito(this.distrito.id);
      this.$cache.salvar(`dados-distrito-${this.distrito.id}`, dadosDistrito);
    }

    this.tituloPrincipal = "Distrito " + this.distrito.get("numero");

    if (this.rota.endsWith("clubes")) {
      this.tituloSecundario = " - Clubes";
    }

    if (this.rota.endsWith("pendencias")) {
      this.tituloSecundario = " - Pendências";
    }
    this.carregando = false;
  }
}
