
import { Component, Vue } from "vue-property-decorator";
import TituloPrincipal from "@/components/TituloPrincipal.vue";
import Section from "@/components/ui/Section.vue";
import Divider from "@/components/ui/Divider.vue";
import Loader from "@/components/Loader.vue";
import CardUsuario from "@/components/CardUsuario.vue";
import SecaoVazia from "@/components/SecaoVazia.vue";
import { ParseObject } from "@/plugin/parse/parse.interfaces";

@Component({
  components: {
    TituloPrincipal,
    Section,
    Divider,
    Loader,
    SecaoVazia,
    CardUsuario,
  },
})
export default class OmirBrasil extends Vue {
  carregando = true;
  cargosOmir: ParseObject[] = [];
  anosRotarios: any[] = [];
  anoRotario = "";
  created() {
    const anoRotario = this.$route.params.ano_rotario;
    this.anoRotario = anoRotario ?? this.$utils.getAnoRotario();
    const fundacaoOmir = new Date();
    fundacaoOmir.setFullYear(1992);

    const hoje = new Date();
    const anosRotarios = this.$utils.getIntervaloAnosRotarios(
      fundacaoOmir,
      hoje
    );

    this.anosRotarios = anosRotarios.map((anoRotario) => ({
      text: `Equipe ${anoRotario}`,
      value: anoRotario,
      id: anoRotario,
    }));

    this.buscaEquipe();
  }

  async buscaEquipe() {
    this.carregando = true;
    // força a renderização quando troca o ano rotário
    setTimeout(async () => {
      this.cargosOmir = this.$cache.obter(`equipe-omir-${this.anoRotario}`);

      if (!this.cargosOmir) {
        this.cargosOmir = await this.$cloud.buscarCargosOmir(this.anoRotario);
        this.$cache.salvar(`equipe-omir-${this.anoRotario}`, this.cargosOmir);
      }

      this.carregando = false;
    }, 1000);
  }
}
