var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('TituloPrincipal',{attrs:{"titulo":_vm.estaCriandoNovoUsuario ? 'Cadastrando novo(a) associado(a)' : 'Editando dados do usuário'}}),(!_vm.carregando && _vm.dadosEdicao)?_c('FormulateForm',{on:{"submit":function($event){return _vm.salvarAlteracoes()}},scopedSlots:_vm._u([{key:"default",fn:function({ hasErrors }){return [(!_vm.salvando)?_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[_c('Button',{staticClass:"me-2",attrs:{"color":"cranberry"},on:{"click":function($event){return _vm.cancelaAlteracoes()}}},[_vm._v("Cancelar")]),_c('Button',{attrs:{"color":"cranberry","type":"submit"},on:{"click":function($event){_vm.clicouEmSalvar = true}}},[_vm._v("Salvar")])],1):_vm._e(),(_vm.salvando)?_c('div',[_c('Loader',{staticClass:"mx-3 my-2 position-relative"})],1):_vm._e(),_c('Section',{staticClass:"mt-3"},[_c('b-alert',{attrs:{"show":_vm.mostrarErro,"variant":"danger"},on:{"dismissed":function($event){_vm.mostrarErro=0}}},[_vm._v(" "+_vm._s(_vm.erro)+" ")]),(hasErrors && _vm.clicouEmSalvar)?_c('p',{staticClass:"text-end my-2 text-danger"},[_vm._v("* O formulário de cadastro contém erros")]):_vm._e(),_c('div',{staticClass:"col-12"},[_c('h3',{staticClass:"text-cranberry"},[_vm._v("Informações Pessoais")])]),_c('h4',{staticClass:"mt-4"},[_vm._v("Nome *")]),_c('FormulateInput',{staticClass:"w-100",attrs:{"disabled":_vm.salvando,"type":"text","name":"nome","value":_vm.dadosEdicao.get('nome') || '',"placeholder":"Digite aqui o nome","validation":"^bail|required","validation-messages":{
          required: 'O nome é obrigatório',
        }}}),_c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col-md-6"},[_c('h4',{staticClass:"mt-4 mb-2"},[_vm._v("Apelido")]),_c('FormulateInput',{staticClass:"w-100",attrs:{"disabled":_vm.salvando,"type":"text","name":"apelido","value":_vm.dadosEdicao.get('apelido') || '',"placeholder":"Apelido","validation":"^optional"}})],1),_c('div',{staticClass:"col-md-6"},[_c('h4',{staticClass:"mt-4 mb-2"},[_vm._v("Data de Nascimento")]),_c('FormulateInput',{staticClass:"w-100",attrs:{"disabled":_vm.salvando,"type":"date","name":"dataNascimento","value":_vm.dadosEdicao.get('dataNascimento') ? _vm.dadosEdicao.get('dataNascimento').toISOString().split('T')[0] : '',"placeholder":"Digite a data de nascimento","validation":"^optional"}})],1),_c('div',{staticClass:"col-md-6"},[_c('h4',{staticClass:"mt-4 mb-2"},[_vm._v("Email *")]),_c('FormulateInput',{staticClass:"w-100",attrs:{"disabled":_vm.salvando,"type":"email","name":"email","value":_vm.dadosEdicao.get('email') || '',"placeholder":"Digite o e-mail","validation":"required","validation-messages":{
              required: 'O email é obrigatório',
            }}})],1),(!_vm.estaCriandoNovoUsuario)?_c('div',{staticClass:"col-md-6"},[_c('h4',{staticClass:"mb-2 mt-4"},[_vm._v("Senha")]),(!_vm.emailRefefinicaoDeSenhaEnviado)?_c('Button',{staticClass:"me-2",attrs:{"color":"cranberry"},on:{"click":function($event){return _vm.redefinirSenha()}}},[_vm._v("Redefinir Senha")]):_vm._e(),(_vm.emailRefefinicaoDeSenhaEnviado)?_c('div',{staticClass:"d-flex align-items-center"},[_c('p',{staticClass:"mb-0 text-success"},[_vm._v("Um e-mail de redefinição de senha foi enviado para "+_vm._s(_vm.dadosEdicao.get('email')))])]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"col-md-6"},[_c('h4',{staticClass:"mt-4 mb-2"},[_vm._v("Celular ")]),_c('FormulateInput',{staticClass:"w-100",attrs:{"disabled":_vm.salvando,"type":"text","name":"celular","value":_vm.dadosEdicao.get('celular') || '',"placeholder":"XX X XXXX-XXXX","validation":"^bail|number|optional","validation-messages":{
              number: 'O celular digitado não é um numero',
            }}})],1),_c('div',{class:_vm.estaCriandoNovoUsuario ? 'col-12' : 'col-md-6'},[_c('h4',{staticClass:"mb-2 mt-4"},[_vm._v("Profissão")]),_c('FormulateInput',{staticClass:"w-100",attrs:{"disabled":_vm.salvando,"type":"text","name":"profissao","value":_vm.dadosEdicao.get('profissao') || '',"validation":"^optional","placeholder":"Digite aqui a profissão"}})],1),(_vm.estaCriandoNovoUsuario)?[_c('Divider'),_c('div',{staticClass:"col-12"},[_c('h3',{staticClass:"text-cranberry"},[_vm._v("Informações da associação")])]),_c('div',{staticClass:"col-md-6"},[_c('h4',{staticClass:"mt-4 mb-2"},[_vm._v("Posse *")]),_c('FormulateInput',{staticClass:"w-100",attrs:{"disabled":_vm.salvando,"type":"date","name":"posse","validation":"^required","validation-messages":{
              required: 'A data de posse é obrigatória',
            }}})],1),_c('div',{staticClass:"col-md-6"},[_c('h4',{staticClass:"mt-4 mb-2"},[_vm._v("Fundador(a)")]),_c('FormulateInput',{staticClass:"w-100",attrs:{"name":"fundador","disabled":_vm.salvando,"type":"select","options":['Não', 'Sim']}})],1)]:_vm._e()],2),(!_vm.estaCriandoNovoUsuario)?_c('div',{staticClass:"row"},[_c('Divider'),_c('div',{staticClass:"col-md-6 mx-lg-auto"},[_c('h4',{staticClass:"mt-4 mb-2"},[_vm._v("Foto de Perfil")]),_c('div',{on:{"click":function($event){_vm.editouFoto = true}}},[_c('Cropper',{attrs:{"src":_vm.fotoUrl,"stencil-props":{
                aspectRatio: 1/1
              },"default-size":_vm.defaultSize},on:{"change":function($event){return _vm.imagemCortada($event)}}})],1),_c('FormulateInput',{staticClass:"w-100 mt-2",attrs:{"disabled":_vm.salvando,"type":"file","name":"foto","help":"Selecione um arquivo nos formatos png, jpg ou jpeg","validation":"^mime:image/jpeg,image/jpg,image/png,image/webp|optional","validation-messages":{
              mime: 'O arquivo deve ser dos seguintes formatos: jpeg. jpg, png ou webp',
            }},on:{"input":function($event){return _vm.carregaImagem($event)}}})],1)],1):_vm._e(),_c('div',{staticClass:"row"},[_c('Divider'),_c('div',{staticClass:"col-md-6 mt-4"},[_c('h4',{staticClass:"mt-4 mb-2"},[_vm._v("Histórico Rotário")]),_c('FormulateInput',{staticClass:"mt-4",attrs:{"type":"group","name":"historicoRotario"}},_vm._l((_vm.historicoRotario),function(label,id){return _c('div',{key:id,staticClass:"d-flex align-items-center mb-3"},[_c('FormulateInput',{attrs:{"disable":_vm.salvando,"type":"checkbox","name":id,"id":id,"validation":"^optional","checked":_vm.dadosEdicao.get('historicoRotario') ? _vm.dadosEdicao.get('historicoRotario')[id] : false}}),_c('label',{staticClass:"ms-2 mb-1",attrs:{"for":id}},[_vm._v(_vm._s(label))])],1)}),0)],1),_c('div',{staticClass:"col-md-6"},[_c('h4',{staticClass:"mt-4 mb-2"},[_vm._v("Redes Sociais")]),_c('FormulateInput',{attrs:{"type":"group","name":"redesSociais"}},_vm._l((_vm.camposRedesSociais),function({ nome, campo, placeholder }){return _c('div',{key:campo},[_c('FormulateInput',{staticClass:"w-100 mt-3",attrs:{"type":"text","label":nome,"name":campo,"value":_vm.dadosEdicao.get('redesSociais') ? _vm.dadosEdicao.get('redesSociais')[campo] : '',"placeholder":placeholder + 'rotaryinternational',"validation":"^bail|optional|url","validation-messages":{
                url: 'Este endereço não é um link valido para o ' + nome,
              }}})],1)}),0)],1)],1)],1)]}}],null,false,743619283),model:{value:(_vm.dadosForm),callback:function ($$v) {_vm.dadosForm=$$v},expression:"dadosForm"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }