
import { ParseObject } from "@/plugin/parse/parse.interfaces";
import { Component, Prop, Vue } from "vue-property-decorator";
@Component({
  components: {},
})
export default class MiniAvatar extends Vue {
  @Prop({ required: true }) usuario!: ParseObject;

  avatarUrl = "";

  created() {
    this.avatarUrl =
      this.usuario.get("foto")?.url() || "/img/blank-avatar.425d092e.png";
  }
}
