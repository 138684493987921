
import { Component, Vue } from "vue-property-decorator";
import Loader from "@/components/Loader.vue";
import { ParseObject } from "@/plugin/parse/parse.interfaces";

@Component({
  components: { Loader },
})
export default class BuscaAssociacoes extends Vue {
  status: "buscando" | "selecionando" | "confirmando" = "buscando";
  confirmando = false;

  associacoes: ParseObject[] = [];
  associacaoSelecionada: any = null;

  created() {
    this.buscaAssociacaoEAcessaSistema();
  }

  async buscaAssociacaoEAcessaSistema() {
    const meusDados = await this.$utils.getMeusDados();

    if (!meusDados) return;

    if (meusDados && meusDados.get("associacaoAtual")) {
      this.verificaFoto(meusDados);
      return;
    }

    this.associacoes = await this.$parse
      .getList({
        className: "Associado",
        where: [["usuario", "=", meusDados]],
        include: ["clube", "clube.distrito"],
        orderBy: "posse",
        descending: true,
      })
      .catch((e) => {
        this.$notificacao.mensagemErro(e);
        this.$emit(
          "erro",
          "Erro ao buscar as suas associações, tente novamente mais tarde. Se o erro persistir, favor contactar o suporte."
        );
        return;
      });

    if (this.associacoes.length === 0) {
      this.$emit("semVinculo");
      return;
    }

    if (this.associacoes.length === 1) {
      await this.$parse
        .updateObject(meusDados, { associacaoAtual: this.associacoes[0] })
        .catch((e) => this.$notificacao.mensagemErro(e));
    }

    const associacaoAtiva = this.associacoes.find((associacao) =>
      this.$utils.verificaSeAssociadoAtivo(associacao)
    );

    let associacaoAtual = meusDados && meusDados.get("associacaoAtual");

    if (
      !associacaoAtual ||
      (associacaoAtiva && associacaoAtual?.id != associacaoAtiva?.id)
    ) {
      await this.$parse
        .updateObject(meusDados, {
          associacaoAtual: associacaoAtiva,
        })
        .catch((e) => this.$notificacao.mensagemErro(e));
      associacaoAtual = associacaoAtiva;
    }

    if (associacaoAtual) {
      this.verificaFoto(meusDados);
      return;
    }

    this.status = "selecionando";
  }

  verificaFoto(meusDados: ParseObject) {
    const possuiFoto = meusDados.get("foto");
    
    if (possuiFoto) this.$emit("entrarNoSistema");
    else this.$emit("definirFoto");
  }

  async confirmarVinculo() {
    this.confirmando = true;
    const meusDados = await this.$utils.getMeusDados();
    if (!meusDados) return;

    const sucesso = await this.$parse
      .updateObject(meusDados, {
        associacaoAtual: this.associacaoSelecionada,
        vinculoRealizado: true,
      })
      .catch((e) => this.$notificacao.mensagemErro(e));

    this.confirmando = false;

    if (sucesso) this.verificaFoto(meusDados);
  }
}
