<template>
  <div class="row mt-5">
    <div class="col-md-7 mx-auto text-center mt-5">
      <h1 class="big-error">Error 404</h1>
      <h1 class="mt-3">Não encontrado</h1>
      <h5 class="mt-3">O endereço que você está tentando acessar pode não existir, não possuir registro no banco de dados ou você pode não ter permissão de acesso.</h5>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>

.big-error {
  font-size: 7em;
}

</style>