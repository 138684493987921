
import { Component, Prop, Vue } from "vue-property-decorator";
import Button from "@/components/ui/Button.vue";
import { ParseObject, SimpleQuery } from "@/plugin/parse/parse.interfaces";
import { CLUBE_DESATIVADO } from "@/plugin/utils/utils.plugin";

export interface IFiltrosProjeto {
  categoriaId?: any;
  areaDeEnfoqueId?: any;
  idNomeAnoRotario?: any;
  statusCadastro?: any;
  clube?: any;
  distrito?: any;
}

@Component({
  components: {
    Button,
  },
})
export default class FiltrosProjetos extends Vue {
  filtros: any = {};

  @Prop({
    type: Boolean,
    required: false,
  })
  disabled!: any;

  @Prop({
    type: Object,
    required: false,
  })
  hide!: any;

  @Prop({
    type: Object,
    required: false,
  })
  classes!: any;

  @Prop({
    type: Object,
    required: false,
  })
  default!: { [nomeFiltro: string]: any };

  @Prop({
    type: Object,
    required: false,
  })
  clube!: ParseObject;

  @Prop({
    type: Object,
    required: false,
  })
  distrito!: ParseObject;

  categorias: any = [];
  areasDeEnfoque: any = [];
  distritos: any = [];
  clubesDistrito: any = [];
  carregandoClubes = false;
  carregandoDistritos = false;
  temFiltroAtivo = false;

  async created() {
    // Se o componente pai tiver passado um valor padrão pra algum dos filtros, o código abaixo seta esses valores.
    if (this.default) {
      Object.entries(this.default).forEach(
        ([nomeFiltro, valorFiltro]: [string, any]) => {
          if (nomeFiltro.endsWith("Id"))
            this.filtros[nomeFiltro] = valorFiltro.id;
          else this.filtros[nomeFiltro] = valorFiltro;
        }
      );
    }

    if (this.distrito) this.filtros.distrito = this.distrito;
    if (this.clube) this.filtros.clube = this.clube;

    // Se um dos campos categoria ou areasDeEnfoque estiver visível, carrega as opções dos inputs.
    if (!this.hide || !this.hide.categoria || !this.hide.areasDeEnfoque) {
      const { categorias, areasDeEnfoque } =
        await this.$utils.getParametrosProjeto();
      this.categorias = categorias;
      this.areasDeEnfoque = areasDeEnfoque;
    }

    // Se o campo distrito estiver visível, carrega a lista dos distritos brasileiros.
    if (!this.hide || !(this.hide.distritos || this.distrito)) {
      this.carregandoDistritos = true;
      let distritos = this.$cache.obter("distritos-brasileiros");

      if (!distritos) {
        distritos = await this.$cloud.buscarDistritosBrasileiros();
        this.$cache.salvar("distritos-brasileiros", distritos);
      }

      this.distritos = this.$utils.converteParaVFOptions(distritos, {
        label: "numero",
      });
      this.carregandoDistritos = false;
    }

    // Se o campo distrito estiver visível, carrega a lista dos distritos brasileiros.
    if (!this.hide || !this.hide.clubes || this.distrito) this.carregaClubes();
  }

  async carregaClubes() {
    if (!this.filtros.distrito && !this.distrito) return;

    this.carregandoClubes = true;

    let clubes = this.$cache.obter(`clubes-todos-${this.filtros.distrito.id}`);

    if (!clubes) {
      clubes = await this.$cloud.buscarClubesDistrito({
        id: this.filtros.distrito.id,
      });
      this.$cache.salvar(`clubes-todos-${this.filtros.distrito.id}`, clubes);
    }

    this.clubesDistrito = this.$utils.converteParaVFOptions(clubes, {
      fnLabel: (clube: ParseObject) => {
        return clube.get("clubeStatus") == CLUBE_DESATIVADO
          ? `${clube.get("nome")} (desativado)`
          : clube.get("nome");
      },
    });
    this.carregandoClubes = false;
  }

  verificaSeTemFiltroAtivo() {
    this.temFiltroAtivo = Object.values(this.filtros).reduce(
      (acc: boolean, value: any) => acc || (value && value !== ""),
      false
    );
  }

  /**
   * Para cada um dos inputs dos filtros, verifica se o valor deles existe, se sim, adiciona no na lista where.
   *
   * No caso da categoria e da área de enfoque, o select possui como valor a ID dos objetos parse,
   * por isso é necessário buscar o objeto da categoria e da área de enfoque.
   *
   * Os métodos getCategoriaProjeto e getAreaDeEnfoque buscam estes objetos e salva eles localmente,
   * assim, toda vez que precisar exibir as categorias e as áreas de enfoque, será necessário buscar no banco somente uma vez.
   *
   * Depois de verificado, é emitido para o componente pai uma lista do tipo SimpleQuery para ser usada como parametro da query dos projetos.
   */
  async buscar(): Promise<void> {
    const where: SimpleQuery[] = [];

    const {
      categoriaId,
      areaDeEnfoqueId,
      statusCadastro,
      idNomeAnoRotario,
      clube,
      distrito,
    } = this.filtros;

    if (categoriaId) {
      const categoria = await this.$utils.getCategoriaProjeto(categoriaId);
      where.push(["categoria", "=", categoria]);
    }

    if (areaDeEnfoqueId) {
      const areaDeEnfoque = await this.$utils.getAreaDeEnfoque(areaDeEnfoqueId);
      where.push(["areaDeEnfoque", "=", areaDeEnfoque]);
    }

    if (clube) where.push(["clube", "=", clube]);
    if (distrito) where.push(["distrito", "=", distrito]);
    if (statusCadastro === "concluido")
      where.push(["cadastroFinalizado", "=", true]);
    if (statusCadastro === "andamento")
      where.push(["cadastroFinalizado", "=", false]);

    // No campo idNomeAnoRotario o usuário pode digitar a ID, o nome ou o ano rotário do projeto. A operação abaixo verifica qual dos três metodos o usuário prosseguiu.
    if (idNomeAnoRotario) {
      switch (true) {
        // Caso seja um ano rotario, o texto terá o formato XXXX-XX
        case /^\d{4}-\d{2}$/.test(idNomeAnoRotario):
          where.push(["anoRotario", "=", idNomeAnoRotario]);
          break;

        // Caso seja uma id, o texto começará com #
        case idNomeAnoRotario?.startsWith("#"):
          where.push(["objectId", "=", idNomeAnoRotario.substring(1)]);
          break;

        // Caso seja o nome do projeto, não seŕa as opções acima e deve ter pelo menos uma letra
        case idNomeAnoRotario?.length > 0:
          where.push(["titulo", "matches", idNomeAnoRotario, "i"]);
          break;

        default:
          break;
      }
    }
    this.$emit("buscar", where);
  }

  limparFiltros() {
    this.filtros = {};
    this.temFiltroAtivo = false;
    this.buscar();
  }
}
