
import { Component, Prop, Vue } from "vue-property-decorator";
import Loader from "@/components/Loader.vue";
import TituloPrincipal from "@/components/TituloPrincipal.vue";
import Section from "@/components/ui/Section.vue";
import Aviso from "@/components/Aviso.vue";
import Divider from "@/components/ui/Divider.vue";
import Button from "@/components/ui/Button.vue";
import { ParseObject } from "@/plugin/parse/parse.interfaces";
import { Cropper } from "vue-advanced-cropper";

@Component({
  components: {
    Loader,
    TituloPrincipal,
    Section,
    Aviso,
    Divider,
    Button,
    Cropper,
  },
})
export default class EditarDadosUsuario extends Vue {
  @Prop()
  dadosUsuario!: ParseObject;
  dadosEdicao: any = null;

  @Prop()
  associacao!: ParseObject;
  statusAssociacao: "ativo" | "desligado" = "ativo";

  @Prop()
  clube!: ParseObject;

  historicoRotario = {
    duploAssociado: "Dupla Associacao",
    interact: "Interact",
    ryep: "Intercâmbio de Juventude (RYEP)",
    ngse: "Intercâmbio de Serviços às novas Gerações (NGSE)",
    ryla: "Prêmio Rotário de Liderança Jovem (RYLA)",
    aperfeicoamento:
      "Programa de Aperfeiçoamento Profissional (Rotary Bolsas Pela Paz)",
    mestrado: "Programa de Mestrado (Rotary Bolsas Pela Paz)",
  };

  foto: any = null;
  fotoUrl: string = "";
  fotoCortada: any = null;
  editouFoto = false;

  salvando = false;
  carregando = false;
  clicouEmSalvar = false;
  estaEditandoAsPropriasInfos = false;
  estaCriandoNovoUsuario = false;
  emailRefefinicaoDeSenhaEnviado = false;

  erro = "";
  mostrarErro = 0;
  campoHistoricoRotario: any = [];
  camposRedesSociais: any = [];

  dadosForm: any = {};

  async created() {
    this.carregando = true;
    this.camposRedesSociais = await this.$utils.getRedesSociais();

    this.dadosEdicao = this.dadosUsuario
      ? this.dadosUsuario
      : this.$parse.newObject("_User");

    const saoMeusDados = await this.$utils.verificaSeSaoMeusDados(
      this.dadosEdicao
    );

    this.estaEditandoAsPropriasInfos = this.dadosEdicao && saoMeusDados;
    this.estaCriandoNovoUsuario = !this.dadosUsuario;
    this.fotoUrl = this.dadosEdicao?.get("foto")?.url() || "";
    this.carregando = false;
  }

  // TODO: Mover isso para o Utils
  readFile(file: File): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = (res: any) => resolve(res.target.result);

      reader.onerror = (err) => reject(err);
    });
  }

  cancelaAlteracoes() {
    this.$emit("cancelar", true);
  }

  async carregaImagem(dadosInput: any) {
    const {
      fileList: [foto],
    } = dadosInput;
    this.foto = foto;
    this.fotoUrl = await this.readFile(foto);
  }

  imagemCortada({ canvas }: any) {
    this.fotoCortada = canvas.toDataURL("image/jpeg");
  }

  defaultSize({ imageSize, visibleArea }: any) {
    return {
      width: (visibleArea || imageSize).width,
      height: (visibleArea || imageSize).height,
    };
  }

  redefinirSenha() {
    const email = this.dadosEdicao.get("email");
    this.$parse.resetPassword(email);
    this.emailRefefinicaoDeSenhaEnviado = true;
  }

  async salvarAlteracoes() {
    this.salvando = true;
    this.erro = "";

    const {
      nome,
      apelido,
      email,
      celular,
      dataNascimento,
      profissao,
      historicoRotario,
      redesSociais,
      posse,
      fundador,
    } = this.dadosForm;

    if (!nome) {
      this.erro = "Nome é obrigatório";
      return;
    }

    if (!email) {
      this.erro = "E-mail é obrigatório";
      this.mostrarErro = 10;
      return;
    }

    const dadosAtualizados: any = {
      nome,
      status: true,
      revisado: true,
    };

    dadosAtualizados.apelido = apelido;
    dadosAtualizados.profissao = profissao;
    dadosAtualizados.dataNascimento = dataNascimento
      ? this.$utils.dataSemFuso(dataNascimento)
      : undefined;

    dadosAtualizados.celular = celular ? Number(celular) : undefined;

    try {
      if (this.estaCriandoNovoUsuario) {
        /**
         * Aqui é chamada a função saveObject() ao invés da singUp(), recomendada pelo Parse, pois,
         * ao chamar a singUp o usuário logado é desconectado automaticamente.
         *
         * Isso acarretaria em logout toda vez que um usuário atualizasse os dados.
         *
         * O usuário será criado apenas para realização do login.
         *
         * Os dados são salvos em uma classe separada, isso é necessário pois o Parse, por questões de segurança,
         * só permite que o próprio usuário logado edite as suas informações na classe _User
         */
        const usuario = await this.$parse.saveObject("_User", {
          /**
           * Para garantir que espaços em branco antes e depois do email não sejam salvos no banco
           */
          email: email.trim(),
          /**
           * O nome de usuário é obrigatório na criação de uma instância na classe _User,
           * mas não está sendo utilizado para login no sistema.
           *
           * Ele é gerado a partir do primeiro nome mais um numero aleatório de
           * 6 digitos gerado a partir do momento da criação do usuário.
           */
          username:
            nome.split(" ")[0].toLowerCase() + `${Date.now()}`.substr(5, 6),
          /**
           * A senha temporária é um timestamp + um número aleatório de 6 digitos.
           *
           * Esse valor é alterado assim que o usuário realiza seu primeiro acesso
           */
          password: `${Date.now() + Math.round(Math.random() * 1000000)}`,
        });

        if (!usuario) {
          this.erro = "Erro ao criar usuário";
          this.salvando = false;
          this.mostrarErro = 10;
          return;
        }

        /**
         * Os dados são salvos em uma tabela aparte para que os cargos que
         * possuam permissão de alteração dos dados dos usuarios possam faze-lo.
         *
         * Como falado anteriormente, caso os dados fossem salvos diretamente na classe _User,
         * não seria possível atualizá-los se não pelo próprio usuário.
         */
        const dados = await this.$parse.saveObject("DadosUsuario", {
          email,
          usuario,
          ...dadosAtualizados,
        });

        /**
         * Antes de criar de fato o usuário, é primeiro criada sua associação,
         * assim, é possível vinculá-la aos dados antes da criação do usuário.
         *
         * O Parse não permite que uma instância da classe _User seja alterada por um usuário que não seja o próprio.
         *
         * A unica forma de atualiza-la é durante a criação, por isso a instância de associação e dados são criadas antes.
         */
        const associacao = await this.$parse.saveObject("Associado", {
          posse: this.$utils.dataSemFuso(posse),
          statusAssociacao: this.statusAssociacao === "ativo" ? 1 : 0,
          clube: this.clube,
          status: true,
          cadastro: this.$utils.dataSemFuso(),
          usuario: dados,
          fundador: fundador === "Sim",
        });

        await this.$parse.saveObject("PrimeiroAcesso", { email });
        this.$emit("novoAssociado", associacao);
      } else {
        /**
         * Normaliza os campos do histórico rotário par excluir os que forem falsos.
         *
         * Isso garante que sejam salvos no banco somente os registros existentes (que são verdadeiros).
         */
        if (historicoRotario) {
          const historicoRotarioFiltrado: any =
            this.dadosEdicao.get("historicoRotario") || {};
          Object.entries(historicoRotario[0]).forEach(
            ([historico, valor]: any) => {
              if (valor) historicoRotarioFiltrado[historico] = true;
              else delete historicoRotarioFiltrado[historico];
            }
          );
          dadosAtualizados.historicoRotario = historicoRotarioFiltrado;
        }

        /**
         * Remove todas as redes sociais que não tiverem um link fornecido pelo usuário.
         * O { ...infosAtualizadas.redesSociais } serve para gerar uma cópia antes de apagar, por garantia.
         */
        dadosAtualizados.redesSociais = redesSociais[0];
        Object.entries({ ...dadosAtualizados.redesSociais }).forEach(
          ([nomeDaRede, linkDaRede]) => {
            if (!linkDaRede) delete dadosAtualizados.redesSociais[nomeDaRede];
          }
        );

        /**
         * Se começar com data:image é pq o usuário fez o upload de uma nova foto
         */

        if (
          this.foto ||
          (this.editouFoto &&
            this.fotoCortada &&
            this.fotoCortada.startsWith("data:image"))
        ) {
          const foto = await this.$parse.saveFile({
            name: `foto_perfil_${this.dadosEdicao.id}.png`,
            base64: this.fotoCortada,
          });
          dadosAtualizados.foto = foto;
        }

        /**
         * A atualização os dados de email é preciso ser feita no cloud por questões de seguranca.
         */
        const emailAtual = this.dadosEdicao.get("email");
        if (email && email !== emailAtual) {
          const alteradoComSucesso = await this.$cloud.alterarEmail({
            idUsuario: this.dadosEdicao.id,
            email,
          });
          if (alteradoComSucesso) dadosAtualizados.email = email;
        }

        // Atualiza os dados do usuário no banco
        await this.$parse.updateObject(this.dadosEdicao, dadosAtualizados);
        return this.$emit("salvo");
      }
    } catch (e: any) {
      this.erro = e.message;
      if (e.code === 203)
        this.erro = "Já existe uma conta com este endereço de e-mail.";
      this.mostrarErro = 10;
      this.$notificacao.mensagemErro(e);
      this.salvando = false;
    }
    this.salvando = false;
  }
}
