
import { Component, Vue } from "vue-property-decorator";
import Loader from "@/components/Loader.vue";
import TituloPrincipal from "@/components/TituloPrincipal.vue";
import Button from "@/components/ui/Button.vue";
import { ParseObject } from "@/plugin/parse/parse.interfaces";
import CRUDTermoDeUso from './CRUDTermoDeUso.vue'
import { Permissao } from "@/plugin/permissoes/permissoes.interfaces";

@Component({
  components: {
    TituloPrincipal,
    Loader,
    Button,
    CRUDTermoDeUso
  },
})
export default class TermosDeUso extends Vue {
  editando = false;
  carregando = false;
  possuiPermissao = false;

  termoAEditar: any = null;

  campos = [
    {
      key: "numero",
      thStyle: "width: 20%",
    },
    {
      key: "dataCriacao",
      thStyle: "width: 35%",
    },
    {
      key: "dataModificacao",
      thStyle: "width: 35%",
    },
    {
      key: "editar",
      thStyle: "width: 10%",
    },
  ];

  termos = [];
  created() {
    this.possuiPermissao = this.$permissoes.possui(Permissao.VISUALIZAR_CARGOS_SISTEMA);
    if (this.possuiPermissao) this.carregaCargos();
  }

  async carregaCargos() {
    this.carregando = true;

    const termos = await this.$parse.getList({ className: "TermoDeUso" }).catch(e => this.$notificacao.mensagemErro(e));

    this.termos = termos.map((termo: ParseObject, index: number) => {
      return {
        numero: `Termo de numero ${index}`,
        dataCriacao: termo.get("createdAt").toLocaleString(),
        dataModificacao: termo.get("updatedAt").toLocaleString(),
        termo,
      };
    });

    this.carregando = false;
  }

  editarTermo(termo: ParseObject) {
    this.termoAEditar = termo;
    this.editando = true;
  }
}
