
import { defineComponent } from 'vue'

export default defineComponent({

  props: {
    numeroDistrito: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      anosRotarios: [],
      carregandoCargos: false
    }
  },
  async created() {
    this.carregandoCargos = true;
    try {
      const response = await fetch('/data/honors.json')
      if (response.ok) {
        const data = await response.json()
        const distritoData = data[this.numeroDistrito]
        if (distritoData) {
          this.anosRotarios = distritoData.anos_rotarios
        } else {
          console.error('Distrito não encontrado')
        }
      } else {
        console.error('Falha ao carregar os dados dos associados')
      }
    } catch (error) {
      console.error('Erro ao carregar os dados:', error)
    } finally {
      this.carregandoCargos = false
    }
  }
})
