
import { Component, Vue } from "vue-property-decorator";
import IconesRedesSociais from "@/components/IconesRedesSociais.vue";
import Loader from "@/components/Loader.vue";
import Button from "@/components/ui/Button.vue";
import Section from "@/components/ui/Section.vue";
import Divider from "@/components/ui/Divider.vue";
import SecaoVazia from "@/components/SecaoVazia.vue";
import CarregandoInfo from "@/components/CarregandoInfo.vue";
import CardUsuario from "@/components/CardUsuario.vue";
import Error404 from "@/components/Error404.vue";
import TituloPrincipal from "@/components/TituloPrincipal.vue";
import NovoCargoAssociado from "@/components/NovoCargoAssociado.vue";
import { ParseObject } from "@/plugin/parse/parse.interfaces";
import { Permissao } from "@/plugin/permissoes/permissoes.interfaces";
import UsuarioFotoNome from "@/components/UsuarioFotoNome.vue";
import { CARGO_NO_CLUBE, CARGO_NO_DISTRITO } from "@/plugin/utils/utils.plugin";

type tipoCargo = "clubes" | "distritos" | "rotaractbr";

@Component({
  components: {
    IconesRedesSociais,
    Loader,
    Button,
    Section,
    Divider,
    SecaoVazia,
    CarregandoInfo,
    Error404,
    CardUsuario,
    TituloPrincipal,
    NovoCargoAssociado,
    UsuarioFotoNome,
  },
})
export default class CargosPendentesSistema extends Vue {
  retorno = [
    {
      url: "/sistema",
      titulo: "Painel do Sistema",
    },
  ];

  carregando = true;
  salvando = false;

  idCargoSendoAtualizado: any = null;

  tipo: tipoCargo = "rotaractbr";

  pendentes: { [tipo: string]: ParseObject[] } = {
    clubes: [],
    distritos: [],
    rotaractbr: [],
  };

  todosCargos: ParseObject[] = [];

  colunas = [
    { key: "associado", label: "Associado(a)", thStyle: "width: 30%" },
    { key: "cargo", label: "Cargo", thStyle: "width: 20%" },
    { key: "clubeDistrito", label: "Clube/Distrito", thStyle: "width: 20%" },
    { key: "anoRotario", label: "Ano Rotário", thStyle: "width: 15%" },
    { key: "acao", label: "Ação", thStyle: "width: 10%" },
  ];

  created() {
    this.carregaPendentes();
  }

  async carregaPendentes() {
    if (!this.$permissoes.possui(Permissao.ATRIBUIR_CARGOS_NA_ROTARACT_BRASIL))
      return;

    const pendentes = await this.$parse
      .getList({
        className: "CargoUsuario",
        where: [
          ["pendente", "=", true],
          ["recusado", "!=", true],
        ],
        include: ["usuario", "cargo", "clube", "clube.distrito", "distrito"],
        descending: true,
      })
      .catch((e) => this.$notificacao.mensagemErro(e));

    if (!pendentes) return (this.carregando = false);

    this.todosCargos = pendentes;

    this.montaListaPorTipo();

    this.carregando = false;
  }

  montaListaPorTipo() {
    this.pendentes = {
      clubes: [],
      distritos: [],
      rotaractbr: [],
    };

    this.todosCargos.forEach((cargo: ParseObject) => {
      if (cargo.get("tipo") === CARGO_NO_CLUBE)
        return this.pendentes.clubes.push(cargo);

      if (cargo.get("tipo") === CARGO_NO_DISTRITO)
        return this.pendentes.distritos.push(cargo);

      this.pendentes.rotaractbr.push(cargo);
    });
  }

  async aprovarCargo(cargo: ParseObject) {
    const confirmado = await this.$notificacao.confirmar({
      titulo: "Aprovar",
      html: "Você deseja aprovar este cargo?",
      textoNao: "Cancelar",
    });

    if (!confirmado) return;

    this.salvando = true;
    this.idCargoSendoAtualizado = cargo.id;

    const success = await this.$parse
      .updateObject(cargo, { pendente: false })
      .catch((e) => this.$notificacao.mensagemErro(e));

    if (!success) return;

    this.pendentes[this.tipo] = this.pendentes[this.tipo].filter(
      (_cargo: ParseObject) => _cargo.id !== cargo.id
    );

    this.idCargoSendoAtualizado = null;
    this.salvando = false;
  }

  async recusarCargo(cargo: ParseObject) {
    const confirmado = await this.$notificacao.confirmar({
      titulo: "Recusar",
      texto: "Tem certeza que deseja recusar este cargo?",
      textoNao: "Cancelar",
    });

    if (!confirmado) return;

    this.salvando = true;
    this.idCargoSendoAtualizado = cargo.id;

    const success = await this.$parse
      .updateObject(cargo, { pendente: false, recusado: true })
      .catch((e) => this.$notificacao.mensagemErro(e));

    if (!success) return;

    this.pendentes[this.tipo] = this.pendentes[this.tipo].filter(
      (_cargo: ParseObject) => _cargo.id !== cargo.id
    );

    this.idCargoSendoAtualizado = null;
    this.salvando = false;
  }

  filtraPorTipo(tipo: tipoCargo) {
    this.carregando = true;
    // Apenas para forçar o vue a renderizar as alterações
    setTimeout(() => {
      this.tipo = tipo;
      this.carregando = false;
    }, 100);
  }

  filtraPorNome(nome: string) {
    this.montaListaPorTipo();

    if (!nome) return;

    this.carregando = true;
    nome = nome.toLowerCase();
    const filtraCargo = (cargoAssociado: ParseObject) =>
      cargoAssociado
        .get("usuario")
        .get("nome")
        ?.toLowerCase()
        ?.startsWith(nome) ||
      cargoAssociado.get("cargo").get("nome")?.toLowerCase()?.startsWith(nome);

    // Apenas para forçar o vue a renderizar as alterações
    setTimeout(() => {
      this.pendentes.clubes = this.pendentes.clubes.filter(filtraCargo);
      this.pendentes.distritos = this.pendentes.distritos.filter(filtraCargo);
      this.pendentes.rotaractbr = this.pendentes.rotaractbr.filter(filtraCargo);
      this.carregando = false;
    }, 200);
  }
}
