
import { Component, Prop, Vue } from "vue-property-decorator";
import {
  CLUBE_ATIVO,
  CLUBE_COMUNITARIO,
  CLUBE_UNIVERSITARIO,
} from "@/plugin/utils/utils.plugin";
import Loader from "@/components/Loader.vue";
import Button from "@/components/ui/Button.vue";
import Section from "@/components/ui/Section.vue";
import Divider from "@/components/ui/Divider.vue";
import { ParseObject } from "@/plugin/parse/parse.interfaces";

@Component({
  components: {
    Loader,
    Button,
    Section,
    Divider,
  },
})
export default class ClubesDistrito extends Vue {
  @Prop({ required: true }) distrito!: ParseObject;

  salvando = false;

  // Usado para armazenar o valor do tipo do clube quando o usuário clica em um dos botões.
  tipoClube: CLUBE_COMUNITARIO | CLUBE_UNIVERSITARIO = CLUBE_COMUNITARIO;

  // Usado para poder realizar a validação da data de reconhecimento (que não pod ser anterior a de fundação)
  fundacaoModel = null;

  // Usado para exibir o nome final do clube quando o usuário digita no campo 'nome'
  nomeModel = null;

  cancelaAlteracoes() {
    this.$emit("cancelar", true);
  }

  async salvarAlteracoes(dadosNovoClube: any) {
    this.salvando = true;

    /**
     * Configura as informações complementares do clube que são padrões mas que não estão disponíveis para o usuário alterar.
     *
     * Estes dadados são concatenados com os dados do formulario em um unico objeto, que será salvo posteriormente no banco.
     */
    const novoClubeInfo = {
      ...dadosNovoClube,
      // Status do clube: Ao criar, sempre será um clube ativo.
      clubeStatus: CLUBE_ATIVO,
      // Revisado: Essa flag serve para distinguir dos clubes que tiveram os dados migrados, clubes criados no sistema estão automaticamente com seus dados revisados.
      revisado: true,
      // Distrito: Configura para o distrito onde a ação de criar o clube foi habilitada.
      distrito: this.$props.distrito,
      // Converte o valor da data de fundação (que é salvo como string) para um objeto do tipo Date.
      fundacao: this.$utils.dataSemFuso(dadosNovoClube.fundacao),
      // Converte o valor da data de reconhecimento (que é salvo como string) para um objeto do tipo Date.
      reconhecimento: this.$utils.dataSemFuso(dadosNovoClube.reconhecimento),
    };

    const novoClube = await this.$parse
      .saveObject("Clube", novoClubeInfo)
      .catch((e) => this.$notificacao.mensagemErro(e));

    if (novoClube) this.$emit("novoClube", novoClube);

    this.salvando = false;
  }
}
