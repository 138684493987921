import { Component, Vue } from "vue-property-decorator";
import { ParseObject } from "../parse/parse.interfaces";

@Component
export default class Cache extends Vue {
  private cache: { [chave: string]: any } = {};

  salvar(chave: string, valor: any): void {
    this.cache[chave] = valor;
  }

  obter(chave: string): any {
    const caminho = chave.split(".");
    let _obj = this.cache[caminho[0]];
    for (let i = 1; i < caminho.length; i++) {
      if (_obj === undefined) return;
      _obj = _obj[caminho[i]];
    }
    return _obj;
  }

  limpar(chave: string): void {
    delete this.cache[chave];
  }

  emCache(chave: string): boolean {
    return this.cache[chave] !== undefined;
  }

  algumEmCache(chaves: string[]): boolean {
    return chaves.some(c => this.emCache(c));
  }

  todosEmCache(chaves: string[]): boolean {
    return chaves.every(c => this.emCache(c));
  }

  atualizar(chave: string, valor: any): void {
    const caminho = chave.split(".");
    let _obj = this.cache[caminho[0]];
    for (let i = 1; i < caminho.length - 1; i++) {
      _obj = _obj[caminho[i]];
      if (_obj === undefined) {
        return;
      }
    }
    const folha = caminho[caminho.length - 1];
    if (_obj) _obj[folha] = valor;
  }

  removerDaLista(chave: string, item: ParseObject): void {
    if (this.$cache.emCache(chave)) {
      const emCache: ParseObject[] = this.$cache.obter(chave) || [];

      this.$cache.atualizar(
        chave,
        emCache.filter((_item: ParseObject) => _item.id !== item.id)
      );
    }
  }

  addNaLista(chave: string, item: ParseObject): void {
    if (this.$cache.emCache(chave)) {
      const emCache: ParseObject[] = this.$cache.obter(chave) || [];
      emCache.push(item)
      this.$cache.atualizar(chave, emCache);
    }
  }
}