
import { Component, Vue } from "vue-property-decorator";
import TextoRotulado from "@/components/TextoRotulado.vue";
import TituloPrincipal from "@/components/TituloPrincipal.vue";
import Aviso from "@/components/Aviso.vue";
import Section from "@/components/ui/Section.vue";
import Button from "@/components/ui/Button.vue";
import Loader from "@/components/Loader.vue";
import Error404 from "@/components/Error404.vue";
import AvatarUsuario from "@/components/AvatarUsuario.vue";
import { Permissao } from "@/plugin/permissoes/permissoes.interfaces";

@Component({
  components: {
    TextoRotulado,
    TituloPrincipal,
    Aviso,
    Section,
    Button,
    Loader,
    Error404,
    AvatarUsuario,
  },
})
export default class ReiniciarLogin extends Vue {
  retorno = [
    {
      url: "/sistema",
      titulo: "Painel do Sistema",
    },
  ];

  dadosUsuario: any = null;

  possuiPermissao = false;
  buscando = false;
  reiniciando = false;

  emailOuId = "";
  nomeClube = "";
  erro = "";
  sucesso = "";
  created() {
    this.possuiPermissao = this.$permissoes.possui(
      Permissao.REDEFINIR_FLUXO_LOGIN
    );
  }

  async localizarUsuario() {
    if (this.emailOuId.length === 0) return;

    this.buscando = true;
    if (this.emailOuId.includes("@")) {
      this.dadosUsuario = await this.$parse.getFirst({
        className: "DadosUsuario",
        where: [["email", "=", this.emailOuId]],
        include: ["usuario", "associacaoAtual", "associacaoAtual.clube"],
      });
    } else {
      this.dadosUsuario = await this.$parse.getById(
        "DadosUsuario",
        this.emailOuId
      );
    }

    const nomeClube =
      this.dadosUsuario.get("associacaoAtual") &&
      this.dadosUsuario.get("associacaoAtual").get("clube") &&
      this.dadosUsuario.get("associacaoAtual").get("clube").get("nome");

    this.nomeClube = nomeClube ? ` Rotaract Club de ${nomeClube}` : "";

    if (!this.dadosUsuario) {
      this.erro = "Usuário não encontrado";
    }
    this.buscando = false;
  }

  async reiniciarLogin() {
    const confirmado = await this.$notificacao.confirmar({
      texto: "Tem certeza que deseja reiniciar o fluxo de login deste usuário?",
    });

    if (!confirmado) return;

    this.reiniciando = true;

    const primeiroAcesso = await this.$parse.getFirst({
      className: "PrimeiroAcesso",
      where: [["email", "=", this.dadosUsuario.get("email")]],
    });

    const reiniciarLogin = {
      termosDeUsoAceitosEm: null,
      termosDeUsoAceitos: false,
      ultimoTermoAceito: null,
    };

    const sucesso = await Promise.all([
      primeiroAcesso &&
        this.$parse.updateObject(primeiroAcesso, reiniciarLogin),
      this.$parse.updateObject(this.dadosUsuario, reiniciarLogin),
    ]);

    if (sucesso) {
      this.dadosUsuario = null;
      this.emailOuId = "";
      this.sucesso = "Login reiniciado com sucesso";
    } else {
      this.erro = "Erro ao reiniciar login";
    }

    this.reiniciando = false;
  }

  cancelar() {
    this.dadosUsuario = null;
    this.erro = "";
    this.sucesso = "";
    this.emailOuId = "";
  }
}
