
import { Component, Vue } from "vue-property-decorator";

@Component({
  props: {
    position: String,
    top: Number,
    right: Number,
    left: Number,
    bottom: Number,
  },
})
export default class Loader extends Vue {}
