
import { Component, Prop, Vue } from "vue-property-decorator";
import Button from "@/components/ui/Button.vue";
import Loader from "@/components/Loader.vue";
import { ParseObject } from "@/plugin/parse/parse.interfaces";
import { IVueFormulateOption } from "@/plugin/utils/utils.interfaces";
import Aviso from "@/components/Aviso.vue";

@Component({
  components: {
    Button,
    Loader,
    Aviso,
  },
})
export default class ReligarAssociadoDesliugado extends Vue {
  @Prop({
    default: "modal-religar-associado",
  })
  idModal!: string;

  @Prop({
    required: true,
  })
  clube!: ParseObject;

  carregando = false;
  cancelar = false;
  religando = false;

  mensagemErro = "";

  desligados: IVueFormulateOption[] = [];
  usuarioSelecionado: any = null;
  posse = "";
  quinzeDiasAFrente = new Date(new Date().setDate(new Date().getDate() + 15))
    .toISOString()
    .split("T")[0];

  async created() {
    this.carregando = true;
    let desligados = this.$cache.obter(`aptos-ao-religamento-${this.clube.id}`);
    if (!desligados) {
      desligados = await this.$cloud.buscarAptosAoReligamentoClube(
        this.clube.id
      );
      this.$cache.salvar(`aptos-ao-religamento-${this.clube.id}`, desligados);
    }

    this.desligados = this.$utils.converteParaVFOptions(desligados, {
      label: "nome",
    });

    this.carregando = false;
  }

  async religar() {
    this.mensagemErro = "";

    if (!this.usuarioSelecionado)
      return (this.mensagemErro = "Nenhum(a) associado(a) foi selecionado(a)");

    if (!this.posse) return (this.mensagemErro = "Data de posse é obrigatória");

    this.religando = true;

    const novaAssociacao = await this.$cloud
      .religarAssociado({
        idUsuario: this.usuarioSelecionado.id,
        idClube: this.clube.id,
        posse: this.$utils.dataSemFuso(this.posse),
      })
      .catch((e) => {
        this.$notificacao.erro({ texto: e.message });
      });

    this.religando = false;

    if (!novaAssociacao) return;

    this.$cache.addNaLista(
      `dados-clube-${this.clube.id}.associados.ativos`,
      novaAssociacao
    );

    this.$emit("novaAssociacao", novaAssociacao);
  }

  resetaForm() {}
}
