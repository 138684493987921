
import { Component, Prop, Vue } from "vue-property-decorator";
import IconesRedesSociais from "@/components/IconesRedesSociais.vue";
import Loader from "@/components/Loader.vue";
import NovoClube from "../clubes/NovoClubeDistrito.vue";
import { ParseObject } from "@/plugin/parse/parse.interfaces";
import { CLUBE_ATIVO } from "@/plugin/utils/utils.plugin";
import Button from "@/components/ui/Button.vue";
import Section from "@/components/ui/Section.vue";
import Divider from "@/components/ui/Divider.vue";
import SecaoVazia from "@/components/SecaoVazia.vue";
import CarregandoInfo from "@/components/CarregandoInfo.vue";
import Error404 from "@/components/Error404.vue";
import TituloPrincipal from "@/components/TituloPrincipal.vue";
import { Permissao } from "@/plugin/permissoes/permissoes.interfaces";

@Component({
  components: {
    IconesRedesSociais,
    Loader,
    NovoClube,
    Button,
    Section,
    Divider,
    SecaoVazia,
    CarregandoInfo,
    Error404,
    TituloPrincipal,
  },
})
export default class ClubesDistrito extends Vue {
  @Prop({ required: true }) distrito!: ParseObject;

  editando = false;
  salvando = false;

  podeCadastrarNovoClube = false;
  criandoNovoClube = false;
  distritoNaoExiste = false;

  campoBusca: string = "";
  statusClube = ["Desativado", "Ativo"];
  statusSelecioando = CLUBE_ATIVO;

  meuDistrito: any = null;

  clubes: ParseObject[] = [];
  todosClubes = [];
  totalClubesAtivos = 0;
  totalClubesDesativados = 0;

  created() {
    this.verificaPermissaoCadastroNovoClube();
    this.carregaClubes();
  }

  async carregaClubes() {
    if (!this.distrito) return;
    let clubesDistrito = await this.$cache.obter(
      `clubes-distrito-${this.distrito.id}`
    );

    if (!clubesDistrito) {
      clubesDistrito = await this.$parse
        .getList({
          className: "Clube",
          where: [["distrito", "=", this.distrito]],
          orderBy: "nome",
        })
        .catch((e) => this.$notificacao.mensagemErro(e));

      this.$cache.salvar(`clubes-distrito-${this.distrito.id}`, clubesDistrito);
    }

    this.todosClubes = clubesDistrito;
    this.contaClubesPorStatus();
    this.filtrarPorStatus();
  }

  verificaPermissaoCadastroNovoClube() {
    const podeCadastrarClubesNoProprioDistrito = this.$permissoes.possui(
      Permissao.CADASTRAR_CLUBES_NO_PROPRIO_DISTRITO,
      { distrito: this.distrito }
    );

    const podeCadastrarClubesEmOutrosDistritos = this.$permissoes.possui(
      Permissao.CADASTRAR_CLUBES_EM_QUALQUER_DISTRITOS
    );

    this.podeCadastrarNovoClube =
      podeCadastrarClubesNoProprioDistrito ||
      podeCadastrarClubesEmOutrosDistritos;
  }

  novoClube(criando: boolean) {
    this.criandoNovoClube = criando;
    criando ? this.$emit("criandoNovoClube") : this.$emit("listandoClubes");
  }

  contaClubesPorStatus() {
    this.todosClubes.forEach((clube: ParseObject) =>
      clube.get("clubeStatus") === CLUBE_ATIVO
        ? this.totalClubesAtivos++
        : this.totalClubesDesativados++
    );
  }

  async filtrarPorStatus() {
    this.clubes = this.todosClubes.filter(
      (clube: ParseObject) =>
        clube?.get("clubeStatus") === this.statusSelecioando
    );
  }

  filtrarPorNome() {
    this.filtrarPorStatus();

    if (this.campoBusca.length === 0) return;

    this.clubes = this.clubes.filter((clube: ParseObject) => {
      const nome = "rotaract club de " + clube?.get("nome").toLowerCase();
      return nome.includes(this.campoBusca.toLowerCase());
    });
  }

  addNovoClubeNaListagem(clube: ParseObject) {
    this.clubes.push(clube);
    this.criandoNovoClube = false;
  }
}
