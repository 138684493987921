
import { Component, Vue } from "vue-property-decorator";
import Button from "@/components/ui/Button.vue";
import Section from "@/components/ui/Section.vue";
import Loader from "@/components/Loader.vue";
import TituloPrincipal from "@/components/TituloPrincipal.vue";
import Aviso from "@/components/Aviso.vue";
import { ParseObject } from "@/plugin/parse/parse.interfaces";
import { IVueFormulateOption } from "@/plugin/utils/utils.interfaces";
@Component({
  components: {
    Button,
    Section,
    Loader,
    TituloPrincipal,
    Aviso,
  },
})
export default class VincularAssociacao extends Vue {
  distritos: IVueFormulateOption[] = [];
  distritoSelecionado: any = null;

  clubes: IVueFormulateOption[] = [];
  carregandoClubes = false;
  clubeSelecionado: any = null;

  emailAssociado: string = "";

  campos = [
    { key: "id", thStyle: "width: 10%" },
    { key: "nome", thStyle: "width: 40%" },
    { key: "posse", thStyle: "width: 20%" },
    { key: "desligamento", thStyle: "width: 20%" },
    { key: "remover", thStyle: "width: 10%" },
  ];
  associacoes: any = [];

  confirmandoVinculo = false;
  buscandoAssociado = false;
  vinculando = false;

  escondeAlertaEm = 0; // seg;
  mensagemAlerta = "";
  varianteAlerta = "danger";

  retorno = [
    {
      url: "/sistema",
      titulo: "Painel do Sistema",
    },
  ];

  async created() {
    let distritos = this.$cache.obter("distritos-brasileiros");

    if (!distritos) {
      distritos = await this.$cloud.buscarDistritosBrasileiros();
      this.$cache.salvar("distritos-brasileiros", distritos);
    }

    this.distritos = this.$utils.converteParaVFOptions(distritos, {
      label: "numero",
    });
  }

  async carregaClubes() {
    this.carregandoClubes = true;

    const clubes = await this.$utils.listarClubesDistrito(
      this.distritoSelecionado
    );

    this.clubes = this.$utils.converteParaVFOptions(clubes, {
      label: "nome",
    });

    this.carregandoClubes = false;
  }

  async buscarAssociado() {
    if (!this.emailAssociado) return;

    this.mensagemAlerta = "";
    this.varianteAlerta = "danger";

    this.buscandoAssociado = true;
    const associado = await this.$parse
      .getFirst({
        className: "Associado",
        where: [["usuario._User.email", "=", this.emailAssociado.trim()]],
        include: ["usuario", "usuario.dados", "clube", "clube.distrito"],
      })
      .catch((e) => this.$notificacao.mensagemErro(e));

    if (associado) {
      if (!associado.get("clube")) this.associacoes.push(associado);
      else {
        this.escondeAlertaEm = 8; // seg
        this.mensagemAlerta = "Usuário já está vinculado a um clube";
      }
    } else {
      this.escondeAlertaEm = 8; // seg
      this.mensagemAlerta =
        "Usuário não possui registros no sistema com este e-mail";
    }

    this.buscandoAssociado = false;
    this.emailAssociado = "";
  }

  removerAssociado(associado: ParseObject) {
    const index = this.associacoes.indexOf(associado);
    this.associacoes.splice(index, 1);
  }

  async vincularAssociacoes() {
    this.vinculando = true;
    const sucesso = await Promise.all([
      this.associacoes.map((associacao: ParseObject) =>
        this.$parse.updateObject(associacao, { clube: this.clubeSelecionado })
      ),
    ]).catch((e) => this.$notificacao.mensagemErro(e));

    this.vinculando = false;
    if (!sucesso) return;

    this.distritoSelecionado = null;
    this.clubeSelecionado = null;
    this.associacoes = [];
    this.escondeAlertaEm = 8;
    this.varianteAlerta = "success";
    this.mensagemAlerta = "Usuários vinculados ao clube com sucesso!";
  }
}
