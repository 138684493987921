import { DESABILITAR_TODAS_RESTRICOES, IExisteAlgumaPermissao, IPermissaoParaEntidade, Permissao, PermissoesSistema, USAR_PERMISSOES_PADROES_DO_SISTEMA } from "./permissoes.interfaces"
import { Component, Vue } from "vue-property-decorator";
import { ParseObject } from "../parse/parse.interfaces";


@Component
export default class PermissoesPlugin extends Vue {

  private permissoes: any = {};

  private meuClube: any = null;
  private meuDistrito: any = null;

  async carregaPermissoesUsuario() {

    // Se as permissões ja tiverem sido carregadas
    if (Object.keys(this.permissoes).length > 0) return;

    const todasPermissoesSistema = this.getPermissoesSistema();


    if (USAR_PERMISSOES_PADROES_DO_SISTEMA) this.permissoes = { ...PermissoesSistema };
    else {
      let permissoesUsuario = await this.$cache.obter('permissoes-usuario');

      if (!permissoesUsuario) {
        permissoesUsuario = await this.$cloud.buscarPermissoesUsuarioLogado();
        this.$cache.salvar('permissoes-usuario', permissoesUsuario);
      }

      todasPermissoesSistema.forEach((permissao) =>
        this.permissoes[permissao] = permissoesUsuario[permissao] || this.permissoes[permissao] || false
      );
    }

    this.meuClube = await this.$utils.getMeuClube()
    this.meuDistrito = await this.$utils.getMeuDistrito()
  }

  possui(permissao: Permissao | Permissao[], paraEntidade?: IPermissaoParaEntidade): boolean {

    if (DESABILITAR_TODAS_RESTRICOES) return true

    const { usuario, clube, distrito } = paraEntidade || {};

    if (!permissao) return false;

    /**
     * Esta parte verifica se há permissão para o usuario.
     * 
     * Se um usuário é passado como argumento em 'paraEntidade', significa que é necessário verificar também se o usuário é o logado.
     * 
     * O usuário passado no argumento não é necessariamente o usuário logado.
     * 
     * Caso não seja passado nenhum usuário, significa que não há necessidade de verificar se é o próprio usuário acessando o componente.
     * 
     * @example
     * // Para verificar as permissões no perfil, é necessário saber se o usuário do perfil acessado é o mesmo usuário logado.
     * this.$permissoes.possui(Permissao.EDITAR_INFORMACOES, { usuario })
     */
    const requerQueSejaEu = usuario !== undefined;
    const proprioUsuario = this.$parse.usuarioLogado && usuario && this.$parse.usuarioLogado.id === usuario.id
    const temPermissaoSeSouEu = !requerQueSejaEu || requerQueSejaEu && proprioUsuario;

    /**
     * Esta parte verifica se há permissão para o usuario em relação ao clube fornecido.
     * 
     * Se um clube é passado como argumento em 'paraEntidade', significa que é necessário verificar também se é o clube do mesmo usuário.
     * 
     * Caso não seja passado nenhum clube, significa que não há necessidade de verificar se é o próprio usuário acessando o componente.
     * 
     * @example
     * // Para verificar se o usuário possui permissao de cadastro de associados no clube acessado.
     * this.$permissoes.possui(Permissao.CADASTRAR_E_EDITAR_ASSOCIADOS_NO_PROPRIO_CLUBE, { clube })
     */
    const requerMeuClube = clube !== undefined;
    const eMeuClube = this.meuClube && clube && this.meuClube.id === clube.id
    const temPermissaoSeMeuClube = !requerMeuClube || requerMeuClube && eMeuClube;

    /**
     * Esta parte verifica se há permissão para o usuario em relação ao distrito fornecido.
     * 
     * Se um distrito é passado como argumento em 'paraEntidade', significa que é necessário verificar também se é o distrito do mesmo usuário.
     * 
     * Caso não seja passado nenhum distrito, significa que não há necessidade de verificar se é o próprio usuário acessando o componente.
     * 
     * @example
     * // Para verificar se o usuário possui permissao de cadastro de clubes no distrito acessado.
     * this.$permissoes.possui(Permissao.CADASTRAR_CLUBES_NO_PROPRIO_DISTRITO, { distrito })
     */
    const requerMeuDistrito = distrito !== undefined;
    const eMeuDistrito = this.meuDistrito && distrito && this.meuDistrito.id === distrito.id
    const temPermissaoSeMeuDistrito = !requerMeuDistrito || requerMeuDistrito && eMeuDistrito;


    const temPermissoesParaEntidades = temPermissaoSeSouEu && temPermissaoSeMeuClube && temPermissaoSeMeuDistrito;


    let possuiPermissao = false;
    // Se for passado apenas uma permissao
    if (typeof permissao === 'string') possuiPermissao = this.permissoes[permissao.trim()];
    // Se for passado uma lista de permissoes
    else possuiPermissao = permissao.reduce((acc, perm) => acc && this.permissoes[perm.trim()], true);
    // console.log(permissao, this.meuDistrito?.id, distrito?.id, possuiPermissao, temPermissoesParaEntidades);

    return possuiPermissao && temPermissoesParaEntidades;
  }

  possuiAlguma(permissoes: IExisteAlgumaPermissao[]): boolean {
    return permissoes
      .map(({ permissao, clube, distrito, usuario }) => {
        if (clube) return this.possui(permissao, { clube })
        if (distrito) return this.possui(permissao, { distrito })
        if (usuario) return this.possui(permissao, { usuario })
        return this.possui(permissao)
      })
      .some((existe) => existe);
  }

  possuiTodas(permissoes: IExisteAlgumaPermissao[]): boolean {
    return permissoes
      .map(({ permissao, clube, distrito, usuario }) => {
        if (clube) return this.possui(permissao, { clube })
        if (distrito) return this.possui(permissao, { distrito })
        if (usuario) return this.possui(permissao, { usuario })
        return this.possui(permissao)
      })
      .every((existe) => existe);
  }

  getPermissoesSistema(): Permissao[] {
    return Object.keys(PermissoesSistema) as Permissao[];
  }


  verificaSeAceitouTermos(dadosUsuario: ParseObject | void) {
    if (!dadosUsuario) return false;

    const termosDeUsoAceitos = dadosUsuario.get("termosDeUsoAceitos");

    if (termosDeUsoAceitos) return true;

    const temFoto = dadosUsuario.get("foto");

    if (temFoto) return true;

    return false;
  }
}