
import { IRetornoBuscarDadosConvite } from "@/plugin/parse/cloud.interfaces";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class CadastroViaConvite extends Vue {
  erro: string = "";
  carregando: boolean = false;
  validandoConvite: boolean = false;

  convite: IRetornoBuscarDadosConvite | null = null;

  async created() {
    await this.$parse.logOut();
    const idConvite = this.$route.params.id_convite;
    this.validandoConvite = true;
    const convite = await this.$cloud
      .buscarDadosConvite(idConvite)
      .catch((erro) => {
        this.erro = erro;
        this.validandoConvite = false;
      });

    if (!convite) {
      this.$emit("erro", "O código do convite é invalido ou expirou!");
      return this.$emit("autenticacao");
    }

    this.convite = convite;

    this.validandoConvite = false;
  }

  async convidar(dados: any) {
    this.erro = "";
    const idConvite = this.$route.params.id_convite;
    const { nome, confirmacaoSenha, senha } = dados;

    if (senha !== confirmacaoSenha) {
      this.erro = "As senhas não conferem";
      return;
    }

    if (senha.length < 6) {
      this.erro = "A senha deve ter no mínimo 6 caracteres";
      return;
    }

    this.carregando = true;

    const sucesso = await this.$cloud
      .criarContaViaConvite({
        idConvite,
        nome,
        senha,
        confirmacaoSenha,
      })
      .catch((err: any) => {
        this.erro = err.message;
      });

    if (sucesso)
      this.$emit(
        "sucesso",
        "Conta criada com sucesso! Realize o login para acessar o sistema."
      );
    else this.$emit("erro", this.erro);

    this.$emit("autenticacao");

    this.$router.replace("/login");

    this.carregando = false;
  }
}
