
import { Component, Vue } from "vue-property-decorator";
import TituloPrincipal from "@/components/TituloPrincipal.vue";
import Section from "@/components/ui/Section.vue";
import Divider from "@/components/ui/Divider.vue";
import Loader from "@/components/Loader.vue";
import CardUsuario from "@/components/CardUsuario.vue";
import SecaoVazia from "@/components/SecaoVazia.vue";
import { ParseObject } from "@/plugin/parse/parse.interfaces";

@Component({
  components: {
    TituloPrincipal,
    Section,
    Divider,
    Loader,
    SecaoVazia,
    CardUsuario,
  },
})
export default class RDRs extends Vue {
  carregando = true;
  rdrs: ParseObject[] = [];
  anosRotarios: any[] = [];
  anoRotario = "";
  created() {
    const anoRotario = this.$route.params.ano_rotario;
    this.anoRotario = anoRotario ?? this.$utils.getAnoRotario();
    
    const fundacaoOmir = new Date();
    fundacaoOmir.setFullYear(1992);

    const hoje = new Date();
    const anosRotarios = this.$utils.getIntervaloAnosRotarios(
      fundacaoOmir,
      hoje
    );

    this.anosRotarios = anosRotarios.map((anoRotario) => ({
      text: `Ano Rotario ${anoRotario}`,
      value: anoRotario,
      id: anoRotario,
    }));

    this.buscaEquipe();
  }

  async buscaEquipe() {
    this.carregando = true;
    // força a renderização quando troca o ano rotário
    setTimeout(async () => {
      this.rdrs = this.$cache.obter(`rdrs-${this.anoRotario}`);

      if (!this.rdrs) {
        this.rdrs = await this.$cloud.buscarRDRs(this.anoRotario);
        this.$cache.salvar(`rdrs-${this.anoRotario}`, this.rdrs);
      }

      this.carregando = false;
    }, 1000);
  }
}
