
import { Component, Vue } from "vue-property-decorator";
import Loader from "@/components/Loader.vue";

@Component({
  components: { Loader },
})
export default class Autenticacao extends Vue {
  carregando = false;
  reenvioDeEmail = false;
  emailEnviadoComSucesso = false;
  erro = "";

  async login(dadosLogin: { email: string; senha: string }): Promise<void> {
    const { email: _email, senha } = dadosLogin;
    this.carregando = true;
    this.erro = "";

    const email = _email.toLowerCase().trim();
    const { usuarioExiste, aceitouTermos } = await this.$cloud.buscarDadosLogin(email);

    if (!usuarioExiste) {
      // Por segurança, não informa somente que o usuáruo não existe.
      this.$emit("erro", "E-mail ou senha inválidos ou usuário não existe.");
      this.carregando = false;
      return;
    }

    const usuarioLogado = await this.$parse
      .logIn(email, senha)
      .catch((e) => this.$notificacao.mensagemErro(e));

    if (!usuarioLogado) {
      this.$emit("erro", "E-mail ou senha inválidos ou usuário não existe.");
      this.carregando = false;
      return;
    }

    const dadosUsuario = await this.$utils.getMeusDados();

    if (!dadosUsuario) {
      const id = usuarioLogado.id
      if (usuarioLogado) await this.$parse.logOut();
      this.$emit(
        "erro",
        `Usuário não está vinculado a nenhum cadastro, favor entrar em contato com o suporte@rotaractbrasil.com.br (ID: ${id})`
      );
      this.carregando = false;
    }

    if (!aceitouTermos) {
      this.$emit("aceitarTermos");
      this.carregando = false;
      return;
    }

    this.$emit("autenticado");
    this.carregando = false;
  }

  async reenviarEmail() {
    this.erro = "";
    this.reenvioDeEmail = false;
    await this.$parse
      .resetPassword(this.$store.state.email)
      .catch((e) => this.$notificacao.mensagemErro(e));
    this.emailEnviadoComSucesso = true;
    setTimeout(() => (this.emailEnviadoComSucesso = false), 5000);
  }
}
