
import { Component, Vue } from "vue-property-decorator";

import Loader from "@/components/Loader.vue";
import CarregandoInfo from "@/components/CarregandoInfo.vue";
import CRUDProjeto from "./CRUDProjeto.vue";
import Section from "@/components/ui/Section.vue";
import Divider from "@/components/ui/Divider.vue";
import Button from "@/components/ui/Button.vue";
import { Permissao } from "@/plugin/permissoes/permissoes.interfaces";
import { ParseObject } from "@/plugin/parse/parse.interfaces";
import { IVueFormulateOption } from "@/plugin/utils/utils.interfaces";
import { ASSOCIADO_ATIVO, CLUBE_DESATIVADO } from "@/plugin/utils/utils.plugin";

@Component({
  components: {
    Loader,
    CarregandoInfo,
    Section,
    Divider,
    Button,
    CRUDProjeto,
  },
})
export default class VerProjeto extends Vue {
  carregando = true;
  editando = false;
  reabrindo = false;
  excluindo = false;

  projeto: any = null;
  envolvidos: any = {};
  faixasEtarias: any = {};
  grausDeDificuldade: any = {};

  podeEditarProjeto = false;
  podeReabrirProjeto = false;
  deletando = false;
  possuiPermissaoDeExcluirProjeto = false;

  distritos: IVueFormulateOption[] = [];
  distritoParaVinculo: ParseObject | null = null;
  clubeParaVinculo: ParseObject | null = null;
  clubesDistrito: IVueFormulateOption[] = [];
  carregandoClubes = false;
  vinculandoProjeto = false;
  podeVincularClube = false;

  camposRelatorioFinanceiro = [
    {
      key: "data",
      label: "Data",
      thStyle: "width: 15%",
    },
    {
      key: "descricao",
      label: "Descrição",
      thStyle: "width: 70%",
    },
    {
      key: "valor",
      label: "Valor",
      thStyle: "width: 15%",
    },
  ];

  camposCronograma = [
    {
      key: "data",
      label: "Data",
      thStyle: "width: 15%",
    },
    {
      key: "atividade",
      label: "Atividade",
      thStyle: "width: 85%",
    },
  ];

  async created() {
    const { envolvidos, faixasEtarias, grausDeDificuldade } =
      await this.$utils.getParametrosProjeto();

    envolvidos.forEach((item: { id: string | number; label: any }) => {
      this.envolvidos[item.id] = item.label;
    });

    faixasEtarias.forEach((item: { id: string | number; label: any }) => {
      this.faixasEtarias[item.id] = item.label;
    });

    grausDeDificuldade.forEach((item: { id: string | number; label: any }) => {
      this.grausDeDificuldade[item.id] = item.label;
    });

    this.carregaInfosProjeto();
  }

  async carregaInfosProjeto() {
    this.carregando = true;
    const idProjeto = this.$route.params.id_projeto;

    const idAntiga = Number(idProjeto);
    if (Number.isInteger(idAntiga))
      this.projeto = await this.$parse
        .getFirst({
          className: "Projeto",
          where: [["ID", "=", idAntiga]],
        })
        .catch((e) => this.$notificacao.mensagemErro(e));
    else
      this.projeto = await this.$parse
        .getById("Projeto", idProjeto)
        .catch((e) => this.$notificacao.mensagemErro(e));

    if (!this.projeto) {
      this.$notificacao.erro({
        titulo: "Ops!",
        texto: "Este projeto não existe ou a id está invalida!",
      });

      this.$router.push("/projetos");
    }

    const meuClube =
      this.projeto.get("clube") &&
      this.projeto.get("clube").id === (await this.$utils.getMeuClube()).id;

    const meusDados = await this.$utils.getMeusDados();
    const associacaoAtual = meusDados && meusDados.get("associacaoAtual");
    const naoEstaDesligado =
      associacaoAtual &&
      associacaoAtual.get("statusAssociacao") === ASSOCIADO_ATIVO;

    this.podeEditarProjeto =
      naoEstaDesligado &&
      (meuClube || this.$permissoes.possui(Permissao.EDITAR_QUALQUER_PROJETO));

    this.podeReabrirProjeto = this.$permissoes.possui(
      Permissao.REABRIR_PROJETOS
    );

    this.possuiPermissaoDeExcluirProjeto = this.$permissoes.possui(
      Permissao.EXCLUIR_PROJETOS
    );

    this.podeVincularClube = this.$permissoes.possui(
      Permissao.TI_VINCULAR_PROJETO_A_UM_CLUBE
    );

    if (!this.projeto.get("clube")) await this.carregaDistritos();

    this.carregando = false;
  }

  async reabrirProjeto() {
    this.reabrindo = true;
    this.projeto.add("reaberturas", new Date().toLocaleString());
    await this.$parse
      .updateObject(this.projeto, { cadastroFinalizado: false })
      .catch((e) => this.$notificacao.mensagemErro(e));
    this.reabrindo = false;
  }

  async excluirProjeto() {
    const confirmacao = await this.$notificacao.confirmar({
      texto: "Tem certeza que deseja excluir este projeto?",
    });

    if (!confirmacao) return;

    this.deletando = true;
    await this.$parse
      .deleteObject(this.projeto)
      .catch((e) => this.$notificacao.mensagemErro(e));

    const clubeProjeto = this.projeto.get("clube");

    if (!clubeProjeto) return this.$router.push("/projetos");

    this.$router.push(`/clube/${clubeProjeto.id}/projetos`);
  }

  async solicitarExclusao() {
    const confirmacao = await this.$notificacao.confirmar({
      texto: "Tem certeza que deseja solicitar a exclusão este projeto?",
    });

    if (!confirmacao) return;

    this.carregando = true;

    const meusDados = await this.$utils.getMeusDados();

    const solicitacaoDeExclusao = await this.$parse
      .saveObject("SolicitacaoExclusaoProjeto", {
        projeto: this.projeto,
        solicitadoPor: meusDados,
      })
      .catch((e) => this.$notificacao.mensagemErro(e));

    await this.$parse
      .updateObject(this.projeto, {
        paraExclusao: true,
        solicitacaoDeExclusao,
      })
      .catch((e) => this.$notificacao.mensagemErro(e));
    this.carregando = false;
  }

  async carregaDistritos() {
    let distritos = this.$cache.obter("distritos-brasileiros");

    if (!distritos) {
      distritos = await this.$cloud.buscarDistritosBrasileiros();
      this.$cache.salvar("distritos-brasileiros", distritos);
    }

    this.distritos = this.$utils.converteParaVFOptions(distritos, {
      label: "numero",
    });
  }

  async carregaClubes() {
    if (!this.distritoParaVinculo) return;

    this.carregandoClubes = true;

    let clubes = this.$cache.obter(
      `clubes-todos-${this.distritoParaVinculo.id}`
    );

    if (!clubes) {
      clubes = await this.$cloud.buscarClubesDistrito({
        id: this.distritoParaVinculo.id,
      });
      this.$cache.salvar(`clubes-todos-${this.distritoParaVinculo.id}`, clubes);
    }

    this.clubesDistrito = this.$utils.converteParaVFOptions(clubes, {
      fnLabel: (clube: ParseObject) => {
        return clube.get("clubeStatus") == CLUBE_DESATIVADO
          ? `${clube.get("nome")} (desativado)`
          : clube.get("nome");
      },
    });
    this.carregandoClubes = false;
  }

  async vincularProjeto() {
    if (!this.distritoParaVinculo || !this.clubeParaVinculo) return;
    this.vinculandoProjeto = true;

    const sucesso = await this.$parse
      .updateObject(this.projeto, {
        clube: this.clubeParaVinculo,
        distrito: this.distritoParaVinculo,
      })
      .catch((e) => this.$notificacao.mensagemErro(e));

    this.vinculandoProjeto = false;
    if (!sucesso)
      this.$notificacao.erro({
        texto: "Erro ao vincular projeto",
      });

    this.$bvModal.hide("modal-vincular-clube-projeto");
  }
}
