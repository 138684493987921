
import { Component, Prop, Vue } from "vue-property-decorator";
import { ParseObject } from "@/plugin/parse/parse.interfaces";
import Loader from "@/components/Loader.vue";
import TituloPrincipal from "@/components/TituloPrincipal.vue";
import Section from "@/components/ui/Section.vue";
import Divider from "@/components/ui/Divider.vue";
import Button from "@/components/ui/Button.vue";
import { IVueFormulateOption } from "@/plugin/utils/utils.interfaces";
import {
  IItemRelatorioFinanceiro,
  IItemCronogramaAtividade,
  IDadosCadastroProjeto,
} from "@/plugin/parse/cloud.interfaces";

const TEMPO_DE_ATUALIZACAO_DO_PROJETO = 120000; // em milissegundos (padrão 2 minutos)

@Component({
  components: {
    Loader,
    TituloPrincipal,
    Section,
    Divider,
    Button,
  },
})
export default class CRUDProjeto extends Vue {
  /**
   * Esse parametro é inicializado pelo componente de visualizar.
   *
   * Quando o usuário clica em editar um projeto, o projeto a ser
   * editado é passado por parametro para esse componente.
   *
   * Caso esse parametro seja undefined, significa que quem está chamando
   * esse componente é a rota para criar um novo projeto.
   */
  @Prop() projetoParaEdicao!: ParseObject;

  /**
   * Esta variável armazenará um novo projeto inicialmente.
   *
   * Caso o componente seja chamado pelo componente de editar um projeto,
   * essa variável será sobrescrita com o projeto a ser editado.
   */
  projeto: ParseObject = this.$parse.newObject("Projeto");

  carregando = true;
  salvando = false;
  enviado = false;
  erro = "";

  intervaloAutoSave: any;

  /**
   * Essas variáveis abaixo são responsáveis por armazenar os as opções disponíveis dentro de um projeto.
   *
   * categorias: A lista de todas as categorias, que serão exibidas no select;
   * areasDeEnfoque: A lista de todas as areasDeEnfoque, que serão exibidas no select;
   * envolvidos: A lista de todas as opções de envolvidos (Rotaractianos, Rotarianos, ...);
   * faixasEtarias: A lista de todas as faixas etárias nos checkboxes de público alvo;
   * dificuldade: A lista de todas as dificuldades, que serão exibidas no select,
   */
  categorias: IVueFormulateOption[] = [];
  areasDeEnfoque: IVueFormulateOption[] = [];
  envolvidos: any = [];
  faixasEtarias: any = [];
  grausDeDificuldade: any = {};

  /**
   * Por algum motivo, ao salvar um projeto, a categoria é atualizada para uma string vazia se apenas passar o valor para o FormulateInput
   *
   * Isso provavelmente é um bug do FormulateInput do tipo 'select'.
   *
   * A solução foi em vez de passar valor, salvar em uma variável separada e usar o v-model no FormulateInput
   */
  categoriaSelecionada: any = null;
  areaDeEnfoqueSelecionada: any = null;

  relatorioFinanceiro = [
    {
      data: "",
      descricao: "",
      valor: 0,
    },
  ];

  cronogramaDeAtividades = [
    {
      data: "",
      atividade: "",
    },
  ];

  emAndamento = true;
  finalizarEdicao = false;
  autoSalvarHabilitado = false;

  dadosProjetoForm: any = {
    titulo: "",
    categoria: "",
    iniciadoEm: "",
  };

  async created() {
    this.carregando = true;

    if (this.projetoParaEdicao) {
      this.projeto = this.projetoParaEdicao;
      const relatorioFinanceiro = this.projetoParaEdicao?.get(
        "relatorioFinanceiro"
      );
      if (relatorioFinanceiro && relatorioFinanceiro.length > 0)
        this.relatorioFinanceiro = relatorioFinanceiro;

      const cronogramaDeAtividades = this.projetoParaEdicao?.get("cronograma");
      if (cronogramaDeAtividades && cronogramaDeAtividades.length > 0)
        this.cronogramaDeAtividades = cronogramaDeAtividades;

      this.categoriaSelecionada = this.projetoParaEdicao?.get("categoria")?.id;
      this.areaDeEnfoqueSelecionada =
        this.projetoParaEdicao?.get("areaDeEnfoque")?.id;
    }

    // Parametros já formatados para o FormulateInput
    let parametrosProjetoFormatados = this.$cache.obter(
      "parametros-projeto-formatados"
    );

    if (!parametrosProjetoFormatados) {
      const parametrosProjeto = await this.$cloud.buscarDadosProjeto();
      const {
        categorias,
        areasDeEnfoque,
        envolvidos,
        faixasEtarias,
        grausDeDificuldade,
      } = parametrosProjeto;

      parametrosProjetoFormatados = {
        categorias: this.$utils.converteParaVFOptions(categorias, {
          label: "nome",
          value: "id",
        }),
        areasDeEnfoque: this.$utils.converteParaVFOptions(areasDeEnfoque, {
          label: "nome",
          value: "id",
        }),
        envolvidos: envolvidos.sort(
          (a: ParseObject, b: ParseObject) => a?.get("ID") - b?.get("ID")
        ),
        faixasEtarias: faixasEtarias.sort(
          (a: ParseObject, b: ParseObject) => a?.get("ID") - b?.get("ID")
        ),
        grausDeDificuldade: this.$utils.converteParaVFOptions(
          grausDeDificuldade,
          {
            label: "nome",
            value: "ID",
          }
        ),
      };

      parametrosProjetoFormatados.areasDeEnfoque.unshift({
        id: "sem-area-de-enfoque",
        label: "Selecioar Área de Enfoque",
        value: null,
      });

      this.$cache.salvar(
        "parametros-projeto-formatados",
        parametrosProjetoFormatados
      );
    }

    this.categorias = this.categorias = parametrosProjetoFormatados.categorias;
    this.areasDeEnfoque = parametrosProjetoFormatados.areasDeEnfoque;

    this.envolvidos = parametrosProjetoFormatados.envolvidos;
    this.faixasEtarias = parametrosProjetoFormatados.faixasEtarias;
    this.grausDeDificuldade = parametrosProjetoFormatados.grausDeDificuldade;

    this.carregando = false;
  }

  async salvarProjeto(dadosProjeto: any) {
    if (this.salvando) return;
    this.enviado = true;
    this.salvando = true;

    // Remove itens do relatório financeiro que não estiverem preenchidos
    dadosProjeto.relatorioFinanceiro =
      dadosProjeto?.relatorioFinanceiro?.filter(
        ({ data, descricao, valor }: IItemRelatorioFinanceiro) =>
          data && descricao && valor
      ) || [];

    // Remove itens do cronograma que não estiverem preenchidos
    dadosProjeto.cronograma =
      dadosProjeto?.cronograma?.filter(
        ({ data, atividade }: IItemCronogramaAtividade) => data && atividade
      ) || [];

    if (dadosProjeto?.envolvidos) {
      const [envolvidos = {}] = dadosProjeto?.envolvidos;
      dadosProjeto.envolvidos = envolvidos;
    }

    if (dadosProjeto?.publicoAlvo) {
      const [publicoAlvo = {}] = dadosProjeto?.publicoAlvo;
      dadosProjeto.publicoAlvo = publicoAlvo;
    }

    // Se o grau de dificuldade estiver em branco
    const dificuldade = Number(dadosProjeto.dificuldade);
    dadosProjeto.dificuldade = Number.isNaN(dificuldade) ? null : dificuldade;

    dadosProjeto.palavrasChave = dadosProjeto.palavrasChave?.split(",") || [];

    const clube = await this.$utils.getMeuClube();

    const dados: IDadosCadastroProjeto = {
      ...dadosProjeto,
      emAndamento: this.emAndamento,
      cadastroFinalizado: !this.emAndamento,
    };

    if (!this.projetoParaEdicao) dados.idClube = clube.id;

    const idProjeto = this.projetoParaEdicao?.id;

    const projetoSalvo = await this.$cloud
      .salvarProjeto({ idProjeto, dados })
      .catch((e) => {
        this.enviado = false;
        this.salvando = false;
        this.erro = e.message;
      });

    this.enviado = false;
    this.salvando = false;

    if (!this.projetoParaEdicao && projetoSalvo)
      this.$router.push({
        name: "visualizar-projeto",
        params: { id_projeto: projetoSalvo.id },
      });
    this.$emit("edicaoFinalizada", true);
  }

  /**
   * Esse método verifica no intervalo de tempo do TEMPO_DE_ATUALIZACAO_DO_PROJETO (2 min) teve seu progresso já salvo.
   * Caso não tenha sido salvo há mais de dois minutos, salva o projeto automaticamente.
   */
  async salvarProgresso() {
    if (this.salvando) return;

    // console.log("Salvando progresso do projeto");
    this.intervaloAutoSave = setInterval(() => {
      const ultimaAtualizacao: Date = this.projetoParaEdicao.get("updatedAt");

      const progressoParaSalvar =
        ultimaAtualizacao &&
        ultimaAtualizacao.getTime() + TEMPO_DE_ATUALIZACAO_DO_PROJETO <
          new Date().getTime();
      if (!progressoParaSalvar) return;

      // this.salvarProjeto("andamento");
    }, TEMPO_DE_ATUALIZACAO_DO_PROJETO);
  }

  // finalizarEdicaoProjeto(progresso: "andamento" | "finalizado") {
  //   this.finalizarEdicao = true;
  //   // this.salvarProjeto(progresso);
  // }

  /**
   * Esse método é chamado ao clicar em cancelar a edição de um projeto.
   *
   * Se foi clicado na edição de um projeto, finaliza a edição sem salvar as alterações.
   *
   * Se foi clicado no cadastro de um novo projeto, retorna para a URL projetos, excluindo automaticamente o projeto (pois não estava salvo).
   */
  cancelarEdicao() {
    clearInterval(this.intervaloAutoSave);
    if (this.projetoParaEdicao) return this.$emit("edicaoFinalizada", true);
    this.$router.push({ name: "projetos" });
  }
}
