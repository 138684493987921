
import CardUsuario from "@/components/CardUsuario.vue";
import SecaoVazia from "@/components/SecaoVazia.vue";
import Section from "@/components/ui/Section.vue";
import { ParseObject } from "@/plugin/parse/parse.interfaces";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {
    CardUsuario,
    SecaoVazia,
    Section,
  },
})
export default class TransferenciasPendentes extends Vue {
  @Prop({ required: true })
  clube!: ParseObject;

  transferencias: any = {
    pendentes: [],
    solicitadas: [],
  };

  idTransferenciaSendoAtualizada = "";
  pendentesOuSolicitadas = "pendentes";
  colunas = [
    { associado: "Associado(a)" },
    { cargo: "Cargo" },
    { anoRotario: "Ano Rotário" },
    { aprovar: "Aprovar" },
    { recusar: "Recusar" },
  ];
  temPendencias = false;

  created() {
    const transferencias: any = this.$cache.obter(
      `dados-clube-${this.clube.id}.pendencias.transferencias`
    );

    this.transferencias = transferencias || {
      pendentes: [],
      solicitadas: [],
    };

    this.temPendencias =
      this.transferencias.pendentes.length > 0 ||
      this.transferencias.solicitadas.length > 0;

    if (!this.transferencias.pendentes.length)
      this.pendentesOuSolicitadas = "solicitadas";

    if (this.temPendencias) this.$emit("temPendencias");
  }

  async aprovarTransferencia(transferencia: any) {
    const confirmado = await this.$notificacao.confirmar({
      titulo: "Aprovar",
      html: "Você deseja aprovar a transferência dessa associação?",
    });

    if (!confirmado) return;

    this.idTransferenciaSendoAtualizada = transferencia.id;

    const success = await this.$cloud
      .aprovarTransferencia(transferencia.id)
      .catch((e) => this.$notificacao.mensagemErro(e));

    this.idTransferenciaSendoAtualizada = "";

    if (!success) return;

    this.removeTransferencia(transferencia);
    this.$emit("transferenciaAprovada", transferencia);
  }

  async recusarTransferencia(transferencia: any) {
    const confirmado = await this.$notificacao.confirmar({
      titulo: "Recusar",
      texto: "Tem certeza que deseja recusar a transferência dessa associação?",
    });

    if (!confirmado) return;

    this.idTransferenciaSendoAtualizada = transferencia.id;

    const success = await this.$parse
      .updateObject(transferencia, { pendente: false, recusado: true })
      .catch((e) => this.$notificacao.mensagemErro(e));

    this.idTransferenciaSendoAtualizada = "";

    if (!success) return;

    this.removeTransferencia(transferencia);
  }

  removeTransferencia(transferencia: ParseObject) {
    this.transferencias[this.pendentesOuSolicitadas] = this.transferencias[
      this.pendentesOuSolicitadas
    ].filter(
      (_transferencia: ParseObject) => _transferencia.id !== transferencia.id
    );

    this.$cache.removerDaLista(
      `dados-clube-${this.clube.id}.pendencias.transferencias.${this.pendentesOuSolicitadas}`,
      transferencia
    );

    const idDistrito = this.clube.get("distrito").id;
    this.$cache.removerDaLista(
      `dados-distrito-${idDistrito}.transferenciasPendentes`,
      transferencia
    );
  }
}
