
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class Rodape extends Vue {
  anoAtual = new Date().getFullYear();
  termoDeUso: any = null;

  async created() {
    this.termoDeUso = await this.$parse.getFirst({ className: "TermoDeUso" }).catch(e => this.$notificacao.mensagemErro(e));
  }
}
