import _Vue from "vue";
import UtilsPlugin from "./utils.plugin";

const utils = {
    install(app: typeof _Vue): void {
        const utils = new UtilsPlugin();
        app.prototype.$utils = utils;
    },
}

declare module 'vue/types/vue' {
    interface Vue {
        $utils: UtilsPlugin;
    }
}

export default utils;