import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// import "@/assets/css/_colors.css";

// import "@/assets/js/vendor/res-poll/public.css";
// import "@/assets/js/vendor/res-poll/plugin.responsive-poll.css";
// import "@/assets/js/vendor/eventon/eventon_styles.css";
// import "@/assets/js/vendor/eventon/fc_styles.css";
// import "@/assets/js/vendor/eventon/plugin.eventon.css";
// import "@/assets/css/fonts/stylesheet.css";
import "@/assets/css/fontello/css/fontello.css";
import "@/assets/css/fontello/css/animation.css";
import "@/assets/css/style.css";
// import "@/assets/css/template.animation.css";
import "@/assets/css/template.shortcodes.css";
// import "@/assets/css/template.colors.css";
import "@/assets/css/custom.css";
// import "@/assets/css/plugins.css";
// import "@/assets/css/template.messages.css";
// import "@/assets/css/responsive.css";

import 'vue-advanced-cropper/dist/style.css';

import "@/assets/images/cropped-512x512-32x32.png";
import "@/assets/images/blank-avatar.png";

import VueMeta from 'vue-meta';
Vue.use(VueMeta);

Vue.config.productionTip = false;

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)

// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

// Make parse plataform available throughout your project
import parse from "./plugin/parse";
Vue.use(parse);

// Plugin com funções úteis do sistema
import utils from "./plugin/utils";
Vue.use(utils);

// Plugin com funções de notificacao do sistema
import notificacao from "./plugin/notificacao";
Vue.use(notificacao);

import permissoes from "./plugin/permissoes";
Vue.use(permissoes);

import cache from "./plugin/cache";
Vue.use(cache);

// Loads VueFormulate
const { default: VueFormulate } = require('@braid/vue-formulate')
const { default: VueFormulateDatepickerPlugin } = require("vue-formulate-datepicker");
// FIX: Not working
// const { pt } = require('@braid/vue-formulate-i18n')
// Add styles if need
import "@sum.cumo/vue-datepicker/dist/Datepicker.css";
// import '@braid/vue-formulate/themes/snow/snow.scss';

Vue.use(VueFormulate, {
    plugins: [VueFormulateDatepickerPlugin],
});

import VueFilterDateFormat from '@vuejs-community/vue-filter-date-format';
Vue.use(VueFilterDateFormat, {
    dayOfWeekNames: [
        'Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira',
        'Sexta-feira', 'Sabado'
    ],
    dayOfWeekNamesShort: [
        'Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'
    ],
    monthNames: [
        'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
        'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
    ],
    monthNamesShort: [
        'Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun',
        'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'
    ],
    // Timezone offset, em minutos (0 - UTC, -180 - Brasilia, undefined - atual)
    timezone: -180
});


Vue.filter('dois-digitos', (value: any) => {
    if (value < 0) {
        return '00';
    }
    if (value.toString().length <= 1) {
        return `0${value}`;
    }
    return value;
});

// import "../node_modules/@ckeditor/ckeditor5-build-classic/build/ckeditor.js"
// import "../node_modules/@ckeditor/ckeditor5-vue2/dist/ckeditor.js"></script>
import "@ckeditor/ckeditor5-build-classic"
// import CKEditor from '@ckeditor/ckeditor5-vue2';

// Vue.use(CKEditor);


// import {default as VueHtml2pdf } from 'vue-html2pdf'
// Vue.use(VueHtml2pdf);

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2, {
    confirmButtonColor: '#f9136b',
    customClass: {
        confirmButton: 'fw-bold'
    }
});

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
