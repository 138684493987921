var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.associacao)?_c('B-Modal',{attrs:{"id":"modal-editar-associacao","size":"lg","title":"Editar Associação","title-class":"mx-auto text-uppercase","ok-title":"Salvar","cancel-title":"Cancelar","no-close-on-backdrop":"","no-close-on-esc":"","hide-footer":true},on:{"hidden":function($event){return _vm.$emit('cancelar')}}},[_c('FormulateForm',{ref:"form",on:{"submit":function($event){return _vm.salvarAssociacao()}},model:{value:(_vm.dadosAssociacao),callback:function ($$v) {_vm.dadosAssociacao=$$v},expression:"dadosAssociacao"}},[_c('div',{staticClass:"row"},[(_vm.podeEditarClube)?[_c('div',{staticClass:"col-md-4"},[_c('h6',{staticClass:"mt-3 mb-2"},[_vm._v("Distrito")]),_c('FormulateInput',{staticClass:"w-100",attrs:{"options":_vm.distritos,"disabled":_vm.salvando,"type":"select","name":"distrito","placeholder":"Selecionar Distrito"},on:{"input":function($event){return _vm.carregaClubes()}},model:{value:(_vm.distritoSelecionado),callback:function ($$v) {_vm.distritoSelecionado=$$v},expression:"distritoSelecionado"}})],1),_c('div',{staticClass:"col-md-8"},[_c('h6',{staticClass:"mt-3 mb-2"},[_vm._v("Clube")]),_c('FormulateInput',{staticClass:"w-100",attrs:{"options":_vm.clubes,"type":"select","name":"clube","disabled":!_vm.distritoSelecionado || _vm.carregandoClubes || _vm.salvando,"placeholder":"Selecionar Clube","validation":"required","validation-messages":{
              required: 'Clube é obrigatório'
            }},model:{value:(_vm.clubeSelecionado),callback:function ($$v) {_vm.clubeSelecionado=$$v},expression:"clubeSelecionado"}})],1)]:_vm._e(),_c('div',{staticClass:"col-md-4"},[_c('h6',{staticClass:"mt-3 mb-2"},[_vm._v("Fundador(a)")]),_c('FormulateInput',{staticClass:"w-100",attrs:{"name":"fundador","disabled":_vm.salvando,"type":"select","value":_vm.associacao.get('fundador') ? 'Sim' : 'Não',"options":['Não', 'Sim']}})],1),_c('div',{staticClass:"col-md-4"},[_c('h6',{staticClass:"mt-3"},[_vm._v("Data de posse: ")]),_c('FormulateInput',{staticClass:"w-100",attrs:{"type":"date","name":"posse","disabled":_vm.salvando,"value":_vm.associacao.get('posse') ? _vm.associacao.get('posse').toISOString().split('T')[0]: '',"validation":"required","validation-messages":{
          required: 'campo obrigatório'
        }}})],1),_c('div',{staticClass:"col-md-4"},[_c('h6',{staticClass:"mt-3 mb-2"},[_vm._v("Status")]),_c('FormulateInput',{staticClass:"w-100",attrs:{"name":"statusAssociacao","disabled":_vm.salvando,"type":"select","options":{ 1:'Ativo(a)', 0:'Desligado(a)' }},model:{value:(_vm.statusAssociacao),callback:function ($$v) {_vm.statusAssociacao=$$v},expression:"statusAssociacao"}})],1),(_vm.statusAssociacao == '0')?_c('div',{staticClass:"col-md-4 ms-auto"},[_c('h6',{staticClass:"mt-3"},[_vm._v("Data de desligamento: ")]),_c('FormulateInput',{staticClass:"w-100",attrs:{"type":"date","name":"desligamento","disabled":_vm.salvando,"value":_vm.associacao.get('desligamento') ? _vm.associacao.get('desligamento').toISOString().split('T')[0]: '',"validation":"required","validation-messages":{
            required: 'campo obrigatório'
          }}})],1):_vm._e(),(_vm.statusAssociacao == '0')?[_c('div',{staticClass:"col-md-4 me-auto"},[_c('h6',{staticClass:"mt-3"},[_vm._v("Honorário(a) ")]),_c('FormulateInput',{staticClass:"w-100",attrs:{"type":"select","name":"honorario","disabled":_vm.salvando,"options":['Sim', 'Não']},model:{value:(_vm.honorario),callback:function ($$v) {_vm.honorario=$$v},expression:"honorario"}})],1),(_vm.honorario == 'Sim')?_c('div',{staticClass:"col-md-4 me-auto"},[_c('h6',{staticClass:"mt-3"},[_vm._v("Honorário(a) desde: ")]),_c('FormulateInput',{staticClass:"w-100",attrs:{"type":"date","name":"posseHonorario","disabled":_vm.salvando,"value":_vm.associacao.get('posseHonorario') ? _vm.associacao.get('posseHonorario').toISOString().split('T')[0]: '',"validation":"required","validation-messages":{
            required: 'campo obrigatório'
          }}})],1):_vm._e()]:_vm._e(),(_vm.precisaJustificar)?_c('div',{staticClass:"col-12"},[_c('h6',{staticClass:"mt-3"},[_vm._v("Justificativa")]),_c('FormulateInput',{attrs:{"type":"textarea","name":"justificativa","placeholder":"Justifique ao clube os motivos pelos quais você deseja alterar as informações desta associação.","disabled":_vm.salvando,"help":`${_vm.justificativa.length}/200.`,"validation":"^bail|max:200,length|min:30,length","validation-messages":{
            required: 'Justificativa é obrigatória.',
            max: 'A justificativa precisa conter menos de 200 caracteres.',
            min: 'A justificativa precisa conter pelo menos 30 caracteres.'
          }},model:{value:(_vm.justificativa),callback:function ($$v) {_vm.justificativa=$$v},expression:"justificativa"}})],1):_vm._e()],2),(!_vm.salvando)?_c('div',{staticClass:"d-flex justify-content-between mt-3"},[_c('div',[(_vm.associacao.get('statusAssociacao') == 0)?_c('Button',{staticClass:"me-2 text-danger",attrs:{"color":"default"},on:{"click":function($event){_vm.cancelar = true; _vm.$emit('excluir'); _vm.$bvModal.hide('modal-editar-associacao')}}},[_vm._v("Remover")]):_vm._e()],1),_c('div',[_c('Button',{staticClass:"me-2",attrs:{"color":"default"},on:{"click":function($event){_vm.cancelar = true; _vm.$bvModal.hide('modal-editar-associacao')}}},[_vm._v("Cancelar")]),_c('Button',{staticClass:"float-end",attrs:{"color":"cranberry","type":"submit","disabled":_vm.salvando},on:{"click":function($event){_vm.cancelar = false;}}},[_vm._v("Salvar")])],1)]):_vm._e(),(_vm.salvando)?_c('Loader',{staticClass:"float-end"}):_vm._e()],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }