
import { Component, Prop, Vue } from "vue-property-decorator";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Loader from "@/components/Loader.vue";
import Button from "@/components/ui/Button.vue";
import { ParseObject } from "@/plugin/parse/parse.interfaces";

@Component({
  components: {
    Loader,
    Button,
  },
})
export default class CRUDTermoDeUso extends Vue {
  salvando = false;

  @Prop({ required: true })
  termo!: ParseObject;

  editor = ClassicEditor;
  texto: string = "";

  editorConfig = {
    // The configuration of the editor.
  };

  created() {
    this.texto = this.termo.get("texto") || "";
  }

  async salvar() {
    this.salvando = true;
    const termoSalvo = await this.$parse.saveObject("TermoDeUso", {
      texto: this.texto,
    }).catch(e => this.$notificacao.mensagemErro(e));
    if (!termoSalvo) return;
    this.salvando = false;
    this.$emit("salvo", "true");
  }

  cancelar() {
    this.$emit("cancelar");
  }
}
