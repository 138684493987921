
import CardUsuario from "@/components/CardUsuario.vue";
import Section from "@/components/ui/Section.vue";
import { ParseObject } from "@/plugin/parse/parse.interfaces";
import { Component, Prop, Vue } from "vue-property-decorator";
import UsuarioFotoNome from "@/components/UsuarioFotoNome.vue";

@Component({
  components: {
    CardUsuario,
    Section,
    UsuarioFotoNome,
  },
})
export default class AlteracoesAssociacoesDistrito extends Vue {
  @Prop({ required: true })
  distrito!: ParseObject;

  alteracoesPendentes: ParseObject[] = [];
  idAssociacaoSendoAtualizada: string = "";

  salvando = false;

  colunas = [
    { key: "associado", label: "Associado(a)", thStyle: "width: 20%" },
    { key: "clube", label: "Clube", thStyle: "width: 20%" },
    { key: "alteracoes", label: "Alterações", thStyle: "width: 30%" },
    { key: "justificativa", label: "Justificativa", thStyle: "width: 20%" },
    { key: "acao", label: "Ação", thStyle: "width: 10%" },
  ];

  created() {
    const associacoes: ParseObject[] =
      this.$cache.obter(
        `dados-distrito-${this.distrito.id}.alteracoesDeAssociacaoPendentes`
      ) || [];

    this.alteracoesPendentes = associacoes;

    if (this.alteracoesPendentes.length) this.$emit("temPendencias");
  }

  async aprovarAlteracoesAssociacao(alteracoesAssociacao: ParseObject) {
    this.idAssociacaoSendoAtualizada = alteracoesAssociacao.id;
    const confirmado = await this.$utils.aprovarAlteracoesAssociacao(
      alteracoesAssociacao
    );
    this.idAssociacaoSendoAtualizada = "";

    if (!confirmado) return;

    this.removeAssociacao(alteracoesAssociacao);
  }

  async recusarAlteracoesAssociacao(alteracoesAssociacao: ParseObject) {
    this.idAssociacaoSendoAtualizada = alteracoesAssociacao.id;
    const confirmado = await this.$utils.recusarAlteracoesAssociacao(
      alteracoesAssociacao
    );
    this.idAssociacaoSendoAtualizada = "";

    if (!confirmado) return;

    this.removeAssociacao(alteracoesAssociacao);
  }

  removeAssociacao(associacao: ParseObject) {
    this.alteracoesPendentes = this.alteracoesPendentes.filter(
      (associacaoPendente) => associacaoPendente.id !== associacao.id
    );

    this.$cache.atualizar(
      `dados-distrito-${this.distrito.id}.alteracoesDeAssociacaoPendentes`,
      this.alteracoesPendentes
    );

    this.idAssociacaoSendoAtualizada = "";
  }
}
