
import { ETipoNotificacao } from "@/plugin/notificacao/notificacao.interfaces";
import { Permissao } from "@/plugin/permissoes/permissoes.interfaces";
import { Component, Prop, Vue } from "vue-property-decorator";

export interface IParametroCard {
  nome: string;
  rota: string;
  permissao: Permissao;
  desabilitado?: boolean;
  notificacao?: ETipoNotificacao;
}

@Component({
  components: {},
})
export default class CardSistema extends Vue {
  @Prop({ required: true })
  params!: IParametroCard;

  possuiNotificacao(notificacao: ETipoNotificacao) {
    return this.$notificacao.temNotificacao(notificacao);
  }
}
