import Parse from "parse";
import { SimpleQuery, CompoundQuery, operator, IGetFirstQuery, IGetListQuery, ParseObject, ICountQuery, IGetRelationFirstQuery, IGetRelationListQuery, IAddRelationObject, IRemoveRelationObject, IFileParams, ISavedFile, IParseFile, ISingUpParams, IParseConfig } from "./parse.interfaces";

export default class ParsePlugin {
    private _usuarioLogado: Parse.User | undefined; // usuário logado

    private _config: IParseConfig;

    constructor(params: IParseConfig) {
        this._config = params;
        Parse.serverURL = params.serverURL || ''
        Parse.initialize(
            params.appId || '',
            params.javascriptKey
        );
        if (params.enableLocalDatastore) Parse.enableLocalDatastore();
        this._setUser();
    }

    /**
     * Retorna o usuário logado atual no parse.
     * 
     * ----------------
     * @returns Parse.Object
     * @example
     *
     * const user = this.$parse.usuarioLogado();
     *
     */
    get usuarioLogado(): Parse.Object | undefined {
        return this._usuarioLogado || Parse.User.current();
    }

    /**
     * @private
     */
    private _setUser(): void {
        this._usuarioLogado = Parse.User.current();
    }

    private setItem(item: ParseObject, atributes: any) {
        Object.entries(atributes).forEach(([key, value]) => {
            item.set(key, value);
            if (value == null || value == undefined) item.unset(key);
        });
    }

    /**
     * Loga no parse com e-mail ou username e senha
     * 
     * ----------------
     * @param {string} emailOrUsername - Email ou username do usuário.
     * @param {string} password - Senha do usuário
     * @returns {Parse.User} O objeto do usuário logado
     * @example 
     * const loggedUser = await this.$parse.logIn('user@organization.com', 'userpwd')
     */
    async logIn(
        emailOrUsername: string,
        password: string
    ): Promise<Parse.Object | undefined> {
        await Parse.User.logIn(emailOrUsername, password);

        this._setUser();

        return this._usuarioLogado || undefined;
    }

    /**
     * Cadastra um novo usuário no parse 
     * 
     * Sempre que for criar um novo usuário, é recomendado usar esta função invés de simplesmetne criar um usuário com as funções newObject() ou saveObject().
     * 
     * ----------------
     * @param {ISingUpParams} params Os parametros para criar o usuário (email, senha e username são obrigatórios)
     * @returns {Parse.User} O usuário criado.
     * @example
     * const singUpUser = await this.$parse.signUp({
     *     email: 'user@organization.com',
     *     password: '123456#',
     *     username: 'user1'
     *     // ...
     * })
     */
    async signUp(params: ISingUpParams): Promise<Parse.Object | undefined> {
        const user = new Parse.User();
        Object.entries(params).forEach(([key, value]) => user.set(key, value))
        // this._usuarioLogado = Parse.User.current();
        return user.signUp();
    }

    /**
     * Logout o usuário atual.
     * 
     * ----------------
     * @returns {null}
     */
    async logOut(): Promise<Parse.User<Parse.Attributes>> {
        this._usuarioLogado = undefined;
        return await Parse.User.logOut();
    }

    /**
     * Envia um e-mail para redefinir a senha do usuário.
     * 
     * @param {string} email - O e-mail do usuário
     * @returns {boolean} Se o e-mail foi enviado com sucesso
     * @example
     * const success = await this.$parse.resetPass('user@organization.com')
     */
    async resetPassword(email: string): Promise<Parse.User<Parse.Attributes>> {
        return await Parse.User.requestPasswordReset(email);
    }

    /**
     * Atualiza as informações do usuário logado.
     *
     * ----------------
     * @param {object} atributes - Os parametros a serem atualizados.
     * @returns {Parse.User} O usuário atual com as informações atualizadas.
     * @example
     * await this.$parse.updatesCurrUser({
     *     name: 'jon doe', 
     *     username: 'jondoe', 
     *     age: 26,
     *     // ...
     * })
     */
    async updateCurrUser(atributes: any): Promise<Parse.Object | undefined> {
        return await this._usuarioLogado?.save(atributes);
    }

    /**
     * Cria uma nova instância de uma classe no parse.
     *
     * ----------------
     * @param {string} className - Nome da classe no parse.
     * @param {Object} atributes - Os atributos da nova instância.
     * @returns {Parse.Object} - Uma instância da classe (não salva).
     * @example
     * const newGroup = this.$parse.newObject('Group',{
     *     name: 'Group A',
     *     isPrivate: true,
     *     description: 'Lorem ipsum dolor sit amet consect...'
     *     // ...
     * })
     */
    newObject(className: string, atributes: any = null): Parse.Object {
        const obj = new Parse.Object(className);
        if (atributes) obj.set(atributes);
        return obj;
    }

    /**
     * Salva uma instância ou cria uma nova instância de uma classe no parse.
     * 
     * Se o nome da classe for passado, criará uma nova instância.
     * 
     * Se um objeto for passado, atualizará o objeto.
     * 
     * Esta função aceita um Parse Object ou um nome de uma classe com atributos, instanciando um objeto e salvando-o em seguida.
     *
     * ----------------
     * @param {string} classNameOrObject - Nome da classe no parse ou objeto do parse.
     * @param {Object} atributes - Os atributos da nova instância.
     * @param {Boolean} cascadeSave - Se os objeto deve ser salvo em cascata (o padrão é true).
     * @returns {Parse.Object} Uma nova instância da classe ou a instância salva.
     * @example
     * // Funciona passando um objeto Parse como argumento
     * const newGroup = this.$parse.newObject('Group', {
     *     name: 'Group A',
     *     type: 'private',
     *     // ...
     * })
     * await this.$parse.saveObject(newGroup);
     * 
     * // Ou passando o nome da classe e atributos.
     * const newGroup = await this.$parse.saveObject('Group', {
     *     name: 'Group A',
     *     type: 'private',
     *     // ...
     * })
     */
    async saveObject(
        classNameOrObject: string | Parse.Object,
        atributes?: any,
        cascadeSave?: boolean
    ): Promise<Parse.Object> {
        atributes.status = true;
        if (typeof classNameOrObject === 'string') {
            const item = new Parse.Object(classNameOrObject)
            this.setItem(item, atributes);
            return await item.save(null, { cascadeSave });
        }
        this.setItem(classNameOrObject, atributes);
        return await classNameOrObject.save(null, { cascadeSave });
    }

    /**
     * Retorna a instância da classe que possuir a id passada como parametro.
     *
     * ----------------
     * @param {string} className - A o nome da classe do parse
     * @param {string} objectId - A id do objeto em questão.
     * @returns {Parse.Object} O objeto, caso exista.
     * @example
     * const user = await this.$parse.getById(_User, 'tzY36E2b6V')
     */
    async getById(
        className: string,
        objectId: string,
    ): Promise<Parse.Object<Parse.Attributes>> {
        const ObjectObj = Parse.Object.extend(className);
        const query = new Parse.Query(ObjectObj);
        query.include("*");

        if (this._config.useStatus) query.notEqualTo("status", false);

        return await query.get(objectId);
    }

    /**
     * Updates the given Parse.Object
     * 
     * ----------------
     * @param {Parse.Object} item - The Object be update (not the id)
     * @param {object} atributes - An object containing the atribues.
     * @returns {Parse.Object} The saved Object
     * @example
     * const user = await this.$parse.getById('_User', 'aV21ns2G2');
     * 
     * await this.$parse.updateObject(user, {
     *     username: 'user1234',
     *     gender: 'fluid',
     *     // ...
     * })
     */
    async updateObject(
        item: Parse.Object,
        atributes: any,
    ): Promise<Parse.Object> {
        this.setItem(item, atributes);
        return await item.save();
    }

    async salvarUsuario(usuario: Parse.Object, atributos: any): Promise<Parse.User> {
        return Parse.Cloud.run('salvarUsuario', {
            usuario: usuario.id,
            atributos
        });
    }

    /**
     * Deletes the Parse.Object, seting it's status to false.
     * 
     * This action doesn't remove the object in case your parse configuration is set to use 'status' as a avaliability flag. 
     * If the 'status' flag is set to false in configurations, it will delete the object from parse permanently, having the same efect as the destroyObject function.
     * 
     * ----------------
     * @param {Parse.Object} item - The Object be deleted (not the id)
     * @returns {Promisse} If the operation was successful
     * @example 
     * await this.$parse.deleteObject(user)
     */
    async deleteObject(item: Parse.Object): Promise<Parse.Object> {
        if (!this._config.useStatus) return await this.destroyObject(item);
        return await item.save({ status: false });
    }

    /**
     * Destroy the Parse.Object.
     * 
     * ----------------
     * @param {Parse.Object} item - The Object be destroyed (not the id)
     * @returns {Promisse} If the operation was successful
     * @example
     * await this.$parse.destroyObject(user)
     */
    async destroyObject(item: Parse.Object): Promise<Parse.Object> {
        return await item.destroy();
    }

    /** @internal */
    private _queryMethods = {
        [operator.EqualTo]: function (query: Parse.Query, field: string, args: any[]) {
            query.equalTo(field, args[0]); // args[0] = any value
        },

        [operator.NotEqualTo]: function (query: Parse.Query, field: string, args: any[]) {
            query.notEqualTo(field, args[0]); // args[0] = any value
        },

        [operator.GreatherThan]: function (query: Parse.Query, field: string, args: any[]) {
            query.greaterThan(field, args[0]); // args[0] = number or date
        },

        [operator.GreatherThanOrEqualTo]: function (query: Parse.Query, field: string, args: any[]) {
            query.greaterThanOrEqualTo(field, args[0]); // args[0] = number or date
        },

        [operator.LessThan]: function (query: Parse.Query, field: string, args: any[]) {
            query.lessThan(field, args[0]); // args[0] = number or date
        },

        [operator.LessThanOrEqualTo]: function (query: Parse.Query, field: string, args: any[]) {
            query.lessThanOrEqualTo(field, args[0]); // args[0] = number or date
        },

        [operator.ContainsAll]: function (query: Parse.Query, field: string, args: any[]) {
            query.containsAll(field, args[0]); // args[0] = any[]
        },

        [operator.ContainedIn]: function (query: Parse.Query, field: string, args: any[]) {
            query.containedIn(field, args[0]); // args[0] = any[]
        },

        [operator.NotContainedIn]: function (query: Parse.Query, field: string, args: any[]) {
            query.notContainedIn(field, args[0]); // args[0] = any[]
        },

        [operator.MatchKeyInQuery]: function (query: Parse.Query, field: string, args: any[]) {
            query.matchesKeyInQuery(field, args[0], args[1]); // args[0], args[1] = foreing field, Parse.Object
        },

        [operator.DoesNotMatchKeyInQuery]: function (query: Parse.Query, field: string, args: any[]) {
            query.doesNotMatchKeyInQuery(field, args[0], args[1]); // args[0], args[1] = foreing field, Parse.Object
        },

        [operator.Matches]: function (query: Parse.Query, field: string, args: any[]) {
            query.matches(field, args[0], 'i'); // args[0] = string
        },

        [operator.Contains]: function (query: Parse.Query, field: string, args: any[]) {
            query.contains(field, args[0]); // args[0] = string
        },

        [operator.FullText]: function (query: Parse.Query, field: string, args: any[]) {
            query.fullText(field, args[0]); // args[0] = string
        },

        [operator.StartsWith]: function (query: Parse.Query, field: string, args: any[]) {
            query.startsWith(field, args[0]); // args[0] = value
        },

        [operator.EndsWith]: function (query: Parse.Query, field: string, args: any[]) {
            query.endsWith(field, args[0]); // args[0] = value
        },

        [operator.Exists]: function (query: Parse.Query, field: string, args?: any[]) {
            query.exists(field);
        },

        [operator.DoesNotExist]: function (query: Parse.Query, field: string, args?: any[]) {
            query.doesNotExist(field);
        },

        [operator.Near]: function (query: Parse.Query, field: string, args: any[]) {
            query.near(field, args[0]); // args[0] = Parse.GeoPoint
        },

        [operator.WithinKilometers]: function (query: Parse.Query, field: string, args: any[]) {
            const [location, distance, sorted] = args; // location, distance, sorted = GeoPoint, number, boolean
            !sorted ? query.withinKilometers(field, location, distance) : query.withinKilometers(field, location, distance, sorted);
        },

        [operator.WithinMiles]: function (query: Parse.Query, field: string, args: any[]) {
            const [location, distance, sorted] = args; // location, distance, sorted = GeoPoint, number, boolean
            !sorted ? query.withinMiles(field, location, distance) : query.withinMiles(field, location, distance, sorted);
        },

        [operator.WithinGeoBox]: function (query: Parse.Query, field: string, args: any[]) {
            query.withinGeoBox(field, args[0], args[1]); // arg[0], args[1] = GeoPoints
        },

        [operator.WithinPolygon]: function (query: Parse.Query, field: string, args: any[]) {
            query.withinPolygon(field, args[0]); // arg[0] = GeoPoint[]
        },

        [operator.PolygonContains]: function (query: Parse.Query, field: string, args: any[]) {
            query.polygonContains(field, args[0]); // arg[0] = GeoPoint
        },
    }

    /** @internal */
    /** @internal */
    private _setQuery(query: Parse.Query, params: SimpleQuery[]): void {
        if (!params) return;

        params.forEach((param) => {
            const field = param[0];
            const operator = param[1];
            const args = param.slice(2);
            this._queryMethods[operator](query, field, args);
        });
    }

    /** @internal */
    private _buildSimpleQuery(
        className: string,
        queryParams: SimpleQuery[] | undefined
    ): Parse.Query {
        const query = new Parse.Query(className);

        queryParams?.forEach((param) => {
            const field = param[0];
            const operator = param[1];
            const value = param[2];

            // Split the foreing field into the relation name (field), target class (relation class) and foreing field (relation field)
            const [foreing, targetClass, foreignField] = field.split(".");

            // If is a relational query
            if (targetClass && foreignField) {
                // Creates a new query for the relational table
                const relationalQuery = new Parse.Query(targetClass);
                const params: any = [[foreignField, operator, value]];
                this._setQuery(relationalQuery, params);

                // Set the matching query to the main query
                query.matchesQuery(foreing, relationalQuery);
            } else {
                const params: any = [[field, operator, value]];
                this._setQuery(query, params);
            }
        });

        return query;
    }

    /** @internal */
    private _buildCompoundQuery(
        className: string,
        queryParams: CompoundQuery | any
    ): Parse.Query | undefined {
        if (queryParams['and']) {
            return Parse.Query.and(
                this._buildSimpleQuery(className, queryParams.params),
                'params' in queryParams['and'] ?
                    <Parse.Query>this._buildCompoundQuery(className, queryParams['and']) :
                    <Parse.Query>this._buildSimpleQuery(className, queryParams['and'])
            );
        }

        if (queryParams['or']) {
            return Parse.Query.or(
                this._buildSimpleQuery(className, queryParams.params),
                'params' in queryParams['or'] ?
                    <Parse.Query>this._buildCompoundQuery(className, queryParams['or']) :
                    <Parse.Query>this._buildSimpleQuery(className, queryParams['or'])
            );
        }
    }

    /** @internal */
    private _buildQuery(className: string, queryParams: SimpleQuery[] | CompoundQuery | undefined): Parse.Query | undefined {
        if (!queryParams) return

        // If it's a CompoundQuery
        if ("params" in queryParams) {
            return this._buildCompoundQuery(className, queryParams);
        }

        return this._buildSimpleQuery(className, queryParams);
    }

    /**
     * Returns the first item that satisfies the 'where' conditions.
     *
     * ----------------
     * @async
     * @param {IGetFirstQuery} params - An object contaning the params to the query.
     * @returns {Parse.Object} The Parse.Object that matches the params or null
     * @example
     * getFirst({
     *     className: '_User',
     *     where: [['type', '=', 'admin']],
     *     // ...
     * })
     */
    async getFirst(params: IGetFirstQuery): Promise<Parse.Object | undefined> {
        const { className, where, query, orderBy, include, descending, select } = params;

        const _query =
            query || this._buildQuery(className, where) || new Parse.Query(className);

        if (descending) {
            _query.descending(orderBy || "createdAt");
        } else if (orderBy) {
            _query.ascending(orderBy || "createdAt");
        }

        if (include) _query.include(include);

        if (this._config.useStatus) _query.notEqualTo("status", false);

        if (select) _query?.select(select);

        return _query.first();
    }

    /**
     * Returns a list of Parse.Objects or an object {results: [...], count: (number)} in case the flag 'count' is set to true.
     *
     * ----------------
     * @async
     * @param {IGetListQuery} params An IGetListQuery object.
     * @return {Promise<Parse.Object[]>} A promisse of a Parse.Object list.
     * @example
     * getList({
     *     className: '_User',
     *     where: [['type', '=', 'admin']],
     *     // ...
     * })
     */
    async getList(params: IGetListQuery): Promise<ParseObject[] | { results: ParseObject[], count: number } | any> {
        const {
            className,
            where,
            query,
            orderBy,
            descending,
            skip,
            limit,
            count,
            distinct,
            include,
            exclude,
            select
        } = params;

        const _query =
            query || this._buildQuery(className, where) || new Parse.Query(className);

        if (descending) _query.descending(orderBy || "createdAt");

        if (!descending) _query.ascending(orderBy || "createdAt");

        if (include) _query.include(include || ["*"]);

        if (exclude) exclude.forEach((atribute) => _query.exclude(atribute));

        if (select) select.forEach((atribute) => _query.select(atribute));

        if (skip) _query.skip(skip);

        if (limit) _query.limit(limit);

        if (count) _query.withCount();

        if (this._config.useStatus) _query.notEqualTo("status", false);
        let results: any = [];

        if (distinct) {
            results = await _query.distinct(distinct);
        }
        else {
            results = await _query.find();
        }

        return results;
    }

    /**
     * Gets the number of items on this Class
     *
     * ----------------
     * @async
     * @param {ICountQuery} params An ICountQuery object.
     * @returns {number} Number of items
     * @example
     * count({
     *     className: '_User',
     *     where: [['type', '=', 'admin']],
     *     // ...
     * })
     */
    async count(params: ICountQuery): Promise<number> {
        const { className, where, query } = params;

        const _query = query || this._buildQuery(className, where) || new Parse.Query(className);

        if (this._config.useStatus) _query.notEqualTo("status", false);

        return await _query.count();
    }

    /**
     * Returns the firs object in the relation that satisfies the 'where' conditions.
     *
     * ----------------
     * @async
     * @param {IGetRelationFirstQuery} params - An object contaning the params to the query.
     * @returns {Parse.Object} The Parse.Object that matches the params or null
     * @example
     * // Returns the first object.
     * getRelationFirst({
     *     from: user,
     *     relation: 'groups',
     *     where: [['groupType', '=', 1]],
     *     // ...
     * })
    */
    async getRelationFirst(params: IGetRelationFirstQuery): Promise<Parse.Object> {
        const { from: item, relation, where, query, include, exclude, select } = params;
        const _relation = item.relation(relation);

        const _targetClassName = _relation.targetClassName;

        const _targetQuery = query || this._buildQuery(_targetClassName, where) || new Parse.Query(_targetClassName);

        const _query: any = _relation.query();

        _query._where = _targetQuery._where;

        if (include) _query.include(include || ['*'])

        if (exclude) exclude.forEach(atribute => _query.exclude(atribute));

        if (select) select.forEach(s => _query.select(s));

        if (this._config.useStatus) _query.notEqualTo('status', false);

        return _query.first();
    }

    /**
     * Objects may have relationships with other objects. For example, in a blogging application, a Post object may have many Comment objects. Parse supports all kind of relationships, including one-to-one, one-to-many, and many-to-many. Gets a query results from a relation on a Object.
     *
     * ----------------
     * @async
     * @param {IGetRelationListQuery} params - An object contaning the params to the query.
     * @return {Promise<Parse.Object[]>} A promisse of a Parse.Object list.
     * @example
     * // Returns all the groups with type equals to 1, present in the 'groups' relation in the user object. 
     * getRelationList({
     *     from: user,
     *     relation: 'groups',
     *     where: [['groupType', '=', 1]],
     *     // ...
     * })
     */
    async getRelationList(params: IGetRelationListQuery): Promise<Parse.Object[]> {
        const { from: item, relation, where, query, include, exclude, select, distinct, count } = params;

        const _relation = item.relation(relation);

        const _targetClassName = _relation.targetClassName;

        const _targetQuery = query || this._buildQuery(_targetClassName, where) || new Parse.Query(_targetClassName);

        const _query: any = _relation.query();

        _query._where = _targetQuery._where;

        if (include) _query.include(include || ['*'])

        if (exclude) exclude.forEach(atribute => _query.exclude(atribute));

        if (select) select.forEach(s => _query.select(s));

        if (count) _query.withCount();

        if (this._config.useStatus) _query.notEqualTo('status', false);

        let results = [];

        if (distinct) {
            results = await _query.distinct(distinct);
        }
        else {
            results = await _query.find();
        }

        return results;
    }

    /**
     * Adds a Parse.Object to an especific relation.
     *
     * ----------------
     * @async
     * @param {IAddRelationObject} params - An object contaning the params to the query.
     * @example
     * addRelation({
     *     to: user,
     *     relation: 'groups',
     *     items: [group1],
     *     // ...
     * })
     */
    async addRelation(params: IAddRelationObject): Promise<void> {
        const { to, relation, items } = params;

        const _relation = to.relation(relation);

        _relation.add(items);

        await to.save();
    }

    /**
     * Removes a Parse.Object to an especific relation.
     *
     * @async
     * @param {IRemoveRelationObject} params - An object contaning the params to the queryt
     * @returns {array} The results from the query
     * @example
     * removeRelation({
     *     from: user,
     *     relation: 'groups',
     *     items: [group1],
     *     // ...
     * })
     */
    async removeRelation(params: IRemoveRelationObject): Promise<void> {
        const { from: _from, relation, items } = params;
        const _relation = _from.relation(relation);

        _relation.remove(items);

        await _from.save();
    }

    /**
     * Gets the Parse.Config
     *
     * ----------------
     * @async
     * @returns {object}  object with the configurationattributes
     * @example
     * getConfig()
     */
    async getConfig() {
        const conf = await Parse.Config.get();
        return conf.get("attributes");
    }

    /**
     * Subscribes for livequery on a Object
     *
     * ----------------
     * @async
     * @param {string} className - The Class name
     * @param {string} itemId - The id of the item to be observed
     * @returns {subscription} The parse subscription
     * @example
     * subscribeObject('_User', '1k2kk1n23')
     */
    async subscribeObject(className: string, itemId: string) {
        const query = new Parse.Query(className);
        query.equalTo("objectId", itemId);
        return await query.subscribe();
    }

    /**
     * Subscribes for livequery on a Class
     *
     * ----------------
     * @async
     * @param {string} params - The params object to compound a subscripe query.
     * @returns {subscription} The parse subscription
     * @example
     * subscribeQuery({
     *     className: '_User',
     *     where: [['role', '=', 'admin']],
     *     // ...
     * })
     */
    async subscribeQuery(params: IGetListQuery) {
        // let _query = new Parse.Query(params.table);

        const {
            className,
            where,
            query,
            orderBy,
            descending,
            skip,
            limit,
            count,
            include,
            exclude,
            select,
        } = params;

        const _query: Parse.Query =
            query || this._buildQuery(className, where) || new Parse.Query(className);

        descending && _query.descending(orderBy || "createdAt");

        !descending && _query.ascending(orderBy || "createdAt");

        if (include) _query.include(include || ["*"]);

        if (exclude) exclude.forEach((atribute) => _query.exclude(atribute));

        if (select) select.forEach((atribute) => _query.select(atribute));

        if (skip) _query.skip(skip);

        if (limit) _query.limit(limit);

        if (count) _query.withCount();

        if (this._config.useStatus) _query.notEqualTo("status", false);

        return await _query.subscribe();
    }

    /**
     * Stops the subscription for livequery on a query or item
     *
     * ----------------
     * @async
     * @param {subscription} subscription - The parse subscription
     * @returns {boolean} The success of the operation
     */
    async unsubscribe(subscription: any) {
        if (subscription) {
            return await subscription.unsubscribe();
        }
    }

    /**
   * Create a Parse File;
   * 
   * It accepts a File or an object with { name, base64 } format
   *
   * @async
   * @param {IFileParams} file - The file to be uploaded
   * @param {boolean} returnWithOriginal - Flag to indicate the return of the original file
   * @returns {Parse.Object} The created file
   */
    async saveFile(file: IFileParams | File, args?: { returnWithOriginal?: boolean }
    ): Promise<ISavedFile | Parse.File | Error> {

        // Checks if the name is valid
        if (!file.name.match(/[A-Za-z0-9]+[\w-]*?\.*[A-Za-z0-9]{3,4}/g)) {
            const error = `Image file name is invalid. \nExpected: Name with only letters, numbers and special characters '_' or '-'. \nFound: ${file.name} `
            console.error(error);
            return new Error(error);
        }

        const [filename, extension] = file.name.split('.')
        // Removes any character that isn't a letter.
        const cleanName = filename.normalize("NFD").replace(/[^a-zA-Z]/g, "") + '.' + extension

        const finalFile = 'base64' in file ? { base64: file.base64 } : file;

        const parseFile = new Parse.File(cleanName, finalFile);

        await parseFile.save();

        if (args?.returnWithOriginal)
            return {
                original: file,
                parseFile: parseFile
            };

        return parseFile;
    }

    /**
     * Creates a list of Parse.File.
     *
     * ----------------
     * @async
     * @param {IParseFile[]} files - The list of files to be uploaded
     * @param {boolean} returnWithOriginal - Flag to indicate the return of the original file
     * @returns {Parse.Object} The created file
     * @example
     * // Returns the new [Parse.File, Parse.File, Parse.File, ...]
     * saveFiles(
     *[
     *    { name: 'File 123', data: fileData, ... }, 
     *    { name: 'File 123', data: fileData, ... }, 
     *    ...
     *])
     * 
     * // Returns with original:  [{ original: File, new: Parse.File }, { original: File, new: Parse.File }, ...]
     * saveFiles(
     *[
     *    { name: 'File 123', data: fileData, ... }, 
     *    { name: 'File 123', data: fileData, ... }, 
     *    ...
     *], true)
     */
    async saveFiles(
        files: IParseFile[],
        returnWithOriginal = false
    ): Promise<any> {
        const promises = files.map(async (file) => {
            const pFile = new Parse.File(
                file.name.normalize("NFD").replace(/[^a-zA-Z]/g, ""),
                { base64: file.data }
            );

            if (file.metadata) {
                Object.entries(file.metadata).forEach((key, value) => {
                    const meta = String(key);
                    pFile.addMetadata(meta, value);
                });
            }

            await pFile.save();

            if (returnWithOriginal)
                return {
                    original: file,
                    parseFile: pFile,
                };

            return pFile;
        });

        return await Promise.all(promises);
    }

    /**
     * Calls a Cloud Function
     *
     * ----------------
     * @async
     * @param {string} functionName - The name of the Cloud Function
     * @param {Object} params - the params for the Cloud Function
     * @returns {any} Whatever the Cloud Function returns
     */
    async cloudFunction(functionName: string, params?: any) {
        return Parse.Cloud.run(functionName, params);
    }

    /**
     * Loads Parse Class schema
     *
     * ----------------
     * @param {string} className - The name of the table in parse
     * @returns {Object} - Object with the schema of the class
     */
    async getSchema(className: string) {
        const schema = new Parse.Schema(className);
        return await schema.get();
    }

    /**
     * Verifica se o usuário passado como argumento é o mesmo usuário logado.
     * 
     * @param user Usuário a ser verificado
     * @returns Se é o usuário logado ou não
     */
    proprioUsuario(user: Parse.Object): boolean {
        return user?.id === this._usuarioLogado?.id;
    }

    isAdmin(user: Parse.Object) {
        return user.get("admin");
    }

    // downloadFile(file: Parse.Object) {
    //     const pdfUrl = file.get("data").url();
    //     const pdfName = file.get("name");
    //     // FileSaver.saveAs(pdfUrl, pdfName);
    // }

    /**
     * Creates a Parse.Query
     *
     * ----------------
     * @param {string} className - The name of the parse class.
     * @param {SimpleQuery[]} params - A list of a SimpleQuery params.
     * @returns {Parse.Object} - A Parse.Query of the given class
     * @example
     */
    createQuery(className: string, params: SimpleQuery[]): Parse.Query | undefined {
        return this._buildQuery(className, params);
    }

    /**
     * Creates an 'AND' Parse.Query
     *
     * ----------------
     * @param {string} className - The name of the parse class
     * @param {SimpleQuery[]} paramsA - A list of a SimpleQuery params.
     * @param {SimpleQuery[]} paramsB - A list of a SimpleQuery params.
     * @returns {Parse.Query.and} - An 'AND' Parse.Query of the two given params.
     */
    createAndQuery(
        className: string,
        paramsA: SimpleQuery[],
        paramsB: SimpleQuery[]
    ): Parse.Query | undefined {
        const q1 = this._buildQuery(className, paramsA);
        const q2 = this._buildQuery(className, paramsB)
        if (q1 && q2) return Parse.Query.and(q1, q2);
    }

    /**
     * Creates an 'OR' Parse.Query
     *
     * ----------------
     * @param {string} className - The name of the parse class
     * @param {SimpleQuery[]} paramsA - A list of a SimpleQuery params.
     * @param {SimpleQuery[]} paramsB - A list of a SimpleQuery params.
     * @returns {Parse.Query.or} - An 'OR' Parse.Query of the two given params.
     */
    createOrQuery(
        className: string,
        paramsA: SimpleQuery[],
        paramsB: SimpleQuery[]
    ): Parse.Query | undefined {
        const q1 = this._buildQuery(className, paramsA);
        const q2 = this._buildQuery(className, paramsB)
        if (q1 && q2) return Parse.Query.or(q1, q2);
    }

    createACL() {
        return new Parse.ACL();
    }
}
