
// ==================================================================================

export interface IParseConfig {
    serverURL?: string,
    appId?: string,
    javascriptKey?: string,
    // masterKey: string,
    useStatus?: boolean,
    enableLocalDatastore?: boolean,
    // encryptLocalStorage?: boolean,
    // datastoreController?: IDatastoreController,
    datastoreTimeout?: number, // minutes
    includeAll?: boolean,
}

export type ParseObject = Parse.Object;
export type ParseQuery = Parse.Query;
export type ParseGeoPoin = Parse.GeoPoint;

/** @internal */
export enum operator {
    EqualTo = '=',
    NotEqualTo = '!=',
    GreatherThan = '>',
    GreatherThanOrEqualTo = '>=',
    LessThan = '<',
    LessThanOrEqualTo = '<=',
    ContainsAll = 'inAll',
    ContainedIn = 'in',
    NotContainedIn = '!in',
    MatchKeyInQuery = 'matchesKey',
    DoesNotMatchKeyInQuery = '!matchesKey',
    Matches = 'matches',
    Contains = 'contains',
    FullText = 'fulltext',
    StartsWith = 'starts',
    EndsWith = 'ends',
    Exists = 'exists',
    DoesNotExist = '!exists',
    Near = 'near',
    WithinKilometers = 'inKm',
    WithinMiles = 'inMi',
    WithinGeoBox = 'inGeoBox',
    WithinPolygon = 'inPoly',
    PolygonContains = 'polyContains',
}


export interface IFileParams {
    name: string;
    size?: number;
    type?: any;
    base64: string;
}

export interface ISavedFile {
    original: IFileParams | File;
    parseFile: Parse.File;
}


export type EqualTo = '=';
export type NotEqualTo = '!=';
export type GreatherThan = '>';
export type GreatherThanOrEqualTo = '>=';
export type LessThan = '<';
export type LessThanOrEqualTo = '<=';
export type ContainsAll = 'inAll';
export type ContainedIn = 'in';
export type NotContainedIn = '!in';
export type MatchKeyInQuery = 'matchesKey';
export type DoesNotMatchKeyInQuery = '!matchesKey';
export type Matches = 'matches';
export type Contains = 'contains';
export type FullText = 'fulltext';
export type StartsWith = 'starts';
export type EndsWith = 'ends';
export type Exists = 'exists';
export type DoesNotExist = '!exists';
export type Near = 'near';
export type WithinKilometers = 'inKm';
export type WithinMiles = 'inMi';
export type WithinGeoBox = 'inGeoBox';
export type WithinPolygon = 'inPoly';
export type PolygonContains = 'polyContains';

// ========== Queries Types ===========

/**
 * Equivalent to query.equalTo().
 * 
 * @type QEqualTo
 * @example [['name', '=', 'Bento']]
 * @example [['age', '=', 26]]
 */
export type QEqualTo = [string, EqualTo, any];

/**
 * Equivalent to query.notEqualTo().
 * 
 * @type QNotEqualTo
 * @example [['type', '!=', 'car']]
 */
export type QNotEqualTo = [string, NotEqualTo, any];

/**
 * Equivalent to query.greatherThan().
 * 
 * @type QGreatherThan
 * @example [['price', '>', 10]]
 */
export type QGreatherThan = [string, GreatherThan, (number | Date)];

/**
 * Equivalent to query.greatherThanOrEqualTo().
 * 
 * @type QGreatherThanOrEqualTo
 * @example [['size', '<=', 100]]
 */
export type QGreatherThanOrEqualTo = [string, GreatherThanOrEqualTo, (number | Date)];

/**
 * Equivalent to query.lessThan().
 * 
 * @type QLessThan
 * @example [['age', '<', 30]]
 */
export type QLessThan = [string, LessThan, (number | Date)];

/**
 * Equivalent to query.lessThanOrEqualTo().
 * 
 * @type QLessThanOrEqualTo
 * @example [['price', '>=', 12.5]]
 */
export type QLessThanOrEqualTo = [string, LessThanOrEqualTo, (number | Date)];

/**
 * Equivalent to query.containsAll().
 * 
 * @type QcontainsAll
 * @example [['name', 'inAll', ["boo", "foo"]]]
 */
export type QContainsAll = [string, ContainsAll, any[]];

/**
 * Equivalent to query.containedIn().
 * 
 * @type QContainedIn
 * @example [['name', 'in', ["boo", "foo"]]]
 */
export type QContainedIn = [string, ContainedIn, any[]]

/**
 * Equivalent to query.notContainedIn().
 * 
 * @type QNotContainedIn
 * @example [['name', '!in', ["boo", "foo"]]]
 */
export type QNotContainedIn = [string, NotContainedIn, any[]];

/**
 * Equivalent to query.matchKeyInQuery().
 * 
 * @type QMatchKeyInQuery
 * @example [['hometown', 'matchesKey', 'city', eventObject]]
 */
export type QMatchKeyInQuery = [string, MatchKeyInQuery, string, Parse.Object];

/**
 * Equivalent to query.doesNotMatchKeyInQuery().
 * 
 * @type QDoesNotMatchKeyInQuery
 * @example [['hometown', '!matchesKey', 'city', eventObject]]
 */
export type QDoesNotMatchKeyInQuery = [string, DoesNotMatchKeyInQuery, string, Parse.Object];

/**
 * Equivalent to query.matches() (This query is not recomended).
 * 
 * @type QMatches
 * @example [['name', 'matches', 'Boo']] // Case sensitive
 * @example [['name', 'matches', 'Boo', 'i']] // Case insensitive 
 */
export type QMatches = [string, Matches, string] | [string, Matches, string, 'i'];

/**
 * Equivalent to query.contains() (This query is not recomended).
 * 
 * @type QContains
 * @example [['name', 'contains', 'Boo']] // Case sensitive
 */
export type QContains = [string, Contains, string];

/**
 * Equivalent to query.fullText().
 * 
 * @type QFullText @example [['name', 'fulltext', 'Lorem ipsum']]
 */
export type QFullText = [string, FullText, string];

/**
 * Equivalent to query.startsWith().
 * 
 * @type QStartsWith
 * @example [['name', 'starts', 'Fu']]
 */
export type QStartsWith = [string, StartsWith, string];

/**
 * Equivalent to query.endsWith().
 * 
 * @type QEndsWith
 * @example [['name', 'ends', 'Fu']]
 */
export type QEndsWith = [string, EndsWith, string];

/**
 * Equivalent to query.exists().
 * 
 * @type QExists
 * @example [['age', 'exists']]
 */
export type ExistsQuery = [string, Exists];

/**
 * Equivalent to query.doesNotExist().
 * 
 * @type QDoesNotExist
 * @example [['photo', '!exists']]
 */
export type NotExistsQuery = [string, DoesNotExist];

/**
 * Equivalent to query.near().
 * 
 * @type QNear
 * @example [['address', 'near', addressGeoPoint]]
 */
export type QNear = [string, Near, Parse.GeoPoint];

/**
 * Equivalent to query.withinKilometers().
 * 
 * @type QWithinKilometers
 * @example [['address', 'withinKm', location, distance, sorted]]
 */
export type QWithinKilometers = [string, WithinKilometers, Parse.GeoPoint, number] | [string, WithinKilometers, Parse.GeoPoint, number, boolean];

/**
 * Equivalent to query.withinMiles().
 * 
 * @type QWithinMiles
 * @example [['address', 'withinMi', location, distance, sorted]]
 */
export type QWithinMiles = [string, WithinMiles, Parse.GeoPoint, number] | [string, WithinMiles, Parse.GeoPoint, number, boolean];

/**
 * Equivalent to query.withinGeoBox().
 * 
 * @type QWithinGeoBox
 * @example [['address', 'inGeoBox', southwestOfSF, northeastOfSF]]
 */
export type QWithinGeoBox = [string, WithinGeoBox, Parse.GeoPoint, Parse.GeoPoint];

/**
 * Equivalent to query.withinPolygon().
 * 
 * @type QWithinPolygon
 * @example [['address', 'withinPoly', [geoPoint1, geoPoint2, geoPoint3]]]
 */
export type QWithinPolygon = [string, WithinPolygon, Parse.GeoPoint[]];

/**
 * Equivalent to query.polygonContains().
 * 
 * @type QPolygonContains
 * @example [['address', 'polyContains', point]]]
 */
export type QPolygonContains = [string, PolygonContains, Parse.GeoPoint];


/**
 * Type agregator for a Simple Query
 */
export type SimpleQuery = QEqualTo | QNotEqualTo | QGreatherThan | QLessThan | QGreatherThanOrEqualTo | QLessThanOrEqualTo | QContainsAll | QContainedIn | QNotContainedIn | QMatchKeyInQuery | QDoesNotMatchKeyInQuery | QMatches | QContains | QStartsWith | QEndsWith | QFullText | ExistsQuery | NotExistsQuery | QNear | QWithinKilometers | QWithinMiles | QWithinGeoBox | QWithinPolygon | QPolygonContains;


/**
 * Type agregator for a Compound Query
 */
export type CompoundQuery = AndQuery | OrQuery

/**
 * This query will return an "and" compound operation between the "params" and "and" entries.
 */
export interface AndQuery {
    /**
     * @param {SimpleQuery[]} params SimpleQuery array.
     * @example 
     * param: [['name', '=', 'shoe'], ['size', '<', 42]]
     */
    params: SimpleQuery[],
    /**
     * @param {AndQuery | OrQuery | SimpleQuery[]} and An AndQuery or OrQuery object, or a SimpleQuery array.
     * @example 
     * and: {
     *   params: [['author', '=', user]],
     *   and: {
     *     params: [['isEdited', '=', true]],
     *     or: [['content', 'exists']]
     *   },
     * },
     */
    and: AndQuery | OrQuery | SimpleQuery[],
}

/**
 * This query will return an "or" compound operation between the "params" and "or" entries.
 */
export interface OrQuery {
    /**
     * @param {SimpleQuery[]} params SimpleQuery array.
     * @example 
     * param: [['name', '=', 'shoe'], ['size', '<', 42]]
     */
    params: SimpleQuery[],
    /**
     * @param {OrQuery | AndQuery | SimpleQuery[],} or An OrQuery or AndQuery object, or a SimpleQuery array.
     * @example 
     * or: {
     *   params: [['author', '=', user]],
     *   and: {
     *     params: [['isEdited', '=', true]],
     *     or: [['content', 'exists']]
     *   },
     * },
     */
    or: OrQuery | AndQuery | SimpleQuery[],
}

export interface IGetListQuery {
    /**
     *
     * ----------------
     * @param {string} className Name of the parse class (required)
     * @example
     * getList({
     *    className: '_User',
     *    ...
     * });
     */
    className: string;

    /**
     * @param {SimpleQuery[] | CompoundQuery} where SimpleQuery array or a CompoundQuery object.
     * @example 
     * // SimpleQuery[]
     * getList({{
     *    ...
     *    where: [['name', '=', 'shoe'], ['size', '<', 42]]
     *    ...
     * });
     * -----------------------------
     * // CompoundQuery
     * getList({{
     *    ...
     *    where: {
     *      params: [['author', '=', user]],
     *        and: {
     *          params: [['isEdited', '=', true]],
     *          or: [['content', 'exists']]
     *        },
     *      },
     *    ...
     * });
     */
    where?: SimpleQuery[] | CompoundQuery;

    /**
     * @param {Parse.Query} query If you want to use a Parse.Query object, you can pass it directly. 
     * 
     * It will overwrite the param "where" if it exists.
     * 
     * @example
     * let query = new Parse.Query('_User');
     * query.exists('username');
     * 
     * getList({
     *    ...
     *    query: query,
     *    ...
     * });
     */
    query?: Parse.Query;

    /**
     * @param {string} orderBy Sorts the results by the score field.by.
     * @example
     * getList({
     *    ...
     *    orderBy: 'name',
     *    ...
     * });
     * 
     * @default 'createdAt'.
     */
    orderBy?: string;

    /**
     * @param {string} descending For sortable types like numbers and strings, you can control the order in which results are returned.
     * 
     * @example
     * getList({
     *    ...
     *    descending: true, 
     *    ...
     * }); 
     * 
     * @default false.
     */
    descending?: boolean;

    /**
    * @param {number} skip You can use skip to page through results and load the data as is needed. 
    * 
    * @example
    * getList({
    *   ...
    *   // skips the first 50 results.
    *   skip: 50, 
    *   ...
    * });
    */
    skip?: number;

    /**
     * @param {number} limit You can use limit define the number of results loaded as is needed. The query limit is 100 by default.
     * 
     * @example
     * getList({
     *    ...
     *    // limit the query in 20 results.
     *    limit: 20, 
     *    ...
     * });
     * 
     * @default 100
     */
    limit?: number;

    /**
     * @param {string[]} include Por padrão, o parse não retorna os ponteiros de um objeto com todos os seus parâmetros.
     *
     * Se você deseja incluir os parâmetros do ponteiro em uma consulta, pode passar o atributo no parâmetro 'include'.
     *
     * Em algumas situações, você pode precisar retornar ponteiros filhos dentro de uma consulta.
     * Você pode fazer isso usando o '.' entre o ponteiro do pai e o ponteiro filho.
     * @example
     * 
     * // Retorna a query com os dados do ponteiro para clube incluidos.
     * this.$parse.getList({
     *    ...
     *    include: ['clube'],
     *    ...
     * });
     * 
     * // Retorna a query com os dados do ponteiro para distrito, dentro do ponteiro clube, incluidos.
     * this.$parse.getList({
     *    ...
     *    include: ['clube.distrito'],
     *    ...
     * });
     */
    include?: string[];

    /**
     * @param {(string | number)[]} exclude Use exclude to remove undesired fields while retrieving the rest. The remaining fields can be fetched later by calling fetch on the returned objects.
     * @example
     * getList({
     *    ...
     *    exclude: ['playerName'],
     *    ...
     * });
     */
    exclude?: (string | number)[];

    /**
    * @param {string[]} select You can restrict the fields returned by calling select with a list of keys. To retrieve documents that contain only the score and playerName fields (and also special built-in fields such as objectId, createdAt, and updatedAt)
    * @example
    * getList({
    *    ...
    *    select: ['score', 'playerName'],
    *    ...
    * });
    */
    select?: string[]

    /**
     * @param {string} distinct Queries can be made using distinct, allowing you find unique values for a specified field.
     * @example
     * getList({
     *    ...
     *    distinct: 'age',
     *    ...
     * });
     */
    distinct?: string;

    /**
     * @param {boolean} count If you want to know the total number of rows in a table satisfying your query, for e.g. pagination purposes - you can use withCount.
     * Note: Enabling this flag will change the structure of response, see the example below. 
     *  
     * @example
     * getList({
     *    ...
     *    count: false,
     *    ...
     * });
     * // Returns
     * [ Object, Object, ... ] 
     * 
     * ------------------
     * getList({
     *    ...
     *    count: true,
     *    ...
     * });
     * // Returns
     * { 
     *    results: [ Object, Object, ... ], 
     *    count: 200 
     * }
     */
    count?: boolean;
}

export interface IGetFirstQuery {
    /**
    *
     * ----------------
     * @param {string} className Name of the parse class (required)
    * @example
    * getFirst({
    *    className: '_User',
    *    ...
    * });
    */
    className: string;

    /**
     * @param {SimpleQuery[] | CompoundQuery} where SimpleQuery array or a CompoundQuery object.
     * @example 
     * // SimpleQuery[]
     * getFirst({{
     *    ...
     *    where: [['name', '=', 'shoe'], ['size', '<', 42]]
     *    ...
     * });
     * -----------------------------
     * // CompoundQuery
     * getFirst({{
     *    ...
     *    where: {
     *      params: [['author', '=', user]],
     *        and: {
     *          params: [['isEdited', '=', true]],
     *          or: [['content', 'exists']]
     *        },
     *      },
     *    ...
     * });
     */
    where?: SimpleQuery[] | CompoundQuery;

    /**
     * @param {Parse.Query} query If you want to use a Parse.Query object, you can pass it directly. 
     * 
     * It will overwrite the param "where" if it exists.
     * 
     * @example
     * let query = new Parse.Query('_User');
     * query.exists('username');
     * 
     * getFirst({
     *    ...
     *    query: query,
     *    ...
     * });
     */
    query?: Parse.Query;

    /**
     * @param {string} orderBy Sorts the results by the score field.by.
     * @example
     * getFirst({
     *    ...
     *    orderBy: 'name',
     *    ...
     * });
     * 
     * @default 'createdAt'.
     */
    orderBy?: string;

    /**
     * @param {string} descending For sortable types like numbers and strings, you can control the order in which results are returned.
     * 
     * @example
     * getFirst({
     *    ...
     *    descending: true, 
     *    ...
     * }); 
     * 
     * @default false.
     */
    descending?: boolean;

    /**
     * @param {string[]} include Por padrão, o parse não retorna os ponteiros de um objeto com todos os seus parâmetros.
     *
     * Se você deseja incluir os parâmetros do ponteiro em uma consulta, pode passar o atributo no parâmetro 'include'.
     *
     * Em algumas situações, você pode precisar retornar ponteiros filhos dentro de uma consulta.
     * Você pode fazer isso usando o '.' entre o ponteiro do pai e o ponteiro filho.
     * @example
     * 
     * // Retorna a query com os dados do ponteiro para clube incluidos.
     * this.$parse.getFirst({
     *    ...
     *    include: ['clube'],
     *    ...
     * });
     * 
     * // Retorna a query com os dados do ponteiro para distrito, dentro do ponteiro clube, incluidos.
     * this.$parse.getFirst({
     *    ...
     *    include: ['clube.distrito'],
     *    ...
     * });
     */
    include?: string[];

    /**
     * @param {(string | number)[]} exclude Use exclude to remove undesired fields while retrieving the rest. The remaining fields can be fetched later by calling fetch on the returned objects.
     * @example
     * getFirst({
     *    ...
     *    exclude: ['playerName'],
     *    ...
     * });
     */
    exclude?: (string | number)[];

    /**
    * @param {string[]} select You can restrict the fields returned by calling select with a list of keys. To retrieve documents that contain only the score and playerName fields (and also special built-in fields such as objectId, createdAt, and updatedAt)
    * @example
    * getFirst({
    *    ...
    *    select: ['score', 'playerName'],
    *    ...
    * });
    */
    select?: string[];
}


export interface ICountQuery {
    /**
     *
     * ----------------
     * @param {string} className Name of the parse class (required)
     * @example
     * count({
     *    className: '_User',
     *    ...
     * });
     */
    className: string;

    /**
     * @param {SimpleQuery[] | CompoundQuery} where SimpleQuery array or a CompoundQuery object.
     * @example 
     * // SimpleQuery[]
     * count({{
     *    ...
     *    where: [['name', '=', 'shoe'], ['size', '<', 42]]
     *    ...
     * });
     * -----------------------------
     * // CompoundQuery
     * count({{
     *    ...
     *    where: {
     *      params: [['author', '=', user]],
     *        and: {
     *          params: [['isEdited', '=', true]],
     *          or: [['content', 'exists']]
     *        },
     *      },
     *    ...
     * });
     */
    where?: SimpleQuery[] | CompoundQuery;

    /**
     * @param {Parse.Query} query If you want to use a Parse.Query object, you can pass it directly. 
     * 
     * It will overwrite the param "where" if it exists.
     * 
     * @example
     * let query = new Parse.Query('_User');
     * query.exists('username');
     * 
     * count({
     *    ...
     *    query: query,
     *    ...
     * });
     */
    query?: Parse.Query;
}

export interface IGetRelationListQuery {
    /**
     * @param {Parse.Object} from The Parse.Object item where the relation will be retrieved.
     * @example
     * getRelation({
     *    ...
     *    from: user,
     *    ...
     * });
     */
    from: Parse.Object;

    /**
     * @param {string} relation The name of the relation where the data will be retrieved.
     * @example
     * getRelation({
     *    ...
     *    relation: 'groups',
     *    ...
     * });
     */
    relation: string;

    /**
     * @param {SimpleQuery[] | CompoundQuery} where SimpleQuery array or a CompoundQuery object.
     * @example 
     * // SimpleQuery[]
     * getRelation({{
     *    ...
     *    where: [['name', '=', 'shoe'], ['size', '<', 42]]
     *    ...
     * });
     * -----------------------------
     * // CompoundQuery
     * getRelation({{
     *    ...
     *    where: {
     *      params: [['author', '=', user]],
     *        and: {
     *          params: [['isEdited', '=', true]],
     *          or: [['content', 'exists']]
     *        },
     *      },
     *    ...
     * });
     */
    where?: SimpleQuery[] | CompoundQuery;

    /**
     * @param {Parse.Query} query If you want to use a Parse.Query object, you can pass it directly. 
     * 
     * It will overwrite the param "where" if it exists.
     * 
     * @example
     * let query = new Parse.Query('_User');
     * query.exists('username');
     * 
     * getRelation({
     *    ...
     *    query: query,
     *    ...
     * });
     */
    query?: any;

    /**
     * @param {string} orderBy Sorts the results by the score field.by.
     * @example
     * getRelation({
     *    ...
     *    orderBy: 'name',
     *    ...
     * });
     * 
     * @default 'createdAt'.
     */
    orderBy?: string;

    /**
     * @param {string} descending For sortable types like numbers and strings, you can control the order in which results are returned.
     * 
     * @example
     * getRelation({
     *    ...
     *    descending: true, 
     *    ...
     * }); 
     * 
     * @default false.
     */
    descending?: boolean;

    /**
    * @param {number} skip You can use skip to page through results and load the data as is needed. 
    * 
    * @example
    * getRelation({
    *   ...
    *   // skips the first 50 results.
    *   skip: 50, 
    *   ...
    * });
    */
    skip?: number;

    /**
     * @param {number} limit You can use limit define the number of results loaded as is needed. The query limit is 100 by default.
     * 
     * @example
     * getRelation({
     *    ...
     *    // limit the query in 20 results.
     *    limit: 20, 
     *    ...
     * });
     * 
     * @default 100
     */
    limit?: number;

    /**
     * @param {string[]} include Por padrão, o parse não retorna os ponteiros de um objeto com todos os seus parâmetros.
     *
     * Se você deseja incluir os parâmetros do ponteiro em uma consulta, pode passar o atributo no parâmetro 'include'.
     *
     * Em algumas situações, você pode precisar retornar ponteiros filhos dentro de uma consulta.
     * Você pode fazer isso usando o '.' entre o ponteiro do pai e o ponteiro filho.
     * @example
     * 
     * // Retorna a query com os dados do ponteiro para clube incluidos.
     * this.$parse.getRelation({
     *    ...
     *    include: ['clube'],
     *    ...
     * });
     * 
     * // Retorna a query com os dados do ponteiro para distrito, dentro do ponteiro clube, incluidos.
     * this.$parse.getRelation({
     *    ...
     *    include: ['clube.distrito'],
     *    ...
     * });
     */
    include?: string[];

    /**
     * @param {(string | number)[]} exclude Use exclude to remove undesired fields while retrieving the rest. The remaining fields can be fetched later by calling fetch on the returned objects.
     * @example
     * getRelation({
     *    ...
     *    exclude: ['playerName'],
     *    ...
     * });
     */
    exclude?: (string | number)[];

    /**
    * @param {string[]} select You can restrict the fields returned by calling select with a list of keys. To retrieve documents that contain only the score and playerName fields (and also special built-in fields such as objectId, createdAt, and updatedAt)
    * @example
    * getRelation({
    *    ...
    *    select: ['score', 'playerName'],
    *    ...
    * });
    */
    select?: string[];

    /**
     * @param {string} distinct Queries can be made using distinct, allowing you find unique values for a specified field.
     * @example
     * getRelation({
     *    ...
     *    distinct: 'age',
     *    ...
     * });
     */
    distinct?: string;

    /**
     * @param {boolean} count If you want to know the total number of rows in a table satisfying your query, for e.g. pagination purposes - you can use withCount.
     * Note: Enabling this flag will change the structure of response, see the example below. 
     *  
     * @example
     * getRelation({
     *    ...
     *    count: false,
     *    ...
     * });
     * // Returns
     * [ Object, Object, ... ] 
     * 
     * ------------------
     * getRelation({
     *    ...
     *    count: true,
     *    ...
     * });
     * // Returns
     * { 
     *    results: [ Object, Object, ... ], 
     *    count: 200 
     * }
     */
    count?: boolean;
}

export interface IGetRelationFirstQuery {
    /**
     * @param {Parse.Object} from The Parse.Object item where the relation will be retrieved.
     * @example
     * getRelationFirst({
     *    ...
     *    from: user,
     *    ...
     * });
     */
    from: Parse.Object;

    /**
     * @param {string} relation The name of the relation where the data will be retrieved.
     * @example
     * getRelationFirst({
     *    ...
     *    relation: 'groups',
     *    ...
     * });
     */
    relation: string;

    /**
     * @param {SimpleQuery[] | CompoundQuery} where SimpleQuery array or a CompoundQuery object.
     * @example 
     * // SimpleQuery[]
     * getRelationFirst({{
     *    ...
     *    where: [['name', '=', 'shoe'], ['size', '<', 42]]
     *    ...
     * });
     * -----------------------------
     * // CompoundQuery
     * getRelationFirst({{
     *    ...
     *    where: {
     *      params: [['author', '=', user]],
     *        and: {
     *          params: [['isEdited', '=', true]],
     *          or: [['content', 'exists']]
     *        },
     *      },
     *    ...
     * });
     */
    where?: SimpleQuery[] | CompoundQuery;

    /**
     * @param {Parse.Query} query If you want to use a Parse.Query object, you can pass it directly. 
     * 
     * It will overwrite the param "where" if it exists.
     * 
     * @example
     * let query = new Parse.Query('_User');
     * query.exists('username');
     * 
     * getRelationFirst({
     *    ...
     *    query: query,
     *    ...
     * });
     */
    query?: any;

    /**
     * @param {string} orderBy Sorts the results by the score field.by.
     * @example
     * getRelationFirst({
     *    ...
     *    orderBy: 'name',
     *    ...
     * });
     * 
     * @default 'createdAt'.
     */
    orderBy?: string;

    /**
     * @param {string} descending For sortable types like numbers and strings, you can control the order in which results are returned.
     * 
     * @example
     * getRelationFirst({
     *    ...
     *    descending: true, 
     *    ...
     * }); 
     * 
     * @default false.
     */
    descending?: boolean;

    /**
     * @param {string[]} include Por padrão, o parse não retorna os ponteiros de um objeto com todos os seus parâmetros.
     *
     * Se você deseja incluir os parâmetros do ponteiro em uma consulta, pode passar o atributo no parâmetro 'include'.
     *
     * Em algumas situações, você pode precisar retornar ponteiros filhos dentro de uma consulta.
     * Você pode fazer isso usando o '.' entre o ponteiro do pai e o ponteiro filho.
     * @example
     * 
     * // Retorna a query com os dados do ponteiro para clube incluidos.
     * this.$parse.getRelationFirst({
     *    ...
     *    include: ['clube'],
     *    ...
     * });
     * 
     * // Retorna a query com os dados do ponteiro para distrito, dentro do ponteiro clube, incluidos.
     * this.$parse.getRelationFirst({
     *    ...
     *    include: ['clube.distrito'],
     *    ...
     * });
     */
    include?: string[];

    /**
     * @param {(string | number)[]} exclude Use exclude to remove undesired fields while retrieving the rest. The remaining fields can be fetched later by calling fetch on the returned objects.
     * @example
     * getList({
     *    ...
     *    exclude: ['playerName'],
     *    ...
     * });
     */
    exclude?: (string | number)[];

    /**
    * @param {string[]} select You can restrict the fields returned by calling select with a list of keys. To retrieve documents that contain only the score and playerName fields (and also special built-in fields such as objectId, createdAt, and updatedAt)
    * @example
    * getList({
    *    ...
    *    select: ['score', 'playerName'],
    *    ...
    * });
    */
    select?: string[];

    /**
     * @param {string} distinct Queries can be made using distinct, allowing you find unique values for a specified field.
     * @example
     * getRelationFirst({
     *    ...
     *    distinct: 'age',
     *    ...
     * });
     */
    distinct?: string;
}

export interface IAddRelationObject {
    /**
     * @param {Parse.Object} in The Parse.Object that will have objects added to it's relation.
     * @example
     * addRelation({
     *    ...
     *    in: user,
     *    ...
     * });
     */
    to: Parse.Object;

    /**
     * @param {string} relation The name of the relation where the objects will be added.
     * @example
     * addRelation({
     *    ...
     *    relation: 'groups',
     *    ...
     * });
     */
    relation: string;

    /**
     * @param {Parse.Object[]} items The Parse.Object items that will be added to the relation.
     * @example
     * addRelation({
     *    ...
     *    items: [ Object, Object, ... ],
     *    ...
     * });
     */
    items: Parse.Object[];
}


export interface IRemoveRelationObject {
    /**
     * @param {Parse.Object} from The Parse.Object that will have objects removed from it's relation.
     * @example
     * removeRelation({
     *    ...
     *    from: user,
     *    ...
     * });
     */
    from: Parse.Object;

    /**
     * @param {string} relation The name of the relation where the objects will be removed.
     * @example
     * removeRelation({
     *    ...
     *    relation: 'groups',
     *    ...
     * });
     */
    relation: string;

    /**
     * @param {Parse.Object[]} items The Parse.Object items that will be removed to the relation..
     * @example
     * removeRelation({
     *    ...
     *    items: [ Object, Object, ... ],
     *    ...
     * });
     */
    items: Parse.Object[];
}
// ==================================================================================

export interface ISingUpParams {
    email: string;
    password?: string;
    username?: string;
    [args: string]: any;
}

export interface IParseFile {
    name: string;
    data: string;
    metadata?:
    | any
    | {
        size?: number;
        type?: string;
        owner?: Parse.User;
    };
    tags?: any;
}