import _Vue from "vue";
import Notificacao from "./notificacao";
import mitt, { Emitter } from 'mitt'

const notificacao = {
  install(app: typeof _Vue): void {
    const notificacao = new Notificacao();
    app.prototype.$notificacao = notificacao;
    app.prototype.$globalEvent = mitt();
  },
}

declare module 'vue/types/vue' {
  interface Vue {
    $notificacao: Notificacao;
    $globalEvent: Emitter<any>
  }
}

export default notificacao;