
import { Component, Vue } from "vue-property-decorator";
import Cabecalho from "@/components/Cabecalho.vue";
import MenuLateral from "./components/MenuLateral.vue";
import Sidebar from "./components/Sidebar.vue";
import Rodape from "./components/Rodape.vue";
import Loader from "./components/Loader.vue";
import Login from "@/views/login/Login.vue";
import ContagemRegressiva from "@/ContagemRegressiva.vue";

@Component({
  components: {
    Cabecalho,
    MenuLateral,
    Sidebar,
    Rodape,
    Login,
    Loader,
    ContagemRegressiva,
  },
  metaInfo: {
    title: "Portal Rotaract Brasil",
  },
})
export default class App extends Vue {
  logado = false;
  carregando = true;
  carregouNotificacoes = false;

  status: string = "";

  async created() {
    this.status = "";
    const meusDados = await this.$utils.getMeusDados();
    const aceitouTermo = this.$permissoes.verificaSeAceitouTermos(meusDados);

    if (meusDados) {
      if (aceitouTermo) await this.carregaDadosSistema();
      else this.logout();
    }
    this.carregando = false;
  }

  /**
   * Ao iniciar o app, carrega as informações do clube e do distrito do usuário logado.
   *
   * O app não será renderizado enquanto os parametros abaixo não forem carregados.
   */
  async carregaDadosSistema() {
    if (!this.logado) return;

    this.carregando = true;
    await Promise.all([
      this.$utils.getMeusDados(),
      this.$permissoes.carregaPermissoesUsuario(),
      this.$notificacao.carregaNotificacoes(),
    ]).catch(this.$notificacao.mensagemErro);
    this.carregando = false;
  }

  async logout() {
    this.status = "saindo...";
    this.carregando = true;
    await this.$parse.logOut();
    this.logado = false;
    this.status = "";
  }
}
