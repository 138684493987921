
import { Component, Prop, Vue } from "vue-property-decorator";
import Button from "@/components/ui/Button.vue";
import Section from "@/components/ui/Section.vue";
import Loader from "@/components/Loader.vue";
import { ParseObject } from "@/plugin/parse/parse.interfaces";
import { Permissao } from "@/plugin/permissoes/permissoes.interfaces";
import {
  CARGO_NA_ROTARACTBR,
  CARGO_NO_CLUBE,
  CARGO_NO_DISTRITO,
  CLUBE_DESATIVADO,
} from "@/plugin/utils/utils.plugin";
import { TStatusClubes } from "@/plugin/parse/cloud.interfaces";

type tiposCargos = "clube" | "distrito" | "rotaractbr";
export const LIMITE_INFERIOR_PARA_CRIACAO_DE_CARGOS = 15; // em anos atrás;
export const LIMITE_SUPERIOR_PARA_CRIACAO_DE_CARGOS = 2; // em próximos anos;

@Component({
  components: {
    Button,
    Section,
    Loader,
  },
})
export default class NovoCargoAssociado extends Vue {
  criandoCargo: boolean = false;
  carregando = false;

  @Prop({
    default: "modal-novo-cargo",
  })
  idModal!: string;
  @Prop()
  pendente!: boolean;
  @Prop()
  usuario: any;
  @Prop()
  clube: any;
  @Prop()
  distrito: any;
  @Prop()
  tipo!: tiposCargos;
  tipoSelecionado: tiposCargos = "clube";

  cargos: any = [];
  cargosPorId: any = {};
  cargoSelecionado: any = null;

  distritos: any = [];
  distritosPorId: any = {};
  distritoSelecionado: any = null;

  clubes: any = [];
  clubeSelecionado: any = null;
  clubesPorId: any = {};
  carregandoClubes = false;

  usuarios: any = [];
  usuarioSelecionado: any = null;
  usuariosPorId: any = {};
  carregandoUsuarios = false;

  anosRotarios: any = [];

  fecharModalCallback: any = null;
  cancelar = false;

  titulo = "Adicionar um Cargo";

  async created() {
    this.carregando = true;

    this.tipoSelecionado = this.tipo || "clube";

    this.distritoSelecionado = this.distrito;
    this.clubeSelecionado = this.clube;
    this.usuarioSelecionado = this.usuario;

    if (this.tipo) {
      if (this.tipo === "clube") this.titulo = `Adicionar um cargo ao clube`;
      if (this.tipo === "distrito")
        this.titulo = `Adicionar um cargo ao distrito`;
      if (this.tipo === "rotaractbr")
        this.titulo = `Adicionar um cargo à Rotaract Brasil`;
    }
    const anoAtual = new Date().getFullYear();
    const limiteParaCriarCargosPassado = new Date(
      anoAtual - LIMITE_INFERIOR_PARA_CRIACAO_DE_CARGOS,
      6, // julho
      1
    );

    const limiteSuperior = this.pendente
      ? 0 // Habilita a atribuição de cargo somente até o ano rotário anterior.
      : LIMITE_SUPERIOR_PARA_CRIACAO_DE_CARGOS; // Habilita a atribuição de cargos para os n próximos anos rotários.
    const limiteParaCriarCargosFuturo = new Date(
      anoAtual + limiteSuperior,
      5, // junho
      30
    );
    this.anosRotarios = this.$utils.getIntervaloAnosRotarios(
      limiteParaCriarCargosPassado,
      limiteParaCriarCargosFuturo
    );

    if (this.distrito) this.carregaClubes();
    else {
      let distritos = this.$cache.obter("distritos-brasileiros");

      if (!distritos) {
        distritos = await this.$cloud.buscarDistritosBrasileiros();
        this.$cache.salvar("distritos-brasileiros", distritos);
      }

      this.distritos = this.$utils.converteParaVFOptions(distritos, {
        label: "numero",
      });

      distritos.forEach((distrito: any) => {
        this.distritosPorId[distrito.id] = distrito;
      });
    }

    if (this.clube) this.carregaUsuarios();

    this.carregaCargos(this.tipoSelecionado);
    this.carregando = false;
  }

  async carregaCargos(instancia: tiposCargos) {
    if (!this.tipoSelecionado) return;

    this.cargoSelecionado = null;
    this.carregando = true;
    const cargos = await this.$utils.getCargos(instancia);

    cargos?.forEach((cargo: ParseObject) => {
      this.cargosPorId[cargo.id] = cargo;
    });

    this.cargos = this.$utils.converteParaVFOptions(cargos, {
      label: "nome",
    });

    this.carregando = false;
  }

  async carregaClubes() {
    if (
      !this.cargoSelecionado ||
      !(this.distritoSelecionado || this.distrito) ||
      this.clube
    )
      return;

    this.clubeSelecionado = null;
    this.carregando = true;
    this.carregandoClubes = true;

    const exibirClubesDesativados = this.$permissoes.possui(
      Permissao.ATRIBUIR_CARGOS_EM_CLUBES_DESATIVADOS
    );

    const statusClubes: TStatusClubes | null = exibirClubesDesativados
      ? null
      : "ativos";

    let clubes = this.$cache.obter(
      `clubes-${statusClubes}-${this.distritoSelecionado.id}`
    );

    if (!clubes) {
      clubes = await this.$cloud.buscarClubesDistrito({
        id: this.distritoSelecionado.id,
        statusClubes,
      });

      this.$cache.salvar(
        `clubes-${statusClubes}-${this.distritoSelecionado.id}`,
        clubes
      );
    }

    clubes.forEach((clube: ParseObject) => {
      this.clubesPorId[clube.id] = clube;
    });

    this.clubes = this.$utils.converteParaVFOptions(clubes, {
      fnLabel: (clube: ParseObject) => {
        return clube.get("clubeStatus") == CLUBE_DESATIVADO
          ? `${clube.get("nome")} (desativado)`
          : clube.get("nome");
      },
    });
    this.carregando = false;
    this.carregandoClubes = false;
  }

  async carregaUsuarios() {
    if (!this.clubeSelecionado || this.usuario) return;
    this.usuarioSelecionado = null;
    this.carregando = true;
    this.carregandoUsuarios = true;
    const clube = this.clube ?? this.clubeSelecionado;

    this.usuarios = [];

    const usuarios = await this.$utils.getUsuariosDoClube(clube.id, "ativos");

    if (!Array.isArray(usuarios)) return;

    usuarios.forEach((usuario: ParseObject) => {
      this.usuariosPorId[usuario.id] = usuario;
    });

    this.usuarios = this.$utils.converteParaVFOptions(usuarios, {
      label: "nome",
    });

    this.carregando = false;
    this.carregandoUsuarios = false;
  }

  async salvarCargo(dados: any) {
    const { anoRotario } = dados;

    if (
      this.cancelar ||
      !this.cargoSelecionado ||
      !this.usuarioSelecionado ||
      (this.tipo === "distrito" && !this.distritoSelecionado) ||
      (this.tipo === "clube" && !this.clubeSelecionado)
    )
      return;

    this.criandoCargo = true;

    const { inicio, termino } = this.$utils.getDatasDoAnoRotario(anoRotario);

    const novoCargo: any = {
      anoRotario,
      inicio,
      termino,
      tipo: 1,
      usuario: this.usuario || this.usuariosPorId[this.usuarioSelecionado.id],
    };

    novoCargo.cargo = this.cargosPorId[this.cargoSelecionado.id];

    if (this.tipoSelecionado === "clube") {
      novoCargo.clube =
        this.clube || this.clubesPorId[this.clubeSelecionado.id];
      novoCargo.tipo = 3;
    }

    if (this.tipoSelecionado === "distrito") {
      novoCargo.distrito =
        this.distrito || this.distritosPorId[this.distritoSelecionado.id];
      novoCargo.tipo = 2;
    }

    this.verificaSePendente(novoCargo);

    const cargoCriado = await this.$parse
      .saveObject("CargoUsuario", novoCargo)
      .catch((e) => this.$notificacao.mensagemErro(e));

    this.criandoCargo = false;

    if (!cargoCriado)
      this.$notificacao.mensagemErro(
        new Error("Não foi possível salvar o cargo, algo deu errado")
      );

    this.$emit("cargoCriado", cargoCriado);
    this.$bvModal.hide(this.idModal);
  }

  verificaSePendente(novoCargo: any) {
    const { tipo, clube, distrito } = novoCargo;

    novoCargo.pendente = true;

    if (tipo == CARGO_NO_CLUBE && clube) {
      const podeAddCargosEmQualquerClube = this.$permissoes.possui(
        Permissao.ATRIBUIR_CARGOS_EM_QUALQUER_CLUBE
      );
      const podeAddCargosNoProprioClube = this.$permissoes.possui(
        Permissao.ATRIBUIR_CARGOS_NO_PROPRIO_CLUBE,
        { clube }
      );
      const podeAddCargosNosClubesDoProprioDistrito = this.$permissoes.possui(
        Permissao.ATRIBUIR_CARGOS_EM_CLUBES_DO_PROPRIO_DISTRITO,
        { distrito: distrito || clube?.get("distrito") }
      );
      const podeAtribuirCargoNoClube =
        podeAddCargosEmQualquerClube ||
        podeAddCargosNoProprioClube ||
        podeAddCargosNosClubesDoProprioDistrito;

      if (podeAtribuirCargoNoClube) novoCargo.pendente = false;
    }

    if (tipo == CARGO_NO_DISTRITO && distrito) {
      const podeAddCargosNoProprioDistrito = this.$permissoes.possui(
        Permissao.ATRIBUIR_CARGOS_NO_PROPRIO_DISTRITO,
        { distrito }
      );
      const podeAddCargosEmQualquerDistrito = this.$permissoes.possui(
        Permissao.ATRIBUIR_CARGOS_EM_QUALQUER_DISTRITO
      );

      const podeAtribuirCargoNoDistrito =
        podeAddCargosNoProprioDistrito || podeAddCargosEmQualquerDistrito;

      if (podeAtribuirCargoNoDistrito) novoCargo.pendente = false;
    }

    if (tipo == CARGO_NA_ROTARACTBR) {
      const podeAddCargosNaRotaractbr = this.$permissoes.possui(
        Permissao.ATRIBUIR_CARGOS_NA_ROTARACT_BRASIL
      );

      if (podeAddCargosNaRotaractbr) novoCargo.pendente = false;
    }
  }

  resetaForm(event: any) {
    if (this.carregando || this.criandoCargo) event.preventDefault();

    if (!this.distrito) this.distritoSelecionado = null;
    if (!this.clube) this.clubeSelecionado = null;
    if (!this.usuarios) this.usuarioSelecionado = null;
    this.cargoSelecionado = null;
  }
}
