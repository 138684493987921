
import { Component, Vue } from "vue-property-decorator";
import Loader from "@/components/Loader.vue";
import { ParseObject } from "@/plugin/parse/parse.interfaces";
import CarregandoInfo from "@/components/CarregandoInfo.vue";
import Error404 from "@/components/Error404.vue";
import Section from "@/components/ui/Section.vue";
import Divider from "@/components/ui/Divider.vue";
import Button from "@/components/ui/Button.vue";
import TituloPrincipal from "@/components/TituloPrincipal.vue";
import { Permissao } from "@/plugin/permissoes/permissoes.interfaces";

@Component({
  components: {
    Loader,
    CarregandoInfo,
    Section,
    Divider,
    Button,
    Error404,
    TituloPrincipal,
  },
})
export default class CargosSistema extends Vue {
  cargosClube: any = [];
  cargosDistrito: any = [];
  cargosRotaractBr: any = [];
  todosCargos: any = [];
  campos = ["id", "nome", "ativo", "ordem_exibicao"];

  editando = false;
  carregando = true;

  possuiPermissao = false;

  cargoParaEditar = null;

  retorno = [{
    url: "/sistema",
    titulo: "Painel do Sistema"
  }];

  created() {
    this.possuiPermissao = this.$permissoes.possui(Permissao.VISUALIZAR_CARGOS_SISTEMA);
    if (this.possuiPermissao) this.carregaCargos();
  }

  async carregaCargos() {
    this.carregando = true;

    const cargos = await this.$parse
      .getList({
        className: "Cargo",
        // TODO: Gambiarra: Por algum motivo o parse não estava retornando todos os cargos, apenas 100
        limit: 200,
        descending: false,
        orderBy: "ordemExibicao",
      })
      .catch((e) => this.$notificacao.mensagemErro(e));

    this.todosCargos = cargos.map((cargo: ParseObject) => {
      return {
        id: cargo.id,
        nome: cargo.get("nome"),
        ativo: cargo.get("ativo"),
        ordem_exibicao: cargo.get("ordemExibicao"),
        clube: cargo.get("clube"),
        distrito: cargo.get("distrito"),
        rotaractbr: !cargo.get("clube") && !cargo.get("distrito"),
      };
    });

    this.cargosClube = this.todosCargos.filter((cargo: any) => cargo.clube);
    this.cargosDistrito = this.todosCargos.filter(
      (cargo: any) => cargo.distrito
    );
    this.cargosRotaractBr = this.todosCargos.filter(
      (cargo: any) => cargo.rotaractbr
    );

    this.carregando = false;
  }

  pesquisarCargo(input: any) {
    const { value } = input.target;

    /**
     * Aqui re-inicializa as listagens antes de fazer a pesquisa novamente.
     *
     * Isso garante que quando apagado, os dados sejam atualizados e
     * se não houver nenhum texto pesquisado, a listagem original é retomada.
     *
     * Como a filtragem é feito de forma local (sem buscar no banco),
     * não há grandes custos computacionais envolvidos no processo.
     */
    this.cargosClube = this.todosCargos.filter((cargo: any) => cargo.clube);
    this.cargosDistrito = this.todosCargos.filter(
      (cargo: any) => cargo.distrito
    );
    this.cargosRotaractBr = this.todosCargos.filter(
      (cargo: any) => cargo.rotaractbr
    );

    if (value) {
      this.cargosClube = this.cargosClube.filter((cargo: any) =>
        cargo.nome.toLowerCase().includes(value.toLowerCase())
      );
      this.cargosDistrito = this.cargosDistrito.filter((cargo: any) =>
        cargo.nome.toLowerCase().includes(value.toLowerCase())
      );
      this.cargosRotaractBr = this.cargosRotaractBr.filter((cargo: any) =>
        cargo.nome.toLowerCase().includes(value.toLowerCase())
      );
    }
  }
}
