
import { ParseObject, SimpleQuery } from "@/plugin/parse/parse.interfaces";
import { Component, Vue, Watch } from "vue-property-decorator";
import Loader from "@/components/Loader.vue";
import Paginacao from "@/components/Paginacao.vue";
import Divider from "@/components/ui/Divider.vue";
import FiltrosProjetos from "@/views/projetos/FiltrosProjetos.vue";

// import { gerarPDFProjeto } from "@/plugin/utils/geradorDePDF";

const PROJETOS_POR_PAGINA = 10;

@Component({
  components: {
    Loader,
    Paginacao,
    Divider,
    FiltrosProjetos,
  },
})
export default class ProjetosClube extends Vue {
  carregando = false;

  clube: any = null;

  projetos: ParseObject[] = [];

  totalDeProjetosDoClube = 0;
  numeroPaginaAtual = 0;
  mudancaDePagina = false;
  linhasPorPagina = PROJETOS_POR_PAGINA;

  clubeNaoExiste = false;
  meuClube = false;

  filtros: SimpleQuery[] = [];

  /**
   * Carrega o objeto do clube com base na url.
   *
   * Após pegar o objeto do clube, carrega todas as informações necessárias para exibição.
   *
   * O decorator @watch garante que quando o usuário estiver com esse componente aberto (já acessando as informações de um clube)
   * e clicar para visualizar outro clube, que o Vue recarrega as informações do novo clube.
   */
  @Watch("$route", { immediate: true, deep: true })
  async carregaClube() {
    // Impede que seja chamado se já estiver carregando;
    if (this.carregando) return;
    this.carregando = true;

    const routeName = this.$route.name || "";

    if (routeName === "projetos-clube") {
      this.clube = await this.$utils.getMeuClube();
      this.meuClube = true;
      this.carregarProjetos();
      return;
    }

    const idClube = this.$route.params.id_clube;

    if (idClube) {
      const clube = await this.$parse
        .getFirst({
          className: "Clube",
          where: [["objectId", "=", idClube]],
          include: ["distrito"],
        })
        .catch((e) => this.$notificacao.mensagemErro(e));

      if (clube) this.clube = clube;
    }

    if (!this.clube) {
      this.clubeNaoExiste = true;
      this.carregando = false;
      return;
    }

    this.carregarProjetos();

    this.carregando = false;
  }

  async carregarProjetos() {
    this.carregando = true;

    const where: SimpleQuery[] = [["clube", "=", this.clube], ...this.filtros];

    if (!this.meuClube) where.push(["solicitacaoDeExclusao", "!exists"]);

    const skip = this.mudancaDePagina
      ? this.projetos.length * this.numeroPaginaAtual
      : 0;

    const response = await this.$parse
      .getList({
        className: "Projeto",
        where,
        skip,
        count: true,
        limit: PROJETOS_POR_PAGINA,
        orderBy: "anoRotario",
        descending: true,
      })
      .catch((e) => this.$notificacao.mensagemErro(e));

    const { results: projetos, count } = response;
    this.totalDeProjetosDoClube = count;
    
    this.projetos = projetos.sort((a: ParseObject, b: ParseObject) => {
      const anoRotarioA = a.get("anoRotario");
      const anoRotarioB = b.get("anoRotario");
      if (anoRotarioA == anoRotarioB) {
        const createdAtA = a.get("createdAt").getTime();
        const createdAtB = b.get("createdAt").getTime();
        return createdAtB - createdAtA;
      }
      return anoRotarioB - anoRotarioA;
    });

    this.carregando = false;
    this.mudancaDePagina = false;
  }
}
