
import { Component, Prop, Vue } from "vue-property-decorator";
import CardUsuario from "@/components/CardUsuario.vue";
import SecaoVazia from "@/components/SecaoVazia.vue";
import Section from "@/components/ui/Section.vue";
import { ParseObject } from "@/plugin/parse/parse.interfaces";

@Component({
  components: {
    CardUsuario,
    SecaoVazia,
    Section,
  },
})
export default class TabGaleriaDeRDs extends Vue {
  @Prop({ default: [] })
  galeriaRDRs!: ParseObject[];

  @Prop({ default: false })
  carregandoCargos!: boolean;

  @Prop({ default: null })
  numeroDistrito!: number;

  created() {}
}
