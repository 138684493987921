
import { Component, Vue } from "vue-property-decorator";
import TextoRotulado from "@/components/TextoRotulado.vue";
import TituloPrincipal from "@/components/TituloPrincipal.vue";
import Aviso from "@/components/Aviso.vue";
import Section from "@/components/ui/Section.vue";
import Button from "@/components/ui/Button.vue";
import Loader from "@/components/Loader.vue";
import Error404 from "@/components/Error404.vue";
import { Permissao } from "@/plugin/permissoes/permissoes.interfaces";
import { CARGO_NO_CLUBE } from "@/plugin/utils/utils.plugin";
import { IVueFormulateOption } from "@/plugin/utils/utils.interfaces";

@Component({
  components: {
    TextoRotulado,
    TituloPrincipal,
    Aviso,
    Section,
    Button,
    Loader,
    Error404,
  },
})
export default class MigrarHistorico extends Vue {
  retorno = [
    {
      url: "/sistema",
      titulo: "Painel do Sistema",
    },
  ];
  possuiPermissao: boolean = false;
  buscando: boolean = false;
  confirmandoMigracao: boolean = false;
  migrando: boolean = false;

  fecharAlertaSucessoEm = 0;
  fecharAlertaErroEm = 0;

  distritos: IVueFormulateOption[] = [];
  distritoDestinoSelecionado: any = null;
  clubeDestinoSelecionado: any = null;
  clubesDestino: IVueFormulateOption[] = [];

  associacoes: any[] = [];
  associacoesParaMigrar: any[] = [];
  colunasAssociacoesParaMigrar = [
    { clube: "Clube" },
    { posse: "Posse" },
    { desligamento: "Desligamento" },
    { fundador: "Fundador(a)" },
    { honorario: "Honorário(a)" },
  ];

  colunasAssociacoes = [
    { clube: "Clube" },
    { posse: "Posse" },
    { desligamento: "Desligamento" },
    { fundador: "Fundador(a)" },
    { honorario: "Honorário(a)" },
    { migrar: "Migrar" },
  ];

  cargos: any[] = [];
  cargosParaMigrar: any[] = [];
  colunasCargosParaMigrar = [
    {
      key: "anoRotario",
      label: "Ano Rotário",
      thStyle: "width: 12%",
    },
    {
      key: "nome",
      thStyle: "width: 45%",
    },
    {
      key: "clube",
      thStyle: "width: 30%",
    },
  ];

  colunasCargosClube = [
    {
      key: "anoRotario",
      label: "Ano Rotário",
      thStyle: "width: 12%",
    },
    {
      key: "nome",
      thStyle: "width: 45%",
    },
    {
      key: "clube",
      thStyle: "width: 30%",
    },
    { migrar: "Migrar" },
  ];

  usuario: any = null;

  possuiItemsASeremMigrados = false;

  async created() {
    this.possuiPermissao = this.$permissoes.possui(Permissao.MIGRAR_HISTORICO);
    let distritos = this.$cache.obter("distritos-brasileiros");

    if (!distritos) {
      distritos = await this.$cloud.buscarDistritosBrasileiros();
      this.$cache.salvar("distritos-brasileiros", distritos);
    }

    this.distritos = this.$utils.converteParaVFOptions(distritos, {
      label: "numero",
    });
  }

  async localizar({ emailOuId }: { emailOuId: string }) {
    if (!emailOuId) return;

    this.buscando = true;
    if (emailOuId.includes("@")) {
      this.usuario = await this.$parse.getFirst({
        className: "DadosUsuario",
        where: [["email", "=", emailOuId]],
      });
    } else {
      this.usuario = await this.$parse.getById("DadosUsuario", emailOuId);
    }

    if (this.usuario)
      await Promise.all([this.carregaAssociacoes(), this.carregaCargos()]);

    this.possuiItemsASeremMigrados = true;

    this.buscando = false;
  }

  async carregaAssociacoes() {
    this.associacoes = await this.$parse
      .getList({
        className: "Associado",
        where: [["usuario", "=", this.usuario]],
        include: ["clube", "clube.distrito"],
        orderBy: "posse",
        descending: true,
      })
      .catch((e) => this.$notificacao.mensagemErro(e));

    this.associacoes.forEach((associado) => (associado.migrar = true));
  }

  async carregaCargos() {
    this.cargos = await this.$parse
      .getList({
        className: "CargoUsuario",
        where: [
          ["usuario", "=", this.usuario],
          ["pendente", "!=", true],
          ["recusado", "!=", true],
          ["tipo", "=", CARGO_NO_CLUBE],
        ],
        include: ["cargo", "clube", "distrito", "distritoAntigo"],
        orderBy: "anoRotario",
        descending: true,
      })
      .catch((e) => this.$notificacao.mensagemErro(e));

    this.cargos.forEach((cargo) => (cargo.migrar = true));
  }

  async carregaClubes() {
    if (!this.distritoDestinoSelecionado) return;
    this.clubeDestinoSelecionado = null;
    this.buscando = true;

    let clubes = this.$cache.obter(
      `clubes-todos-${this.distritoDestinoSelecionado.id}`
    );

    if (!clubes) {
      clubes = await this.$cloud.buscarClubesDistrito({
        id: this.distritoDestinoSelecionado.id,
      });

      this.$cache.salvar(
        `clubes-todos-${this.distritoDestinoSelecionado.id}`,
        clubes
      );
    }

    this.clubesDestino = this.$utils.converteParaVFOptions(clubes, {
      label: "nome",
    });

    this.buscando = false;
  }

  verificarSeHaItemsASeremMigrados(item: any) {
    item.migrar = !item.migrar;
    this.possuiItemsASeremMigrados =
      this.associacoes.some((item: any) => item.migrar) ||
      this.cargos.some((cargo: any) => cargo.migrar);
  }

  confirmarMigracao() {
    if (!this.clubeDestinoSelecionado) return;
    this.associacoesParaMigrar = this.associacoes.filter(
      (associado) => associado.migrar
    );

    this.cargosParaMigrar = this.cargos.filter((cargo) => cargo.migrar);
    this.confirmandoMigracao = true;
  }

  async migrar() {
    if (!this.clubeDestinoSelecionado) return;

    this.migrando = true;

    const idCargos = this.cargosParaMigrar.map((cargo) => cargo.id);
    const idAssociacoes = this.associacoesParaMigrar.map(
      (associado) => associado.id
    );
    const idClubeDestino = this.clubeDestinoSelecionado.id;

    try {
      const sucesso = await this.$cloud.migrarHistorico({
        idCargos,
        idAssociacoes,
        idClubeDestino,
      });

      sucesso
        ? (this.fecharAlertaSucessoEm = 5)
        : (this.fecharAlertaErroEm = 5);
    } catch (error) {
      console.log(error);
    }

    this.possuiItemsASeremMigrados = false;
    this.associacoesParaMigrar = [];
    this.associacoes = [];
    this.cargosParaMigrar = [];
    this.cargos = [];
    this.clubeDestinoSelecionado = null;
    this.clubesDestino = [];
    this.usuario = null;
    this.confirmandoMigracao = false;
    this.distritoDestinoSelecionado = null;
    this.migrando = false;
  }
}
