
import { ParseObject } from "@/plugin/parse/parse.interfaces";
import { Component, Prop, Vue } from "vue-property-decorator";
import CardUsuario from "@/components/CardUsuario.vue";
import SecaoVazia from "@/components/SecaoVazia.vue";
import Section from "@/components/ui/Section.vue";

@Component({
  components: {
    CardUsuario,
    SecaoVazia,
    Section,
  },
})
export default class TabHonorarios extends Vue {
  removendoHonorario = false;

  @Prop({
    default: [],
  })
  honorarios!: ParseObject[];

  @Prop()
  carregando!: boolean;

  @Prop({
    default: false,
  })
  podeRemoverHonorario!: boolean;

  created() {}

  async removerHonorario(honorario: ParseObject) {
    const confirmado = await this.$notificacao.confirmar({
      texto: "Deseja remover esta pessoa da lista de honorários do clube?",
    });

    if (!confirmado) return;

    this.removendoHonorario = true;
    const honorarios = this.honorarios;
    this.honorarios = [];
    await this.$parse.updateObject(honorario, { honorario: false });
    this.honorarios = honorarios.filter(
      (_honorario: ParseObject) => _honorario.id !== honorario.id
    );
    this.removendoHonorario = false;
  }
}
