
import { Component, Vue } from "vue-property-decorator";
import TituloPrincipal from "@/components/TituloPrincipal.vue";
import Section from "@/components/ui/Section.vue";
import Divider from "@/components/ui/Divider.vue";
import Loader from "@/components/Loader.vue";
import { ParseObject } from "@/plugin/parse/parse.interfaces";
@Component({
  components: {
    TituloPrincipal,
    Section,
    Divider,
    Loader,
  },
})
export default class Distritos extends Vue {
  distritos: ParseObject[] = [];
  carregando = false;
  ultimoDistritoDestacado: number | null = null;

  async created() {
    this.carregando = true;
    let distritos = this.$cache.obter("distritos-brasileiros");

    if (!distritos) {
      distritos = await this.$cloud.buscarDistritosBrasileiros();
      this.$cache.salvar("distritos-brasileiros", distritos);
    }

    this.distritos = distritos

    this.carregando = false;
  }

  destacaDistrito(numeroDistrito?: number) {
    if (this.ultimoDistritoDestacado) {
      const mapaDistritoRef = this.$refs[
        `mapa-${this.ultimoDistritoDestacado}`
      ] as Element;

      mapaDistritoRef?.classList?.remove("destacado");
      this.ultimoDistritoDestacado = null;
    }

    if (!numeroDistrito) return;
    const mapaDistritoRef = this.$refs[`mapa-${numeroDistrito}`] as Element;

    mapaDistritoRef?.classList?.add("destacado");
    this.ultimoDistritoDestacado = numeroDistrito;
  }
}
