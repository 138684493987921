
import { Component, Vue } from "vue-property-decorator";
import { Cropper } from "vue-advanced-cropper";
import Loader from "@/components/Loader.vue";
import Section from "@/components/ui/Section.vue";

@Component({
  components: {
    Cropper,
    Loader,
    Section,
  },
})
export default class DefinirFoto extends Vue {
  foto: any = null;
  fotoUrl: string = "";
  fotoCortada: any = null;

  salvando = false;
  created() {}

  async carregaImagem(dadosInput: any) {
    const {
      fileList: [foto],
    } = dadosInput;
    this.foto = foto;
    this.fotoUrl = await this.$utils.readFile(foto);
  }

  imagemCortada({ canvas }: any) {
    this.fotoCortada = canvas.toDataURL("image/jpeg");
  }

  defaultSize({ imageSize, visibleArea }: any) {
    const { width, height } = imageSize || visibleArea;    // const defaultSize = 100;
    return { width, height };
  }

  async salvarFoto() {
    const meusDados = await this.$utils.getMeusDados();
    if (!meusDados) return;

    this.salvando = true;
    if (
      this.foto ||
      (this.fotoCortada && this.fotoCortada.startsWith("data:image"))
    ) {
      const foto = await this.$parse.saveFile({
        name: `foto_perfil_${meusDados.id}.png`,
        base64: this.fotoCortada,
      });

      const sucesso = await this.$parse.saveObject(meusDados, { foto });

      if (sucesso) this.$emit("entrarNoSistema");
      else
        this.$emit(
          "erro",
          "Ocorreu um erro ao salvar a foto, tente novamente mais tarde"
        );
    }

    this.salvando = false;
  }
}
