
import { ParseObject } from "@/plugin/parse/parse.interfaces";
import { Component, Prop, Vue } from "vue-property-decorator";
import CardUsuario from "@/components/CardUsuario.vue";
import SecaoVazia from "@/components/SecaoVazia.vue";
import Section from "@/components/ui/Section.vue";

@Component({
  components: {
    CardUsuario,
    SecaoVazia,
    Section,
  },
})
export default class TabPresidentes extends Vue {
  created() {}

  @Prop({
    default: [],
  })
  fundadores!: ParseObject[];

  @Prop()
  carregando!: boolean;
}
