
import SecaoVazia from "@/components/SecaoVazia.vue";
import { ParseObject } from "@/plugin/parse/parse.interfaces";
import { Permissao } from "@/plugin/permissoes/permissoes.interfaces";
import { Component, Prop, Vue } from "vue-property-decorator";
import AlteracoesAssociacoesClube from "./secoes/AlteracoesAssociacoesClube.vue";
import CargosPendentesClube from "./secoes/CargosPendentesClube.vue";
import TransferenciasPendentes from "./secoes/TransferenciasPendentes.vue";

@Component({
  components: {
    CargosPendentesClube,
    AlteracoesAssociacoesClube,
    TransferenciasPendentes,
    SecaoVazia,
  },
})
export default class PendenciasClube extends Vue {
  @Prop({ required: true })
  clube!: ParseObject;

  @Prop({ required: true })
  meuClube!: boolean;

  temPendencias = false;

  podeAprovarCargos = false;
  podeAprovarTransferencias = false;
  podeSolicitarTransferencias = false;
  podeAprovarAlteracoesDeAssociacoes = false;

  created() {
    this.podeAprovarCargos = this.$permissoes.possuiAlguma([
      {
        permissao: Permissao.ATRIBUIR_CARGOS_NO_PROPRIO_CLUBE,
        clube: this.clube,
      },
      {
        permissao: Permissao.ATRIBUIR_CARGOS_EM_CLUBES_DO_PROPRIO_DISTRITO,
        distrito: this.clube.get("distrito"),
      },
      {
        permissao: Permissao.ATRIBUIR_CARGOS_EM_QUALQUER_CLUBE,
      },
    ]);

    this.podeAprovarTransferencias = this.$permissoes.possuiAlguma([
      {
        permissao:
          Permissao.APROVAR_TRANSFERENCIA_DE_ASSOCIACAO_NO_PROPRIO_CLUBE,
        clube: this.clube,
      },
      {
        permissao:
          Permissao.APROVAR_TRANSFERENCIA_DE_ASSOCIACAO_EM_CLUBES_DO_PROPRIO_DISTRITO,
        distrito: this.clube.get("distrito"),
      },
      {
        permissao:
          Permissao.APROVAR_TRANSFERENCIA_DE_ASSOCIACAO_DE_QUALQUER_CLUBE,
      },
    ]);

    this.podeSolicitarTransferencias = this.$permissoes.possuiAlguma([
      {
        permissao:
          Permissao.SOLICITAR_TRANSFERENCIA_DE_ASSOCIACAO_PARA_O_PROPRIO_CLUBE,
        clube: this.clube,
      },
    ]);

    this.podeAprovarAlteracoesDeAssociacoes = this.$permissoes.possuiAlguma([
      {
        permissao: Permissao.EDITAR_ASSOCIADOS_NO_PROPRIO_CLUBE,
        clube: this.clube,
      },
      {
        permissao: Permissao.EDITAR_ASSOCIADOS_EM_CLUBES_DO_PROPRIO_DISTRITO,
        distrito: this.clube.get("distrito"),
      },
      {
        permissao: Permissao.EDITAR_ASSOCIADOS_DE_QUALQUER_CLUBE,
      },
    ]);

    const temPermissao =
      this.podeAprovarCargos ||
      this.podeAprovarTransferencias ||
      this.podeSolicitarTransferencias ||
      this.podeAprovarAlteracoesDeAssociacoes;

    if (!temPermissao)
      return this.$router.push(
        this.meuClube ? "/meuclube" : "/clube/" + this.clube.id
      );
  }
}
