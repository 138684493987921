
import { Component, Vue } from "vue-property-decorator";
import Button from "@/components/ui/Button.vue";
import Loader from "@/components/Loader.vue";

@Component({
  components: {
    Button,
    Loader,
  },
})
export default class VincularClube extends Vue {
  distritos: any = [];
  clubes: any = [];

  distritoSelecionado: any = null;
  clubeSelecionado: any = null;
  posse: any = null;

  carregandoClubes = false;
  vinculando = false;

  confirmandoVinculo = false;

  sucesso: any = true;

  async created() {
    let distritos = this.$cache.obter("distritos-brasileiros");

    if (!distritos) {
      distritos = await this.$cloud.buscarDistritosBrasileiros();
      this.$cache.salvar("distritos-brasileiros", distritos);
    }

    this.distritos = this.$utils.converteParaVFOptions(distritos, {
      label: "numero",
    });
  }

  async carregaClubes() {
    this.carregandoClubes = true;

    const clubes = await this.$utils.listarClubesDistrito(
      this.distritoSelecionado
    );

    this.clubes = this.$utils.converteParaVFOptions(clubes, {
      label: "nome",
    });

    this.carregandoClubes = false;
  }

  async vincularClube() {
    this.vinculando = true;
    const minhaAssociacao = await this.$utils.getMinhaAssociacao();
    this.sucesso = await this.$parse
      .updateObject(minhaAssociacao, {
        clube: this.clubeSelecionado,
        posse: this.$utils.dataSemFuso(this.posse),
      })
      .catch((e) => this.$notificacao.mensagemErro(e));

    if (this.sucesso) {
      await this.$utils.getMeuClube();
      this.$emit("clubeVinculado", this.clubeSelecionado);
    }

    this.vinculando = false;
  }
}
