
import { Component, Vue } from "vue-property-decorator";
import Loader from "@/components/Loader.vue";
import Error404 from "@/components/Error404.vue";
import Section from "@/components/ui/Section.vue";
import SecaoVazia from "@/components/SecaoVazia.vue";
import Divider from "@/components/ui/Divider.vue";
import Button from "@/components/ui/Button.vue";
import TituloPrincipal from "@/components/TituloPrincipal.vue";
import { Permissao } from "@/plugin/permissoes/permissoes.interfaces";
import { ParseObject } from "@/plugin/parse/parse.interfaces";
import UsuarioFotoNome from "@/components/UsuarioFotoNome.vue";

@Component({
  components: {
    Loader,
    Section,
    SecaoVazia,
    Divider,
    Button,
    Error404,
    TituloPrincipal,
    UsuarioFotoNome,
  },
})
export default class AlteracoesAssociacaoPendentesSistema extends Vue {
  retorno = [
    {
      url: "/sistema",
      titulo: "Painel do Sistema",
    },
  ];

  possuiPermissao = false;
  carregando = true;
  salvando = false;

  alteracoesPendentes: ParseObject[] = [];

  colunas = [
    { key: "associado", label: "Associado(a)", thStyle: "width: 20%" },
    { key: "clube", label: "Clube", thStyle: "width: 14%" },
    { key: "distrito", label: "Distrito", thStyle: "width: 6%" },
    { key: "alteracoes", label: "Alterações", thStyle: "width: 30%" },
    { key: "justificativa", label: "Justificativa", thStyle: "width: 20%" },
    { key: "acao", label: "Ação", thStyle: "width: 10%" },
  ];

  created() {
    this.possuiPermissao = this.$permissoes.possui(
      Permissao.TRANSFERIR_ASSOCIACAO_PARA_QUALQUER_CLUBE
    );

    if (!this.possuiPermissao) return;

    this.carregaAlteracoesPendentes();
    // Buscar todas as alteracoesPendentes
    // Filtrar por distrito
    // Filtrar por clube novo
    // Filtrar por clube antigo
  }

  async carregaAlteracoesPendentes() {
    this.carregando = true;
    this.alteracoesPendentes = await this.$parse.getList({
      className: "AlteracaoAssociacao",
      where: [
        ["pendente", "!=", false],
        ["associacao", "exists"],
      ],
      include: [
        "associacao.usuario",
        "associacao.clube",
        "associacao.clube.distrito",
        "clube",
      ],
    });

    this.carregando = false;
  }

  async aprovarAlteracoesAssociacao(alteracoesAssociacao: ParseObject) {
    const confirmado = await this.$utils.aprovarAlteracoesAssociacao(
      alteracoesAssociacao.get("associacao"),
      alteracoesAssociacao
    );

    if (!confirmado) return;

    this.removeAssociacao(alteracoesAssociacao);
  }

  async recusarAlteracoesAssociacao(alteracoesAssociacao: ParseObject) {
    const confirmado = await this.$utils.recusarAlteracoesAssociacao(
      alteracoesAssociacao
    );

    if (!confirmado) return;

    this.removeAssociacao(alteracoesAssociacao);
  }

  removeAssociacao(associacao: ParseObject) {
    this.alteracoesPendentes = this.alteracoesPendentes.filter(
      (associacaoPendente) => associacaoPendente.id !== associacao.id
    );
  }
}
