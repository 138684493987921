
import { Component, Vue } from "vue-property-decorator";
import Loader from "@/components/Loader.vue";
import Error404 from "@/components/Error404.vue";
import Section from "@/components/ui/Section.vue";
import SecaoVazia from "@/components/SecaoVazia.vue";
import Divider from "@/components/ui/Divider.vue";
import Button from "@/components/ui/Button.vue";
import TituloPrincipal from "@/components/TituloPrincipal.vue";
import { Permissao } from "@/plugin/permissoes/permissoes.interfaces";
import { ParseObject } from "@/plugin/parse/parse.interfaces";
import UsuarioFotoNome from "@/components/UsuarioFotoNome.vue";

@Component({
  components: {
    Loader,
    Section,
    SecaoVazia,
    Divider,
    Button,
    Error404,
    TituloPrincipal,
    UsuarioFotoNome,
  },
})
export default class ProjetosParaExcluirSistema extends Vue {
  retorno = [
    {
      url: "/sistema",
      titulo: "Painel do Sistema",
    },
  ];

  possuiPermissao = false;
  carregando = true;
  salvando = false;

  solicitacoes: ParseObject[] = [];
  meusDados!: ParseObject;

  colunas = [
    { key: "titulo", label: "Titulo", thStyle: "width: 20%" },
    { key: "clube", label: "Clube", thStyle: "width: 14%" },
    { key: "distrito", label: "Distrito", thStyle: "width: 6%" },
    { key: "solicitadoPor", label: "Solicitado Por", thStyle: "width: 30%" },
    { key: "acao", label: "Ação", thStyle: "width: 10%" },
  ];

  async created() {
    this.possuiPermissao = this.$permissoes.possui(Permissao.EXCLUIR_PROJETOS);

    if (!this.possuiPermissao) return;

    const meusDados = await this.$utils.getMeusDados();
    if (meusDados) this.meusDados = meusDados;

    this.carregaSolicitacoesDeExclusao();
  }

  async carregaSolicitacoesDeExclusao() {
    this.carregando = true;
    this.solicitacoes = await this.$parse.getList({
      className: "SolicitacaoExclusaoProjeto",
      where: [["pendente", "=", true]],
      include: [
        "solicitadoPor",
        "excluidoPor",
        "projeto",
        "projeto.clube",
        "projeto.distrito",
      ],
    });

    this.carregando = false;
  }

  async excluirProjeto(solicitacao: ParseObject) {
    const confirmado = await this.$notificacao.confirmar({
      titulo: "Aprovar",
      html: "Você deseja excluir este projeto?",
      textoNao: "Cancelar",
    });

    if (!confirmado) return;

    const projeto = solicitacao.get("projeto");
    await this.$parse.updateObject(projeto, {
      status: false,
    });

    await this.$parse.updateObject(solicitacao, {
      pendente: false,
      excluidoPor: this.meusDados,
      excluidoEm: new Date(),
    });

    this.removeAssociacao(solicitacao);
  }

  async recusarExclusao(solicitacao: ParseObject) {
    const confirmado = await this.$notificacao.confirmar({
      titulo: "Recusar",
      texto: "Tem certeza que deseja cancelar a exclusão desse projeto?",
      textoNao: "Cancelar",
    });

    if (!confirmado) return;

    const projeto = solicitacao.get("projeto");
    await this.$parse
      .updateObject(projeto, {
        solicitacaoDeExclusao: undefined,
      })
      .catch((e) => this.$notificacao.mensagemErro(e));

    await this.$parse
      .updateObject(solicitacao, {
        pendente: false,
        recusadoPor: this.meusDados,
        recusadoEm: new Date(),
      })
      .catch((e) => this.$notificacao.mensagemErro(e));

    this.removeAssociacao(solicitacao);
  }

  removeAssociacao(associacao: ParseObject) {
    this.solicitacoes = this.solicitacoes.filter(
      (associacaoPendente) => associacaoPendente.id !== associacao.id
    );
  }

  excluirTodos() {
    this.$notificacao.confirmarEEsperar({
      titulo: "Excluir Todos",
      texto:
        "Tem certeza que deseja aprovar a exclusão de todos os projetos abaixo?",
      confirmou: async () => {
        const sucesso = await this.$cloud
          .excluirTodosProjetosSolicitados()
          .catch((e) => this.$notificacao.mensagemErro(e));

        if (!sucesso) return;

        this.$notificacao.sucesso({
          texto: "Projetos excluídos com sucesso!",
        });

        this.solicitacoes = [];
      },
    });
  }
}
