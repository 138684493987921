
import { ParseObject, SimpleQuery } from "@/plugin/parse/parse.interfaces";
import { Component, Vue } from "vue-property-decorator";
import Loader from "@/components/Loader.vue";
import Paginacao from "@/components/Paginacao.vue";
import FiltrosProjetos from "@/views/projetos/FiltrosProjetos.vue";
import Divider from "@/components/ui/Divider.vue";

const PROJETOS_POR_PAGINA = 30;

@Component({
  components: {
    Loader,
    Paginacao,
    FiltrosProjetos,
    Divider,
  },
})
export default class ProjetosDistrito extends Vue {
  carregando = false;
  temFiltroAtivo = false;

  distrito: any = null;

  projetos: ParseObject[] = [];
  totalDeProjetosDoDistrito = 0;
  numeroPaginaAtual = 0;
  mudancaDePagina = false;
  linhasPorPagina = PROJETOS_POR_PAGINA;

  filtros: SimpleQuery[] = [];

  async created() {
    this.carregando = true;

    this.distrito = await this.$utils.getMeuDistrito();

    this.carregarProjetos();
  }

  async carregarProjetos() {
    this.carregando = true;

    const where: SimpleQuery[] = [
      ["distrito", "=", this.distrito],
      ["clube", "exists"],
      ["solicitacaoDeExclusao", "!exists"],
      ...this.filtros,
    ];

    const skip = this.mudancaDePagina
      ? this.projetos.length * this.numeroPaginaAtual
      : 0;

    const response = await this.$parse
      .getList({
        className: "Projeto",
        where,
        count: true,
        limit: PROJETOS_POR_PAGINA,
        skip,
        orderBy: "createdAt",
        descending: true,
        include: ["clube"],
      })
      .catch((e) => this.$notificacao.mensagemErro(e));

    const { results: projetos, count } = response;
    this.totalDeProjetosDoDistrito = count;
    this.projetos = projetos.sort((a: ParseObject, b: ParseObject) => {
      const anoRotarioA = a.get("anoRotario");
      const anoRotarioB = b.get("anoRotario");
      if (anoRotarioA == anoRotarioB) {
        const createdAtA = a.get("createdAt").getTime();
        const createdAtB = b.get("createdAt").getTime();
        return createdAtB - createdAtA;
      }
      return anoRotarioB - anoRotarioA;
    });

    this.carregando = false;
    this.mudancaDePagina = false;
  }
}
