
import { ParseObject } from "@/plugin/parse/parse.interfaces";
import { Component, Prop, Vue } from "vue-property-decorator";
import CardUsuario from "@/components/CardUsuario.vue";
import SecaoVazia from "@/components/SecaoVazia.vue";
import Section from "@/components/ui/Section.vue";
import Button from "@/components/ui/Button.vue";
import ConvidarAssociado from "../modais/ConvidarAssociado.vue";
import { Permissao } from "@/plugin/permissoes/permissoes.interfaces";

type tipoConvite = "pendentes" | "aceitos" | "expirados";

@Component({
  components: {
    CardUsuario,
    SecaoVazia,
    Section,
    Button,
    ConvidarAssociado,
  },
})
export default class ConvitesClube extends Vue {
  @Prop({ required: true })
  clube!: ParseObject;

  @Prop({ required: true })
  meuClube!: boolean;

  convites: { [status: string]: ParseObject[] } = {
    pendentes: [],
    aceitos: [],
    expirados: [],
  };

  statusSelecionado: tipoConvite = "pendentes";
  colunasConvites = [
    { nome: "Nome" },
    { email: "Email" },
    { posse: "Data de posse" },
    { expiraEm: "Expira em" },
    { statusConvite: "Status" },
    { acoes: "Ações" },
  ];

  created() {
    const possuiPermissao = this.$permissoes.possuiAlguma([
      {
        permissao: Permissao.ENVIAR_CONVITES_PARA_O_PROPRIO_CLUBE,
        clube: this.clube,
      },
      {
        permissao: Permissao.ENVIAR_CONVITES_PARA_CLUBES_DO_PROPRIO_DISTRITO,
        distrito: this.clube.get("distrito"),
      },
      {
        permissao: Permissao.ENVIAR_CONVITES_PARA_QUALQUER_CLUBE,
      },
    ]);

    if (!possuiPermissao)
      return this.$router.push(
        this.meuClube ? "/meuclube" : "/clube/" + this.clube.id
      );

    const convites: ParseObject[] =
      this.$cache.obter(`dados-clube-${this.clube.id}.pendencias.convites`) ||
      [];

    this.convites.aceitos = convites.filter((convite) => convite.get("aceito"));

    this.convites.expirados = convites.filter((convite) =>
      convite.get("expirou")
    );

    this.convites.pendentes = convites.filter(
      (convite) => !convite.get("aceito") && !convite.get("expirou")
    );

    const temPendencias = this.convites.pendentes.length > 0;

    if (!temPendencias) this.statusSelecionado = "aceitos";
  }

  podeReenviarEmail(convite: ParseObject): boolean {
    const naoFoiEnviadoEmail = !convite.get("ultimoEmailEnviadoEm");
    const temMaisDe15MinOEnvio =
      new Date().getTime() - convite.get("ultimoEmailEnviadoEm")?.getTime() >
      1000 * 60 * 15;
    return naoFoiEnviadoEmail || temMaisDe15MinOEnvio;
  }

  inserirConvite(convite: ParseObject) {
    this.convites.pendentes.push(convite);
  }

  async removerConvite(convite: ParseObject) {
    if (!convite) return;

    const confirmou = await this.$notificacao.confirmar({
      titulo: "Remover convite",
      html: `Deseja remover o convite para ${convite.get(
        "nome"
      )}? <br/><br/> Esta ação não poderá ser desfeita. <br/><br/> <b class="text-danger">Caso o convite estiver pendente, a pessoa não conseguirá utulizar o convite mais</b>.`,
    });

    if (!confirmou) return;

    const sucesso = await this.$parse.deleteObject(convite);

    if (sucesso) {
      this.convites[this.statusSelecionado] = this.convites[
        this.statusSelecionado
      ].filter((c) => c.id !== convite.id);

      this.$cache.atualizar(
        `dados-clube-${this.clube.id}.pendencias.convites`,
        this.convites
      );
    }
  }
}
