import { Permissao } from "../permissoes/permissoes.interfaces";
import {
    IAlterarEmail,
    IBuscarClubesDistrito,
    IConvidarNovoAssociado,
    ICriarContaViaConvite,
    IMigrarHistorico,
    IReligarAssociado,
    IRetornoBuscaNotificacoes,
    IRetornoBuscarAssociacoesClube,
    IRetornoBuscarDadosClube,
    IRetornoBuscarDadosConvite,
    IRetornoBuscarDadosDistrito,
    IRetornoBuscarDadosLogin,
    IRetornoBuscarDadosProjeto,
    IRetornoBuscarDadosUsuario,
    ISalvarProjeto,
    ISolicitarTransferenciaAssociacao
} from "./cloud.interfaces";
import { ParseObject } from "./parse.interfaces";
import ParsePlugin from "./parse.plugin";

/**
 * AVISO: Sempre que uma nova função for adicionada no cloud, ela deve ser adicionada aqui também.
 */
export default class ParseCloudPlugin {
    constructor(private parse: ParsePlugin) {
        this.parse = parse;
    }

    /**
     * Esse é um método genérico para chamar qualquer função do Parse.Cloud
     *
     * ----------------
     * @async
     * @param {string} nomeFuncao - The name of the Cloud Function
     * @param {Object} params - the params for the Cloud Function
     * @returns {any} Whatever the Cloud Function returns
     */
    async executa(nomeFuncao: string, params?: any): Promise<any> {
        return this.parse.cloudFunction(nomeFuncao, params);
    }

    async alterarEmail(params: IAlterarEmail): Promise<boolean> {
        return this.parse.cloudFunction("alterarEmail", params);
    }

    async verificaEmailExiste(email: string): Promise<boolean> {
        return this.parse.cloudFunction("verificaEmailExiste", { email });
    }

    async verificaAceitouTermos(email: string): Promise<boolean> {
        return this.parse.cloudFunction("verificaAceitouTermos", { email });
    }

    /**
     * Busca as informações se o e-mail informado existe e se, caso exista, o usuário aceitou os termos de uso.
     */
    async buscarDadosLogin(email: string): Promise<IRetornoBuscarDadosLogin> {
        return this.parse.cloudFunction("buscarDadosLogin", { email });
    }

    /**
     * Retorna as informações de assocaições e cargos vinculados ao clube, bem como as informações de pendências.
     */
    async buscarDadosClube(idClube: string): Promise<IRetornoBuscarDadosClube> {
        return this.parse.cloudFunction("buscarDadosClube", { idClube });
    }

    /**
     * Gera o convite para ingressar no sistema.
     * 
     * Por padrão a validade do convite é de 15 dias, para alterar, é necessário alterar diretamente na função no cloud.
     * 
     * A função retorna o objeto do convite criado.
     */
    async convidarNovoAssociado(params: IConvidarNovoAssociado): Promise<ParseObject> {
        return this.parse.cloudFunction("convidarNovoAssociado", params);
    }

    /**
     * Busca as informações relativas ao convite para ingressar no sistema.
     * 
     * Por segurança, o objeto do convite não é retornado, somente as informações relevantes para exibição no front-end.
     */
    async buscarDadosConvite(idConvite: string): Promise<IRetornoBuscarDadosConvite> {
        return this.parse.cloudFunction("buscarDadosConvite", { idConvite });
    }

    /**
     * Essa função é chamada no momento que o usuário aceita o convite para se cadastrar no sistema.
     * 
     * Ela cria um novo usuário no Parse, e associa o usuário ao clube vinculado ao convite.
     */
    async criarContaViaConvite(params: ICriarContaViaConvite): Promise<boolean> {
        return this.parse.cloudFunction("criarContaViaConvite", params);
    }


    /**
     * Migra todos os dados de associações e cargos de um usuário de um clube para outro.
     */
    async migrarHistorico(params: IMigrarHistorico): Promise<boolean> {
        return this.parse.cloudFunction("migrarHistorico", params);
    }

    /**
     * 
     */
    async recuperarDadosUsuario(params: any): Promise<any> {
        return this.parse.cloudFunction("recuperarDadosUsuario", params);
    }


    /**
     * Busca os dados básicos para a criação de um projeto, tais como:
     * - Categorias de projetos
     * - Áreas de enfoque
     * - Graus de dificuldade
     * - Lista de envolvidos
     * - Lista de faixas etarias
     */
    async buscarDadosProjeto(): Promise<IRetornoBuscarDadosProjeto> {
        return this.parse.cloudFunction("buscarDadosProjeto");
    }

    /**
     * Salva as informações relativas a um projeto.
     * 
     * Caso seja passado uma id nos parametros, atualiza o projeto existente, caso contrário, cria um novo projeto.
     */
    async salvarProjeto(params: ISalvarProjeto): Promise<ParseObject> {
        return this.parse.cloudFunction("salvarProjeto", params);
    }

    /**
     * Busca os clubes do distrito pela id do distrito ou pelo número, 
     * filtrando quais clubes deseja: 'ativos', 'desativados'.
     * 
     * Caso não informado o status do clube, retorna todos os clubes.
     */
    async buscarClubesDistrito(params: IBuscarClubesDistrito): Promise<ParseObject[]> {
        return this.parse.cloudFunction("buscarClubesDistrito", params);
    }

    async buscarDistritosBrasileiros(): Promise<ParseObject[]> {
        const excluirPorNumero = [];
        if (process.env.VUE_APP_MOSTRAR_DISTRITO_DE_TESTE !== 'true') excluirPorNumero.push(1234);
        return this.parse.cloudFunction("buscarDistritosBrasileiros", { excluirPorNumero });
    }

    async buscarCargosOmir(anoRotario: string): Promise<ParseObject[]> {
        return this.parse.cloudFunction("buscarCargosOmir", { anoRotario });
    }

    async buscarRDRs(anoRotario: string): Promise<ParseObject[]> {
        return this.parse.cloudFunction("buscarRDRs", { anoRotario });
    }

    /**
     * Busca todas as permissões do usuário logado.
     */
    async buscarPermissoesUsuarioLogado(): Promise<{ [permissao in Permissao]: boolean }> {
        return this.parse.cloudFunction("buscarPermissoesUsuarioLogado");
    }

    async buscarDadosDistrito(idDistrito: string): Promise<IRetornoBuscarDadosDistrito> {
        return this.parse.cloudFunction("buscarDadosDistrito", { idDistrito });
    }

    async buscarDadosUsuario(idUsuario: string): Promise<IRetornoBuscarDadosUsuario> {
        return this.parse.cloudFunction("buscarDadosUsuario", { idUsuario });
    }

    async buscarAssociacoesClube(idClube: string): Promise<IRetornoBuscarAssociacoesClube> {
        return this.parse.cloudFunction("buscarAssociacoesClube", { idClube });
    }

    async buscarAptosAoReligamentoClube(idClube: string): Promise<ParseObject[]> {
        return this.parse.cloudFunction("buscarAptosAoReligamentoClube", { idClube });
    }

    async religarAssociado(params: IReligarAssociado): Promise<ParseObject> {
        return this.parse.cloudFunction("religarAssociado", params);
    }

    async solicitarTransferenciaAssociacao(params: ISolicitarTransferenciaAssociacao): Promise<boolean> {
        return this.parse.cloudFunction("solicitarTransferenciaAssociacao", params);
    }

    async aprovarTransferencia(idTransferencia: string): Promise<boolean> {
        return this.parse.cloudFunction("aprovarTransferencia", { idTransferencia });
    }

    async buscaNotificacoes(): Promise<IRetornoBuscaNotificacoes> {
        return this.parse.cloudFunction("buscaNotificacoes");
    }
    async reenviarEmailConvite(idConvite: string): Promise<Boolean> {
        return this.parse.cloudFunction("reenviarEmailConvite", { idConvite });
    }

    async verificaValidadeTokenRecuperacao(token: string): Promise<Boolean> {
        return this.parse.cloudFunction("verificaValidadeTokenRecuperacao", { token });
    }

    async enviarEmailRecuperacaoUsuario(idUsuario: string): Promise<Boolean> {
        return this.parse.cloudFunction("enviarEmailRecuperacaoUsuario", { idUsuario });
    }

    async excluirCadastro(idUsuario: string): Promise<Boolean> {
        return this.parse.cloudFunction("excluirCadastro", { idUsuario });
    }

    async excluirTodosProjetosSolicitados(): Promise<Boolean> {
        return this.parse.cloudFunction("excluirTodosProjetosSolicitados");
    }
}
