var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row recuperar-dados"},[_c('div',{staticClass:"col-md-12 mx-auto"},[_c('div',{},[_c('h1',{staticClass:"text-center mb-4"},[_vm._v("Recuperação de Dados")]),_c('FormulateForm',{staticClass:"w-100 row px-xl-5 m-0",attrs:{"name":"formLogin"},on:{"submit":function($event){return _vm.recuperar($event)}}},[_c('FormulateInput',{staticClass:"w-100 mb-3 col-12",attrs:{"type":"text","name":"nome","validation":"^bail|required","disabled":_vm.carregando,"label":"Nome completo","placeholder":"Digite aqui o seu nome completo","validation-messages":{
              required: 'O nome é obrigatório',
            }}}),_c('FormulateInput',{staticClass:"w-100 mb-3 col-12",attrs:{"type":"text","name":"email","validation":"^bail|required|email","disabled":_vm.carregando,"label":"E-mail","placeholder":"E-mail cadastrado no sistema","validation-messages":{
              required: 'O e-mail é obrigatório',
              email: 'O e-mail é inválido',
            }}}),_c('FormulateInput',{staticClass:"w-100 mb-3",attrs:{"type":"password","name":"senha","disabled":_vm.carregando,"validation":"required","label":"Senha","placeholder":"Digite aqui a sua senha","validation-messages":{
              required: 'A senha é obrigatória',
            }}}),_c('FormulateInput',{staticClass:"w-100 mb-3",attrs:{"type":"password","name":"confirmacao_senha","disabled":_vm.carregando,"validation":"required","label":"Repetir Senha","placeholder":"Digite aqui a sua senha","validation-messages":{
              required: 'A senha é obrigatória',
            }}}),_c('div',{staticClass:"col-12"},[(!_vm.carregando)?_c('button',{staticClass:"btn btn-success mt-3 float-end px-4 py-2",attrs:{"type":"submit"}},[_vm._v("Recuperar")]):_vm._e()])],1),_c('B-Alert',{staticClass:"text-center mt-5",attrs:{"show":_vm.erro.length > 0,"variant":"danger"},domProps:{"innerHTML":_vm._s(_vm.erro)}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }