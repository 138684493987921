
import { Component, Vue } from "vue-property-decorator";
import Loader from "@/components/Loader.vue";
import CardSistema, { IParametroCard } from "@/views/sistema/CardSistema.vue";
import Error404 from "@/components/Error404.vue";
import Divider from "@/components/ui/Divider.vue";
import TituloPrincipal from "@/components/TituloPrincipal.vue";
import { Permissao } from "@/plugin/permissoes/permissoes.interfaces";
import { ETipoNotificacao } from "@/plugin/notificacao/notificacao.interfaces";

interface ISecaoSistema {
  nomeSecao: string;
  cards: IParametroCard[];
}

@Component({
  components: {
    Loader,
    Error404,
    Divider,
    TituloPrincipal,
    CardSistema,
  },
})
export default class PainelSistema extends Vue {
  possuiPermissao = false;

  secoesSistema: ISecaoSistema[] = [
    {
      nomeSecao: "Secretaria",
      cards: [
        {
          nome: "Cargos Pendentes",
          rota: "/sistema/pendencias/cargos",
          permissao: Permissao.APROVAR_CARGOS_PENDENTES_DA_ROTARACT_BRASIL,
          notificacao: ETipoNotificacao.CARGOS_PARA_APROVAR_ROTARACT_BR
        },
        {
          nome: "Transferências Pendentes",
          rota: "/sistema/pendencias/transferencias",
          permissao: Permissao.TRANSFERIR_ASSOCIACAO_PARA_QUALQUER_CLUBE,
          // desabilitado: true,
        },
        {
          nome: "Alterações de <br> Associação Pendentes",
          rota: "/sistema/pendencias/associacoes",
          permissao: Permissao.EDITAR_ASSOCIADOS_DE_QUALQUER_CLUBE,
          // desabilitado: true,
        },
        {
          nome: "Localizar Associação",
          rota: "/sistema/localizar-associacao",
          permissao: Permissao.LOCALIZAR_ASSOCIACAO_NO_SISTEMA,
        },
        {
          nome: "Vincular Associação",
          rota: "/sistema/vinculacao",
          permissao: Permissao.TI_VINCULAR_ASSOCIADO_A_QUALQUER_CLUBE,
          // desabilitado: true,
        },
      ],
    },
    {
      nomeSecao: "Projetos",
      cards: [
        {
          nome: "Projetos para Excluir",
          rota: "/sistema/projetos-para-excluir",
          permissao: Permissao.EXCLUIR_PROJETOS,
          notificacao: ETipoNotificacao.PROJETOS_PARA_EXCLUIR_ROTARACT_BR
        },
      ],
    },
    {
      nomeSecao: "Jurídico",
      cards: [
        {
          nome: "Termos de Uso",
          rota: "/sistema/termos",
          permissao: Permissao.VISUALIZAR_TERMOS_DE_USO,
          desabilitado: true,
        },
      ],
    },
    {
      nomeSecao: "TI",
      cards: [
        {
          nome: "Cargos do Sistema",
          rota: "/sistema/cargos",
          permissao: Permissao.VISUALIZAR_CARGOS_SISTEMA,
        },
        {
          nome: "Reiniciar Login",
          rota: "/sistema/reiniciar-login",
          permissao: Permissao.REDEFINIR_FLUXO_LOGIN,
        },
        // {
        //   nome: "Distritos",
        //   rota: "/sistema/termos",
        //   permissao: Permissao.CADASTRAR_NOVO_DISTRITO,
        // },
        {
          nome: "Migrar Histórico",
          rota: "/sistema/migrar-historico",
          permissao: Permissao.MIGRAR_HISTORICO,
        },
      ],
    },
  ];

  created() {
    this.possuiPermissao = this.$permissoes.possui(
      Permissao.VISUALIZAR_SISTEMA
    );

    this.secoesSistema = this.secoesSistema.map(({ nomeSecao, cards }) => ({
      nomeSecao,
      cards: cards.filter(
        (card) => this.$permissoes.possui(card.permissao) && !card.desabilitado
      ),
    }));
  }
}
