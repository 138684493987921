
import { Component, Prop, Vue } from "vue-property-decorator";
import Button from "@/components/ui/Button.vue";
import Section from "@/components/ui/Section.vue";
import Loader from "@/components/Loader.vue";
import Aviso from "@/components/Aviso.vue";
import { ParseObject } from "@/plugin/parse/parse.interfaces";

@Component({
  components: {
    Button,
    Section,
    Loader,
    Aviso,
  },
})
export default class ConvidarAssociado extends Vue {
  @Prop({ required: true })
  clube: any;

  convidandoAssociado: boolean = false;
  carregando = false;
  email: string = "";
  nome: string = "";
  posse: string = "";

  mensagemErro = "";

  cancelar = false;

  quinzeDiasAFrente = new Date(new Date().setDate(new Date().getDate() + 15))
    .toISOString()
    .split("T")[0];

  async created() {}

  async gerarConvite({ nome, email, posse }: any) {
    this.mensagemErro = "";
    if (!nome || !email || !posse || this.cancelar) return;
    this.convidandoAssociado = true;

    const convites =
      this.$cache.obter(`dados-clube-${this.clube.id}.pendencias.convites`) ||
      [];

    const emailJaPossuiConvite = convites.find(
      (convite: ParseObject) => convite.get("email") === email
    );

    if (emailJaPossuiConvite) {
      this.mensagemErro = "Este e-mail já possui um convite pendente";
      this.convidandoAssociado = false;
      return;
    }

    const paramsConvite = {
      nome: nome.trim(),
      email,
      posse: this.$utils.dataSemFuso(posse),
      idClube: this.clube.id,
    };

    const conviteCriado = await this.$cloud
      .convidarNovoAssociado(paramsConvite)
      .catch((e) => {
        this.$notificacao.logErro(e);
        this.mensagemErro =
          "Ocorreu um erro ao enviar o convite, tente novamente mais tarde.";
      });

    if (conviteCriado) {
      convites.push(conviteCriado);

      this.$cache.atualizar(
        `dados-clube-${this.clube.id}.pendencias.convites`,
        convites
      );

      this.$emit("conviteEnviado", conviteCriado);
      this.$bvModal.hide("modal-convite-associado");
    }

    this.convidandoAssociado = false;
  }

  resetaForm(event: any) {
    if (this.carregando || this.convidandoAssociado) event.preventDefault();
    else {
      this.email = "";
      this.nome = "";
      this.posse = "";
    }
  }
}
