
import { Component, Vue } from "vue-property-decorator";
import Loader from "@/components/Loader.vue";
import Error404 from "@/components/Error404.vue";
import Section from "@/components/ui/Section.vue";
import SecaoVazia from "@/components/SecaoVazia.vue";
import Divider from "@/components/ui/Divider.vue";
import Button from "@/components/ui/Button.vue";
import TituloPrincipal from "@/components/TituloPrincipal.vue";

@Component({
  components: {
    Loader,
    Section,
    SecaoVazia,
    Divider,
    Button,
    Error404,
    TituloPrincipal,
  },
})
export default class LocalizarClube extends Vue {
  buscando = false;
  buscaRealizada = false;

  clubes: any = [];
  camposClube = [
    {
      key: "nome",
      label: "Nome",
    },
    {
      key: "distrito",
      label: "Distrito",
    },
  ];

  async buscarClubes({ nome }: { nome: string }) {
    this.buscando = true;
    this.clubes = [];
    const clubes = await this.$parse
      .getList({
        className: "Clube",
        where: [["nome", "matches", nome]],
        include: ["distrito"],
      })
      .catch((e) => this.$notificacao.mensagemErro(e));

    this.clubes = clubes || [];
    this.buscando = false;
    this.buscaRealizada = true;
  }
}
