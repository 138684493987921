
import { Component, Prop, Watch, Vue } from "vue-property-decorator";

@Component({})
export default class Paginacao extends Vue {
  @Prop({ type: Number, required: true, default: 10 })
  totalDeEntradas!: number;

  @Prop({ type: Number, required: true, default: 0 })
  numeroPaginaAtual!: number;

  @Prop({ type: Number, default: 10 })
  linhasPorPagina!: number;

  @Prop({ type: Number, default: 0 })
  totalLinhasPaginaAtual!: number;

  indexPrimeiraLinha = 1;
  indexUltimaLinha = 10;
  paginas = 0;
  atual = 0;

  @Watch("totalDeEntradas")
  atualizaPagina(value: number) {
    this.paginas = Math.ceil(value / this.linhasPorPagina) - 1;
  }

  mudarPagina(pagina: number) {
    if (pagina < 0 || pagina > this.paginas) return;

    this.$emit("mudarPagina", pagina);
  }
}
