
import { Component, Prop, Vue } from "vue-property-decorator";

export interface IRetornoTituloPrincipal {
  url: string;
  titulo: string;
}

@Component({})
export default class TituloPrincipal extends Vue {
  @Prop({ required: true })
  titulo!: string;

  @Prop()
  retorno!: IRetornoTituloPrincipal[];
}
