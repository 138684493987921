
import { ParseObject } from "@/plugin/parse/parse.interfaces";
import { Component, Vue, Watch } from "vue-property-decorator";
import CarregandoInfo from "@/components/CarregandoInfo.vue";
import Error404 from "@/components/Error404.vue";
import TituloPrincipal from "@/components/TituloPrincipal.vue";
import VincularClube from "./vincular-clube/VincularClube.vue";
import InformacoesClube from "./informacoes/InformacoesClube.vue";
import PendenciasClube from "./pendencias/PendenciasClube.vue";
import ConvitesClube from "./convites/ConvitesClube.vue";

@Component({
  components: {
    CarregandoInfo,
    Error404,
    TituloPrincipal,
    VincularClube,
    InformacoesClube,
    PendenciasClube,
    ConvitesClube,
  },
})
export default class Clube extends Vue {
  clube!: ParseObject;

  carregando = false;
  clubeNaoExiste = false;
  vincularClube = false;
  meuClube = false;

  nomeRota = "";

  created() {
    // this.route =
  }

  /**
   * Carrega o objeto do clube com base na url.
   *
   * Se a url não possuir o parametro id, significa que está acessando o 'meuclube', caso contrário, está acessando um outro clube.
   *
   * Após pegar o objeto do clube, carrega todas as informações necessárias para exibição.
   *
   * O decorator @watch garante que quando o usuário estiver com esse componente aberto (já acessando as informações de um clube)
   * e clicar para visualizar outro clube, que o Vue recarrega as informações do novo clube.
   */
  @Watch("$route", { immediate: true, deep: true })
  async carregaClube() {
    // Impede que seja chamado se já estiver carregando;
    if (this.carregando) return;
    this.carregando = true;

    this.nomeRota = this.$route.name || "";

    const idClube = this.$route.params.id_clube;

    if (this.$route.name?.startsWith("meuclube")) {
      this.clube = await this.$utils.getMeuClube();

      if (!this.clube) {
        this.vincularClube = true;
        this.carregando = false;
        return;
      }

      this.meuClube = true
    }

    if (idClube) {
      const clube = await this.$parse
        .getFirst({
          className: "Clube",
          where: [["objectId", "=", idClube]],
          include: ["distrito"],
        })
        .catch((e) => this.$notificacao.mensagemErro(e));

      if (clube) this.clube = clube;
    }

    if (!this.clube) {
      this.clubeNaoExiste = true;
      this.carregando = false;
      return;
    }

    const dadosClubeEmCache = this.$cache.emCache(
      `dados-clube-${this.clube.id}`
    );

    if (!dadosClubeEmCache) {
      const dadosClube = await this.$cloud.buscarDadosClube(this.clube.id);
      this.$cache.salvar(`dados-clube-${this.clube.id}`, dadosClube);
    }

    this.carregando = false;
  }
}
