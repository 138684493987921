
import { Component, Prop, Vue } from "vue-property-decorator";
import IconesRedesSociais from "@/components/IconesRedesSociais.vue";
import Loader from "@/components/Loader.vue";
import Button from "@/components/ui/Button.vue";
import Section from "@/components/ui/Section.vue";
import Divider from "@/components/ui/Divider.vue";
import SecaoVazia from "@/components/SecaoVazia.vue";
import CarregandoInfo from "@/components/CarregandoInfo.vue";
import CardUsuario from "@/components/CardUsuario.vue";
import Error404 from "@/components/Error404.vue";
import TituloPrincipal from "@/components/TituloPrincipal.vue";
import NovoCargoAssociado from "@/components/NovoCargoAssociado.vue";
import Aviso from "@/components/Aviso.vue";
import { ParseObject } from "@/plugin/parse/parse.interfaces";
import { Permissao } from "@/plugin/permissoes/permissoes.interfaces";
import TabEquipeDistrital from "./tabs/TabEquipeDistrital.vue";
import TabGaleriaDeRDs from "./tabs/TabGaleriaDeRDs.vue";
import TabListaHonraria from "./tabs/TabListaHonraria.vue";

@Component({
  components: {
    IconesRedesSociais,
    Loader,
    Button,
    Section,
    Aviso,
    Divider,
    SecaoVazia,
    CarregandoInfo,
    Error404,
    CardUsuario,
    TituloPrincipal,
    NovoCargoAssociado,
    TabEquipeDistrital,
    TabGaleriaDeRDs,
    TabListaHonraria,
  },
})
export default class InformacoesDistrito extends Vue {
  @Prop({ required: true }) 
  distrito!: ParseObject;
  
  @Prop({ required: true }) 
  meuDistrito!: boolean;

  editando = false;
  carregandoCargos = true;
  salvando = false;

  distritoNaoExiste = false;
  podeEditarDadosDistrito = false;
  podeAtribuirCargos = false;
  podeVizualizarPendenciasDistrito = false;
  temRedesSociais = false;
  idCargoSendoAtualizado: any = null;

  bgDistritoSrc: string = "";

  camposRedesSociais: any[] = [];

  equipeDistrital: ParseObject[] = [];
  galeriaRDRs: ParseObject[] = [];
  galeriaRDRsRedistritado: {
    [numeroDistrito: number]: ParseObject[];
  } = {};
  cargosPendentes: ParseObject[] = [];

  async created() {
    this.camposRedesSociais = await this.$utils.getRedesSociais();
    this.carregaCargosDistrito();

    this.temRedesSociais =
      this.distrito.get("redesSociais") &&
      Object.keys(this.distrito.get("redesSociais")).length > 0;

    this.verificaPermissaoEditarDadosDistrito();
    this.verificaPermissaoAtribuicaoCargos();
    this.verificaPermissaoVisualizarPendencias();
  }

  async carregaCargosDistrito() {
    this.carregandoCargos = true;

    let cargosDistrito = this.$cache.obter(
      `cargos-distrito-${this.distrito.id}`
    );

    if (!cargosDistrito) {
      cargosDistrito = await this.$cloud.buscarDadosDistrito(this.distrito.id);
      this.$cache.salvar(`cargos-distrito-${this.distrito.id}`, cargosDistrito);
    }

    const { equipeDistrital, galeriaRDRs, galeriaRDRsRedistritado } =
      cargosDistrito;

    this.equipeDistrital = equipeDistrital;
    this.galeriaRDRs = galeriaRDRs;
    if (galeriaRDRsRedistritado)
      this.galeriaRDRsRedistritado = galeriaRDRsRedistritado;

    this.carregandoCargos = false;
  }

  verificaPermissaoEditarDadosDistrito() {
    this.podeEditarDadosDistrito = this.$permissoes.possuiAlguma([
      {
        permissao: Permissao.EDITAR_DADOS_DO_PROPRIO_DISTRITO,
        distrito: this.distrito,
      },
      { permissao: Permissao.EDITAR_DADOS_DE_QUALQUER_DISTRITO },
    ]);
  }

  verificaPermissaoAtribuicaoCargos() {
    this.podeAtribuirCargos = this.$permissoes.possuiAlguma([
      {
        permissao: Permissao.ATRIBUIR_CARGOS_NO_PROPRIO_DISTRITO,
        distrito: this.distrito,
      },
      { permissao: Permissao.ATRIBUIR_CARGOS_EM_QUALQUER_DISTRITO },
    ]);
  }

  verificaPermissaoVisualizarPendencias() {
    const podeAprovarTransferencias = this.$permissoes.possuiAlguma([
      {
        permissao:
          Permissao.APROVAR_TRANSFERENCIA_DE_ASSOCIACAO_EM_CLUBES_DO_PROPRIO_DISTRITO,
        distrito: this.distrito,
      },
      {
        permissao:
          Permissao.APROVAR_TRANSFERENCIA_DE_ASSOCIACAO_DE_QUALQUER_CLUBE,
      },
    ]);

    const podeAprovarAlteracoesDeAssociacoes = this.$permissoes.possuiAlguma([
      {
        permissao: Permissao.EDITAR_ASSOCIADOS_EM_CLUBES_DO_PROPRIO_DISTRITO,
        distrito: this.distrito,
      },
      {
        permissao: Permissao.EDITAR_ASSOCIADOS_DE_QUALQUER_CLUBE,
      },
    ]);

    this.podeVizualizarPendenciasDistrito =
      this.podeAtribuirCargos ||
      podeAprovarTransferencias ||
      podeAprovarAlteracoesDeAssociacoes;
  }

  /**
   * Normaliza as informações presentes nas infosAtualizadas conforme os atributos e em seguida salva as alterações no banco.
   */
  async salvarAlteracoes(infosAtualizadas: any) {
    this.salvando = true;

    /**
     * As redesSociais no FormulateForm são um input do tipo 'group' e o VueFormulate armazena esses valores em uma lista de objetos.
     * O atributo redesSociais é do tipo 'object' no banco de dados, por isso é necessário pegar o primeiro elemento da lista.
     */
    infosAtualizadas.redesSociais = infosAtualizadas.redesSociais[0];

    /**
     * Remove todos os campos que estão vazios.
     * O { ...infosAtualizadas } serve para gerar uma cópia antes de apagar, por garantia.
     */
    Object.entries({ ...infosAtualizadas }).forEach(([campo, valor]) => {
      if (!valor) delete infosAtualizadas[campo];
    });

    /**
     * Remove todas as redes sociais que não tiverem um link fornecido pelo usuário.
     * O { ...infosAtualizadas.redesSociais } serve para gerar uma cópia antes de apagar, por garantia.
     */
    Object.entries({ ...infosAtualizadas.redesSociais }).forEach(
      ([nomeDaRede, linkDaRede]) => {
        if (!linkDaRede) delete infosAtualizadas.redesSociais[nomeDaRede];
      }
    );

    const sucesso = await this.$parse
      .updateObject(this.distrito, infosAtualizadas)
      .catch((e) => this.$notificacao.mensagemErro(e));

    if (sucesso) this.editando = false;

    this.salvando = false;
  }

  inserirNovoCargo(cargo: ParseObject) {
    const anoRotario = this.$utils.getAnoRotario();
    if (cargo.get("anoRotario") === anoRotario) {
      this.equipeDistrital.push(cargo);
      this.equipeDistrital.sort(
        (a, b) =>
          a.get("cargo").get("ordemExibicao") -
          b.get("cargo").get("ordemExibicao")
      );
      this.$cache.atualizar(
        `cargos-distrito-${this.distrito.id}.equipeDistrital`,
        this.equipeDistrital
      );
    }

    if (cargo.get("cargo").get("sigla") === "rdr") {
      this.galeriaRDRs.push(cargo);
      this.galeriaRDRs.sort(
        (a, b) =>
          a.get("cargo").get("ordemExibicao") -
          b.get("cargo").get("ordemExibicao")
      );
      this.$cache.atualizar(
        `cargos-distrito-${this.distrito.id}.galeriaRDRs`,
        this.galeriaRDRs
      );

      // Força o vue a renderizar a galeria de RDRs novamente
      this.galeriaRDRs = [...this.galeriaRDRs];
    }
  }
}
