
import { ParseObject } from "@/plugin/parse/parse.interfaces";
import { Component, Prop, Vue } from "vue-property-decorator";
import MiniAvatar from "./MiniAvatar.vue";

@Component({
  components: {
    MiniAvatar,
  },
})
export default class UsuarioFotoNome extends Vue {
  @Prop({ required: true }) usuario!: ParseObject;
  @Prop({ default: true }) comLink!: boolean;

  created() {}
}
