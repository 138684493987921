
import { Component, Prop, Vue } from "vue-property-decorator";
import Button from "@/components/ui/Button.vue";
import Section from "@/components/ui/Section.vue";
import Loader from "@/components/Loader.vue";
import { ParseObject } from "@/plugin/parse/parse.interfaces";
import { Permissao } from "@/plugin/permissoes/permissoes.interfaces";
import { IVueFormulateOption } from "@/plugin/utils/utils.interfaces";
import { TStatusClubes } from "@/plugin/parse/cloud.interfaces";
import { ASSOCIADO_ATIVO } from "@/plugin/utils/utils.plugin";

@Component({
  components: {
    Button,
    Section,
    Loader,
  },
})
export default class NovaAssociacao extends Vue {
  @Prop({
    default: true,
  })
  pendente!: boolean;

  @Prop()
  usuario!: ParseObject;

  @Prop()
  associacaoAtual!: ParseObject;

  @Prop()
  associacoes!: ParseObject[];

  // @Prop({ default: true })
  // dataDesligamentoObrigatoria!: boolean;

  salvando = false;
  cancelar = false;
  carregando = false;

  formNovaAssociacao: any = {};

  distritos: IVueFormulateOption[] = [];
  distritoSelecionado: any = null;

  clubes: IVueFormulateOption[] = [];
  clubeSelecionado: any = null;
  carregandoClubes = true;

  dataLimiteDesligamento: any = new Date();
  podeCadastrarDireto = false;
  erro = "";

  hoje = new Date().toISOString().split("T")[0];

  async created() {
    this.erro = "";
    this.carregando = true;
    let distritos = this.$cache.obter("distritos-brasileiros");

    if (!distritos) {
      distritos = await this.$cloud.buscarDistritosBrasileiros();
      this.$cache.salvar("distritos-brasileiros", distritos);
    }

    this.distritos = this.$utils.converteParaVFOptions(distritos, {
      label: "numero",
    });
    this.carregando = false;
  }

  async carregaClubes() {
    if (!this.distritoSelecionado) return;
    this.clubes = [];
    this.clubeSelecionado = null;
    this.carregando = true;
    this.carregandoClubes = true;
    const exibirClubesDesativados = this.$permissoes.possui(
      Permissao.TI_CADASTRAR_ASSOCIADOS_EM_CLUBES_DESATIVADOS
    );

    const statusClubes: TStatusClubes | null = exibirClubesDesativados
      ? null
      : "ativos";

    let clubes = this.$cache.obter(
      `clubes-${statusClubes}-${this.distritoSelecionado.id}`
    );

    if (!clubes) {
      clubes = await this.$cloud.buscarClubesDistrito({
        id: this.distritoSelecionado.id,
        statusClubes,
      });

      this.$cache.salvar(
        `clubes-${statusClubes}-${this.distritoSelecionado.id}`,
        clubes
      );
    }

    this.clubes = this.$utils.converteParaVFOptions(clubes, {
      label: "nome",
    });
    this.carregando = false;
    this.carregandoClubes = false;
  }

  /**
   * Caso possa cadastrar direto, a nova associação não ficará pendente.
   *
   * Caso contrário, a nova associação precisará ser aprovada pelo clube.
   */
  temPermissaoDeCadastroDireto(clube: ParseObject, distrito: ParseObject) {
    const podeCadastrarAssociadosNoProprioClube = this.$permissoes.possui(
      Permissao.EDITAR_ASSOCIADOS_NO_PROPRIO_CLUBE,
      { clube }
    );

    const podeCadastrarAssociadosEmOutrosClubes = this.$permissoes.possui(
      Permissao.EDITAR_ASSOCIADOS_EM_CLUBES_DO_PROPRIO_DISTRITO,
      { distrito }
    );

    const podeCadastrarAssociadosEmQualquerClube = this.$permissoes.possui(
      Permissao.EDITAR_ASSOCIADOS_DE_QUALQUER_CLUBE
    );

    return (
      podeCadastrarAssociadosNoProprioClube ||
      podeCadastrarAssociadosEmOutrosClubes ||
      podeCadastrarAssociadosEmQualquerClube
    );
  }

  async salvarAssociacao() {
    this.erro = "";
    const { clube, distrito, posse, desligamento, fundador, honorario } =
      this.formNovaAssociacao;

    fundador && honorario;

    if (!clube || !posse) return;

    /**
     * Se o usuário tiver a permissão para cadastrar uma nova associação diretamente,
     * a associação não é obrigada a ter a data de desligamento e nem ficará pendente.
     */
    const podeCadastrarDireto = this.temPermissaoDeCadastroDireto(
      clube,
      distrito
    );

    if (!podeCadastrarDireto && !desligamento) {
      this.erro = "Data de posse é obrigatória";
      return;
    }

    const posseNovaAss = this.$utils.dataSemFuso(posse);
    const desligamentoNovaAss = this.$utils.dataSemFuso(desligamento);

    const associacaoAtualAtiva =
      this.associacaoAtual &&
      this.associacaoAtual.get("statusAssociacao") == ASSOCIADO_ATIVO;

    const doisCadastrosAtivos = associacaoAtualAtiva && !desligamento;
    const clubeAtual =
      this.associacaoAtual?.get("clube")?.get("nome") || "(sem clube)";

    if (doisCadastrosAtivos) {
      this.erro = `Não é possível adicionar esta associação sem a data de desligamento pois este usuário encontra-se com vínculo ativo no Rotaract Club de ${clubeAtual}. <br><br> Adicione uma data de desligamento ou realize o desligamento da associação ativa.`;
      return;
    }

    if (desligamentoNovaAss && desligamentoNovaAss < posseNovaAss) {
      this.erro = "Data de posse não pode ser posterior a data de desligamento";
      return;
    }

    const posseAtual = this.associacaoAtual?.get("posse");
    const novaAssociacaoAnteriorOuIgualAAtual =
      posseAtual >= desligamentoNovaAss ||
      posseAtual.toLocaleDateString("pt-BR") ==
        desligamentoNovaAss.toLocaleDateString("pt-BR");
    // console.log(
    //   posseAtual >= desligamentoNovaAss,
    //   posseAtual.toLocaleDateString("pt-BR"),
    //   desligamentoNovaAss.toLocaleDateString("pt-BR")
    // );

    if (associacaoAtualAtiva && !novaAssociacaoAnteriorOuIgualAAtual) {
      const dataPosseAtual = this.associacaoAtual
        ?.get("posse")
        ?.toLocaleDateString("pt-BR");
      this.erro = `A data de desligamento precisa ser anterior a data de posse da associação atual: ${dataPosseAtual} (Rotaract Club de ${clubeAtual})`;
      return;
    }

    if (this.associacoes?.length > 0) {
      for (const associacao of this.associacoes) {
        const posseAss = associacao.get("posse");
        const desligamentoAss = associacao.get("desligamento");

        const posseDentroDoIntervalo =
          posseNovaAss >= posseAss && posseNovaAss <= desligamentoAss;
        const desligamentoDentroDoIntervalo =
          desligamentoNovaAss >= posseAss &&
          desligamentoNovaAss <= desligamentoAss;

        const haAssociacaoNoIntervaloDaNovaAssociacao =
          posseDentroDoIntervalo || desligamentoDentroDoIntervalo;

        if (haAssociacaoNoIntervaloDaNovaAssociacao) {
          this.erro = `Não é possível adicionar esta associação pois já existe uma associação cadastrada no mesmo período.`;
          return;
        }
      }
    }

    this.salvando = true;
    const novaAssociacao = await this.$parse.saveObject("Associado", {
      clube,
      usuario: this.usuario,
      posse: this.$utils.dataSemFuso(posse),
      desligamento: desligamento ? new Date(desligamento) : undefined,
      fundador: fundador === "Sim",
      honorario: honorario === "Sim",
      status: true,
      cadastro: this.$utils.dataSemFuso(),
      pendente: !podeCadastrarDireto,
      statusAssociacao: desligamento ? 0 : 1,
    });

    if (novaAssociacao) {
      this.$emit("novaAssociacao", novaAssociacao);
      this.resetaForm();
    }

    this.salvando = false;
  }

  resetaForm(event?: any) {
    this.erro = "";
    if (this.carregando) event.preventDefault();
    this.$bvModal.hide("modal-nova-associacao");
    this.distritoSelecionado = null;
    this.clubeSelecionado = null;
  }
}
