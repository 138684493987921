
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class Buttom extends Vue {
  @Prop() color!: 'cranberry' | 'default' | 'primary' | 'warning' | 'danger';
  @Prop() type!: 'button' | 'submit';
  @Prop() size!: string

  onClick(e: MouseEvent) {
    this.$emit("click", e);
  }
}
