
import { Component, Prop, Vue } from "vue-property-decorator";
import AvatarUsuario from "@/components/AvatarUsuario.vue";
import { ParseObject } from "@/plugin/parse/parse.interfaces";

@Component({
  components: {
    AvatarUsuario,
  },
})
export default class CardUsuario extends Vue {
  @Prop() usuario!: ParseObject;
  @Prop() desabilitado!: boolean;
  @Prop() semFooter!: boolean;

  nome = "";

  created() {
    this.nome = this.usuario.get("nome") || "-";
    // const listaNomes: string[] = nomeUsuario.trim().split(" ");
    // if (listaNomes.length < 3) return (this.nome = nomeUsuario);

    // const primeiro = listaNomes[0];
    // const ultimo = listaNomes[listaNomes.length - 1];
    // const sobrenome = listaNomes[1].trim()[0].toUpperCase() + '. '
    
    // this.nome = `${primeiro} ${sobrenome} ${ultimo}`;
  }
}
