
import { Component, Vue } from "vue-property-decorator";
import Loader from "@/components/Loader.vue";

@Component({
  components: {
    Loader,
  },
})
export default class CarregandoInfo extends Vue {}
