var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row recuperar-dados"},[_c('div',{staticClass:"col-md-12 mx-auto"},[(!_vm.validandoConvite && _vm.convite)?_c('div',{},[_c('h1',{staticClass:"text-center mb-4"},[_vm._v("Formulário de Cadastro")]),_c('FormulateForm',{staticClass:"w-100 row px-xl-5 m-0",attrs:{"name":"formLogin"},on:{"submit":function($event){return _vm.convidar($event)}}},[_c('b',{staticClass:"mb-2"},[_c('i',[_vm._v("Dados do convite: ")])]),_c('h5',{staticClass:"mb-0"},[_vm._v("Clube: "),_c('b',[_vm._v("Rotaract Club de "+_vm._s(_vm.convite.clube.nome))])]),_c('h5',{staticClass:"mb-4"},[_vm._v("Seu e-mail: "),_c('b',[_vm._v(_vm._s(_vm.convite.email))])]),_c('FormulateInput',{staticClass:"w-100 mb-3 col-12",attrs:{"type":"text","name":"nome","validation":"^bail|required","disabled":_vm.carregando,"label":"Seu nome completo","placeholder":"Digite aqui o seu nome completo","validation-messages":{
              required: 'O nome é obrigatório',
            },"value":_vm.convite.nome}}),_c('FormulateInput',{staticClass:"w-100 mb-3",attrs:{"type":"password","name":"senha","disabled":_vm.carregando,"label":"Senha","placeholder":"Digite aqui a sua senha","validation":"required|min:6","validation-messages":{
            required: 'A senha é obrigatória',
            min: 'A senha deve ter no mínimo 6 caracteres',
          }}}),_c('FormulateInput',{staticClass:"w-100 mb-3",attrs:{"type":"password","name":"confirmacaoSenha","disabled":_vm.carregando,"label":"Repetir Senha","placeholder":"Digite aqui a sua senha","validation":"required|min:6","validation-messages":{
            required: 'A senha é obrigatória',
            min: 'A senha deve ter no mínimo 6 caracteres',
          }}}),_c('div',{staticClass:"col-12"},[(!_vm.carregando)?_c('button',{staticClass:"btn btn-success mt-3 float-end px-4 py-2",attrs:{"type":"submit"}},[_vm._v("Cadastrar")]):_vm._e()])],1),_c('B-Alert',{staticClass:"text-center mt-5",attrs:{"show":_vm.erro.length > 0,"variant":"danger"},domProps:{"innerHTML":_vm._s(_vm.erro)}})],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }