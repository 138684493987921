
import { ParseObject } from "@/plugin/parse/parse.interfaces";
import { Component, Prop, Vue } from "vue-property-decorator";
import Button from "@/components/ui/Button.vue";
import Loader from "@/components/Loader.vue";
import { Permissao } from "@/plugin/permissoes/permissoes.interfaces";
import { TStatusClubes } from "@/plugin/parse/cloud.interfaces";
import { IVueFormulateOption } from "@/plugin/utils/utils.interfaces";
import { ASSOCIADO_ATIVO } from "@/plugin/utils/utils.plugin";

@Component({
  components: {
    Button,
    Loader,
  },
})
export default class EditarAssociacao extends Vue {
  @Prop()
  associacao!: ParseObject;

  @Prop({
    default: true,
  })
  precisaJustificar!: boolean;

  @Prop({
    default: true,
  })
  podeExcluir!: boolean;

  salvando = false;
  cancelar = false;
  carregandoClubes = false;

  statusAssociacao: any = null;
  honorario: any = null;

  dadosAssociacao: any = {};

  justificativa = "";

  podeEditarClube = false;

  distritos: any = [];
  distritoSelecionado: any = null;
  clubes: any = [];
  clubeSelecionado: any = null;
  clubesPorId: any = {};

  async created() {
    this.statusAssociacao = this.associacao.get("statusAssociacao");
    this.honorario = this.associacao.get("honorario") ? "Sim" : "Não";

    const semVinculo = !this.associacao.get("clube");

    this.podeEditarClube =
      semVinculo ||
      this.$permissoes.possui(Permissao.TROCAR_CLUBE_DE_QUALQUER_ASSOCIACAO);

    if (this.podeEditarClube) {
      let distritos = this.$cache.obter("distritos-brasileiros");

      if (!distritos) {
        distritos = await this.$cloud.buscarDistritosBrasileiros();
        this.$cache.salvar("distritos-brasileiros", distritos);
      }

      this.distritos = this.$utils.converteParaVFOptions(distritos, {
        label: "numero",
      });

      if (!semVinculo) {
        this.distritoSelecionado = distritos.find(
          (distrito: IVueFormulateOption) =>
            distrito.id == this.associacao.get("clube")?.get("distrito")?.id
        );

        await this.carregaClubes();
      }
      this.clubeSelecionado = this.clubes.find((clube: IVueFormulateOption) => {
        return clube.id == this.associacao.get("clube")?.id;
      }).value;
    }
  }

  async carregaClubes() {
    this.carregandoClubes = true;
    this.clubeSelecionado = null;
    const exibirClubesDesativados = this.$permissoes.possui(
      Permissao.TI_CADASTRAR_ASSOCIADOS_EM_CLUBES_DESATIVADOS
    );

    const statusClubes: TStatusClubes | null = exibirClubesDesativados
      ? null
      : "ativos";

    let clubes = this.$cache.obter(
      `clubes-${statusClubes}-${this.distritoSelecionado.id}`
    );

    if (!clubes) {
      clubes = await this.$cloud.buscarClubesDistrito({
        id: this.distritoSelecionado.id,
        statusClubes,
      });

      this.$cache.salvar(
        `clubes-${statusClubes}-${this.distritoSelecionado.id}`,
        clubes
      );
    }

    this.clubes = this.$utils.converteParaVFOptions(clubes, {
      label: "nome",
    });

    /**
     * O VueFormulate converte o objeto parse em um objeto simples no valor de uma option do select.
     *
     * Isso causa um problema ao tentar salvar passando direto o objeto que vem do select.
     *
     * Por isso, é necessário salvar o objeto parse em um objeto a parte e deixar o valor do select como a id.
     *
     * Assim, quando salvar, basta pegar o objeto parse novamente.
     */
    clubes.forEach((clube: ParseObject) => {
      this.clubesPorId[clube.id] = clube;
    });
    this.carregandoClubes = false;
  }

  async salvarAssociacao() {
    this.salvando = true;

    const {
      clube,
      fundador,
      posse,
      statusAssociacao,
      desligamento,
      honorario,
      justificativa,
      posseHonorario,
    } = this.dadosAssociacao;

    const associacaoAtualizada: any = {};
    if (posse) {
      const posseAtualizada = this.$utils.dataSemFuso(posse);
      const posseAntiga: Date = this.associacao.get("posse");
      const houveAlteracao =
        posseAntiga?.toISOString()?.split("T")[0] !== posse;
      if (!posseAntiga || houveAlteracao)
        associacaoAtualizada.posse = posseAtualizada;
    }

    const clubeAntigo = this.associacao.get("clube");
    const naoTinhaClube = !clubeAntigo;
    const mudouOClube = clube && clube?.id !== clubeAntigo?.id;
    if (naoTinhaClube || mudouOClube)
      associacaoAtualizada.vinculo = this.clubesPorId[clube.id];

    if (desligamento) {
      const desligamentoAtualizada = this.$utils.dataSemFuso(desligamento);
      const desligamentoAntigo: Date = this.associacao.get("desligamento");
      const houveAlteracao =
        desligamentoAntigo?.toISOString()?.split("T")[0] !== desligamento;
      if (!desligamentoAntigo || houveAlteracao)
        associacaoAtualizada.desligamento = desligamentoAtualizada;
    }

    const fundadorAntigo = this.associacao.get("fundador");
    const fundadorAtualizado = fundador === "Sim" ? true : false;
    if (fundadorAntigo !== fundadorAtualizado)
      associacaoAtualizada.fundador = fundadorAtualizado;

    const honorarioAntigo = this.associacao.get("honorario");
    const honorarioAtualizado = honorario === "Sim" ? true : false;
    if (honorarioAntigo !== honorarioAtualizado) {
      associacaoAtualizada.honorario = honorarioAtualizado;
    }

    associacaoAtualizada.posseHonorario =
      this.$utils.dataSemFuso(posseHonorario);

    const posseHonorarioAntiga = this.associacao.get("posseHonorario");
    const naoHouveAlteracaoHonorario =
      posseHonorarioAntiga?.toISOString()?.split()[0] == posseHonorario;
    if (!posseHonorario || honorario == "Não" || naoHouveAlteracaoHonorario)
      associacaoAtualizada.posseHonorario = undefined;

    if (statusAssociacao) {
      const statusAssociacaoAntigo =
        this.associacao.get("statusAssociacao") === false ? 0 : 1;
      if (statusAssociacaoAntigo !== statusAssociacao)
        associacaoAtualizada.statusAssociacao = Number(statusAssociacao);

      if (associacaoAtualizada.statusAssociacao === ASSOCIADO_ATIVO) {
        associacaoAtualizada.desligamento = undefined;
        associacaoAtualizada.honorario = false;
        associacaoAtualizada.posseHonorario = undefined;
      }
    }

    const alteracoesFeitas = Object.keys(associacaoAtualizada);
    const temAlteracoes = alteracoesFeitas.length > 0;

    if (temAlteracoes) {
      /**
       * Se precisar justificar, é porque o usuário não tem permissão para alterar a associação direto,
       * por isso é criado um objeto da classe 'AlteracaoAssociacao'.
       *
       * As alterações só serão aplicadas depois da aprovação do clube.
       */
      if (this.precisaJustificar) {
        associacaoAtualizada.associacao = this.associacao;
        associacaoAtualizada.pendente = true;
        associacaoAtualizada.justificativa = justificativa;

        const alteracao = await this.$parse
          .saveObject("AlteracaoAssociacao", associacaoAtualizada)
          .catch((e) => this.$notificacao.mensagemErro(e));
        if (alteracao)
          await this.$parse.updateObject(this.associacao, { alteracao });
      } else {
        /**
         * Só entrará aqui se o usuário tiver permissão para alterar a associação diretamente.
         */
        if (associacaoAtualizada.vinculo) {
          associacaoAtualizada.clube = associacaoAtualizada.vinculo;
          delete associacaoAtualizada.vinculo;
        }

        await this.$parse.updateObject(this.associacao, associacaoAtualizada);

        const clube = this.associacao.get("clube");

        if (clube) {
          this.$cache.removerDaLista(
            `dados-clube-${clube.id}.associados.ativos`,
            this.associacao
          );

          this.$cache.addNaLista(
            `dados-clube-${clube.id}.associados.desligados`,
            this.associacao
          );
        }
      }
    }
    this.$emit("atualizado", this.associacao);
    this.$bvModal.hide("modal-editar-associacao");
    this.salvando = false;
  }
}
