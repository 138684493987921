
import { Component, Prop, Vue } from "vue-property-decorator";
import Button from "@/components/ui/Button.vue";
import Section from "@/components/ui/Section.vue";
import Divider from "@/components/ui/Divider.vue";
import Loader from "@/components/Loader.vue";
import TextoRotulado from "@/components/TextoRotulado.vue";

@Component({
  components: {
    Loader,
    Button,
    Section,
    Divider,
    TextoRotulado,
  },
})
export default class EditarInformacoesClube extends Vue {
  salvando = false;

  @Prop({
    default: null,
  })
  clube: any;

  @Prop({
    default: false,
  })
  podeDarBaixaEmUmClube!: boolean;
  formularioClube: any = {};

  camposRedesSociais: any[] = [];
  redesSociais = {};
  periodosReuniao = {
    S: "Semanalmente",
    Q: "Quinzenalmente",
  };
  diasSemana = {
    1: "Domingo",
    2: "Segunda-feira",
    3: "Terça-feira",
    4: "Quarta-feira",
    5: "Quinta-feira",
    6: "Sexta-feira",
    7: "Sábado",
  };
  quinzenas = {
    1: "1ª Quinzena",
    2: "2ª Quinzena",
  };

  clubeStatus: "ativo" | "desativado" = "ativo";

  estados: any[] = [];
  estadoSelecionado: any = null;
  cidades: any[] = [];
  cidadeSelecionada: any = null;
  buscandoCidades = false;

  // Usado para exibir como o nome final fica na hora da edição.
  novoNome = "";

  async created() {
    this.camposRedesSociais = await this.$utils.getRedesSociais();

    this.buscarEstados();
  }

  /**
   * Normaliza as informações presentes nas infosAtualizadas conforme os atributos e em seguida salva as alterações no banco.
   */
  async salvarAlteracoes() {
    if (this.salvando) return;

    if (!this.cidadeSelecionada || !this.estadoSelecionado) return;
    this.salvando = true;

    const infosAtualizadas: any = {};

    // O input do tipo date retorna uma string, por isso é necessário converter para uma data antes de salvar.
    const {
      nome,
      email,
      padrinho,
      site,
      fundacao,
      reconhecimento,
      fechamento,
      tipo,
      rua,
      numero,
      bairro,
      cep,
      redesSociais: [redesSociais],
      infoReuniao: [infoReuniao],
    } = this.formularioClube;

    /**
     * As redesSociais no FormulateForm são um input do tipo 'group' e o VueFormulate armazena esses valores em uma lista de objetos.
     * Os atributos redesSociais e infoReuniao são do tipo 'object' no banco de dados, por isso é necessário pegar o primeiro elemento da lista.
     */
    infosAtualizadas.infoReuniao = infoReuniao || {};

    if (nome) infosAtualizadas.nome = nome;
    if (email) infosAtualizadas.email = email;
    if (site) infosAtualizadas.site = site;
    if (padrinho) infosAtualizadas.padrinho = padrinho;

    infosAtualizadas.estado = this.estadoSelecionado?.sigla?.toLowerCase();
    infosAtualizadas.cidade = this.cidadeSelecionada?.nome?.toLowerCase();
    infosAtualizadas.endereco = {
      rua,
      numero,
      bairro,
      cep,
    };

    let fundacaoAtualizada;
    if (fundacao) fundacaoAtualizada = this.$utils.dataSemFuso(fundacao);

    infosAtualizadas.fundacao = fundacaoAtualizada;

    let reconhecimentoAtualizada;
    if (reconhecimento)
      reconhecimentoAtualizada = this.$utils.dataSemFuso(reconhecimento);

    infosAtualizadas.reconhecimento = reconhecimentoAtualizada;

    let fechamentoAtualizada;
    if (fechamento) fechamentoAtualizada = this.$utils.dataSemFuso(fechamento);

    infosAtualizadas.fechamento = fechamentoAtualizada;

    infosAtualizadas.tipo = Number(tipo);

    /**
     * Remove todas as redes sociais que não tiverem um link fornecido pelo usuário.
     */
    infosAtualizadas.redesSociais = redesSociais || {};
    Object.entries(redesSociais).forEach(([nome, link]) => {
      if (!link) delete infosAtualizadas.redesSociais[nome];
    });

    infosAtualizadas.clubeStatus = this.clubeStatus === "ativo" ? 1 : 0;

    const sucesso = await this.$parse
      .updateObject(this.clube, infosAtualizadas)
      .catch((e) => this.$notificacao.mensagemErro(e));

    if (sucesso) this.$emit("edicaoFinalizada");

    this.salvando = false;
  }

  async buscarEstados() {
    this.estados = await fetch(
      "https://servicodados.ibge.gov.br/api/v1/localidades/estados/"
    )
      .then((response) => response.json())
      .then((estados) =>
        estados
          .map((estado: any) => ({
            value: estado,
            label: estado.nome,
            id: estado.id,
          }))
          .sort((a: any, b: any) => a.label.localeCompare(b.label))
      );

    this.estados.unshift({
      value: "0-online",
      label: "Online",
      id: "estado-online",
    });

    if (this.clube.get("estado")) {
      this.estadoSelecionado = this.estados.find(
        (estado) =>
          estado.value?.sigla?.toLowerCase() === this.clube.get("estado")
      )?.value;
      this.buscaCidades();
    }
  }

  async buscaCidades() {
    if (!this.estadoSelecionado) return;

    if (this.estadoSelecionado?.sigla == "online") {
      this.cidades = ["Online"];
      return;
    }

    this.buscandoCidades = true;

    this.cidades = await fetch(
      `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${this.estadoSelecionado?.id}/municipios`
    )
      .then((response) => response.json())
      .then((cidades) =>
        cidades
          .map((cidade: any) => ({
            value: cidade,
            label: cidade.nome,
            id: cidade.id,
          }))
          .sort((a: any, b: any) => a.label.localeCompare(b.label))
      );

    if (this.clube.get("cidade")) {
      this.cidadeSelecionada = this.cidades.find(
        (cidade) => cidade.label?.toLowerCase() === this.clube.get("cidade")
      )?.value;
    }

    this.buscandoCidades = false;
  }
}
