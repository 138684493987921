
import { Component, Vue } from "vue-property-decorator";

@Component({
  props: {
    links: Object,
    tamanho: String,
  },
})
export default class IconesRedesSociais extends Vue {}
