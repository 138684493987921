import _Vue from "vue";
import PermissoesPlugin from "./permissoes.plugin";

const permissoes = {
  install(app: typeof _Vue): void {
    const permissao = new PermissoesPlugin();
    app.prototype.$permissoes = permissao;

    app.directive('requerPermissao', (el, binding, vNode) => {      
      const naoPossuiPermissao = binding.value ? !permissao.possui(binding.value) : false;
      if (naoPossuiPermissao) {
          // replace HTMLElement with comment node
          const comment = document.createComment(' ');
          Object.defineProperty(comment, 'setAttribute', {
              value: () => undefined,
          });
          vNode.elm = comment;
          vNode.text = ' ';
          vNode.isComment = true;
          vNode.context = undefined;
          vNode.tag = undefined;
    
          if (el.parentNode) {
              el.parentNode.replaceChild(comment, el);
          }
      }
    });
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    $permissoes: PermissoesPlugin;
  }
}

export default permissoes;