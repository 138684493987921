
import SecaoVazia from "@/components/SecaoVazia.vue";
import { ParseObject } from "@/plugin/parse/parse.interfaces";
import { Permissao } from "@/plugin/permissoes/permissoes.interfaces";
import { Component, Prop, Vue } from "vue-property-decorator";
import CargosPendentesDistrito from "./secoes/CargosPendentesDistrito.vue";
import TransferenciasPendentesDistrito from "./secoes/TransferenciasPendentesDistrito.vue";
import AlteracoesAssociacoesDistrito from "./secoes/AlteracoesAssociacoesDistrito.vue";

@Component({
  components: {
    CargosPendentesDistrito,
    TransferenciasPendentesDistrito,
    AlteracoesAssociacoesDistrito,
    SecaoVazia,
  },
})
export default class PendenciasDistrito extends Vue {
  @Prop({ required: true })
  distrito!: ParseObject;

  @Prop({ required: true })
  meuDistrito!: boolean;

  temPendencias = false;

  podeAprovarCargos = false;
  podeAprovarTransferencias = false;
  podeAprovarAlteracoesDeAssociacoes = false;

  created() {
    this.podeAprovarCargos = this.$permissoes.possuiAlguma([
      {
        permissao: Permissao.ATRIBUIR_CARGOS_EM_CLUBES_DO_PROPRIO_DISTRITO,
        distrito: this.distrito,
      },
      {
        permissao: Permissao.ATRIBUIR_CARGOS_NO_PROPRIO_DISTRITO,
        distrito: this.distrito,
      },
      {
        permissao: Permissao.ATRIBUIR_CARGOS_EM_QUALQUER_DISTRITO,
      },
    ]);

    this.podeAprovarTransferencias = this.$permissoes.possuiAlguma([
      {
        permissao:
          Permissao.APROVAR_TRANSFERENCIA_DE_ASSOCIACAO_EM_CLUBES_DO_PROPRIO_DISTRITO,
        distrito: this.distrito,
      },
      {
        permissao:
          Permissao.APROVAR_TRANSFERENCIA_DE_ASSOCIACAO_DE_QUALQUER_CLUBE,
      },
    ]);

    this.podeAprovarAlteracoesDeAssociacoes = this.$permissoes.possuiAlguma([
      {
        permissao: Permissao.EDITAR_ASSOCIADOS_EM_CLUBES_DO_PROPRIO_DISTRITO,
        distrito: this.distrito,
      },
      {
        permissao: Permissao.EDITAR_ASSOCIADOS_DE_QUALQUER_CLUBE,
      },
    ]);

    const temPermissao =
      this.podeAprovarCargos ||
      this.podeAprovarTransferencias ||
      this.podeAprovarAlteracoesDeAssociacoes;

    if (!temPermissao)
      return this.$router.push(
        this.meuDistrito
          ? "/meudistrito"
          : "/distrito/" + this.distrito.get("numero")
      );
  }

  possuiPermissao(permissao: string): boolean {
    return this.$permissoes.possui(permissao as Permissao);
  }
}
