
import { Component, Vue } from "vue-property-decorator";
import Loader from "@/components/Loader.vue";
import Paginacao from "@/components/Paginacao.vue";
import FiltrosProjetos from "@/views/projetos/FiltrosProjetos.vue";
import Divider from "@/components/ui/Divider.vue";

import { ParseObject, SimpleQuery } from "@/plugin/parse/parse.interfaces";

const PROJETOS_POR_PAGINA = 50;

@Component({
  components: {
    Loader,
    Paginacao,
    Divider,
    FiltrosProjetos,
  },
})
export default class ProjetosANP extends Vue {
  carregando = false;

  projetos: ParseObject[] = [];
  totalDeProjetosDoDistrito = 0;

  numeroPaginaAtual = 0;
  mudancaDePagina = false;
  linhasPorPagina = PROJETOS_POR_PAGINA;

  filtros: SimpleQuery[] = [];

  created() {
    this.carregarProjetos();
  }

  async carregarProjetos() {
    this.carregando = true;

    const where: SimpleQuery[] = this.filtros;
    const skip = this.mudancaDePagina
      ? this.projetos.length * this.numeroPaginaAtual
      : 0;

    const response = await this.$parse
      .getList({
        className: "Projeto",
        where,
        skip,
        count: true,
        limit: PROJETOS_POR_PAGINA,
        orderBy: "createdAt",
        descending: true,
        select: [
          "anoRotario",
          "titulo",
          "clube.nome",
          "categoria",
          "cadastroFinalizado",
          "clube",
          "distrito",
        ],
      })
      .catch((e) => this.$notificacao.mensagemErro(e));

    const { results: projetos, count } = response || {};
    this.totalDeProjetosDoDistrito = count;
    this.projetos = projetos.sort((a: ParseObject, b: ParseObject) => {
      const anoRotarioA = a.get("anoRotario");
      const anoRotarioB = b.get("anoRotario");
      if (anoRotarioA == anoRotarioB) {
        const createdAtA = a.get("createdAt").getTime();
        const createdAtB = b.get("createdAt").getTime();
        return createdAtB - createdAtA;
      }
      return anoRotarioB - anoRotarioA;
    });

    this.carregando = false;
    this.mudancaDePagina = false;
  }
}
