
import { Component, Prop, Vue } from "vue-property-decorator";
import Button from "@/components/ui/Button.vue";
import Loader from "@/components/Loader.vue";
import { ParseObject, SimpleQuery } from "@/plugin/parse/parse.interfaces";
import { ETipoNotificacao } from "@/plugin/notificacao/notificacao.interfaces";

@Component({
  components: {
    Button,
    Loader,
  },
})
export default class SolicitarTransferencia extends Vue {
  @Prop({
    default: "modal-solicitar-transferencia",
  })
  idModal!: string;

  @Prop({
    required: true,
  })
  clube!: ParseObject;

  buscando = false;
  cancelar = false;
  transferindo = false;

  emailOuId = "";
  posse = "";
  mensagemErro = "";

  usuario: any = null;
  associacao: any = null;

  created() {}

  transferirOuBuscar() {
    if (this.cancelar) return;
    if (this.usuario) this.transferir();
    else this.buscar();
  }

  async buscar() {
    this.mensagemErro = "";

    const email = this.emailOuId.includes("@");

    const where: SimpleQuery[] = [];

    if (email) {
      if (!this.emailOuId.match(/^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/)) {
        this.mensagemErro = "Este não é um e-mail valido";
        return;
      }
      where.push([`usuario.DadosUsuario.email`, `=`, this.emailOuId]);
    } else where.push([`usuario.DadosUsuario.objectId`, `=`, this.emailOuId]);

    this.buscando = true;

    this.associacao = await this.$parse.getFirst({
      className: "Associado",
      where,
      include: ["usuario", "clube"],
      orderBy: "posse",
    });

    let temErro = false;

    if (!this.associacao) {
      this.mensagemErro = "Não há nenhum associado vinculado a este e-mail.";
      this.buscando = false;
      return;
    }

    const associadoAtivo =
      this.associacao.get("statusAssociacao") == 1 ||
      !this.associacao.get("desligamento");

    if (!temErro && associadoAtivo) {
      // this.mensagemErro = `O(A) associado(a) encontra-se ativo no Rotaract Club de ;
      this.mensagemErro = `O(A) associado(a) encontra-se ativo no Rotaract Club de ${this.associacao
        ?.get("clube")
        ?.get("nome")}. 
        Só é possível realizar a transferência após o desligamento no clube.`;
      this.buscando = false;
      return;
    }

    this.usuario = this.associacao.get("usuario");

    this.buscando = false;
  }

  async transferir() {
    this.mensagemErro = "";
    if (!this.posse) {
      this.mensagemErro = "Por favor, informe a data de posse.";
      return;
    }

    this.transferindo = true;

    const transferencia = await this.$cloud
      .solicitarTransferenciaAssociacao({
        idClubeAntigo: this.associacao.get("clube").id,
        idClubeNovo: this.clube.id,
        novaPosse: this.$utils.dataSemFuso(this.posse),
        idUsuario: this.usuario.id,
      })
      .catch((e) => {
        this.mensagemErro = e.message;
        this.transferindo = false;
      });

    if (transferencia) {
      const pendencias =
        this.$cache.obter(
          `dados-clube-${this.clube.id}.pendencias.transferencias.solicitadas`
        ) || [];

      pendencias.push(transferencia);

      this.$cache.atualizar(
        `dados-clube-${this.clube.id}.pendencias.transferencias.solicitadas`,
        pendencias
      );

      this.$notificacao.notificaNoMenu(
        ETipoNotificacao.TRANSFERENCIAS_SOLICITADAS_PENDENTES_MEU_CLUBE
      );
    }

    this.transferindo = false;
    this.$bvModal.hide(this.idModal);
  }

  resetaForm() {
    this.emailOuId = "";
    this.mensagemErro = "";
    this.usuario = null;
  }
}
