
import { Component, Vue } from "vue-property-decorator";
import Loader from "@/components/Loader.vue";
import Error404 from "@/components/Error404.vue";
import Section from "@/components/ui/Section.vue";
import SecaoVazia from "@/components/SecaoVazia.vue";
import Divider from "@/components/ui/Divider.vue";
import Button from "@/components/ui/Button.vue";
import TituloPrincipal from "@/components/TituloPrincipal.vue";
import { SimpleQuery } from "@/plugin/parse/parse.interfaces";
import { Permissao } from "@/plugin/permissoes/permissoes.interfaces";

@Component({
  components: {
    Loader,
    Section,
    SecaoVazia,
    Divider,
    Button,
    Error404,
    TituloPrincipal,
  },
})
export default class LocalizarAssociacao extends Vue {
  possuiPermissao = false;
  buscando = false;
  buscaRealizada = false;
  retorno = [
    {
      url: "/sistema",
      titulo: "Painel do Sistema",
    },
  ];

  clubes: any = [];
  camposClube = [
    {
      key: "nome",
      label: "Nome",
    },
    {
      key: "distrito",
      label: "Distrito",
    },
  ];

  usuarios: any = [];
  camposUsuario = [
    {
      key: "nome",
      label: "Nome",
    },
    {
      key: "email",
      label: "Email",
    },
    {
      key: "clube",
      label: "Clube",
    },
  ];

  created() {
    this.possuiPermissao = this.$permissoes.possui(
      Permissao.LOCALIZAR_ASSOCIACAO_NO_SISTEMA
    );
  }

  async pesquisar(dados: any) {
    const { nomeOuEmail } = dados;

    if (!nomeOuEmail) return;

    this.buscando = true;

    this.usuarios = [];
    const where: SimpleQuery[] = [];
    if (nomeOuEmail.includes("@")) where.push(["email", "=", nomeOuEmail]);
    else where.push(["nome", "matches", nomeOuEmail]);

    this.usuarios = await this.$parse
      .getList({
        className: "DadosUsuario",
        where,
        include: ["usuario", "associacaoAtual.clube"],
      })
      .catch((e) => this.$notificacao.mensagemErro(e));
    this.buscando = false;
    this.buscaRealizada = true;
  }
}
