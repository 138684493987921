
import { Component, Vue } from "vue-property-decorator";
@Component({
  components: {},
})
export default class EsqueciMinhaSenha extends Vue {
  email: string = "";
  enviando = false;
  emailEnviadoComSucesso = false;

  async redefinirSenha() {
    this.enviando = true;
    await this.$parse
      .resetPassword(this.email)
      .catch((e) => this.$notificacao.mensagemErro(e));

    this.$emit(
      "emailEnviado",
      "Um e-mail com as intruções de redefinição de senha foi enviado. <br> Caso não tiver recebido, <b>verificado o spam</b>, entre em contato com a secretaria do seu clube para verificar o e-mail cadastrado."
    );
    this.emailEnviadoComSucesso = true;
    this.enviando = false;
  }
}
