var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"border boder-1 mb-5 position-relative",class:{
    'py-2 ': !_vm.semEspacamentoVertival,
    'px-2': !_vm.semEspacamentoHorizontal
}},[_c('div',{class:{
    'py-3 py-md-5': !_vm.semEspacamentoVertival,
    'px-3 px-md-5': !_vm.semEspacamentoHorizontal
  }},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }