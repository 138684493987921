
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class Section extends Vue {
  @Prop({
    default: false,
  })
  semEspacamentoHorizontal!: boolean;
  @Prop({
    default: false,
  })
  semEspacamentoVertival!: boolean;
}
