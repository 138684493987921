
import { Component, Vue } from "vue-property-decorator";
import Loader from "@/components/Loader.vue";
import Error404 from "@/components/Error404.vue";
import Section from "@/components/ui/Section.vue";
import SecaoVazia from "@/components/SecaoVazia.vue";
import Divider from "@/components/ui/Divider.vue";
import Button from "@/components/ui/Button.vue";
import TituloPrincipal from "@/components/TituloPrincipal.vue";
import { Permissao } from "@/plugin/permissoes/permissoes.interfaces";
import { ParseObject } from "@/plugin/parse/parse.interfaces";
import UsuarioFotoNome from "@/components/UsuarioFotoNome.vue";

@Component({
  components: {
    Loader,
    Section,
    SecaoVazia,
    Divider,
    Button,
    Error404,
    TituloPrincipal,
    UsuarioFotoNome,
  },
})
export default class TransferenciasPendentesSistema extends Vue {
  retorno = [
    {
      url: "/sistema",
      titulo: "Painel do Sistema",
    },
  ];

  possuiPermissao = false;
  carregando = true;
  salvando = false;

  transferencias: ParseObject[] = [];

  colunas = [
    { key: "associado", label: "Associado(a)", thStyle: "width: 20%" },
    { key: "clubeAntigo", label: "Clube Antigo", thStyle: "width: 20%" },
    { key: "clubeNovo", label: "Clube Novo", thStyle: "width: 30%" },
    { key: "acao", label: "Ação", thStyle: "width: 10%" },
  ];

  created() {
    this.possuiPermissao = this.$permissoes.possui(
      Permissao.TRANSFERIR_ASSOCIACAO_PARA_QUALQUER_CLUBE
    );

    this.carregaTransferencias();
    // Buscar todas as transferencias
    // Filtrar por distrito
    // Filtrar por clube novo
    // Filtrar por clube antigo
  }

  async carregaTransferencias() {
    this.carregando = true;
    this.transferencias = await this.$parse.getList({
      className: "TransferenciaAssociacao",
      where: [["pendente", "!=", false]],
      include: [
        "clubeAntigo",
        "clubeAntigo.distrito",
        "clubeNovo",
        "clubeNovo.distrito",
        "usuario",
      ],
    });

    this.carregando = false;
  }

  async aprovarTransferencia(transferencia: any) {
    const confirmado = await this.$notificacao.confirmar({
      titulo: "Aprovar",
      html: "Você deseja aprovar a transferência dessa associação?",
      textoNao: "Cancelar",
    });

    if (!confirmado) return;

    this.salvando = true;

    const success = await this.$cloud
      .aprovarTransferencia(transferencia.id)
      .catch((e) => this.$notificacao.mensagemErro(e));

    this.salvando = false;
    if (!success) return;

    this.removeTransferencia(transferencia);
    this.$emit("transferenciaAprovada", transferencia);
  }

  async recusarTransferencia(transferencia: any) {
    const confirmado = await this.$notificacao.confirmar({
      titulo: "Recusar",
      texto: "Tem certeza que deseja recusar a transferência dessa associação?",
      textoNao: "Cancelar",
    });

    if (!confirmado) return;

    this.salvando = true;

    const success = await this.$parse
      .updateObject(transferencia, { pendente: false, recusado: true })
      .catch((e) => this.$notificacao.mensagemErro(e));

    this.salvando = false;
    if (!success) return;

    this.removeTransferencia(transferencia);
  }

  removeTransferencia(transferencia: ParseObject) {
    this.transferencias = this.transferencias.filter(
      (_transferencia: ParseObject) => _transferencia.id !== transferencia.id
    );
  }
}
