
import { ParseObject } from "@/plugin/parse/parse.interfaces";
import { Component, Prop, Vue } from "vue-property-decorator";
@Component({})
export default class AvatarUsuario extends Vue {
  @Prop() usuario!: ParseObject;

  @Prop({ default: false }) desabilitarRedes!: boolean;

  avatarUrl: string = "";
  redesSociais: any = {};

  created() {
    if (this.usuario) {
      this.redesSociais = this.usuario.get("redesSociais") || {};
      this.avatarUrl =
        this.usuario.get("foto")?.url() || "/img/blank-avatar.425d092e.png";
    }
  }
}
