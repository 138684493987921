<template>
  <div
    class="sidebar_wrap sidebar widget_area scheme_original px-4"
    style="width: 30vw "
  >
    <div class="sidebar_inner widget_area_inner">
      <aside class="widget_number_1 widget widget_birthdays">
        <h5 class="widget_title">Próximos Aniversários</h5>
        <div class="widget_birthdays_inner">
          <ul>
            <li>
              <div class="post_thumb">
                <img
                  src="../assets/images/1000.png"
                  class="avatar photo"
                  width="96"
                  height="96"
                  alt=""
                />
              </div>
              <div class="post_title">
                <strong>Bob Davis</strong>
                <span>(30)</span> on
                <span class="date">Feb 20</span>
              </div>
            </li>
            <li>
              <div class="post_thumb">
                <img
                  src="../assets/images/1000.png"
                  class="avatar photo"
                  width="96"
                  height="96"
                  alt=""
                />
              </div>
              <div class="post_title">
                <strong>admin</strong>
                <span>(27)</span> on
                <span class="date">Apr 07</span>
              </div>
            </li>
          </ul>
        </div>
      </aside>
      <!-- <aside class="widget widget_nav_menu">
        <h5 class="widget_title">Navigation</h5>
        <div class="menu-sidebar-menu-container">
          <ul
            id="menu-sidebar-menu"
            class="menu"
          >
            <li><a href="#">Open a Ticket</a></li>
            <li><a href="#">Corporate Landing Demo</a></li>
            <li><a href="#">Education Demo</a></li>
            <li><a href="#">Online Documentation</a></li>
            <li><a href="#">Featured Pages</a></li>
          </ul>
        </div>
      </aside> -->
      <!-- <aside class="widget widget_bp_core_whos_online_widget buddypress widget">
        <h5 class="widget_title">Who&#8217;s Online</h5>
        <div class="widget-error">
          There are no users currently online
        </div>
      </aside> -->
      <aside class="widget_number_4 widget widget_text">
        <div class="textwidget">
          <div class="sc_infobox sc_infobox_style_regular">
            <span class="sc_icon icon-rocket aligncenter"></span>
            <h5 class="sc_title sc_title_regular sc_align_center">
              Projetos Cadastrados
            </h5>
            <h2 class="sc_title sc_title_regular sc_align_center">
              23.000
            </h2>
          </div>
        </div>
      </aside>
      <!-- <aside class="widget widget_recent_posts">
        <h5 class="widget_title">Recent Posts</h5>
        <article class="post_item with_thumb first">
          <div class="post_thumb">
            <img
              alt=""
              src="../assets/images/1000.png"
            />
          </div>
          <div class="post_content">
            <h6 class="post_title">
              <a href="single-post.html">Analytics: Etiam in finibus felis amet.</a>
            </h6>
            <div class="post_info">
              <span class="post_info_item post_info_posted">
                <a
                  href="single-post.html"
                  class="post_info_date"
                >August 24, 2016</a>
              </span>
              <span class="post_info_item post_info_posted_by">by
                <a
                  href="single-team.html"
                  class="post_info_author"
                >Kate Dillian</a>
              </span>
              <span class="post_info_item post_info_counters">
                <a
                  href="single-post.html"
                  class="post_counters_item"
                >
                  <span class="post_counters_number"></span>
                </a>
              </span>
            </div>
          </div>
        </article>
        <article class="post_item with_thumb">
          <div class="post_thumb">
            <img
              alt=""
              src="../assets/images/1000.png"
            />
          </div>
          <div class="post_content">
            <h6 class="post_title">
              <a href="single-post.html">Presentation: Curabitur lacinia velit nec dui
                consequat.</a>
            </h6>
            <div class="post_info">
              <span class="post_info_item post_info_posted">
                <a
                  href="single-post.html"
                  class="post_info_date"
                >August 24, 2016</a>
              </span>
              <span class="post_info_item post_info_posted_by">by
                <a
                  href="single-team.html"
                  class="post_info_author"
                >Kate Dillian</a>
              </span>
              <span class="post_info_item post_info_counters">
                <a
                  href="single-post.html"
                  class="post_counters_item"
                >
                  <span class="post_counters_number"></span>
                </a>
              </span>
            </div>
          </div>
        </article>
        <article class="post_item with_thumb">
          <div class="post_thumb">
            <img
              alt=""
              src="../assets/images/1000.png"
            />
          </div>
          <div class="post_content">
            <h6 class="post_title">
              <a href="single-post.html">Business Travel: Donec mattis quis lorem
                sed.</a>
            </h6>
            <div class="post_info">
              <span class="post_info_item post_info_posted">
                <a
                  href="single-post.html"
                  class="post_info_date"
                >August 23, 2016</a>
              </span>
              <span class="post_info_item post_info_posted_by">by
                <a
                  href="single-team.html"
                  class="post_info_author"
                >Kate Dillian</a>
              </span>
              <span class="post_info_item post_info_counters">
                <a
                  href="single-post.html"
                  class="post_counters_item"
                >
                  <span class="post_counters_number"></span>
                </a>
              </span>
            </div>
          </div>
        </article>
        <article class="post_item with_thumb">
          <div class="post_thumb">
            <img
              alt=""
              src="../assets/images/1000.png"
            />
          </div>
          <div class="post_content">
            <h6 class="post_title">
              <a href="single-post.html">A Simple Plan to Fix Our Economy.</a>
            </h6>
            <div class="post_info">
              <span class="post_info_item post_info_posted">
                <a
                  href="single-post.html"
                  class="post_info_date"
                >May 30, 2015</a>
              </span>
              <span class="post_info_item post_info_posted_by">by
                <a
                  href="single-team.html"
                  class="post_info_author"
                >Kate Dillian</a>
              </span>
              <span class="post_info_item post_info_counters">
                <a
                  href="single-post.html"
                  class="post_counters_item"
                >
                  <span class="post_counters_number"></span>
                </a>
              </span>
            </div>
          </div>
        </article>
        <article class="post_item with_thumb">
          <div class="post_thumb">
            <img
              alt=""
              src="../assets/images/1000.png"
            />
          </div>
          <div class="post_content">
            <h6 class="post_title">
              <a href="single-post.html">The beautiful Prologue is a new visionary
                design for Audi</a>
            </h6>
            <div class="post_info">
              <span class="post_info_item post_info_posted">
                <a
                  href="single-post.html"
                  class="post_info_date"
                >March 19, 2015</a>
              </span>
              <span class="post_info_item post_info_posted_by">by
                <a
                  href="single-team.html"
                  class="post_info_author"
                >Kate Dillian</a>
              </span>
              <span class="post_info_item post_info_counters">
                <a
                  href="single-post.html"
                  class="post_counters_item"
                >
                  <span class="post_counters_number"></span>
                </a>
              </span>
            </div>
          </div>
        </article>
      </aside> -->
      <aside class="widget_number_6 widget widget_text">
        <div class="textwidget">
          <div class="sc_infobox sc_infobox_style_regular style_2">
            <span class="sc_icon icon-communication1 aligncenter color_primary"></span>
            <h5 class="sc_title sc_title_regular sc_align_center">
              Membros Cadastrados
            </h5>
            <h2 class="sc_title sc_title_regular sc_align_center">
              9.000
            </h2>
          </div>
        </div>
      </aside>
      <!-- <aside class="widget_number_6 widget widget_text">
        <div class="textwidget">
          <div class="sc_infobox sc_infobox_style_error sc_infobox_closeable">
            <span class="sc_icon icon-sound aligncenter"></span>
            <h5 class="sc_title sc_title_regular sc_align_center">
              Notification
            </h5>
            <p>
              The office will be closed <br />
              01.05.2016
            </p>
          </div>
        </div>
      </aside> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Sidebar",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
