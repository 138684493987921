var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.clube)?_c('h2',{staticClass:"sc_title sc_title_underline"},[_vm._v("Projetos do Rotaract Club de "+_vm._s(_vm.clube.get('nome')))]):_vm._e(),(_vm.carregando)?_c('Loader',{attrs:{"position":"absolute","top":0,"right":0}}):_vm._e(),_c('FiltrosProjetos',{attrs:{"disabled":_vm.carregando,"classes":{
      categoria:'col-lg-4 col-md-6 ',
      areaDeEnfoque:'col-lg-4 col-md-6 ',
      statusCadastro:'col-lg-4 col-md-6 ',
      idNomeAnoRotario:'col-md-9',
    },"hide":{
      distrito: true,
      clube: true
    }},on:{"buscar":function($event){_vm.filtros = $event; _vm.numeroPaginaAtual = 0; _vm.carregarProjetos()}}}),_c('Divider'),_c('div',{staticClass:"sc_section_content_wrap overflow-x-scroll"},[_c('div',{staticClass:"sc_table"},[_c('table',[_c('tbody',[_vm._m(0),_vm._l((_vm.projetos),function(projeto){return _c('tr',{key:projeto.id},[_c('td',[_c('RouterLink',{key:projeto.id,attrs:{"to":'/projeto/' + projeto.id}},[_vm._v(" #"+_vm._s(projeto.id)+" ")])],1),_c('td',[_vm._v(_vm._s(projeto.get('anoRotario')))]),_c('td',[_vm._v(" "+_vm._s(projeto.get('titulo'))+" ")]),_c('td',[_vm._v(_vm._s(projeto.get('categoria') ? projeto.get('categoria').get('nome') : '-'))]),_c('td',[(!projeto.get('solicitacaoDeExclusao'))?_c('span',[(!projeto.get('cadastroFinalizado'))?_c('span',{staticClass:"status bg-warning",attrs:{"title":"Em andamento"}},[_c('i',{staticClass:"icon-clock-empty"})]):_vm._e(),(projeto.get('cadastroFinalizado'))?_c('span',{staticClass:"status",attrs:{"title":"Concluído"}},[_c('i',{staticClass:"icon-lock-light"})]):_vm._e()]):_vm._e(),(projeto.get('solicitacaoDeExclusao'))?_c('span',{staticClass:"status bg-danger",attrs:{"title":"Solicitação de Exclusão Pendente"}},[_c('i',{staticClass:"icon-cancel-1"})]):_vm._e()]),(!projeto.get('solicitacaoDeExclusao'))?_c('td',[_c('a',{attrs:{"href":`https://projetos.rotaractbrasil.org.br/projeto/?id=${projeto.id}&pdf=1`,"target":"_blank","title":"Gerar PDF"}},[_c('span',{staticClass:"icon-file-pdf cursor-pointer",attrs:{"title":"Gerar PDF"}})])]):_vm._e()])})],2)]),_c('Paginacao',{attrs:{"numeroPaginaAtual":_vm.numeroPaginaAtual,"totalDeEntradas":_vm.totalDeProjetosDoClube,"linhasPorPagina":_vm.linhasPorPagina,"totalLinhasPaginaAtual":_vm.projetos.length},on:{"mudarPagina":function($event){_vm.numeroPaginaAtual = $event; _vm.mudancaDePagina = true; _vm.carregarProjetos()}}}),_c('p')],1)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('tr',[_c('td',{attrs:{"width":"12em"}},[_vm._v("ID")]),_c('td',{attrs:{"width":"10em"}},[_vm._v("Ano Rotário")]),_c('td',{attrs:{"width":"60%"}},[_vm._v("Título")]),_c('td',{attrs:{"width":"30%"}},[_vm._v("Categoria")]),_c('td',{attrs:{"width":"4em"}},[_vm._v("Status")]),_c('td',{attrs:{"width":"3em"}})])
}]

export { render, staticRenderFns }