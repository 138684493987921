
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class ContagemRegressiva extends Vue {
  @Prop({
    default: "2021-09-19 20:00:00"
  }) encerraEm!: any;

  date = "0";
  agora = Math.trunc(new Date().getTime() / 1000);
  intervalo: any = null;
  mounted() {
    this.date = this.encerraEm;
    this.intervalo = setInterval(() => {
      this.agora = Math.trunc(new Date().getTime() / 1000);
      const tempo = this.dateInMilliseconds() - this.agora;
      if (tempo <= 0) {
        clearInterval(this.intervalo);
        this.$emit("finalizado", true);
      }
    }, 1000);
  }

  dateInMilliseconds() {
    return Math.trunc(Date.parse(this.date) / 1000);
  }

  seconds() {
    return (this.dateInMilliseconds() - this.agora) % 60;
  }
  minutes() {
    return Math.trunc((this.dateInMilliseconds() - this.agora) / 60) % 60;
  }
  hours() {
    return Math.trunc((this.dateInMilliseconds() - this.agora) / 60 / 60) % 24;
  }
  days() {
    return Math.trunc((this.dateInMilliseconds() - this.agora) / 60 / 60 / 24);
  }
}
