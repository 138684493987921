export enum ETipoNotificacao {
  CARGOS_PARA_APROVAR_MEU_CLUBE = 'cargosParaAprovarMeuClube',
  ALTERACOES_DE_ASSOCIACOES_MEU_CLUBE = 'alteracoesDeAssociacoesMeuClube',
  TRANSFERENCIAS_SOLICITADAS_PENDENTES_MEU_CLUBE = 'transferenciasSolicitadasPendentesMeuClube',
  TRANSFERENCIAS_PARA_APROVAR_MEU_CLUBE = 'transferenciasParaAprovarMeuClube',
  CARGOS_PARA_APROVAR_MEU_DISTRITO = 'cargosParaAprovarMeuDistrito',
  TRANSFERENCIAS_PARA_APROVAR_MEU_DISTRITO = 'transferenciasParaAprovarMeuDistrito',
  ALTERACOES_DE_ASSOCIACOES_MEU_DISTRITO = 'alteracoesDeAssociacoesMeuDistrito',
  CARGOS_PARA_APROVAR_ROTARACT_BR = 'cargosParaAprovarRotaractBr',
  PROJETOS_PARA_EXCLUIR_ROTARACT_BR = 'projetosParaExcluirRotaractBr',
}