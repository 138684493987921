import _Vue from "vue";
import ParseCloudPlugin from "./cloud.plugin";
import ParsePlugin from './parse.plugin';

const parse = {
    install(app: typeof _Vue): void {        
        const parse = new ParsePlugin({
            serverURL: process.env.VUE_APP_SERVER_URL,
            appId: process.env.VUE_APP_APP_ID,
            javascriptKey: process.env.VUE_APP_JAVASCRIPT_KEY,
            useStatus: true,
            enableLocalDatastore: true,
        });
        app.prototype.$parse = parse;
        app.prototype.$cloud = new ParseCloudPlugin(parse);
    },
}

declare module 'vue/types/vue' {
    interface Vue {
        $parse: ParsePlugin;
        $cloud: ParseCloudPlugin;
    }
}

export default parse;