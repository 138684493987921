import _Vue from "vue";
import Cache from "../cache/cache";

const cache = {
    install(app: typeof _Vue): void {
        const cache = new Cache();
        app.prototype.$cache = cache;
    },
}

declare module 'vue/types/vue' {
    interface Vue {
        /**
         * Plugin que armazena dados no formato chave:valor na memória do navegador.
         */
        $cache: Cache;
    }
}

export default cache;