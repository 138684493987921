
import { ParseObject } from "@/plugin/parse/parse.interfaces";
import { Permissao } from "@/plugin/permissoes/permissoes.interfaces";
import {
  CARGO_NO_CLUBE,
  CARGO_NO_DISTRITO,
  CARGO_NA_ROTARACTBR,
} from "@/plugin/utils/utils.plugin";
import { Component, Prop, Vue } from "vue-property-decorator";
import Section from "@/components/ui/Section.vue";
import Button from "@/components/ui/Button.vue";
import TextoRotulado from "@/components/TextoRotulado.vue";
import Aviso from "@/components/Aviso.vue";
import NovoCargoAssociado from "@/components/NovoCargoAssociado.vue";

@Component({
  components: {
    Section,
    Button,
    Aviso,
    NovoCargoAssociado,
    TextoRotulado,
  },
})
export default class PerfilCargos extends Vue {
  @Prop({ default: false })
  saoMeusDados!: boolean;

  @Prop({ default: null })
  dadosUsuario!: ParseObject;

  @Prop({
    default: {
      clube: [],
      distrito: [],
      rotaractbr: [],
    },
  })
  cargos!: any;

  @Prop({ default: null })
  clube!: ParseObject;

  @Prop({ default: null })
  distrito!: ParseObject;

  @Prop({ default: false })
  termosDeUsoAceitos!: boolean;

  @Prop({ default: false })
  carregando!: boolean;

  podeAtribuirCargoATerceiros = false;
  deletandoCargo = false;

  tabCargos: any = {
    clube: {
      ativo: true,
      titulo: "Clube",
      campos: [
        {
          key: "anoRotario",
          label: "Ano Rotário",
          thStyle: "width: 12%",
        },
        {
          key: "nome",
          thStyle: "width: 45%",
        },
        {
          key: "clube",
          thStyle: "width: 30%",
        },
      ],
    },
    distrito: {
      titulo: "Distrito",
      campos: [
        {
          key: "anoRotario",
          label: "Ano Rotário",
          thStyle: "width: 12%",
        },
        {
          key: "nome",
          thStyle: "width: 45%",
        },
        {
          key: "distrito",
          thStyle: "width: 20%",
        },
      ],
    },
    rotaractbr: {
      titulo: "Rotaract Brasil",
      campos: [
        {
          key: "anoRotario",
          label: "Ano Rotário",
          thStyle: "width: 15%",
        },
        {
          key: "nome",
          thStyle: "width: 85%",
        },
      ],
    },
  };

  created() {
    this.verificaPermissaoAtribuirCargo();
  }

  verificaPermissaoAtribuirCargo() {
    const podeEditarAsPropriasInfos = this.saoMeusDados;

    this.podeAtribuirCargoATerceiros = this.$permissoes.possuiAlguma([
      { permissao: Permissao.ATRIBUIR_CARGOS_EM_QUALQUER_CLUBE },
      {
        permissao: Permissao.ATRIBUIR_CARGOS_NO_PROPRIO_CLUBE,
        clube: this.clube,
      },
      {
        permissao: Permissao.ATRIBUIR_CARGOS_EM_CLUBES_DO_PROPRIO_DISTRITO,
        distrito: this.distrito,
      },
      {
        permissao: Permissao.ATRIBUIR_CARGOS_NO_PROPRIO_DISTRITO,
        distrito: this.distrito,
      },
      { permissao: Permissao.ATRIBUIR_CARGOS_EM_QUALQUER_DISTRITO },
      { permissao: Permissao.ATRIBUIR_CARGOS_NA_ROTARACT_BRASIL },
    ]);

    if (
      (this.podeAtribuirCargoATerceiros && this.termosDeUsoAceitos) ||
      podeEditarAsPropriasInfos
    ) {
      this.tabCargos.clube.campos.push({
        key: "remover",
        thStyle: "width: 10%",
      });
      this.tabCargos.distrito.campos.push({
        key: "remover",
        thStyle: "width: 10%",
      });
      this.tabCargos.rotaractbr.campos.push({
        key: "remover",
        thStyle: "width: 10%",
      });
    }
  }

  async inserirCargo(cargo: ParseObject) {
    if (cargo.get("tipo") === CARGO_NO_CLUBE)
      this.cargos.clube = [cargo, ...this.cargos.clube];
    if (cargo.get("tipo") === CARGO_NO_DISTRITO)
      this.cargos.distrito = [cargo, ...this.cargos.distrito];
    if (cargo.get("tipo") === CARGO_NA_ROTARACTBR)
      this.cargos.rotaractbr = [cargo, ...this.cargos.rotaractbr];

    this.cargos = { ...this.cargos };
  }

  async removerCargo(cargo: ParseObject) {
    const nomeCargo = cargo.get("cargo").get("nome");
    const anoRotarioCargo = cargo.get("anoRotario");
    const nomeClube = cargo.get("clube")?.get("nome");

    const confirmado = await this.$notificacao.confirmar({
      html: `Deseja realmente remover este cargo? 
        <br> <br> 
        <h4 style="margin-bottom: 0"><b>${nomeCargo} - ${anoRotarioCargo}</b></h4>
        <br>
        ${nomeClube ? "<h5>Rotaract Club de" + nomeClube + " </h5>" : ""}
        `,
    });

    if (!confirmado) return;

    this.deletandoCargo = true;

    let tipo = "clube";
    if (cargo.get("tipo") === CARGO_NA_ROTARACTBR) tipo = "rotaractbr";
    if (cargo.get("tipo") === CARGO_NO_DISTRITO) tipo = "distrito";

    this.cargos[tipo] = this.cargos[tipo].filter(
      (c: ParseObject) => c.id !== cargo.id
    );

    // Atualiza na cache tb
    const idUsuario = cargo.get("usuario").id;
    const dadosUsuario = this.$cache.obter(`dados-usuario-${idUsuario}`);

    if (dadosUsuario) {
      dadosUsuario.cargos[tipo] = dadosUsuario.cargos[tipo].filter(
        (c: ParseObject) => c.id !== cargo.id
      );
      this.$cache.salvar(`dados-usuario-${idUsuario}`, dadosUsuario);
    }

    await this.$parse.deleteObject(cargo);

    this.cargos = { ...this.cargos };

    this.deletandoCargo = false;
  }

  getAvisoPendenteOuRecusado(item: any) {
    if (item.get("tipo") === CARGO_NA_ROTARACTBR) {
      if (item.get("pendente"))
        return "Aguardando aprovação da Rotaract Brasil (cargo visível apenas para você)";
      if (item.get("recusado"))
        return "Cargo não aprovado pela Rotaract Brasil (cargo visível apenas para você)";
    }
    if (item.get("tipo") === CARGO_NO_DISTRITO) {
      if (item.get("pendente"))
        return "Aguardando aprovação do distrito (cargo visível apenas para você)";
      if (item.get("recusado"))
        return "Cargo não aprovado pelo distrio (cargo visível apenas para você)";
    }
    if (item.get("tipo") === CARGO_NO_CLUBE) {
      if (item.get("pendente"))
        return "Aguardando aprovação do clube (cargo visível apenas para você)";
      if (item.get("recusado"))
        return "Cargo não aprovado pelo clube (cargo visível apenas para você)";
    }
  }
}
