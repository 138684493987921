
import { ASSOCIADO_DESLIGADO } from "@/plugin/utils/utils.plugin";
import { Component, Vue } from "vue-property-decorator";
import { ETipoNotificacao } from "../plugin/notificacao/notificacao.interfaces";
import { Permissao } from "../plugin/permissoes/permissoes.interfaces";

interface IMenu {
  rota: string;
  nome: string;
  icone: string;
  tipo: string;
  subRotaPadrao?: string;
  permissao?: Permissao;
  algumaPermissao?: Permissao[];
  todasPermissoes?: Permissao[];
  subRotas?: {
    rota: string;
    nome: string;
    permissao?: Permissao;
    algumaPermissao?: Permissao[];
    todasPermissoes?: Permissao[];
    temNotificacao?: boolean;
  }[];
  temNotificacao?: boolean;
}

interface INotificacoes {
  rota: string;
  notificacoes: ([ETipoNotificacao, Permissao] | ETipoNotificacao)[];
}

const menuCompleto: IMenu[] = [
  // {
  //   rota: "/inicio",
  //   nome: "Início",
  //   icone: "icon-home",
  //   tipo: "link",
  // },
  {
    rota: "/meusdados",
    nome: "Meus Dados",
    icone: "icon-user",
    tipo: "link",
  },
  {
    rota: "/meuclube",
    nome: "Meu Clube",
    icone: "icon-users",
    tipo: "sub",
    subRotas: [
      { rota: "", nome: "Informações" },
      {
        rota: "/convites",
        nome: "Convites",
        permissao: Permissao.ENVIAR_CONVITES_PARA_O_PROPRIO_CLUBE,
      },
    ],
    temNotificacao: false,
  },
  {
    rota: "/meudistrito",
    nome: "Meu Distrito",
    icone: "icon-users-group",
    tipo: "sub",
    subRotas: [
      { rota: "", nome: "Informações" },
      { rota: "/clubes", nome: "Clubes" },
    ],
    temNotificacao: false,
  },
  {
    rota: "/rotaractbr",
    nome: "Rotaract BR",
    icone: "icon-globe-light",
    tipo: "sub",
    subRotas: [
      { rota: "/omir", nome: "Equipe" },
      { rota: "/distritos", nome: "Distritos" },
      { rota: "/rdrs", nome: "RDRs" },
      { rota: "/localizar-clube", nome: "Localizar Clube" },
    ],
  },
  {
    rota: "/projetos",
    nome: "Projetos",
    icone: "icon-doc-text",
    tipo: "sub",
    subRotas: [
      { rota: "/clube", nome: "Clube" },
      { rota: "/distrito", nome: "Distrito" },
      { rota: "/anp", nome: "ANP" },
      // { rota: "/anpd", nome: "ANPD" },
    ],
  },
  {
    rota: "/sistema",
    nome: "Sistema",
    icone: "icon-cogs",
    tipo: "link",
    temNotificacao: false,
    permissao: Permissao.VISUALIZAR_SISTEMA,
  },
];

@Component({
  components: {},
})
export default class MenuLateral extends Vue {
  rootItem = "/inicio";
  active = "/inicio";

  menuitems: IMenu[] = [];

  async mounted() {
    await this.verificaTodasNotificacoes();
    this.criaMenuEVerificaPermissoes();
    this.transformaSubsParaLinks();
    this.verificaSePodeCadastrarProjeto();
    /**
     * Sempre que um componente disparar o evento "atualizarNotificacoesMenu" globalmente,
     * atualiza as notificações do menu lateral para aparecer a bolinha de notificação.
     */
    this.$globalEvent.on(
      "atualizarNotificacoesMenu",
      this.verificaTodasNotificacoes
    );
  }

  /**
   * Retorna os items do menu que satisfazerem as permissões se existigem
   */
  criaMenuEVerificaPermissoes() {
    this.menuitems = menuCompleto.filter((item) => {
      if (!item.permissao && !item.subRotas) return true;

      if (item.subRotas) {
        const novaSubRotas = item.subRotas.filter((subRota) => {
          const { permissao, algumaPermissao, todasPermissoes } = subRota;

          if (!permissao && !algumaPermissao && !todasPermissoes) return true;

          if (permissao) return this.$permissoes.possui(permissao);
          else {
            if (algumaPermissao) {
              return this.$permissoes.possuiAlguma(
                algumaPermissao.map((permissao) => ({
                  permissao,
                }))
              );
            } else if (todasPermissoes)
              return this.$permissoes.possuiTodas(
                todasPermissoes.map((permissao) => ({ permissao }))
              );
          }

          return false;
        });

        if (novaSubRotas.length) item.subRotas = novaSubRotas;
      }

      if (item.permissao && !this.$permissoes.possui(item.permissao))
        return false;

      return true;
    });
  }

  async verificaTodasNotificacoes() {
    await Promise.all([
      this.verificaNotificacoesPendentes({
        rota: "/meuclube",
        notificacoes: [
          [
            ETipoNotificacao.CARGOS_PARA_APROVAR_MEU_CLUBE,
            Permissao.ATRIBUIR_CARGOS_NO_PROPRIO_CLUBE,
          ],
          [
            ETipoNotificacao.ALTERACOES_DE_ASSOCIACOES_MEU_CLUBE,
            Permissao.EDITAR_ASSOCIADOS_NO_PROPRIO_CLUBE,
          ],
          [
            ETipoNotificacao.TRANSFERENCIAS_SOLICITADAS_PENDENTES_MEU_CLUBE,
            Permissao.APROVAR_TRANSFERENCIA_DE_ASSOCIACAO_NO_PROPRIO_CLUBE,
          ],
          [
            ETipoNotificacao.TRANSFERENCIAS_PARA_APROVAR_MEU_CLUBE,
            Permissao.SOLICITAR_TRANSFERENCIA_DE_ASSOCIACAO_PARA_O_PROPRIO_CLUBE,
          ],
        ],
      }),

      this.verificaNotificacoesPendentes({
        rota: "/meudistrito",
        notificacoes: [
          [
            ETipoNotificacao.CARGOS_PARA_APROVAR_MEU_DISTRITO,
            Permissao.ATRIBUIR_CARGOS_NO_PROPRIO_DISTRITO,
          ],
          [
            ETipoNotificacao.CARGOS_PARA_APROVAR_MEU_DISTRITO,
            Permissao.ATRIBUIR_CARGOS_EM_CLUBES_DO_PROPRIO_DISTRITO,
          ],
          [
            ETipoNotificacao.TRANSFERENCIAS_PARA_APROVAR_MEU_DISTRITO,
            Permissao.APROVAR_TRANSFERENCIA_DE_ASSOCIACAO_EM_CLUBES_DO_PROPRIO_DISTRITO,
          ],
          [
            ETipoNotificacao.ALTERACOES_DE_ASSOCIACOES_MEU_DISTRITO,
            Permissao.EDITAR_ASSOCIADOS_EM_CLUBES_DO_PROPRIO_DISTRITO,
          ],
        ],
      }),

      this.verificaNotificacoesPendentes({
        rota: "/sistema",
        notificacoes: [
          [
            ETipoNotificacao.CARGOS_PARA_APROVAR_ROTARACT_BR,
            Permissao.ATRIBUIR_CARGOS_NA_ROTARACT_BRASIL,
          ],
          [
            ETipoNotificacao.PROJETOS_PARA_EXCLUIR_ROTARACT_BR,
            Permissao.EXCLUIR_PROJETOS,
          ],
        ],
      }),
    ]);
  }

  /**
   * Se a item do menu tiver notificações pendentes, add a rota pendencias nas suas subrotas
   */
  verificaNotificacoesPendentes(params: INotificacoes) {
    const { rota, notificacoes } = params;

    let temPermissao = false;

    const rotaTemNotificacao = notificacoes.some((notificacaoPermissao) => {
      if (Array.isArray(notificacaoPermissao)) {
        const [notificacao, permissao] = notificacaoPermissao;
        temPermissao = temPermissao || this.$permissoes.possui(permissao);
        return this.$notificacao.temNotificacao(notificacao);
      } else return this.$notificacao.temNotificacao(notificacaoPermissao);
    });

    if (!temPermissao) return;
    
    const menuItem = menuCompleto.find((item) => item.rota === rota);

    if (!menuItem) return;

    menuItem.temNotificacao = rotaTemNotificacao;

    const subMenuPendencias = menuItem.subRotas?.find(
      (item) => item.rota == "/pendencias"
    );

    if (!subMenuPendencias)
      return menuItem.subRotas?.push({
        rota: "/pendencias",
        nome: "Pendências",
        temNotificacao: rotaTemNotificacao,
      });

    subMenuPendencias.temNotificacao = rotaTemNotificacao;
  }

  /**
   * Caso o item possua somente uma rota, muda o tipo de "sub" para "link"
   */
  transformaSubsParaLinks() {
    this.menuitems.forEach((item) => {
      const soTemUmaSubRota = item.subRotas?.length === 1;
      if (soTemUmaSubRota) {
        item.tipo = "link";
        const [subRota] = item.subRotas || [];
        if (!subRota) return;
        const subRotaExiste = subRota.rota !== "";
        if (subRotaExiste) {
          item.rota = item.rota + subRota.rota;
          item.nome = subRota.nome;
        }
      }
    });
  }

  async verificaSePodeCadastrarProjeto() {
    const usuario = await this.$utils.getMeusDados();

    if (!usuario) return;

    const associacaoAtual = usuario.get("associacaoAtual");
    if (associacaoAtual.get("statusAssociacao") === ASSOCIADO_DESLIGADO) return;

    const indexTabProjetos = this.menuitems.findIndex(
      (item) => item.rota === "/projetos"
    );

    this.menuitems[indexTabProjetos].subRotas?.unshift({
      rota: "/novo",
      nome: "Novo Projeto",
    });
  }
}
