import { render, staticRenderFns } from "./PerfilInfosGerais.vue?vue&type=template&id=945b3ae0"
import script from "./PerfilInfosGerais.vue?vue&type=script&lang=ts"
export * from "./PerfilInfosGerais.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports