
import CardUsuario from "@/components/CardUsuario.vue";
import Section from "@/components/ui/Section.vue";
import UsuarioFotoNome from "@/components/UsuarioFotoNome.vue";
import { ParseObject } from "@/plugin/parse/parse.interfaces";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {
    CardUsuario,
    UsuarioFotoNome,
    Section,
  },
})
export default class TransferenciasPendentesDistrito extends Vue {
  @Prop({ required: true })
  distrito!: ParseObject;

  transferencias!: ParseObject[];
  salvando = false;
  colunas = [
    { key: "associado", label: "Associado(a)", thStyle: "width: 20%" },
    { key: "clubeAntigo", label: "Clube Antigo", thStyle: "width: 20%" },
    { key: "clubeNovo", label: "Clube Novo", thStyle: "width: 30%" },
    { key: "acao", label: "Ação", thStyle: "width: 10%" },
  ];

  created() {
    const transferencias: ParseObject[] = this.$cache.obter(
      `dados-distrito-${this.distrito.id}.transferenciasPendentes`
    );

    this.transferencias = transferencias || [];

    if (this.transferencias.length) this.$emit("temPendencias");
  }

  async aprovarTransferencia(transferencia: any) {
    const confirmado = await this.$notificacao.confirmar({
      titulo: "Aprovar",
      html: "Você deseja aprovar a transferência dessa associação?",
      textoNao: "Cancelar",
    });

    if (!confirmado) return;

    this.salvando = true;

    const success = await this.$cloud
      .aprovarTransferencia(transferencia.id)
      .catch((e) => this.$notificacao.mensagemErro(e));

    this.salvando = false;
    if (!success) return;

    this.removeTransferencia(transferencia);
    this.$emit("transferenciaAprovada", transferencia);
  }

  async recusarTransferencia(transferencia: any) {
    const confirmado = await this.$notificacao.confirmar({
      titulo: "Recusar",
      texto: "Tem certeza que deseja recusar a transferência dessa associação?",
      textoNao: "Cancelar",
    });

    if (!confirmado) return;

    this.salvando = true;

    const success = await this.$parse
      .updateObject(transferencia, { pendente: false, recusado: true })
      .catch((e) => this.$notificacao.mensagemErro(e));

    this.salvando = false;
    if (!success) return;

    this.removeTransferencia(transferencia);
  }

  removeTransferencia(transferencia: ParseObject) {
    this.transferencias = this.transferencias.filter(
      (_transferencia: ParseObject) => _transferencia.id !== transferencia.id
    );

    this.$cache.removerDaLista(
      `dados-distrito-${this.distrito.id}.transferenciasPendentes`,
      transferencia
    );

    const clubeAntigo = transferencia.get("clubeAntigo");
    const clubeNovo = transferencia.get("clubeNovo");

    this.$cache.removerDaLista(
      `dados-clube-${clubeAntigo.id}.pendencias.transferencias.pendentes`,
      transferencia
    );
    
    this.$cache.removerDaLista(
      `dados-clube-${clubeNovo.id}.pendencias.transferencias.solicitadas`,
      transferencia
    );
  }
}
