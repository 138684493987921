
import { Component, Vue } from "vue-property-decorator";
@Component({
  components: {},
})
export default class RecuperarDadosUsuario extends Vue {
  erro: string = "";
  carregando: boolean = false;
  validandoToken: boolean = false;

  async created() {
    await this.$parse.logOut();

    this.validandoToken = true;
    const valido = await this.$cloud
      .verificaValidadeTokenRecuperacao(this.$route.params.token_recuperacao)
      .catch(console.log);

    if (!valido) {
      this.$emit("erro", "O token é inválido ou expirou!");
      this.$emit("autenticacao");
      this.$router.push("/login");
    }
  }

  async recuperar(dados: any) {
    this.erro = "";
    const { email, nome, confirmacao_senha, senha } = dados;

    if (senha !== confirmacao_senha) {
      this.erro = "As senhas não conferem";
      return;
    }

    this.carregando = true;

    const sucesso = await this.$parse
      .cloudFunction("recuperarDadosUsuario", {
        email,
        nome,
        senha,
        confirmacao_senha,
        token: this.$route.params.token_recuperacao,
      })
      .catch((err: any) => {
        this.erro = err.message;
      });

    if (sucesso)
      this.$emit(
        "sucesso",
        "Dados recuperados com sucesso! Realize o login para acessar o sistema."
      );
    else this.$emit("erro", "Ocorreu um erro ao recuperar os dados!");

    this.$emit("autenticacao");

    this.$router.push("/login");

    this.carregando = false;
  }
}
