
import { Component, Vue } from "vue-property-decorator";
import Autenticacao from "./fluxo/Autenticacao.vue";
import BuscaAssociacoes from "./fluxo/BuscaAssociacoes.vue";
import SemVinculoAUmClube from "./fluxo/SemVinculoAUmClube.vue";
import AceitarTermos from "./fluxo/AceitarTermos.vue";
import EsqueciMinhaSenha from "./fluxo/EsqueciMinhaSenha.vue";
import DefinirFoto from "./fluxo/DefinirFoto.vue";
import RecuperarDadosUsuario from "./fluxo/RecuperarDadosUsuario.vue";
import CadastroViaConvite from "./fluxo/CadastroViaConvite.vue";

@Component({
  components: {
    Autenticacao,
    BuscaAssociacoes,
    SemVinculoAUmClube,
    AceitarTermos,
    EsqueciMinhaSenha,
    DefinirFoto,
    RecuperarDadosUsuario,
    CadastroViaConvite,
  },
})
export default class Login extends Vue {
  banner = 1;
  fluxos = {
    AUTENTICACAO: 1,
    BUSCANDO_ASSOCIACAO: 2,
    ACEITAR_TERMOS: 3,
    DEFINIR_FOTO: 4,
    SEM_VINCULO: 5,
    REDEFINIR_SENHA: 6,
    RECUPERAR_DADOS: 7,
    CADASTRO_VIA_CONVITE: 8,
    ERRO: 9,
  };

  fluxoAtual: number = this.fluxos.AUTENTICACAO;

  primeiroAcesso: any = null;
  temErro = false;
  mensagemErro: null | string = "asdasd";
  mensagemSucesso: null | string = "null";
  carregando: boolean = false;
  recuperandoDados: boolean = false;
  aguardadoUrlCarregar: boolean = true;

  created() {
    this.banner = Math.floor(Math.random() * 5) + 1;

    if (window.location.pathname.startsWith("/r/")) {
      return this.mudaFluxo(this.fluxos.RECUPERAR_DADOS);
    }

    if (window.location.pathname.startsWith("/convite/")) {
      return this.mudaFluxo(this.fluxos.CADASTRO_VIA_CONVITE);
    }

    if (this.$parse.usuarioLogado) {
      return this.mudaFluxo(this.fluxos.BUSCANDO_ASSOCIACAO);
    }
  }

  entrarNoSistema() {
    this.$emit("logado", true);
    this.$store.state.logado = true;

    if (this.$router.currentRoute.name == "login")
      this.$router.push("/");
  }

  mudaFluxo(fluxo: number) {
    this.fluxoAtual = fluxo;

    this.$emit("status", "");

    if (fluxo === this.fluxos.BUSCANDO_ASSOCIACAO)
      this.$emit("status", "buscando dados...");
  }

  mostraErro(erro: any) {
    this.temErro = true;
    this.mensagemErro = erro;
    this.$emit("logado", false);
    this.$store.state.logado = false;
    this.$notificacao.erro({ texto: erro });
    // this.$router.push("/login");
  }

  mostraSucesso(mensagem: string) {
    this.mensagemSucesso = mensagem;
  }
}
