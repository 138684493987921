/**
 * Essas são as permissões padrões do sistema
 */

import { ParseObject } from "../parse/parse.interfaces";

export const DESABILITAR_TODAS_RESTRICOES = process.env.VUE_APP_DESABILITAR_TODAS_RESTRICOES === 'true';
export const USAR_PERMISSOES_PADROES_DO_SISTEMA = process.env.VUE_APP_USAR_PERMISSOES_PADROES_DO_SISTEMA === 'true';

export enum Permissao {
  // Permissoes sistema
  VISUALIZAR_SISTEMA = 'VISUALIZAR_SISTEMA',
  VISUALIZAR_TERMOS_DE_USO = 'VISUALIZAR_TERMOS_DE_USO',
  VISUALIZAR_CARGOS_SISTEMA = 'VISUALIZAR_CARGOS_SISTEMA',
  CADASTRAR_E_EDITAR_CARGOS_SISTEMA = 'CADASTRAR_E_EDITAR_CARGOS_SISTEMA',
  LOCALIZAR_ASSOCIACAO_NO_SISTEMA = 'LOCALIZAR_ASSOCIACAO_NO_SISTEMA',
  REDEFINIR_FLUXO_LOGIN = 'REDEFINIR_FLUXO_LOGIN',
  MIGRAR_HISTORICO = 'MIGRAR_HISTORICO',
  RECUPERAR_CADASTRO = 'RECUPERAR_CADASTRO',

  // Permissoes para cargos
  ATRIBUIR_CARGOS_NO_PROPRIO_CLUBE = 'ATRIBUIR_CARGOS_NO_PROPRIO_CLUBE',
  ATRIBUIR_CARGOS_EM_CLUBES_DO_PROPRIO_DISTRITO = 'ATRIBUIR_CARGOS_EM_CLUBES_DO_PROPRIO_DISTRITO',
  ATRIBUIR_CARGOS_EM_QUALQUER_CLUBE = 'ATRIBUIR_CARGOS_EM_QUALQUER_CLUBE',
  ATRIBUIR_CARGOS_NO_PROPRIO_DISTRITO = 'ATRIBUIR_CARGOS_NO_PROPRIO_DISTRITO',
  ATRIBUIR_CARGOS_EM_QUALQUER_DISTRITO = 'ATRIBUIR_CARGOS_EM_QUALQUER_DISTRITO',
  ATRIBUIR_CARGOS_NA_ROTARACT_BRASIL = 'ATRIBUIR_CARGOS_NA_ROTARACT_BRASIL',
  ATRIBUIR_CARGOS_EM_CLUBES_DESATIVADOS = 'ATRIBUIR_CARGOS_EM_CLUBES_DESATIVADOS',
  APROVAR_CARGOS_PENDENTES_DA_ROTARACT_BRASIL = 'APROVAR_CARGOS_PENDENTES_DA_ROTARACT_BRASIL',

  // Permissões para transferencias
  SOLICITAR_TRANSFERENCIA_DE_ASSOCIACAO_PARA_O_PROPRIO_CLUBE = 'SOLICITAR_TRANSFERENCIA_DE_ASSOCIACAO_PARA_O_PROPRIO_CLUBE',
  TRANSFERIR_ASSOCIACAO_ENTRE_CLUBES_DO_PROPRIO_DISTRITO = 'TRANSFERIR_ASSOCIACAO_ENTRE_CLUBES_DO_PROPRIO_DISTRITO',
  TRANSFERIR_ASSOCIACAO_PARA_QUALQUER_CLUBE = 'TRANSFERIR_ASSOCIACAO_PARA_QUALQUER_CLUBE',
  APROVAR_TRANSFERENCIA_DE_ASSOCIACAO_NO_PROPRIO_CLUBE = 'APROVAR_TRANSFERENCIA_DE_ASSOCIACAO_NO_PROPRIO_CLUBE',
  APROVAR_TRANSFERENCIA_DE_ASSOCIACAO_EM_CLUBES_DO_PROPRIO_DISTRITO = 'APROVAR_TRANSFERENCIA_DE_ASSOCIACAO_EM_CLUBES_DO_PROPRIO_DISTRITO',
  APROVAR_TRANSFERENCIA_DE_ASSOCIACAO_DE_QUALQUER_CLUBE = 'APROVAR_TRANSFERENCIA_DE_ASSOCIACAO_DE_QUALQUER_CLUBE',

  // Permissoes associados do clube
  EDITAR_ASSOCIADOS_NO_PROPRIO_CLUBE = 'EDITAR_ASSOCIADOS_NO_PROPRIO_CLUBE',
  EDITAR_ASSOCIADOS_EM_CLUBES_DO_PROPRIO_DISTRITO = 'EDITAR_ASSOCIADOS_EM_CLUBES_DO_PROPRIO_DISTRITO',
  EDITAR_ASSOCIADOS_DE_QUALQUER_CLUBE = 'EDITAR_ASSOCIADOS_DE_QUALQUER_CLUBE',
  ENVIAR_CONVITES_PARA_O_PROPRIO_CLUBE = 'ENVIAR_CONVITES_PARA_O_PROPRIO_CLUBE',
  ENVIAR_CONVITES_PARA_CLUBES_DO_PROPRIO_DISTRITO = 'ENVIAR_CONVITES_PARA_CLUBES_DO_PROPRIO_DISTRITO',
  ENVIAR_CONVITES_PARA_QUALQUER_CLUBE = 'ENVIAR_CONVITES_PARA_QUALQUER_CLUBE',
  RELIGAR_ASSOCIADOS_DESLIGADOS_NO_PROPRIO_CLUBE = 'RELIGAR_ASSOCIADOS_DESLIGADOS_NO_PROPRIO_CLUBE',
  RELIGAR_ASSOCIADOS_DESLIGADOS_EM_CLUBES_DO_PROPRIO_DISTRITO = 'RELIGAR_ASSOCIADOS_DESLIGADOS_EM_CLUBES_DO_PROPRIO_DISTRITO',
  RELIGAR_ASSOCIADOS_DESLIGADOS_DE_QUALQUER_CLUBE = 'RELIGAR_ASSOCIADOS_DESLIGADOS_DE_QUALQUER_CLUBE',
  TROCAR_CLUBE_DE_QUALQUER_ASSOCIACAO = 'TROCAR_CLUBE_DE_QUALQUER_ASSOCIACAO',

  // Permissoes dados clube
  EDITAR_DADOS_DO_PROPRIO_CLUBE = 'EDITAR_DADOS_DO_PROPRIO_CLUBE',
  EDITAR_DADOS_DE_CLUBES_DO_PROPRIO_DISTRITO = 'EDITAR_DADOS_DE_CLUBES_DO_PROPRIO_DISTRITO',
  EDITAR_DADOS_DE_QUALQUER_CLUBE = 'EDITAR_DADOS_DE_QUALQUER_CLUBE',

  // Permissoes para distrito
  EDITAR_DADOS_DO_PROPRIO_DISTRITO = 'EDITAR_DADOS_DO_PROPRIO_DISTRITO',
  EDITAR_DADOS_DE_QUALQUER_DISTRITO = 'EDITAR_DADOS_DE_QUALQUER_DISTRITO',
  CADASTRAR_CLUBES_NO_PROPRIO_DISTRITO = 'CADASTRAR_CLUBES_NO_PROPRIO_DISTRITO',
  CADASTRAR_CLUBES_EM_QUALQUER_DISTRITOS = 'CADASTRAR_CLUBES_EM_QUALQUER_DISTRITOS',

  // Permissoes Rotaract Brasil
  CADASTRAR_NOVO_DISTRITO = 'CADASTRAR_NOVO_DISTRITO',

  // Permissoes para Projetos
  REABRIR_PROJETOS = 'REABRIR_PROJETOS',
  EXCLUIR_PROJETOS = 'EXCLUIR_PROJETOS',
  EDITAR_QUALQUER_PROJETO = 'EDITAR_QUALQUER_PROJETO',

  // ============ SOMENTE TI ============
  TI_EXCLUIR_CADASTRO_DE_ASSOCIACAO = 'TI_EXCLUIR_CADASTRO_DE_ASSOCIACAO',
  TI_CADASTRAR_ASSOCIADOS_EM_CLUBES_DESATIVADOS = 'TI_CADASTRAR_ASSOCIADOS_EM_CLUBES_DESATIVADOS',
  TI_VINCULAR_ASSOCIADO_A_QUALQUER_CLUBE = 'TI_VINCULAR_ASSOCIADO_A_QUALQUER_CLUBE',
  TI_VINCULAR_PROJETO_A_UM_CLUBE = 'TI_VINCULAR_PROJETO_A_UM_CLUBE',
}

export const PermissoesSistema: { [permissao in Permissao]: boolean } = {

  // Permissoes sistema
  VISUALIZAR_SISTEMA: true,
  VISUALIZAR_TERMOS_DE_USO: true,
  VISUALIZAR_CARGOS_SISTEMA: true,
  CADASTRAR_E_EDITAR_CARGOS_SISTEMA: true,
  LOCALIZAR_ASSOCIACAO_NO_SISTEMA: true,
  REDEFINIR_FLUXO_LOGIN: true,
  MIGRAR_HISTORICO: true,
  RECUPERAR_CADASTRO: true,

  // Permissoes para cargos
  ATRIBUIR_CARGOS_NO_PROPRIO_CLUBE: true,
  ATRIBUIR_CARGOS_EM_CLUBES_DO_PROPRIO_DISTRITO: true,
  ATRIBUIR_CARGOS_EM_QUALQUER_CLUBE: true,
  ATRIBUIR_CARGOS_NO_PROPRIO_DISTRITO: true,
  ATRIBUIR_CARGOS_EM_QUALQUER_DISTRITO: true,
  ATRIBUIR_CARGOS_NA_ROTARACT_BRASIL: true,
  ATRIBUIR_CARGOS_EM_CLUBES_DESATIVADOS: true,
  APROVAR_CARGOS_PENDENTES_DA_ROTARACT_BRASIL: true,

  // Permissões para transferencias
  SOLICITAR_TRANSFERENCIA_DE_ASSOCIACAO_PARA_O_PROPRIO_CLUBE: true,
  TRANSFERIR_ASSOCIACAO_ENTRE_CLUBES_DO_PROPRIO_DISTRITO: true,
  TRANSFERIR_ASSOCIACAO_PARA_QUALQUER_CLUBE: true,
  APROVAR_TRANSFERENCIA_DE_ASSOCIACAO_NO_PROPRIO_CLUBE: true,
  APROVAR_TRANSFERENCIA_DE_ASSOCIACAO_EM_CLUBES_DO_PROPRIO_DISTRITO: true,
  APROVAR_TRANSFERENCIA_DE_ASSOCIACAO_DE_QUALQUER_CLUBE: true,

  // Permissoes associados do clube
  EDITAR_ASSOCIADOS_NO_PROPRIO_CLUBE: true,
  EDITAR_ASSOCIADOS_EM_CLUBES_DO_PROPRIO_DISTRITO: true,
  EDITAR_ASSOCIADOS_DE_QUALQUER_CLUBE: true,
  ENVIAR_CONVITES_PARA_O_PROPRIO_CLUBE: true,
  ENVIAR_CONVITES_PARA_CLUBES_DO_PROPRIO_DISTRITO: true,
  ENVIAR_CONVITES_PARA_QUALQUER_CLUBE: true,
  RELIGAR_ASSOCIADOS_DESLIGADOS_NO_PROPRIO_CLUBE: true,
  RELIGAR_ASSOCIADOS_DESLIGADOS_EM_CLUBES_DO_PROPRIO_DISTRITO: true,
  RELIGAR_ASSOCIADOS_DESLIGADOS_DE_QUALQUER_CLUBE: true,
  TROCAR_CLUBE_DE_QUALQUER_ASSOCIACAO: true,
  TI_EXCLUIR_CADASTRO_DE_ASSOCIACAO: true,
  TI_CADASTRAR_ASSOCIADOS_EM_CLUBES_DESATIVADOS: true,
  TI_VINCULAR_ASSOCIADO_A_QUALQUER_CLUBE: true,

  // Permissoes dados clube
  EDITAR_DADOS_DO_PROPRIO_CLUBE: true,
  EDITAR_DADOS_DE_CLUBES_DO_PROPRIO_DISTRITO: true,
  EDITAR_DADOS_DE_QUALQUER_CLUBE: true,

  // Permissoes para distrito
  EDITAR_DADOS_DO_PROPRIO_DISTRITO: true,
  EDITAR_DADOS_DE_QUALQUER_DISTRITO: true,
  CADASTRAR_CLUBES_NO_PROPRIO_DISTRITO: true,
  CADASTRAR_CLUBES_EM_QUALQUER_DISTRITOS: true,

  // Permissoes Rotaract Brasil
  CADASTRAR_NOVO_DISTRITO: true,

  // Permissoes para Projetos
  REABRIR_PROJETOS: true,
  EXCLUIR_PROJETOS: true,
  TI_VINCULAR_PROJETO_A_UM_CLUBE: true,
  EDITAR_QUALQUER_PROJETO: true,
}

export interface IPermissaoParaEntidade {
  usuario?: ParseObject;
  clube?: ParseObject;
  distrito?: ParseObject;
}

export interface IExisteAlgumaPermissao {
  permissao: Permissao;
  usuario?: ParseObject;
  clube?: ParseObject;
  distrito?: ParseObject;
}