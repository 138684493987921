
import { Component, Prop, Vue } from "vue-property-decorator";
import IconesRedesSociais from "@/components/IconesRedesSociais.vue";
import Button from "@/components/ui/Button.vue";
import Section from "@/components/ui/Section.vue";
import Divider from "@/components/ui/Divider.vue";
import Loader from "@/components/Loader.vue";
import CardUsuario from "@/components/CardUsuario.vue";
import SecaoVazia from "@/components/SecaoVazia.vue";
import CarregandoInfo from "@/components/CarregandoInfo.vue";
import Error404 from "@/components/Error404.vue";
import TituloPrincipal from "@/components/TituloPrincipal.vue";
import TextoRotulado from "@/components/TextoRotulado.vue";
import NovoCargoAssociado from "@/components/NovoCargoAssociado.vue";
import AvatarUsuario from "@/components/AvatarUsuario.vue";
import { ParseObject } from "@/plugin/parse/parse.interfaces";
import VincularClube from "../vincular-clube/VincularClube.vue";
import SolicitarTransferencia from "../modais/SolicitarTransferencia.vue";
import ReligarAssociadoDesliugado from "../modais/ReligarAssociadoDesliugado.vue";
import ConvidarAssociado from "../modais/ConvidarAssociado.vue";
import Aviso from "@/components/Aviso.vue";
import { Permissao } from "@/plugin/permissoes/permissoes.interfaces";
import { IRetornoBuscarDadosClube } from "@/plugin/parse/cloud.interfaces";
import TabAssociacoes from "./tabs/TabAssociacoes.vue";
import TabDirigentes from "./tabs/TabDirigentes.vue";
import TabDesligamentos from "./tabs/TabDesligamentos.vue";
import TabPresidentes from "./tabs/TabPresidentes.vue";
import TabHonorarios from "./tabs/TabHonorarios.vue";
import TabFundadores from "./tabs/TabFundadores.vue";
import EditarInformacoesClube from "./EditarInformacoesClube.vue";

@Component({
  components: {
    IconesRedesSociais,
    Loader,
    CardUsuario,
    Aviso,
    Button,
    Section,
    Divider,
    SecaoVazia,
    CarregandoInfo,
    Error404,
    TituloPrincipal,
    TextoRotulado,
    NovoCargoAssociado,
    AvatarUsuario,
    VincularClube,
    SolicitarTransferencia,
    ReligarAssociadoDesliugado,
    ConvidarAssociado,
    TabAssociacoes,
    TabDirigentes,
    TabDesligamentos,
    TabPresidentes,
    TabHonorarios,
    TabFundadores,
    EditarInformacoesClube,
  },
})
export default class InformacoesClube extends Vue {
  @Prop({ required: true })
  clube!: ParseObject;

  @Prop({ required: true })
  meuClube!: boolean;

  editando = false;
  carregando = false;
  carregandoMembrosECargos = true;
  podeRemoverHonorario = false;
  removendoHonorario = false;
  salvando = false;

  clubeNaoExiste = false;
  vincularClube = false;

  podeEditarDadosClube = false;
  podeDarBaixaEmUmClube = false;
  podeEnviarConvitesClube = true;
  podeVizualizarPendenciasClube = true;

  formularioClube: any = {};

  camposRedesSociais: any[] = [];
  redesSociais = {};
  periodosReuniao = {
    S: "Semanalmente",
    Q: "Quinzenalmente",
  };
  diasSemana = {
    1: "Domingo",
    2: "Segunda-feira",
    3: "Terça-feira",
    4: "Quarta-feira",
    5: "Quinta-feira",
    6: "Sexta-feira",
    7: "Sábado",
  };
  quinzenas = {
    1: "1ª Quinzena",
    2: "2ª Quinzena",
  };

  clubeStatus: "ativo" | "desativado" = "ativo";

  // Usado para exibir como o nome final fica na hora da edição.
  novoNome = "";

  associados: any[] = [];
  dirigentes: any[] = [];
  presidentes: any[] = [];
  desligados: any[] = [];
  fundadores: any[] = [];
  honorarios: any[] = [];

  acoes = {
    atribuirCargo: false,
    enviarConvite: false,
    transferirAssociado: false,
    religarAssociado: false,
  };

  async created() {
    this.carregaClube();
    this.camposRedesSociais = await this.$utils.getRedesSociais();
  }

  async carregaClube() {
    // Impede que seja chamado se já estiver carregando;
    if (this.carregando) return;
    this.carregando = true;

    this.verificaPermissaoEnviarConvite();
    this.verificaPermissaoEditarDadosClube();
    this.verificaPermissaoAtribuirCargos();
    this.verificaPermissaoReligarAssociado();
    this.verificaPermissaoTransferirAssociados();
    this.verificaPermissaoVisualizarPendencias();

    if (this.clube) {
      this.clubeStatus = this.clube.get("clubeStatus") ? "ativo" : "desativado";

      this.novoNome = this.clube.get("nome");

      this.redesSociais = this.clube.get("redesSociais") || {};
      this.carregaMembrosECargos();
    }

    this.carregando = false;
  }

  async carregaMembrosECargos() {
    this.carregandoMembrosECargos = true;
    let dadosClube: IRetornoBuscarDadosClube = this.$cache.obter(
      `dados-clube-${this.clube.id}`
    );

    const { associados, dirigentes, presidentes } = dadosClube;

    this.associados = associados.ativos || [];
    this.desligados = associados.desligados || [];
    this.honorarios = associados.honorarios || [];
    this.fundadores = associados.fundadores || [];
    this.dirigentes = dirigentes || [];
    this.presidentes = presidentes || [];

    this.carregandoMembrosECargos = false;
  }

  verificaPermissaoEnviarConvite() {
    this.acoes.enviarConvite = this.$permissoes.possuiAlguma([
      {
        permissao: Permissao.EDITAR_ASSOCIADOS_NO_PROPRIO_CLUBE,
        clube: this.clube,
      },
      {
        permissao: Permissao.EDITAR_ASSOCIADOS_EM_CLUBES_DO_PROPRIO_DISTRITO,
        distrito: this.clube?.get("distrito"),
      },
      {
        permissao: Permissao.EDITAR_ASSOCIADOS_DE_QUALQUER_CLUBE,
      },
    ]);
  }

  verificaPermissaoAtribuirCargos() {
    this.acoes.atribuirCargo = this.$permissoes.possuiAlguma([
      {
        permissao: Permissao.ATRIBUIR_CARGOS_NO_PROPRIO_CLUBE,
        clube: this.clube,
      },
      {
        permissao: Permissao.ATRIBUIR_CARGOS_EM_CLUBES_DO_PROPRIO_DISTRITO,
        distrito: this.clube?.get("distrito"),
      },
      {
        permissao: Permissao.ATRIBUIR_CARGOS_EM_QUALQUER_CLUBE,
      },
    ]);
  }

  verificaPermissaoTransferirAssociados() {
    this.acoes.transferirAssociado = this.$permissoes.possuiAlguma([
      {
        permissao:
          Permissao.SOLICITAR_TRANSFERENCIA_DE_ASSOCIACAO_PARA_O_PROPRIO_CLUBE,
        clube: this.clube,
      },
      {
        permissao:
          Permissao.TRANSFERIR_ASSOCIACAO_ENTRE_CLUBES_DO_PROPRIO_DISTRITO,
        distrito: this.clube?.get("distrito"),
      },
      {
        permissao: Permissao.TRANSFERIR_ASSOCIACAO_PARA_QUALQUER_CLUBE,
      },
    ]);
  }

  verificaPermissaoReligarAssociado() {
    this.acoes.religarAssociado = this.$permissoes.possuiAlguma([
      {
        permissao: Permissao.RELIGAR_ASSOCIADOS_DESLIGADOS_NO_PROPRIO_CLUBE,
        clube: this.clube,
      },
      {
        permissao:
          Permissao.RELIGAR_ASSOCIADOS_DESLIGADOS_EM_CLUBES_DO_PROPRIO_DISTRITO,
        distrito: this.clube?.get("distrito"),
      },
      {
        permissao: Permissao.RELIGAR_ASSOCIADOS_DESLIGADOS_DE_QUALQUER_CLUBE,
      },
    ]);
  }

  verificaPermissaoEditarDadosClube() {
    const podeEditarDadosDoProprioClube = this.$permissoes.possui(
      Permissao.EDITAR_DADOS_DO_PROPRIO_CLUBE,
      { clube: this.clube }
    );

    const podeEditarDados = this.$permissoes.possuiAlguma([
      {
        permissao: Permissao.EDITAR_DADOS_DE_CLUBES_DO_PROPRIO_DISTRITO,
        distrito: this.clube?.get("distrito"),
      },
      { permissao: Permissao.EDITAR_DADOS_DE_QUALQUER_CLUBE },
    ]);

    this.podeEditarDadosClube =
      podeEditarDadosDoProprioClube || podeEditarDados;

    this.podeDarBaixaEmUmClube = podeEditarDados;

    this.podeRemoverHonorario = this.$permissoes.possui(
      Permissao.EDITAR_ASSOCIADOS_NO_PROPRIO_CLUBE,
      { clube: this.clube }
    );
  }

  verificaPermissaoVisualizarPendencias() {
    const podeAprovarCargos = this.$permissoes.possuiAlguma([
      {
        permissao: Permissao.ATRIBUIR_CARGOS_NO_PROPRIO_CLUBE,
        clube: this.clube,
      },
      {
        permissao: Permissao.ATRIBUIR_CARGOS_EM_CLUBES_DO_PROPRIO_DISTRITO,
        distrito: this.clube.get("distrito"),
      },
      {
        permissao: Permissao.ATRIBUIR_CARGOS_EM_QUALQUER_CLUBE,
      },
    ]);

    const podeAprovarAlteracoesDeAssociacoes = this.$permissoes.possuiAlguma([
      {
        permissao: Permissao.EDITAR_ASSOCIADOS_NO_PROPRIO_CLUBE,
        clube: this.clube,
      },
      {
        permissao: Permissao.EDITAR_ASSOCIADOS_EM_CLUBES_DO_PROPRIO_DISTRITO,
        distrito: this.clube.get("distrito"),
      },
      {
        permissao: Permissao.EDITAR_ASSOCIADOS_DE_QUALQUER_CLUBE,
      },
    ]);

    this.podeVizualizarPendenciasClube =
      podeAprovarCargos ||
      podeAprovarAlteracoesDeAssociacoes ||
      this.acoes.transferirAssociado;
  }

  possuiAcoes() {
    return this.clube && Object.values(this.acoes).some((existe) => existe);
  }

  inserirNovoCargo(cargo: ParseObject) {
    const anoRotario = this.$utils.getAnoRotario();

    const membroDaComissaoAtualDoClube = cargo.get("anoRotario") === anoRotario;

    if (membroDaComissaoAtualDoClube) {
      this.dirigentes.push(cargo);
      this.dirigentes.sort(
        (a: ParseObject, b: ParseObject) =>
          b.get("cargo").get("ordemExibicao") -
          a.get("cargo").get("ordemExibicao")
      );

      this.$cache.atualizar(
        `dados-clube-${this.clube.id}.dirigentes`,
        this.dirigentes
      );
    }

    const parteDaGaleriaDePresidentes =
      cargo.get("cargo").get("sigla") === "presidente";

    if (parteDaGaleriaDePresidentes) {
      this.presidentes.push(cargo);
      this.presidentes.sort(
        (a: ParseObject, b: ParseObject) =>
          b.get("anoRotario") - a.get("anoRotario")
      );

      this.$cache.atualizar(
        `dados-clube-${this.clube.id}.presidentes`,
        this.presidentes
      );
    }
  }

  inserirNovaAssociacao(associacao: ParseObject) {
    this.associados.unshift(associacao);
  }
}
