
import { Component, Vue } from "vue-property-decorator";
import Loader from "@/components/Loader.vue";
import CarregandoInfo from "@/components/CarregandoInfo.vue";
import TituloPrincipal from "@/components/TituloPrincipal.vue";
import Error404 from "@/components/Error404.vue";
import Section from "@/components/ui/Section.vue";
import Divider from "@/components/ui/Divider.vue";
import Button from "@/components/ui/Button.vue";
import { Permissao } from "@/plugin/permissoes/permissoes.interfaces";

@Component({
  components: {
    Loader,
    CarregandoInfo,
    TituloPrincipal,
    Section,
    Divider,
    Button,
    Error404,
  },
})
export default class CRUDCargosSistema extends Vue {
  cargo: any = this.$parse.newObject("Cargo");

  carregando = false;
  editando = false;
  salvando = false;
  possuiPermissao = false;

  permissoesCargo: any = {};

  retorno = [
    {
      url: "/sistema",
      titulo: "Painel do Sistema",
    },
    {
      url: "/sistema/cargos",
      titulo: "Cargos do Sistema",
    },
  ];

  instanciaCargo: "clube" | "distrito" | "rotaractbr" = "clube";
  async created() {
    this.possuiPermissao = this.$permissoes.possui(
      Permissao.CADASTRAR_E_EDITAR_CARGOS_SISTEMA
    );

    const idCargo = this.$route.params.id_cargo;
    if (idCargo) await this.getCargo(idCargo);
    else this.editando = true;
    this.filtraPermissoes();
  }

  async getCargo(id: string) {
    this.carregando = true;
    this.cargo = await this.$parse
      .getById("Cargo", id)
      .catch((e) => this.$notificacao.mensagemErro(e));

    if (!this.cargo) this.$router.push("sistema-cargos");

    if (this.cargo.get("distrito")) this.instanciaCargo = "distrito";
    if (this.cargo.get("rotaractbr")) this.instanciaCargo = "rotaractbr";

    this.carregando = false;
  }

  filtraPermissoes() {
    const _permissoesCargo: any = this.cargo.get("permissoes") || {};

    const todasPermissoesSistema = this.$permissoes.getPermissoesSistema();
    let permissoesSistema: Permissao[] = [];
    this.permissoesCargo = {};

    if (this.instanciaCargo == "clube") {
      permissoesSistema = todasPermissoesSistema.filter((permissao) =>
        permissao.endsWith("PROPRIO_CLUBE")
      );
    }

    if (this.instanciaCargo == "distrito") {
      permissoesSistema = todasPermissoesSistema.filter((permissao) =>
        permissao.endsWith("PROPRIO_DISTRITO")
      );
    }

    if (this.instanciaCargo == "rotaractbr") {
      permissoesSistema = todasPermissoesSistema.filter(
        (permissao) =>
          !permissao.endsWith("PROPRIO_CLUBE") &&
          !permissao.endsWith("PROPRIO_DISTRITO")
      );
    }

    // Remove as permissões exclusivas para TI caso n seja o cargo de presidente ou assessor da comissão
    if (!this.cargo.get("sigla")?.endsWith("TI")) {
      permissoesSistema = permissoesSistema.filter(
        (permissao) => !permissao.startsWith("TI")
      );
    }

    permissoesSistema.sort()

    permissoesSistema.forEach((permissao) => {
      this.permissoesCargo[permissao] = _permissoesCargo[permissao] || false;
    });
  }

  async salvarCargo(dadosCargo: any) {
    if (this.salvando) return;
    this.salvando = true;

    const { ordemExibicao, instanciaCargo, nome, ativo } = dadosCargo;

    const permissoes: any = {};

    /**
     * Salva somente as permissões que estão marcadas como true
     */
    Object.keys(this.permissoesCargo).forEach((permissao) => {
      if (this.permissoesCargo[permissao]) permissoes[permissao] = true;
    });

    const dadosAtualizados: any = {
      permissoes,
      ordemExibicao: Number(ordemExibicao),
      clube: instanciaCargo === "clube",
      distrito: instanciaCargo === "distrito",
      rotaractbr: instanciaCargo === "rotaractbr",
    };

    if (nome !== undefined) dadosAtualizados.nome = nome;
    if (ativo !== undefined) dadosAtualizados.ativo = ativo;

    const sucesso = await this.$parse
      .updateObject(this.cargo, dadosAtualizados)
      .catch((e) => this.$notificacao.mensagemErro(e));

    if (!sucesso) {
      this.salvando = false;
      return;
    }

    await this.$permissoes.carregaPermissoesUsuario();
    this.salvando = false;
    this.editando = false;
  }
}
