
import { ParseObject } from "@/plugin/parse/parse.interfaces";
import { Component, Prop, Vue } from "vue-property-decorator";
import IconesRedesSociais from "@/components/IconesRedesSociais.vue";
import AvatarUsuario from "@/components/AvatarUsuario.vue";
import Section from "@/components/ui/Section.vue";
import Button from "@/components/ui/Button.vue";
import Loader from "@/components/Loader.vue";
import TextoRotulado from "@/components/TextoRotulado.vue";
import { Permissao } from "@/plugin/permissoes/permissoes.interfaces";

@Component({
  components: {
    IconesRedesSociais,
    AvatarUsuario,
    Section,
    Button,
    Loader,
    TextoRotulado,
  },
})
export default class PerfilInfosGerais extends Vue {
  @Prop({ default: null })
  dadosUsuario!: ParseObject;

  @Prop({ default: false })
  saoMeusDados!: boolean;

  @Prop({ default: null })
  clube!: ParseObject;

  @Prop({ default: null })
  distrito!: ParseObject;

  @Prop({ default: null })
  recuperacao!: ParseObject;

  @Prop({ default: false })
  termosDeUsoAceitos!: boolean;

  podeExcluirCadastro = false;
  podeRecuperarCadastro = false;
  podeEditarInformacoes = false;

  salvandoDadosRecuperacao = false;
  cancelar = false;

  historicoRotario = {
    duploAssociado: "Dupla Associacao",
    interact: "Interact",
    ryep: "Intercâmbio de Juventude (RYEP)",
    ngse: "Intercâmbio de Serviços às novas Gerações (NGSE)",
    ryla: "Prêmio Rotário de Liderança Jovem (RYLA)",
    aperfeicoamento:
      "Programa de Aperfeiçoamento Profissional (Rotary Bolsas Pela Paz)",
    mestrado: "Programa de Mestrado (Rotary Bolsas Pela Paz)",
  };

  created() {
    this.verificaPermissaoEditarAssociado();
  }

  async verificaPermissaoEditarAssociado() {
    const podeEditarAsPropriasInfos = this.saoMeusDados;

    this.podeExcluirCadastro = this.$permissoes.possui(
      Permissao.TI_EXCLUIR_CADASTRO_DE_ASSOCIACAO
    );

    const { recuperacao } = this.$cache.obter(
      `dados-usuario-${this.dadosUsuario.id}`
    );

    this.podeRecuperarCadastro =
      this.$permissoes.possui(Permissao.RECUPERAR_CADASTRO) && recuperacao;

    const podeEditarInfosGerais = this.$permissoes.possuiAlguma([
      {
        permissao: Permissao.EDITAR_ASSOCIADOS_NO_PROPRIO_CLUBE,
        clube: this.clube,
      },
      {
        permissao: Permissao.EDITAR_ASSOCIADOS_EM_CLUBES_DO_PROPRIO_DISTRITO,
        distrito: this.distrito,
      },
      {
        permissao: Permissao.EDITAR_ASSOCIADOS_DE_QUALQUER_CLUBE,
      },
    ]);

    this.podeEditarInformacoes =
      podeEditarInfosGerais || podeEditarAsPropriasInfos;
  }

  async excluirCadastro() {
    const idUsuario = this.dadosUsuario.id;
    const sucesso = await this.$notificacao
      .confirmarEEsperar({
        texto: "Você tem certeza que deseja excluir esse cadastro?",
        confirmou: () => this.$cloud.excluirCadastro(idUsuario),
      })
      .catch((e) => {
        this.$notificacao.erro({ texto: e.message });
      });

    if (!sucesso) return;

    this.dadosUsuario.set("status", false);

    this.$router.push("/");
    location.reload();
  }

  async recuperarCadastro() {
    const idUsuario = this.dadosUsuario.id;

    if (!this.podeRecuperarCadastro) return;

    if (!this.recuperacao)
      return this.$notificacao.erro({
        texto: "Erro ao carregar informações para recuperação",
      });

    const ultimoEmailEnviadoEm = this.recuperacao.get("ultimoEmailEnviadoEm");

    const agora = new Date();
    const diferenca = this.$utils.difEntreDatas(agora, ultimoEmailEnviadoEm);

    if (diferenca < 60)
      return this.$notificacao.erro({
        texto:
          "Ultimo e-mail enviado há menos de uma hora, aguarde para enviar novamente.",
      });

    const sucesso = await this.$notificacao
      .confirmarEEsperar({
        texto: "Você deseja enviar o e-mail de recuperação desse cadastro?",
        confirmou: () =>
          this.$cloud
            .enviarEmailRecuperacaoUsuario(idUsuario)
            .catch(console.log),
      })
      .catch((e) => {
        this.$notificacao.erro({
          texto:
            "Erro ao enviar e-mail, verifique as informações de recuperação no painel do sistema",
        });
        console.log(e);
      });

    if (!sucesso) return;

    // Apenas para forçar o vue a atualizar localmente
    this.recuperacao.set("ultimoEmailEnviadoEm", new Date());

    this.$cache.atualizar(
      `dados-usuario-${idUsuario}.recuperacao`,
      this.recuperacao
    );

    this.$notificacao.sucesso({
      texto: "E-mail de recuperação enviado com sucesso!",
    });
  }

  async salvarDadosRecuperacao(dados: any) {
    if (!dados || !this.recuperacao || this.cancelar) return;

    if (!dados.nome)
      this.$notificacao.erro({
        texto: "Nome é obrigatório",
      });

    if (!dados.email)
      this.$notificacao.erro({
        texto: "E-mail é obrigatório",
      });

    this.salvandoDadosRecuperacao = true;

    const sucesso = await this.$parse
      .updateObject(this.recuperacao, dados)
      .catch((e) => {
        this.$notificacao.erro({
          texto: `Erro ao salvar dados de recuperação: ${e.message}`,
        });
      });

    this.salvandoDadosRecuperacao = false;

    if (!sucesso) return;

    this.$bvModal.hide("modal-dados-de-recuperacao");
  }

  editar() {
    this.$emit("editar");
  }
}
