
import { Component, Vue } from "vue-property-decorator";
@Component({
  components: {},
})
export default class AceitarTermos extends Vue {
  email = this.$store.state.email;

  aceitouTermos = false;
  termoDeUso: any = null;

  carregando = false;
  salvandoTermos = false;
  emailEnviado = false;

  async created() {
    this.carregando = true;
    this.termoDeUso = await this.$parse
      .getFirst({ className: "TermoDeUso" })
      .catch((e) => this.$notificacao.mensagemErro(e));
    this.carregando = false;
  }
  async aceitarTermosDeUso() {
    if (!this.aceitouTermos || !this.termoDeUso) return;

    const aceitouTermosDeUso = {
      termosDeUsoAceitosEm: new Date(),
      termosDeUsoAceitos: true,
      ultimoTermoAceito: this.termoDeUso,
    };

    const meusDados = await this.$utils.getMeusDados();

    if (!meusDados) return;

    this.salvandoTermos = true;

    const sucesso = await this.$parse
      .updateObject(meusDados, aceitouTermosDeUso)
      .catch((e) => this.$notificacao.mensagemErro(e));

    if (!sucesso) {
      this.salvandoTermos = false;
      await this.$parse.logOut();
      this.$emit(
        "erro",
        "Ocorreu um erro ao aceitar os termos de uso, tente novamente mais tarde"
      );
      return;
    }

    this.$emit("termoAceito");
  }

  async retornar() {
    await this.$parse.logOut();
    this.$emit("retorno");
  }
}
