
import { Component, Vue, Watch } from "vue-property-decorator";
import IconesRedesSociais from "@/components/IconesRedesSociais.vue";
import TituloPrincipal from "@/components/TituloPrincipal.vue";
import AvatarUsuario from "@/components/AvatarUsuario.vue";
import Error404 from "@/components/Error404.vue";
import Aviso from "@/components/Aviso.vue";
import SecaoVazia from "@/components/SecaoVazia.vue";
import Section from "@/components/ui/Section.vue";
import Button from "@/components/ui/Button.vue";
import CarregandoInfo from "@/components/CarregandoInfo.vue";
import TextoRotulado from "@/components/TextoRotulado.vue";
import { ParseObject } from "@/plugin/parse/parse.interfaces";
import NovoCargoAssociado from "@/components/NovoCargoAssociado.vue";
import EditarDadosUsuario from "@/views/perfil/secoes/EditarDadosUsuario.vue";

import { IRetornoBuscarDadosUsuario } from "@/plugin/parse/cloud.interfaces";
import PerfilInfosGerais from "./secoes/PerfilInfosGerais.vue";
import PerfilAssociacoes from "./secoes/associacoes/PerfilAssociacoes.vue";
import PerfilCargos from "./secoes/PerfilCargos.vue";

@Component({
  components: {
    IconesRedesSociais,
    AvatarUsuario,
    TituloPrincipal,
    Section,
    SecaoVazia,
    Button,
    CarregandoInfo,
    NovoCargoAssociado,
    Error404,
    Aviso,
    TextoRotulado,
    EditarDadosUsuario,
    PerfilInfosGerais,
    PerfilAssociacoes,
    PerfilCargos,
  },
})
export default class Perfil extends Vue {
  idUsuario = "";
  editando = false;
  carregando = true;
  salvando = false;
  perfilExiste = true;
  buscandoDados = false;
  saoMeusDados = false;

  removendoAssociacao = false;
  associacaoParaRemover: any = null;

  dadosUsuario: any = null;
  associacaoAtual: any = null;
  associacaoParaEditar: any = null;
  transferenciaParaAprovar: any = null;
  recuperacao: any = null;
  atualizandoAssociacao = false;
  associacoes: any[] = [];
  clube: any = null;
  distrito: any = null;
  clubeVinculado: any = null;
  cargos: any;

  termosDeUsoAceitos = false;

  created() {
    this.getUsuario();
  }

  @Watch("$route", { immediate: true, deep: true })
  async getUsuario() {
    this.carregando = true;
    this.editando = false;
    this.idUsuario = this.$route.params.id_usuario;
    const meusDados = await this.$utils.getMeusDados();

    if (this.$route.name === "meusdados" && meusDados) {
      this.idUsuario = meusDados?.id;
    }

    if (this.idUsuario) await this.buscaDadosUsuario();

    if (!this.dadosUsuario) {
      this.perfilExiste = false;
      this.carregando = false;
      return;
    }

    this.saoMeusDados = this.dadosUsuario.id === meusDados?.id;

    this.termosDeUsoAceitos = this.$permissoes.verificaSeAceitouTermos(
      this.dadosUsuario
    );

    this.carregando = false;
  }

  async buscaDadosUsuario() {
    this.buscandoDados = true;

    let todosDadosUsuario: IRetornoBuscarDadosUsuario = this.$cache.obter(
      `dados-usuario-${this.idUsuario}`
    );

    if (!todosDadosUsuario) {
      const _todosDadosUsuario = await this.$cloud
        .buscarDadosUsuario(this.idUsuario)
        .catch(console.log);

      if (!_todosDadosUsuario) {
        this.perfilExiste = false;
        this.carregando = false;
        this.buscandoDados = false;
        return;
      }
      this.$cache.salvar(`dados-usuario-${this.idUsuario}`, _todosDadosUsuario);
      todosDadosUsuario = _todosDadosUsuario;
    }

    const {
      dadosUsuario,
      associacoes,
      cargos,
      associacaoAtual,
      clubeAtual,
      distritoAtual,
      recuperacao
    } = todosDadosUsuario;

    await dadosUsuario.fetch();

    if (dadosUsuario.get("status") == false) {
      this.perfilExiste = false;
      this.carregando = false;
      this.buscandoDados = false;
      return;
    }

    this.dadosUsuario = dadosUsuario;
    this.associacoes = associacoes;
    this.cargos = cargos;
    this.associacaoAtual = associacaoAtual;
    this.clube = clubeAtual;
    this.distrito = distritoAtual;
    this.recuperacao = recuperacao;
    this.buscandoDados = false;
  }

  atualizaUsuario(novoUsuario: ParseObject) {
    this.dadosUsuario = novoUsuario;
    this.editando = false;
    this.$router.push({
      name: "perfil",
      params: { id_usuario: novoUsuario.id },
    });
  }
}
