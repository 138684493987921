import { render, staticRenderFns } from "./CRUDProjeto.vue?vue&type=template&id=3eb672b3&scoped=true"
import script from "./CRUDProjeto.vue?vue&type=script&lang=ts"
export * from "./CRUDProjeto.vue?vue&type=script&lang=ts"
import style0 from "./CRUDProjeto.vue?vue&type=style&index=0&id=3eb672b3&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3eb672b3",
  null
  
)

export default component.exports