
import CardUsuario from "@/components/CardUsuario.vue";
import Section from "@/components/ui/Section.vue";
import UsuarioFotoNome from "@/components/UsuarioFotoNome.vue";
import { ParseObject } from "@/plugin/parse/parse.interfaces";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {
    CardUsuario,
    UsuarioFotoNome,
    Section,
  },
})
export default class CargosPendentesDistrito extends Vue {
  @Prop({ required: true })
  distrito!: ParseObject;

  cargosPendentes!: ParseObject[];
  idCargoSendoAtualizado = "";
  salvando = false;
  colunas = [
    { key: "associado", label: "Associado(a)", thStyle: "width: 20%" },
    { key: "cargo", label: "Cargo", thStyle: "width: 35%" },
    { key: "anoRotario", label: "Ano Rotário", thStyle: "width: 15%" },
    { key: "acao", label: "Ação", thStyle: "width: 10%" },
  ];

  created() {
    const cargos: ParseObject[] = this.$cache.obter(
      `dados-distrito-${this.distrito.id}.cargosPendentes`
    );

    this.cargosPendentes = cargos || [];

    if (this.cargosPendentes.length) this.$emit("temPendencias");
  }
  async aprovarCargo(cargo: any) {
    const confirmado = await this.$notificacao.confirmar({
      titulo: "Aprovar",
      texto: "Você deseja aprovar este cargo?",
      textoNao: "Cancelar"
    });

    if (!confirmado) return;

    this.salvando = true;
    this.idCargoSendoAtualizado = cargo.id;

    const success = await this.$parse
      .updateObject(cargo, { pendente: false })
      .catch((e) => this.$notificacao.mensagemErro(e));

    if (!success) {
      this.idCargoSendoAtualizado = "";
      this.salvando = false;
      return;
    }

    this.inserirNovoCargo(cargo);
    this.removeCargoPendente();
    this.$emit("cargoAprovado", cargo);
  }

  async recusarCargo(cargo: any) {
    const confirmado = await this.$notificacao.confirmar({
      titulo: "Recusar",
      texto: "Tem certeza que deseja recusar este cargo?",
      textoNao: "Cancelar"
    });

    if (!confirmado) return;

    this.idCargoSendoAtualizado = cargo.id;

    const success = await this.$parse
      .updateObject(cargo, { pendente: false, recusado: true })
      .catch((e) => this.$notificacao.mensagemErro(e));

    if (!success) {
      this.idCargoSendoAtualizado = "";
      this.salvando = false;
      return;
    }

    this.removeCargoPendente();
  }

  inserirNovoCargo(cargo: ParseObject) {
    const anoRotario = this.$utils.getAnoRotario();

    const membroDaComissaoAtualDoClube = cargo.get("anoRotario") === anoRotario;

    if (membroDaComissaoAtualDoClube) {
      const equipeDistrital = this.$cache.obter(
        `dados-distrito-${this.distrito.id}.equipeDistrital`
      );

      equipeDistrital.push(cargo);
      equipeDistrital.sort(
        (a: ParseObject, b: ParseObject) =>
          b.get("cargo").get("ordemExibicao") -
          a.get("cargo").get("ordemExibicao")
      );

      this.$cache.atualizar(
        `dados-distrito-${this.distrito.id}.equipeDistrital`,
        equipeDistrital
      );
    }

    const parteDaGaleriaRDRs = cargo.get("cargo").get("sigla") === "rdr";

    if (parteDaGaleriaRDRs) {
      const galeriaRDRs = this.$cache.obter(
        `dados-distrito-${this.distrito.id}.galeriaRDRs`
      );

      galeriaRDRs.push(cargo);
      galeriaRDRs.sort(
        (a: ParseObject, b: ParseObject) =>
          b.get("anoRotario") - a.get("anoRotario")
      );

      this.$cache.atualizar(
        `dados-distrito-${this.distrito.id}.galeriaRDRs`,
        galeriaRDRs
      );
    }
  }

  removeCargoPendente() {
    this.salvando = true;

    this.cargosPendentes = this.cargosPendentes.filter(
      (_cargo: ParseObject) => _cargo.id !== this.idCargoSendoAtualizado
    );

    this.$cache.atualizar(
      `dados-distrito-${this.distrito.id}.cargosPendentes`,
      this.cargosPendentes
    );

    this.idCargoSendoAtualizado = "";
    this.salvando = false;
  }
}
