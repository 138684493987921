import Vue from 'vue'
import VueRouter from 'vue-router'

import Parse from 'parse';

import Inicio from '../views/Inicio.vue'

import Perfil from '../views/perfil/Perfil.vue'

import Clube from '../views/clube/Clube.vue'

import Distrito from '../views/distrito/Distrito.vue'

import CRUDProjeto from '../views/projetos/CRUDProjeto.vue'
import VerProjeto from '../views/projetos/VerProjeto.vue'
import ProjetosClube from '../views/projetos/ProjetosClube.vue'
import ProjetosDistrito from '../views/projetos/ProjetosDistrito.vue'
import Anp from '../views/projetos/Anp.vue'
import Anpd from '../views/projetos/Anpd.vue'
import Login from '../views/login/Login.vue'
import PainelSistema from '../views/sistema/PainelSistema.vue'
import Omir from '../views/rotaractbr/Omir.vue'
import Distritos from '../views/rotaractbr/Distritos.vue'
import RDRs from '../views/rotaractbr/RDRs.vue'
import LocalizarClube from '../views/rotaractbr/LocalizarClube.vue'
import CargosSistema from '../views/sistema/cargos-sistema/CargosSistema.vue'
import CRUDCargosSistema from '../views/sistema/cargos-sistema/CRUDCargosSistema.vue'
import TermosDeUso from '../views/sistema/termos-de-uso/TermosDeUso.vue'
import CargosPendentesSistema from '../views/sistema/pendencias/cargos/CargosPendentesSistema.vue'
import TransferenciasPendentesSistema from '../views/sistema/pendencias/transferencias/TransferenciasPendentesSistema.vue'
import AlteracoesAssociacaoPendentesSistema from '../views/sistema/pendencias/alteracoes-associacao/AlteracoesAssociacaoPendentesSistema.vue'
import VincularAssociacao from '../views/sistema/vincular-associacao/VincularAssociacao.vue'
import LocalizarAssociacao from '../views/sistema/localizar-associacao/LocalizarAssociacao.vue'
import ReiniciarLogin from '../views/sistema/reiniciar-login/ReiniciarLogin.vue'
import MigrarHistorico from '../views/sistema/migrar-historico/MigrarHistorico.vue'
import ProjetosParaExcluirSistema from '../views/sistema/projetos-para-excluir/ProjetosParaExcluirSistema.vue'

const titulo = 'Portal Rotaract Brasil'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'root',
    redirect: '/perfil',
    meta: {
      title: titulo,
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      requiresAuth: false,
      title: `Login - ${titulo}`
    }
  },
  {
    path: '/r/:token_recuperacao',
    name: 'recuperar-dados',
    component: Login,
    meta: {
      requiresAuth: false,
      title: `Recuperar Dados - ${titulo}`
    }
  },
  {
    path: '/convite/:id_convite',
    name: 'cadastrar-via-convite',
    component: Login,
    meta: {
      requiresAuth: false,
      title: `Aceitar Convite - ${titulo}`
    }
  },
  {
    path: '/perfil',
    redirect: 'meusdados',
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/meusdados',
    name: 'meusdados',
    component: Perfil,
    meta: {
      requiresAuth: true,
      title: `Meus Dados - ${titulo}`
    }
  },
  {
    path: '/perfil/:id_usuario',
    name: 'perfil',
    component: Perfil,
    meta: {
      requiresAuth: true,
      title: `Perfil - ${titulo}`
    }
  },
  {
    path: '/clube',
    name: 'clube',
    redirect: 'meuclube',
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/meuclube',
    name: 'meuclube-info',
    component: Clube,
    meta: {
      requiresAuth: true,
      title: `Meu Clube - ${titulo}`
    }
  },
  {
    path: '/meuclube/pendencias',
    name: 'meuclube-pendencias',
    component: Clube,
    meta: {
      requiresAuth: true,
      title: `Pendencias - Meu Clube - ${titulo}`
    }
  },
  {
    path: '/meuclube/convites',
    name: 'meuclube-convites',
    component: Clube,
    meta: {
      requiresAuth: true,
      title: `Convites - Meu Clube - ${titulo}`
    }
  },
  {
    path: '/clube/:id_clube',
    name: 'clube-info',
    component: Clube,
    meta: {
      requiresAuth: true,
      title: `Informações do Clube - ${titulo}`
    }
  },
  {
    path: '/clube/:id_clube/pendencias',
    name: 'clube-pendencias',
    component: Clube,
    meta: {
      requiresAuth: true,
      title: `Pendencias - Clube - ${titulo}`
    }
  },
  {
    path: '/clube/:id_clube/convites',
    name: 'clube-convites',
    component: Clube,
    meta: {
      requiresAuth: true,
      title: `Convites - Clube - ${titulo}`
    }
  },
  {
    path: '/clube/:id_clube/projetos',
    name: 'clube-projetos',
    component: ProjetosClube,
    meta: {
      requiresAuth: true,
      title: `Projetos do Clube - ${titulo}`
    }
  },
  {
    path: '/distrito',
    name: 'distrito',
    redirect: 'meudistrito',
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/meudistrito',
    name: 'meudistrito-info',
    component: Distrito,
    meta: {
      requiresAuth: true,
      title: `Meu Distrito - ${titulo}`
    }
  },
  {
    path: '/meudistrito/clubes',
    name: 'meudistrito-clubes',
    component: Distrito,
    meta: {
      requiresAuth: true,
      title: `Clubes do Meu Distrito - ${titulo}`
    }
  },
  {
    path: '/meudistrito/pendencias',
    name: 'meudistrito-pendencias',
    component: Distrito,
    meta: {
      requiresAuth: true,
      title: `Pendências - Meu Distrito - ${titulo}`
    }
  },
  {
    path: '/distrito/:id_distrito',
    name: 'distrito-info',
    component: Distrito,
    meta: {
      requiresAuth: true,
      title: `Informações do Distrito - ${titulo}`
    }
  },
  {
    path: '/distrito/:id_distrito/pendencias',
    name: 'distrito-pendencias',
    component: Distrito,
    meta: {
      requiresAuth: true,
      title: `Pendências do Distrito - ${titulo}`
    }
  },
  {
    path: '/distrito/:id_distrito/clubes',
    name: 'distrito-clubes',
    component: Distrito,
    meta: {
      requiresAuth: true,
      title: `Clubes do Distrito - ${titulo}`
    }
  },
  {
    path: '/projetos/novo',
    name: 'projetos-novo',
    component: CRUDProjeto,
    meta: {
      requiresAuth: true,
      title: `Novo Projeto - ${titulo}`
    }
  },
  {
    path: '/projetos',
    name: 'projetos',
    redirect: '/projetos/clube',
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/projetos/clube',
    name: 'projetos-clube',
    component: ProjetosClube,
    meta: {
      requiresAuth: true,
      title: `Projetos - Meu Clube - ${titulo}`
    }
  },
  {
    path: '/projetos/distrito',
    name: 'projetos-distrito',
    component: ProjetosDistrito,
    meta: {
      requiresAuth: true,
      title: `Projetos - Meu Distrito - ${titulo}`
    }
  },
  {
    path: '/projetos/Anp',
    name: 'projetos-anp',
    component: Anp,
    meta: {
      requiresAuth: true,
      title: `Arquivo Nacional de Projetos - ${titulo}`
    }
  },
  {
    path: '/projetos/Anpd',
    name: 'projetos-anpd',
    component: Anpd,
    meta: {
      requiresAuth: true,
      title: `Arquivo Nacional de Projetos Distritais - ${titulo}`
    }
  },
  {
    path: '/projeto/:id_projeto',
    name: 'visualizar-projeto',
    component: VerProjeto,
    meta: {
      requiresAuth: true,
      title: `Visualizar Projeto - ${titulo}`
    }
  },
  {
    path: '/rotaractbr',
    name: 'rotaract-brasil',
    redirect: '/rotaractbr/omir',
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/rotaractbr/distritos',
    name: 'rotaract-brasil-distritos',
    component: Distritos,
    meta: {
      requiresAuth: true,
      title: `Distritos Brasileiros - ${titulo}`
    }
  },
  {
    path: '/rotaractbr/omir',
    name: 'rotaract-brasil-omir',
    component: Omir,
    meta: {
      requiresAuth: true,
      title: `Omir Brasil - ${titulo}`
    }
  },
  {
    path: '/rotaractbr/rdrs',
    name: 'rotaract-brasil-rdrs',
    component: RDRs,
    meta: {
      requiresAuth: true,
      title: `Omir Brasil - ${titulo}`
    }
  },
  {
    path: '/rotaractbr/omir/:ano_rotario',
    name: 'rotaract-brasil-omir',
    component: Omir,
    meta: {
      requiresAuth: true,
      title: `Omir Brasil - ${titulo}`
    }
  },
  {
    path: '/rotaractbr/rdrs/:ano_rotario',
    name: 'rotaract-brasil-rdrs',
    component: RDRs,
    meta: {
      requiresAuth: true,
      title: `Omir Brasil - ${titulo}`
    }
  },
  {
    path: '/rotaractbr/localizar-clube',
    name: 'rotaract-brasil-localizar-clube',
    component: LocalizarClube,
    meta: {
      requiresAuth: true,
      title: `Localizar Clube - ${titulo}`
    }
  },
  {
    path: '/sistema',
    name: 'sistema',
    component: PainelSistema,
    meta: {
      requiresAuth: true,
      title: `Sistema - ${titulo}`
    }
  },
  {
    path: '/sistema/cargos',
    name: 'sistema-cargos',
    component: CargosSistema,
    meta: {
      requiresAuth: true,
      title: `Cargos do Sistema - ${titulo}`
    }
  },
  {
    path: '/sistema/cargo/novo',
    name: 'sistema-novo-cargo',
    component: CRUDCargosSistema,
    meta: {
      requiresAuth: true,
      title: `Novo Cargo do Sistema - ${titulo}`
    },
  },
  {
    path: '/sistema/cargo/:id_cargo',
    name: 'sistema-visualizar-cargo',
    component: CRUDCargosSistema,
    meta: {
      requiresAuth: true,
      title: `Cargo do Sistema - ${titulo}`
    }
  },
  {
    path: '/sistema/termos/',
    name: 'sistema-termos',
    component: TermosDeUso,
    meta: {
      requiresAuth: true,
      title: `Termos de Uso - ${titulo}`
    }
  },
  {
    path: '/sistema/pendencias/cargos/',
    name: 'sistema-cargos-pendentes',
    component: CargosPendentesSistema,
    meta: {
      requiresAuth: true,
      title: `Cargos Rotaract Brasil - ${titulo}`
    },
  },
  {
    path: '/sistema/pendencias/transferencias/',
    name: 'sistema-pendencias-transferencias',
    component: TransferenciasPendentesSistema,
    meta: {
      requiresAuth: true,
      title: `Sistema - Transferências Pendentes - ${titulo}`
    }
  },
  {
    path: '/sistema/pendencias/associacoes/',
    name: 'sistema-pendencias-associacoes',
    component: AlteracoesAssociacaoPendentesSistema,
    meta: {
      requiresAuth: true,
      title: `Sistema - Alterações de Associação Pendentes - ${titulo}`
    }
  },
  {
    path: '/sistema/vinculacao/',
    name: 'sistema-vincular-associacao',
    component: VincularAssociacao,
    meta: {
      requiresAuth: true,
      title: `Vincular Associação - ${titulo}`
    }
  },
  {
    path: '/sistema/localizar-associacao/',
    name: 'sistema-localizar-associacao',
    component: LocalizarAssociacao,
    meta: {
      requiresAuth: true,
      title: `Localizar Associação - ${titulo}`
    }
  },
  {
    path: '/sistema/reiniciar-login/',
    name: 'sistema-reiniciar-login',
    component: ReiniciarLogin,
    meta: {
      requiresAuth: true,
      title: `Reiniciar Login - ${titulo}`
    }
  },
  {
    path: '/sistema/migrar-historico/',
    name: 'sistema-migrar-historico',
    component: MigrarHistorico,
    meta: {
      requiresAuth: true,
      title: `Migrar Histórico - ${titulo}`
    }
  },
  {
    path: '/sistema/projetos-para-excluir/',
    name: 'sistema-projetos-para-excluir',
    component: ProjetosParaExcluirSistema,
    meta: {
      requiresAuth: true,
      title: `Migrar Histórico - ${titulo}`
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
});

router.beforeEach(async (to, from, next) => {
  const { title }: any = to.meta;
  document.title = title || titulo;

  // Se a url for a de recuperação, faz o logout independentemente se estiver logado ou não
  if (to.name === 'recuperar-dados' || to.name === 'cadastro-via-convite') {
    await Parse.User.logOut();
    return next();
  }

  // Se requer autenticação para acessar a url
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // Se não estiver autenticado, redireciona para a página de login
    if (Parse.User.current() === null) {
      return next({
        name: '/login',
        params: { nextUrl: to.fullPath }
      });
    }
  }

  next();
})

export default router
