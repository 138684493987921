
import { ParseObject } from "@/plugin/parse/parse.interfaces";
import { Permissao } from "@/plugin/permissoes/permissoes.interfaces";
import { Component, Prop, Vue } from "vue-property-decorator";
import Section from "@/components/ui/Section.vue";
import Button from "@/components/ui/Button.vue";
import TextoRotulado from "@/components/TextoRotulado.vue";
import Aviso from "@/components/Aviso.vue";
import EditarAssociacao from "./EditarAssociacao.vue";
import NovaAssociacao from "./NovaAssociacao.vue";
import { ASSOCIADO_ATIVO } from "@/plugin/utils/utils.plugin";

@Component({
  components: {
    Section,
    Button,
    Aviso,
    TextoRotulado,
    EditarAssociacao,
    NovaAssociacao,
  },
})
export default class PerfilAssociacoes extends Vue {
  @Prop({ default: [] })
  associacoes!: ParseObject[];

  @Prop({ default: false })
  saoMeusDados!: boolean;

  @Prop({ default: null })
  clube!: ParseObject;

  @Prop({ default: null })
  distrito!: ParseObject;

  @Prop({ default: null })
  associacaoAtual!: ParseObject;

  @Prop({ default: false })
  termosDeUsoAceitos!: boolean;

  @Prop({ default: false })
  carregando!: boolean;

  @Prop({ default: null })
  dadosUsuario!: ParseObject;

  colunasAssociacoes: any = [
    { clube: "Clube" },
    { posse: "Posse" },
    { desligamento: "Desligamento" },
    { fundador: "Fundador(a)" },
    { honorario: "Honorário(a)" },
  ];

  podeAdicionarAssociacoes = false;
  podeExcluirAssociacoes = false;
  removendoAssociacao = false;
  podeAprovarAlteraçõesAssociacoes = false;
  associacaoParaEditar: ParseObject | null = null;
  transferenciaParaAprovar: ParseObject | null = null;
  associacaoParaRemover: ParseObject | null = null;
  transferenciaParaRemover: ParseObject | null = null;

  created() {
    this.verificaPermissaoEditarAssociado();
  }

  async verificaPermissaoEditarAssociado() {
    const podeEditarAsPropriasInfos = this.saoMeusDados;

    const podeEditarInfosGerais = this.$permissoes.possuiAlguma([
      {
        permissao: Permissao.EDITAR_ASSOCIADOS_NO_PROPRIO_CLUBE,
        clube: this.clube,
      },
      {
        permissao: Permissao.EDITAR_ASSOCIADOS_EM_CLUBES_DO_PROPRIO_DISTRITO,
        distrito: this.distrito,
      },
      {
        permissao: Permissao.EDITAR_ASSOCIADOS_DE_QUALQUER_CLUBE,
      },
    ]);

    this.podeAdicionarAssociacoes =
      podeEditarInfosGerais || podeEditarAsPropriasInfos;

    this.podeExcluirAssociacoes =
      this.podeAdicionarAssociacoes && this.associacoes.length > 1;

    this.podeAprovarAlteraçõesAssociacoes = podeEditarInfosGerais;

    if (this.podeAprovarAlteraçõesAssociacoes || this.saoMeusDados) {
      this.colunasAssociacoes.push({ editar: "Ações" });
    }
  }

  async aprovarAlteracaoAssocaicao(associacao: ParseObject) {
    if (!this.podeAprovarAlteraçõesAssociacoes) return;

    const alteracao = associacao.get("alteracao");

    const vinculo =
      alteracao.get("vinculo") && alteracao.get("vinculo").get("nome")
        ? `<li>Clube: 
            <s class="me-2">${associacao.get("clube")?.get("nome")}</s>
            <b>${alteracao.get("vinculo")?.get("nome")}</b>
          </li>`
        : "";
    const posse = alteracao.get("posse")
      ? `<li>Posse: 
            <s class="me-2">${associacao
              .get("posse")
              ?.toLocaleDateString("pt-BR")}</s>
            <b>${alteracao.get("posse")?.toLocaleDateString("pt-BR")}</b>
          </li>`
      : "";
    const desligamento = alteracao.get("desligamento")
      ? `<li>Desligamento: 
            <s class="me-2">${associacao
              .get("desligamento")
              ?.toLocaleDateString("pt-BR")}</s>
            <b>${alteracao.get("desligamento")?.toLocaleDateString("pt-BR")}</b>
         </li>`
      : "";
    const honorario =
      alteracao.get("honorario") !== undefined
        ? `<li>Honorario: 
            <s class="me-2">${associacao.get("honorario") ? "Sim" : "Não"}</s>
            <b>${alteracao.get("honorario") ? "Sim" : "Não"}</b>
           </li>`
        : "";
    const fundador =
      alteracao.get("fundador") !== undefined
        ? `<li>Fundador: 
            <s class="me-2">${associacao.get("fundador") ? "Sim" : "Não"}</s>
            <b>${alteracao.get("fundador") ? "Sim" : "Não"}</b>
           </li>`
        : "";

    const confirmado = await this.$notificacao.confirmar({
      titulo: "Deseja aprovar esta alteração?",
      html: `
        <div class="text-start">
          <p class="fw-bold">Os seguintes dados serão alterados:</p>
          <ul>
            ${vinculo}
            ${posse}
            ${desligamento}
            ${honorario}
            ${fundador}
          </ul>
          <p class="fw-bold">Justificativa:</p>
          <p class="mb-3">${alteracao.get("justificativa")}</>
        </div>
      `,
    });

    confirmado
      ? await this.$utils.aprovarAlteracoesAssociacao(associacao)
      : await this.$utils.recusarAlteracoesAssociacao(associacao);

    // Força o Vue a renderizar as atualizações.
    this.associacoes = [...this.associacoes];
  }

  inserirAssociacao(associacao: ParseObject) {
    this.associacoes = [associacao, ...this.associacoes];
  }

  async removerAssociacao() {
    if (!this.associacaoParaRemover) return;

    if (this.associacaoParaRemover.get("statusAssociacao") == ASSOCIADO_ATIVO) {
      this.$notificacao.popup({
        texto: "Não é possível remover uma associacao ativa.",
      });

      return;
    }

    this.removendoAssociacao = true;
    const associacaoRemovida = await this.$parse.deleteObject(
      this.associacaoParaRemover
    );

    if (associacaoRemovida.id)
      this.associacoes = this.associacoes.filter(
        (a) => a.id !== associacaoRemovida.id
      );

    this.removendoAssociacao = false;
    this.associacaoParaRemover = null;
    this.associacaoParaEditar = null;
  }

  async removeTransferencia() {
    if (!this.transferenciaParaRemover) return;
    this.associacoes = this.associacoes.filter(
      (associacao) => associacao.id !== this.transferenciaParaAprovar?.id
    );
  }

  atualizarTabela() {
    this.associacoes = [...this.associacoes];
  }
}
