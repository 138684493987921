import Swal from "sweetalert2";
import { Component, Vue } from "vue-property-decorator";
import { IAssociacoesOuUsuariosPorStatus, IRetornoBuscarAssociacoesClube, TStatusClubes } from "../parse/cloud.interfaces";
import { ParseObject, ParseQuery, SimpleQuery } from "../parse/parse.interfaces";
import { Permissao } from "../permissoes/permissoes.interfaces";
import { IVueFormulateOption } from "./utils.interfaces";
// const html_to_pdf = require('html-pdf-node');

export interface IErroForm {
    [field: string]: {
        errors?: string[];
        hasErrors?: boolean;
        name?: string;
    };
}
type statusAssociacao = 'ativos' | 'desligados' | 'honorarios' | 'fundadores';
type tiposRetornos = statusAssociacao | 'todos';
export type RetornoAssociacoes = {
    [status in tiposRetornos]: ParseObject[]
}

// Tipos de clube
export type CLUBE_COMUNITARIO = 1;
export const CLUBE_COMUNITARIO = 1;
export type CLUBE_UNIVERSITARIO = 2;
export const CLUBE_UNIVERSITARIO = 2;

// Status do clube
export type CLUBE_ATIVO = 1;
export const CLUBE_ATIVO = 1;
export type CLUBE_DESATIVADO = 0;
export const CLUBE_DESATIVADO = 0;

export const ASSOCIADO_ATIVO = 1;
export const ASSOCIADO_DESLIGADO = 0;

// export const CARGO_OMIR = 2;
// export const CARGO_DISTRITAL = 1;
// export const CARGO_CLUBE = 0;

export const CARGO_NO_CLUBE = 3;
export const CARGO_NO_DISTRITO = 2;
export const CARGO_NA_ROTARACTBR = 1;


const tipoCargos = {
    rotaractbr: 1,
    distrito: 2,
    clube: 3
}

type TipoCargos = typeof tipoCargos;

const cargos = {
    presidencia: 1,
    rdr: 22,
}

type Cargos = typeof cargos;

export interface INormalizaRedesSociais {
    /**
     * Objeto contendo as redes sociais salvas do usuário, clube ou distrito. 
     * 
     * Possui a chave como o nome da rede social e o valor com o link para a rede sociail do usuário, clube ou distrito.
     * 
     * @example
     * {
     *     twitter: 'http://www.twitter.com/rotaractbrasil'
     * }
     * @param redesSociais
     */
    redesSociais: { [campo: string]: string };

    /**
     * Remove do retorno da função os objetos que forem listados. 
     * 
     * @example
     * redeSociais: {
     *     twitter: 'http://www.twitter.com/rotaractbrasil'
     *     linkedin: 'http://www.linkedin.com/in/rotaractbrasil'
     * }
     * ignore: ['linkedin']
     * 
     * // A rede 'linkedin' não será retornada no objeto final.
     */
    ignore: string[];
}

export interface IParametrosProjeto {
    categorias: any;
    areasDeEnfoque: any;
    envolvidos: any;
    faixasEtarias: any;
    grausDeDificuldade: any;
}
@Component
export default class UtilsPlugin extends Vue {
    private _redesSociais: any;

    private _selectCategoriaProjeto: { value: string, label: string, id: string }[] = [];
    private _categoriasProjeto: { [id: string]: ParseObject } = {}

    private _selectAreasDeEnfoque: { value: string, label: string, id: string }[] = [];
    private _areasDeEnfoque: { [id: string]: ParseObject } = {}

    private _parametrosProjeto: any = null;
    private _usuariosPorClube: {
        [clubeId: string]: {
            associacoes: RetornoAssociacoes
            usuarios: RetornoAssociacoes
        }
    } = {};
    private _cargosPorInstanciaNome: any = {};
    private _cargosPorInstancia: any = {};
    private _cargosSistema: any = {};

    redesSociaisExcluidas = ['github']

    async getMeusDados(): Promise<ParseObject | void> {
        if (!this.$parse.usuarioLogado) return;

        let meusDados = this.$cache.obter('meus-dados');

        if (!meusDados) {
            meusDados = await this.$parse.getFirst({
                className: 'DadosUsuario',
                where: [['usuario', '=', this.$parse.usuarioLogado]],
                include: ['*']
            }).catch(e => this.$notificacao.mensagemErro(e));
            this.$cache.salvar('meus-dados', meusDados);
        }

        return meusDados
    }

    async getMinhaAssociacao() {
        if (!this.$parse.usuarioLogado) return;

        let minhaAssociacao = this.$cache.obter('minha-associacao');

        if (!minhaAssociacao) {
            const meusDados = await this.getMeusDados();
            if (!meusDados) return;

            minhaAssociacao = await this.$parse
                .getById('Associado', meusDados.get('associacaoAtual')?.id)
                .catch((e: Error) => this.$notificacao.mensagemErro(e));

            this.$cache.salvar('minha-associacao', minhaAssociacao);
        }

        return minhaAssociacao
    }

    async getMinhasAssociacoes() {
        if (!this.$parse.usuarioLogado) return;

        let minhasAssociacoes = this.$cache.obter('minhas-associacoes');

        if (!minhasAssociacoes) {
            const meusDados = await this.getMeusDados();

            if (!meusDados) return;

            minhasAssociacoes = await this.$parse
                .getList({
                    className: "Associado",
                    where: [["usuario", "=", meusDados]],
                    include: ["clube", "clube.distrito", "alteracao", "alteracao.clube"],
                    orderBy: "posse",
                    descending: true,
                }).catch((e: Error) => this.$notificacao.mensagemErro(e));

            this.$cache.salvar('minhas-associacoes', minhasAssociacoes);
        }

        return minhasAssociacoes
    }

    async getMeuClube() {

        let meuClube = this.$cache.obter('meu-clube');

        if (!meuClube) {
            const minhaAssociacao = await this.getMinhaAssociacao();

            if (!minhaAssociacao) return;

            meuClube = await this.$parse
                .getById('Clube', minhaAssociacao.get("clube").id)
                .catch((e: Error) => this.$notificacao.mensagemErro(e));
        }

        return meuClube;
    }

    async getMeuDistrito() {

        let meuDistrito = this.$cache.obter('meu-distrito');

        if (!meuDistrito) {

            const meuClube = await this.getMeuClube()

            if (!meuClube) return;

            meuDistrito = await this.$parse
                .getById('Distrito', meuClube.get("distrito").id)
                .catch((e: Error) => this.$notificacao.mensagemErro(e));
        }

        return meuDistrito;
    }

    async listarClubesDistrito(distrito: ParseObject) {
        const exibirClubesDesativados = this.$permissoes.possui(
            Permissao.TI_CADASTRAR_ASSOCIADOS_EM_CLUBES_DESATIVADOS
        );

        const statusClubes: TStatusClubes | null = exibirClubesDesativados
            ? null
            : "ativos";

        let clubes = this.$cache.obter(
            `clubes-${statusClubes}-${distrito.id}`
        );

        if (!clubes) {
            clubes = await this.$cloud.buscarClubesDistrito({
                id: distrito.id,
                statusClubes,
            });

            this.$cache.salvar(
                `clubes-${statusClubes}-${distrito.id}`,
                clubes
            );
        }

        return clubes || []
    }


    private async carregaAssociacoesEUsuarios(idClube: string) {
        const emCache = this.$cache.todosEmCache([
            `associacoes-${idClube}`,
            `usuarios-${idClube}`
        ]);

        if (!emCache) {
            const { associacoes, usuarios } = await this.$cloud.buscarAssociacoesClube(idClube);
            this.$cache.salvar(`associacoes-${idClube}`, associacoes);
            this.$cache.salvar(`usuarios-${idClube}`, usuarios);
        }
    }

    async getAssociadosDoClube(
        idClube: string,
        status: 'ativos' | 'desligados' | 'honorarios' | 'fundadores' | 'todos' = 'ativos',
    ): Promise<ParseObject[] | RetornoAssociacoes> {
        if (!this.$cache.emCache(`associacoes-${idClube}`))
            await this.carregaAssociacoesEUsuarios(idClube);

        const associacoes = this.$cache.obter(
            `associacoes-${idClube}`
        );

        return associacoes[status];
    }

    async getUsuariosDoClube(
        idClube: string,
        status: 'ativos' | 'desligados' | 'honorarios' | 'fundadores' | 'todos' = 'ativos',
    ): Promise<ParseObject[] | RetornoAssociacoes> {
        if (!this.$cache.emCache(`usuarios-${idClube}`))
            await this.carregaAssociacoesEUsuarios(idClube);

        const usuarios = this.$cache.obter(
            `usuarios-${idClube}`
        );

        return usuarios[status];
    }

    /**
     * Retorna as redes sociais disponíveis no sistema, caso elas não estejam carregadas ainda, 
     * busca no parse todas as redes sociais disponíveis na classe RedeSocial.
     * 
     * Para adicionar novas, basta criar um novo objeto RedeSocial. 
     * 
     * @returns {Parse.Object[]} Uma promessa de lista com todas as redes sociais cadastradas no sistema.
     */
    async getTodasRedesSociais(): Promise<ParseObject[]> {

        if (this._redesSociais) return this._redesSociais; // Se já estiver armazenado na sessão

        this._redesSociais = await this.$parse.getList({
            className: "RedeSocial",
        }).catch(e => this.$notificacao.mensagemErro(e));

        return this._redesSociais;
    }

    /**
     * Esta função transforma o objeto redesSociais em uma lista contento todas as redes sociais disponíveis no sistema. 
     * 
     * O objeto retornado possui no campo valor as redes existentes.
     * 
     * Isso é utilizado nos formulários onde o usuário preenche as redes sociais dele, do clube ou do distrito, 
     * garantindo que sempre que houver uma atualização das redes sociais, os objetos salvos serão sempre os mesmos para todo o sistema.
     * 
     * @example
     * // O objeto redes sociais possui apenas o facebook salvo.
     * redesSociais: {
     *     facebook: 'http://www.facebook.com/rotaractbrasil'
     * }
     * 
     * // O resultado será:
     * [
     *     {nome: 'Facebook', campo: 'facebook', valor: 'http://www.facebook.com/rotaractbrasil', placeholder: 'http://www.facebook.com/' }
     *     {nome: 'Instagram', campo: 'instagram', valor: '', placeholder: 'http://www.instagram.com/' }
     *     {nome: 'Twitter', campo: 'twitter', valor: '', placeholder: 'http://www.facebook.com/' }
     *     {nome: 'Linkedin', campo: 'linkedin', valor: '', placeholder: 'http://www.linkedin.com/' }
     *     {nome: 'Youtube', campo: 'youtube', valor: '', placeholder: 'http://www.youtube.com/' }
     * ]
     * 
     * @param {INormalizaRedesSociais} params Objeto contendo as redes sociais do clube (nome:link) e uma lista (opcional) de redes que devem ser ignoradas no processo.
     * @returns A promessa de uma lista de redes sociais contendo {nome, campo, valor, placeholder}
     */
    async normalizaRedesSociais(params: INormalizaRedesSociais): Promise<any[]> {
        const { redesSociais, ignore } = params
        const camposRedesSociais: any[] = [];

        // Busca as redes sociais padrões do tipo RedeSocial no back4app
        const todasRedesSociais = await this.getTodasRedesSociais();

        todasRedesSociais.forEach((redeSocial: ParseObject) => {
            const nome = redeSocial?.get("nome");
            const campo = redeSocial?.get("campo");
            if (ignore?.includes(nome) || ignore?.includes(campo)) return;
            const valor = redesSociais[campo] || null;
            const placeholder = redeSocial?.get("link");
            camposRedesSociais.push({ nome, campo, valor, placeholder });
        });
        return camposRedesSociais;
    }

    async getRedesSociais(): Promise<any[]> {
        const camposRedesSociais: any[] = [];

        // Busca as redes sociais padrões do tipo RedeSocial no back4app
        const todasRedesSociais = await this.getTodasRedesSociais();

        todasRedesSociais.forEach((redeSocial: ParseObject) => {
            const nome = redeSocial?.get("nome");
            const campo = redeSocial?.get("campo");
            if (this.redesSociaisExcluidas?.includes(nome) || this.redesSociaisExcluidas?.includes(campo)) return;
            const placeholder = redeSocial?.get("link");
            const valor = ''
            camposRedesSociais.push({ nome, campo, valor, placeholder });
        });
        return camposRedesSociais;
    }

    /**
     * Retorna uma string com o ano rotário que a data faz parte.
     * 
     * Se nenhuma data for fornecida, será retornado o ano rotário atual.
     * 
     * Para retornar o ano rotário anterior e o próximo, basta passar como parametro as strings 'anterior' e 'proximo' respectivamente.
     * 
     * @example
     * // Para o ano rotario atual 
     * const anoRotarioAtual = this.$utils.getAnoRotario();
     * 
     * // Para o ano rotario anterior 
     * const anoRotarioAnterior = this.$utils.getAnoRotario('anterior');
     * 
     * // Para o próximo ano rotario.
     * const anoRotarioSeguinte = this.$utils.getAnoRotario('proximo');
     * 
     * // Para o ano rotario de uma data qualquer.
     * const data = new Date(2014, 06, 08);
     * const anoRotario = this.$utils.getAnoRotario(data);
     * 
     * @param {Date} data Data a qual deseja extrair o ano rotário, ou os parametros 'anterior' ou 'proximo'
     * @returns {String} O ano rotário no formato de uma string
     */
    getAnoRotario(data?: Date | 'anterior' | 'proximo'): string {
        // Se nenhuma data for fornecida, ou se for os textos 'anterior' e 'proximo'
        const dataFinal = data && typeof data !== 'string' ? data : new Date();

        if (data == 'anterior') dataFinal.setFullYear(dataFinal.getFullYear() - 1);
        if (data == 'proximo') dataFinal.setFullYear(dataFinal.getFullYear() + 1);

        const inicioDoAno = dataFinal.getMonth() > 5
        return inicioDoAno
            ? `${dataFinal.getFullYear()}-${String(dataFinal.getFullYear() + 1).slice(2)}`
            : `${dataFinal.getFullYear() - 1}-${String(dataFinal.getFullYear()).slice(2)}`
    }

    getIntervaloAnosRotarios(inicio: Date, termino?: Date): string[] {

        if (!termino) termino = new Date();

        const dataInicio = new Date(inicio.getFullYear(), 7, 1);
        const dataTermino = new Date(inicio.getFullYear() + 1, 6, 0);

        const anosRotarios = []

        while (dataInicio.getFullYear() <= termino.getFullYear()) {
            anosRotarios.unshift(this.getAnoRotario(dataInicio))
            dataInicio.setFullYear(dataInicio.getFullYear() + 1);
            dataTermino.setFullYear(dataTermino.getFullYear() + 1);
        }

        return anosRotarios;
    }

    getDatasDoAnoRotario(anoRotario: string): { inicio: Date, termino: Date } {
        const anoInicial = Number(anoRotario.split('-')[0]);
        const inicio = new Date(anoInicial, 7, 1, 0, 0, 0, 0);
        const termino = new Date(anoInicial + 1, 6, 0, 23, 59, 59, 59);
        return { inicio, termino }
    }

    /**
     * Essa função retorna os parametros disponíveis em um projeto em um único objeto, com:
     * 
     * * as categorias de um projeto;
     * * as áreas de enfoque;
     * * as faixas etárias do público alvo;
     * * as opções de envolvidos para a parte de quem trabalhou no projeto e
     * * os níveis de dificuldade.
     * @returns Um objeto do tipo IParametrosProjeto
     */
    async getParametrosProjeto(): Promise<IParametrosProjeto> {
        if (this._parametrosProjeto) return this._parametrosProjeto; // Se já estiver armazenado na sessão

        const [categoriasProjeto, parametrosProjeto] = await Promise.all([
            this.$parse.getList({ className: "CategoriaProjeto" }).catch(e => this.$notificacao.mensagemErro(e)),
            this.$parse.getList({ className: "ParametroProjeto" }).catch(e => this.$notificacao.mensagemErro(e))
        ])

        this._selectCategoriaProjeto = [];
        this._selectAreasDeEnfoque = [];
        this._categoriasProjeto = {};
        this._areasDeEnfoque = {};

        categoriasProjeto?.forEach((categoria: ParseObject) => {
            const atributo = categoria.get('atributo');
            const objCategoria = {
                value: categoria.id,
                label: categoria.get('nome'),
                id: categoria.id
            };

            if (atributo === 'categoria') {
                this._selectCategoriaProjeto.push(objCategoria);
                this._categoriasProjeto[categoria.id] = categoria;
            }
            if (atributo === 'areaDeEnfoque') {
                this._selectAreasDeEnfoque.push(objCategoria);
                this._areasDeEnfoque[categoria.id] = categoria
            }
        });

        this._parametrosProjeto = <IParametrosProjeto>{
            categorias: this._selectCategoriaProjeto,
            areasDeEnfoque: this._selectAreasDeEnfoque,
            grausDeDificuldade: [],
            envolvidos: [],
            faixasEtarias: [],
        };

        parametrosProjeto.forEach((parametro: ParseObject) => {
            const atributo: any = parametro.get('atributo');

            if (atributo === 'faixaEtaria')
                this._parametrosProjeto.faixasEtarias.push({
                    id: parametro.get('ID'),
                    value: false,
                    label: parametro.get('nome'),
                })

            if (atributo === 'envolvidos')
                this._parametrosProjeto.envolvidos.push({
                    id: parametro.get('ID'),
                    value: 0,
                    label: parametro.get('nome'),
                })

            if (atributo === 'dificuldade')
                this._parametrosProjeto.grausDeDificuldade.push({
                    id: parametro.get('ID'),
                    value: Number(parametro.get('ID')),
                    label: parametro.get('nome'),
                })
        });

        return { ...this._parametrosProjeto };
    }

    /**
     * Retorna uma lista de objetos formatados para serem utilizados como option para o VueFormulateInput do tipo select. 
     * 
     * @param itens Lista de objetos que serão formatados
     * @param label Atributo do objeto que será usado como label
     * @param id Atributo do objeto que será usado como id, se nenhum for passado, será usado a id do objeto.
     * @returns Uma lista de objetos com valor, label e id
     */
    converteParaVFOptions(itens: ParseObject[], args: IVueFormulateOption, orderBy?: string): IVueFormulateOption[] {
        const mappedItems = itens.map(item => this.converteParaVFOption(item, args));
        if (orderBy) return mappedItems.sort((a, b) => { if (a.label && b.label) return a.label.localeCompare(b.label); return 0 });
        return mappedItems;
    }

    /**
     * Retorna um objeto formatado para ser utilizado como option para o Vue FormulateInput do tipo select. 
     * 
     * @param item Objeto a ser formatado
     * @param label Atributo do objeto que será usado como label
     * @param id Atributo do objeto que será usado como id, se nenhum for passado, será usado a id do objeto.
     * @returns Uma lista de objetos com valor, label e id
     */
    converteParaVFOption(item: ParseObject, args: IVueFormulateOption): IVueFormulateOption {
        const { label = 'objectId', fnLabel, id, attrs, disabled, value, defaultValue } = args || {};
        return {
            value: defaultValue
                ? defaultValue
                : (value
                    ? (value == 'id'
                        ? item?.id
                        : item?.get(value))
                    : item
                ),
            label: fnLabel ? fnLabel(item) : item?.get(label),
            id: id ? item?.get(id) : item?.id,
            disabled,
            attrs,
        }
    }

    /**
     * Busca com base em uma id a categoria referente a ela.
     *
     * @param idCategoria Id do objeto categoria 
     * @returns Um Parse.Object referente a categoria com a id fornecida
     */
    async getCategoriaProjeto(idCategoria: string) {
        if (!this._categoriasProjeto[idCategoria]) await this.getParametrosProjeto();
        return this._categoriasProjeto[idCategoria];
    }

    /**
     * Busca com base em uma id a categoria referente a ela.
     *
     * @param idAreaDeEnfoque Id do objeto categoria 
     * @returns Um Parse.Object referente a categoria com a id fornecida
     */
    async getAreaDeEnfoque(idAreaDeEnfoque: string) {
        if (!this._areasDeEnfoque[idAreaDeEnfoque]) await this.getParametrosProjeto();
        return this._areasDeEnfoque[idAreaDeEnfoque];
    }

    /**
     * Busca o objeto do tipo Cargo referente a instância fornecida e com o a ID registrada no nome fornecido.
     * 
     * O nome é uma chave do objeto Cargos, que armazena a ID referente aquele nome. Ex.: 'presidencia' possui a id 1.
     * 
     * A instância é uma chave do objeto TipoCargos, que armazena o tipo referente a instância. Ex.: 'clube' possui o tipo 0.
     * 
     * Os cargos são armazenados localmente em um objeto {instancia: {cargo: Parse.Object}}
     * 
     * @param {keyof Cargos} nome Nome do cargo (dentro dos parametros em Cargos)
     * @param {keyof TipoCargo} instancia Instância do cargo (clube, distrito, rotaractbr)
     * @returns Um objeto do tipo Parse da classe Cargo.
     */
    async getCargo(nome: keyof Cargos, instancia: keyof TipoCargos) {

        // Primeiro verifica se o objeto já está em cache.
        if (this._cargosPorInstanciaNome && this._cargosPorInstanciaNome[instancia] && this._cargosPorInstanciaNome[instancia][nome]) return this._cargosPorInstanciaNome[instancia][nome];

        // Se o objeto cache _cargos não estiver inicializado ainda, realiza a inicialização.
        if (!this._cargosPorInstanciaNome) this._cargosPorInstanciaNome = {}
        if (!this._cargosPorInstanciaNome[instancia]) this._cargosPorInstanciaNome[instancia] = {}

        // Busca o cargo no banco.
        const cargo = await this.$parse.getFirst({
            className: 'Cargo',
            where: [['ID', '=', cargos[nome]]]
        }).catch(e => this.$notificacao.mensagemErro(e));

        // Salva na cache e retorna o objeto.
        this._cargosPorInstanciaNome[instancia][nome] = cargo;
        return cargo;
    }

    /**
     * 
     * @param {keyof TipoCargo} instancia Instância do cargo (clube, distrito, rotaractbr)
     */
    async getCargos(instancia: keyof TipoCargos) {

        // Primeiro verifica se o objeto já está em cache.
        if (this._cargosPorInstancia && this._cargosPorInstancia[instancia]) return this._cargosPorInstancia[instancia];

        // Se o objeto cache _cargos não estiver inicializado ainda, realiza a inicialização.
        if (!this._cargosPorInstancia) this._cargosPorInstancia = {}

        // Busca o cargo no banco.
        const cargosSistema = await this.getCargosSistema('ativos');

        this._cargosPorInstancia['clube'] = cargosSistema.filter((cargo: ParseObject) => cargo.get('clube'));
        this._cargosPorInstancia['distrito'] = cargosSistema.filter((cargo: ParseObject) => cargo.get('distrito'));
        this._cargosPorInstancia['rotaractbr'] = cargosSistema.filter((cargo: ParseObject) => cargo.get('rotaractbr'));

        return this._cargosPorInstancia[instancia];
    }

    /**
     * 
     * @returns 
     */
    async getCargosSistema(status?: 'ativos' | 'todos'): Promise<ParseObject[]> {
        if (!status) status = 'todos';

        if (!this._cargosSistema) this._cargosSistema = {
            ativos: [],
            todos: [],
        }

        if (this._cargosSistema[status].length > 0) return this._cargosSistema[status];

        const todos = await this.$parse.getList({
            className: 'Cargo',
            limit: 500 // Por padrão o parse retorna somente 100
        }).catch(e => this.$notificacao.mensagemErro(e));

        this._cargosSistema.todos = [...todos];

        this._cargosSistema.ativos = todos.filter((cargo: ParseObject) => cargo.get('ativo'));

        return this._cargosSistema[status];
    }

    async exportarPDF(projeto: any): Promise<any> {
        const options = { format: 'A4' };
        const file = [{ url: "https://example.com", name: 'example.pdf', content: "<h1>Welcome to html-pdf-node</h1>" }];
        // return await html_to_pdf.generatePDFs(file, options);
    }


    async verificaSeSaoMeusDados(dadosUsuario: ParseObject): Promise<boolean> {
        const meusDados = await this.getMeusDados();
        return meusDados?.id === dadosUsuario.id;
    }

    async verificaSeEMeuClube(clube: ParseObject) {
        const meuCLube = await this.getMeuClube();
        return meuCLube.id === clube.id;
    }

    async verificaSeEMeuDistrito(distrito: ParseObject) {
        const meuDistrito = await this.getMeuDistrito();
        return meuDistrito.id === distrito.id;
    }

    /**
     * Devido a ausência de dados consistêntes no banco de dados antigo, não basta apenas olhar se o associado possui ou não a data de desligamento.
     * 
     * Por isso, esse mêtodo verifica se o associado está ativo ou não.
     */
    async verificaSeAssociadoAtivo(associado: ParseObject) {
        if (!associado) return false;
        const associadoAtivo = associado.get("statusAssociacao") === 1;
        const associadoDesligado = associado.get("statusAssociacao") === 0;
        /**
         * Alguns usuários podem ter a data de desligamento mesmo estando ativos.
         * 
         * Isso acontece porque no portal antigo os dados de desligamento estavam inconsistentes, por isso é necessario verificar dessa forma.
         */
        const semStatusMasTemDataDeDesligamentoOuHonorario =
            !associado.get("statusAssociacao") &&
            (associado.get("desligamento") || associado.get("honorario"));

        return !associado.get("transferencia") && (associadoAtivo || !semStatusMasTemDataDeDesligamentoOuHonorario)
    }

    readFile(file: File): Promise<any> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.readAsDataURL(file);

            reader.onload = (res: any) => resolve(res.target.result);

            reader.onerror = (err) => reject(err);
        });
    }

    async aprovarAlteracoesAssociacao(associacao: ParseObject, alteracao?: ParseObject) {
        return this.$notificacao.confirmarEEsperar({
            titulo: "Aprovar",
            texto: "Tem certeza que deseja aprovar as alterações nesta associação?",
            confirmou: async () => {
                const alteracoesAssociacao = alteracao ?? associacao.get("alteracao");
                const alteracoes: any = {};
                if (alteracoesAssociacao.get("posse") !== undefined)
                    alteracoes.posse = alteracoesAssociacao.get("posse");

                if (alteracoesAssociacao.get("honorario") !== undefined)
                    alteracoes.honorario = alteracoesAssociacao.get("honorario");

                if (alteracoesAssociacao.get("posseHonorario") !== undefined)
                    alteracoes.posseHonorario = alteracoesAssociacao.get("posseHonorario");

                if (alteracoesAssociacao.get("fundador") !== undefined)
                    alteracoes.fundador = alteracoesAssociacao.get("fundador");

                if (alteracoesAssociacao.get("desligamento") !== undefined)
                    alteracoes.desligamento = alteracoesAssociacao.get("desligamento");

                if (alteracoesAssociacao.get("statusAssociacao") !== undefined)
                    alteracoes.statusAssociacao =
                        alteracoesAssociacao.get("statusAssociacao");

                if (alteracoesAssociacao.get("vinculo") !== undefined)
                    alteracoes.clube = alteracoesAssociacao.get("vinculo");

                const sucesso = await this.$parse
                    .updateObject(associacao, {
                        ...alteracoes,
                        alteracao: undefined,
                    })
                    .catch((e) => this.$notificacao.mensagemErro(e));

                if (!sucesso) return

                return this.$parse
                    .updateObject(alteracoesAssociacao, { pendente: false })
                    .catch((e) => this.$notificacao.mensagemErro(e));
            }
        });
    }

    async recusarAlteracoesAssociacao(alteracoesAssociacao: ParseObject) {
        return this.$notificacao.confirmarEEsperar({
            titulo: "Recusar",
            texto: "Tem certeza que deseja recusar as alterações nesta associação?",
            confirmou: async () => {
                const associacao = alteracoesAssociacao.get("associacao");
                const sucesso = await this.$parse
                    .updateObject(associacao, { alteracao: undefined })
                    .catch((e) => this.$notificacao.mensagemErro(e));

                if (!sucesso) return

                return this.$parse
                    .updateObject(alteracoesAssociacao, {
                        pendente: false, recusado: true,
                    })
                    .catch((e) => this.$notificacao.mensagemErro(e));
            },
        });
    }

    capitalizarFrase(frase: string) {
        return frase.split(" ").map(palavra => palavra.charAt(0).toUpperCase() + palavra.slice(1)).join(' ');
    }

    dataSemFuso(date?: string) {
        if (date) return new Date(`${date} 12:00:00`)

        const hoje = new Date();
        hoje.setHours(12, 0, 0, 0);
        return hoje
    }

    difEntreDatas(data1: Date, data2: Date, unidade: 'dias' | 'hrs' | 'min' | 'seg' = 'min'): Number {

        const df = Math.abs(data2.getTime() - data1.getTime());

        const umDia = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
        const umaHr = 60 * 60 * 1000; // minutes*seconds*milliseconds
        const umMin = 60 * 1000; // seconds*milliseconds
        const umMs = 1000; // milliseconds

        const dias = Math.round(df / umDia)
        const horas = Math.round(df / umaHr)
        const minutos = Math.abs(Math.round(df / umMin))
        const segundos = Math.abs(Math.round(df / umMs))

        if (unidade == 'dias') return dias
        if (unidade == 'hrs') return horas
        if (unidade == 'min') return minutos
        if (unidade == 'seg') return segundos

        return -1
    }
}